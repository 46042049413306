import styled from 'styled-components';

const Styled = {
  Container: styled.div`
    width: 100%;
    max-width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 14px 19px 22px 19px;
    background: #ffffff;
    border: 0.5px solid #c0ced6;
    box-shadow: 0px 1px 1px rgba(0, 51, 102, 0.05);
    border-radius: 12px;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0px;
    }

    @media ${({ theme }) => theme.devices.desktop} {
      gap: 15px;
      max-width: 480px;
    }
  `,
  WrapperIcon: styled.div`
    @media ${({ theme }) => theme.devices.desktop} {
      max-height: 64px;
    }
  `,
  WrapperProgressContent: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  WrapperContent: styled.div``,
  WrapperTitle: styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
  `,
  Title: styled.h3`
    font-weight: 400;
    font-size: 16px;
    line-height: 175%;
    letter-spacing: -0.01em;
    color: #63696e;

    @media ${({ theme }) => theme.devices.desktop} {
      font-size: 28px;
      line-height: 140%;
      letter-spacing: -0.02em;
    }
  `,
  Points: styled.span`
    font-weight: 700;
    font-size: 16px;
    line-height: 175%;
    letter-spacing: -0.01em;
    color: #44474b;

    @media ${({ theme }) => theme.devices.desktop} {
      font-weight: 700;
      font-size: 28px;
      line-height: 140%;
      letter-spacing: -0.02em;
      color: #44474b;
    }
  `,
  Description: styled.p`
    font-weight: 400;
    font-size: 12px;
    line-height: 13px;
    color: #70767c;
    letter-spacing: -0.01em;

    span {
      text-transform: capitalize;
      font-weight: bold;
    }

    @media ${({ theme }) => theme.devices.desktop} {
      font-size: 16px;
      line-height: 175%;
    }
  `,
};

export default Styled;
