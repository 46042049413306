import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

// Hooks
import useIsMobile from 'infrastructure/hooks/IsMobile';

// Components
import Icon from 'presentation/assets/icons';
import ButtonIcon from 'presentation/components/atoms/ButtonIcon';
import Title from 'presentation/components/atoms/Title';

// Defs
import { GoBackProps } from './GoBack.def';

// Styled
import Styled from './GoBack.style';

const GoBack = ({
  pageTitle,
  onClick,
  onClickTitle,
  arrow = true,
}: GoBackProps) => {
  const isMobile = useIsMobile();
  const history = useHistory();
  const size = useMemo(() => (isMobile ? 'small' : 'medium'), [isMobile]);

  return (
    <Styled.GoBackSection>
      {arrow && (
        <ButtonIcon
          size="S"
          icon={<Icon name="back" />}
          onClick={() => (onClick ? onClick() : history.goBack())}
        />
      )}

      <Title level="1" size={size} onClick={onClickTitle}>
        {pageTitle}
      </Title>
    </Styled.GoBackSection>
  );
};

export default GoBack;
