import { IRichtext, NoRichText } from 'domain/model/content';

const RichTextMapper = (json: any): IRichtext => {
  try {
    const { nodeType } = json;

    if (nodeType !== 'document') throw new Error();

    return json;
  } catch (error) {
    console.warn(
      '[RichTextMapper] The content json structure received differed from expected',
    );

    return NoRichText;
  }
};

export default RichTextMapper;
