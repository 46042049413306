import { AxiosResponse, CancelTokenSource } from 'axios';

// Infrastructure
import { ResolutionType } from 'domain/model/content';
import {
  CountryResolutionFormTypes,
  ResolutionFormType,
} from 'presentation/components/organisms/Resolution/FormModal/FormSteps/CategoryForm/CategoryForm.defs';

// Client

import { httpClient, token } from '../../client';

// Definitions
import { ExtendConfig } from '../../definitions';
import { ComplaintResponse, ResolutionResponseData } from './helpCenter.defs';

// Dynamic token source
let source: CancelTokenSource;

const helpCenter = {
  resolution: (
    authToken: string | undefined,
    resolutionKey: ResolutionType,
    isBank: boolean,
    intentMessage: string,
  ): Promise<AxiosResponse<ResolutionResponseData>> => {
    source = token.source();

    const url = `v2/resolution/${resolutionKey}`;

    const headers = {
      'x-isBank': isBank,
    };

    return httpClient.get<ResolutionResponseData>(url, {
      params: {
        intent: intentMessage,
      },
      authToken,
      cancelToken: source.token,
      headers,
    } as ExtendConfig);
  },
  complaint: (
    authToken: string | undefined,
    request: CountryResolutionFormTypes,
    keyCategoryForm: ResolutionFormType,
  ): Promise<AxiosResponse<ComplaintResponse>> => {
    source = token.source();
    const { files, ...rest } = request;

    const formData = new FormData();

    if (files && files.length > 0) {
      for (const file of files) {
        formData.append('files', file);
      }
    }

    formData.append(
      'fields',
      JSON.stringify({ ...rest, formType: keyCategoryForm }),
    );

    return httpClient.post<ComplaintResponse>('v1/complaint', formData, {
      authToken,
      cancelToken: source.token,
    } as ExtendConfig);
  },
};

export default helpCenter;
