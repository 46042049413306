import { IHelpCenter } from 'domain/model/content';

import Contentful from 'infrastructure/services/loyalty/resources/content';

const LoadsHelpCenter = async (): Promise<IHelpCenter | undefined> => {
  try {
    const resp = await Contentful.helpCenter();

    const { data } = resp?.data?.data;

    return data;
  } catch {
    return undefined;
  }
};

export default LoadsHelpCenter;
