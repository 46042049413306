import React from 'react';

// Components
import Icon from 'presentation/assets/icons';
import { Paragraph } from 'presentation/components/atoms/Text';

// Defs
import { ErrorLoadDataProps } from './ErrorLoadData.def';

// Style
import Container from './ErrorLoadData.styled';

const ErrorLoadData = ({ message, iconName }: ErrorLoadDataProps) => {
  return (
    <Container>
      <Icon name={iconName ?? 'offline'} />
      <Paragraph
        color="gray-message-text"
        size="regular"
        weight="regular"
        style={{ letterSpacing: '-0.2px', lineHeight: '21px' }}>
        {message}
      </Paragraph>
    </Container>
  );
};

export default ErrorLoadData;
