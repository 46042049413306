export interface IBadge {
  name: string;
  text: string;
  color: string;
}

export const NoBadge: IBadge = {
  name: '',
  text: '',
  color: 'white',
};
