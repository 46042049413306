import { IImage, ILink } from './Common';

export interface IAccumulateCard {
  name: string;
  description: string;
  points: number;
  link?: ILink;
  partnersPreview?: IImage;
}

export const NoAccumulateCard: IAccumulateCard = {
  name: '',
  description: '',
  points: NaN,
};

export interface IAccumulateCardsGroup {
  name: string;
  cards: IAccumulateCard[];
}

export const NoAccumulateCardsGroup = {
  name: '',
  cards: [],
};
