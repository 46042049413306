import {
  INotificationMessage,
  NoNotificationMessage,
} from 'domain/model/content';
import LinkMapper from './LinkMapper';

export default function NotificationMessageMapper(
  json: any,
): INotificationMessage | undefined {
  if (!json) return;

  try {
    const { fields } = json;
    const { name, type, title, message, visible, link } = fields;

    return {
      name,
      type: (type as string).toLocaleLowerCase(),
      title,
      message,
      visible,
      link: link && LinkMapper(link),
    };
  } catch {
    console.warn(
      '[NotificationMessageMapper] The content json structure received differed from expected',
    );

    return NoNotificationMessage;
  }
}
