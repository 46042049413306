const commerces = [
  {
    name: 'banco',
    url: 'https://www.bancofalabella.pe/',
    displayText: 'Banco',
  },
  {
    name: 'tottus',
    url: 'https://tottus.falabella.com.pe/tottus-pe',
    displayText: 'Tottus',
  },
  {
    name: 'falabella',
    url: ' https://tienda.falabella.com.pe/falabella-pe',
    displayText: 'Falabella',
  },
  {
    name: 'sodimac',
    url: 'https://sodimac.falabella.com.pe/sodimac-pe',
    displayText: 'Sodimac',
  },
  {
    name: 'maestro',
    url: 'https://www.sodimac.com.pe/sodimac-pe/maestro',
    displayText: 'Maestro',
  },
  {
    name: 'linio',
    url: 'https://linio.falabella.com.pe/linio-pe',
    displayText: 'Linio',
  },
  {
    name: 'tottus-app',
    url: 'https://play.google.com/store/apps/details?id=com.tottus&hl=en',
    displayText: 'Tottus App',
  },
  {
    name: 'mallplaza',
    url: 'https://www.mallplaza.pe/',
    displayText: 'Mallplaza',
  },
  {
    name: 'open',
    url: 'https://openplaza.com.pe/es',
    displayText: 'OPEN',
  },
  {
    name: 'seguros',
    url: 'https://web.segurosfalabella.com/pe',
    displayText: 'Seguros Falabella',
  },
  {
    name: 'viajes',
    url: 'https://www.viajesfalabella.com.pe/',
    displayText: 'Viajes Falabella',
  },
];

export default commerces;
