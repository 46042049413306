import {
  useCallback,
  Dispatch,
  memo,
  SetStateAction,
  useMemo,
  useEffect,
} from 'react';
import { useTour } from '@reactour/tour';
import { useLocation } from 'react-router-dom';
import STEPS_WALKTHROUGH from 'domain/configuration/walkthrough';
import { useAuth } from 'infrastructure/providers/Auth';
import {
  useEventBus,
  UiEventMessage,
  EventTopicEnum,
} from 'infrastructure/eventBus';

// Components
import Fab from 'presentation/components/atoms/Fab';
import WalkthroughSteps from './WalkthroughSteps';

// Defs
import { Walkthrough, WalkthroughTypes } from './Walkthrough.defs';

type WalkthroughConfigProps = {
  type: WalkthroughTypes;
  showFab: boolean;
  setShowFab: Dispatch<SetStateAction<boolean>>;
  setShowWalkthrough: Dispatch<SetStateAction<Walkthrough>>;
};

const WalkthroughConfig = ({
  type,
  showFab,
  setShowFab,
  setShowWalkthrough,
}: WalkthroughConfigProps) => {
  const {
    data: {
      logged: { in: auth },
    },
  } = useAuth();
  const { publish } = useEventBus<UiEventMessage>(EventTopicEnum.UI);
  const { pathname } = useLocation();
  const { isOpen, currentStep, setIsOpen, setSteps, setCurrentStep } =
    useTour();

  const data = useMemo(() => STEPS_WALKTHROUGH[type], [type]);
  const showFabInMainPath = useMemo(
    () => showFab && STEPS_WALKTHROUGH[type].mainPath === pathname,
    [pathname, showFab, type],
  );

  const handleClickFab = useCallback(() => {
    setCurrentStep(data.isPublic ? currentStep : 1);
    setIsOpen(true);
    publish({
      subject: '',
      module: `${data.mainPath}`,
      action: 'click reabrir tour',
      target: 'Volver a ver la guia',
    });
  }, [
    currentStep,
    setCurrentStep,
    data.isPublic,
    data.mainPath,
    setIsOpen,
    publish,
  ]);

  const clearPublicWalkthrough = useCallback(
    (pathName: string) => {
      if (pathName !== data.mainPath) {
        setSteps([]);
        setShowWalkthrough({ show: false, type: null });
        setShowFab(false);
      }
    },
    [data, setSteps, setShowFab, setShowWalkthrough],
  );

  const clearPrivateWalkthrough = useCallback(
    (pathName: string) => {
      if (pathName !== '/' && currentStep === 0) {
        setSteps([]);
        setShowWalkthrough({ show: false, type: null });
        setShowFab(false);
        return;
      }

      if (pathName !== data.mainPath && currentStep > 0) {
        setSteps([]);
        setShowWalkthrough({ show: false, type: null });
        setShowFab(false);
      }
    },
    [currentStep, data, setSteps, setShowWalkthrough, setShowFab],
  );

  const clearWalkthrough = useCallback(
    (pathName: string) => {
      data.isPublic && clearPublicWalkthrough(pathName);
      !data.isPublic && clearPrivateWalkthrough(pathName);
    },
    [data, clearPublicWalkthrough, clearPrivateWalkthrough],
  );

  useEffect(() => {
    !isOpen && clearWalkthrough(pathname);
  }, [clearWalkthrough, pathname, isOpen]);

  return (
    <>
      {data && isOpen && <WalkthroughSteps auth={!!auth} data={data} />}
      {showFabInMainPath && (
        <Fab
          buttonText="Volver a ver la guia"
          nameIcon="questionWhite"
          onClick={handleClickFab}
        />
      )}
    </>
  );
};

export default memo(WalkthroughConfig);
