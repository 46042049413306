import contentful from 'infrastructure/services/contentFul';

// Mappers
import { EnableDataDogMapper } from '../mappers';

// Service
const {
  entriesResource: { getEntry },
} = contentful;

// Vars
const EnableDataDogEntryId =
  process.env.REACT_APP_CONTENTFUL_DATA_ENABLE_DATADOG;

const LoadsEnableDataDog = async () => {
  try {
    const data = await getEntry<any>(EnableDataDogEntryId, {
      'sys.id': EnableDataDogEntryId,
      include: 10,
    });

    return EnableDataDogMapper(data);
  } catch (error) {
    console.error(error);
  }
};

export default LoadsEnableDataDog;
