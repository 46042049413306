import { ILink, NoLink } from './Common';

export interface INotificationMessage {
  name: string;
  title?: string;
  message: string;
  visible?: boolean;
  type: string;
  link?: ILink;
}

export const NoNotificationMessage: INotificationMessage = {
  name: '',
  type: '',
  title: '',
  message: '',
  visible: false,
  link: NoLink,
};
