import styled, { css } from 'styled-components';

// Components
import Icon from 'presentation/assets/icons';
import ButtonIcon from 'presentation/components/atoms/ButtonIcon';
import {
  TextSizeMixin,
  TextWeightMixin,
} from 'presentation/utils/styles/Mixins';

const BACKGROUND_COLORS: { [key: string]: string } = {
  info: '--color-bg-info',
  warning: '--color-bg-warning',
  alert: '--color-bg-error',
  success: '--color-bg-success',
};

const ContainerSmall = css`
  padding: 12px;
`;

export const Container = styled.div<{
  type: string;
  variant: 'small' | 'normal';
  show: boolean;
  duration: number;
}>`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  background-color: ${({ type }) => type && `var(${BACKGROUND_COLORS[type]})`};
  opacity: ${({ show }) => (show ? '100%' : '0')};
  animation-name: ${({ show }) => (show ? 'fadeIn' : 'fadeOut')};
  animation-duration: ${({ duration }) => `${duration}ms`};
  animation-fill-mode: both;

  ${({ variant }) => variant === 'small' && ContainerSmall}
`;

const ContentSmall = css`
  ${TextWeightMixin('light')};

  font-size: 13px;
  margin-left: 8px;
`;

export const Content = styled.div<{ variant: 'small' | 'normal' }>`
  ${TextSizeMixin('regular')}
  ${TextWeightMixin('regular')}

  margin: auto;
  flex-grow: 2;
  line-height: 24.5px;
  letter-spacing: -0.2%;
  margin-left: 16px;

  ${({ variant }) => variant === 'small' && ContentSmall}
`;

export const Title = styled.h2`
  ${TextWeightMixin('bold')}

  font-size: inherit;
  line-height: 21px;
  margin-bottom: 3px;
`;

export const Text = styled.p`
  font-size: inherit;
  font-weight: inherit;
  line-height: 175%;
  margin-bottom: 3px;
`;

export const IconMessageBox = styled(Icon)`
  flex-shrink: 0;
`;

export const CloseButton = styled(ButtonIcon)`
  align-self: center;
  margin-left: 16px;
`;
