import contentful from 'infrastructure/services/contentFul';

// Mappers
import { ContentSectionMapper, CardsGroupMapper } from '../mappers';

// Service
const {
  entriesResource: { getEntry },
} = contentful;

// Vars
const StoresCatalogsEntryId =
  process.env.REACT_APP_CONTENTFUL_DATA_STORESCATALOGS;

const LoadsStoresCatalogs = async () => {
  try {
    const data = await getEntry<any>(StoresCatalogsEntryId, {
      'sys.id': StoresCatalogsEntryId,
      include: 10,
    });

    return {
      storesCatalogsSection: ContentSectionMapper(
        data.fields?.storesCatalogsSection,
        CardsGroupMapper,
      ),
      seccionExchangeCards: CardsGroupMapper(data.fields?.seccionExchangeCards),
    };
  } catch (error) {
    console.error(error);
  }
};

export default LoadsStoresCatalogs;
