import * as React from 'react';
import { useState, CSSProperties } from 'react';
import styled from 'styled-components';

// Domain content
import { IImage, IImageResponsive } from 'domain/model/content';
import { StyledExtendable } from 'presentation/utils/styles';

// Hooks
import useIsMobile from 'infrastructure/hooks/IsMobile';

// Definitions
export type ImageProps = {
  source: IImage | IImageResponsive;
  width?: number;
  height?: number;
  autoFit?: boolean;
  fadeIn?: boolean;
  fadeInSpeed?: number;
  style?: CSSProperties;
  onLoad?: () => void;
} & StyledExtendable;

type styledProps = 'autoFit' | 'fadeIn' | 'fadeInSpeed';
export type StyledImageProps = { show?: boolean } & Pick<
  ImageProps,
  styledProps
> &
  React.ComponentPropsWithoutRef<'img'>;

function isResponsive(source: any): source is IImageResponsive {
  if (!source) {
    return false;
  }
  return 'mobile' in source;
}
/**
 * Atom Image
 * @param source IImage | IImageResponsive
 * @param style CSSProperties
 */
const Image = (props: ImageProps) => {
  // Props
  const {
    source,
    width,
    height,
    autoFit = true,
    fadeIn,
    fadeInSpeed = 1000,
    style,
    onLoad = () => {},
    className,
  } = props;

  // State
  const [isLoad, setIsLoad] = useState(!fadeIn);
  const isDesktop = !useIsMobile();

  if (!source) return <> </>;

  let imageSrc = '';
  let imageAlt = '';

  if (isResponsive(source)) {
    const device = isDesktop ? 'desktop' : 'mobile';
    const sourceResponsive = source as IImageResponsive;
    const deviceImage = sourceResponsive[device];
    const { name } = sourceResponsive;

    imageSrc = deviceImage.url;
    imageAlt = name ?? deviceImage.title;
  } else {
    const sourceSingle = source as IImage;
    imageSrc = sourceSingle.url;
    imageAlt = sourceSingle.title;
  }

  const handleLoad = () => {
    if (fadeIn) setIsLoad(true);

    onLoad();
  };

  return (
    <StyledImage
      className={className}
      show={isLoad}
      src={imageSrc}
      alt={imageAlt}
      width={width}
      height={height}
      autoFit={autoFit}
      fadeIn={fadeIn}
      fadeInSpeed={fadeInSpeed}
      style={style}
      onLoad={handleLoad}
    />
  );
};

const StyledImage = styled.img<StyledImageProps>`
  width: ${({ autoFit }) => autoFit && '100%'};
  height: ${({ autoFit }) => autoFit && 'auto'};
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: ${({ show, fadeIn, fadeInSpeed }) =>
    fadeIn && show && `opacity ${fadeInSpeed}ms ease 0ms`};
`;

export default Image;
