import { ITab, NoTab } from 'domain/model/content';

const TabMapper = <TContent>(
  json: any,
  contentMapper?: (json: any) => TContent,
): ITab<TContent> => {
  try {
    if (!json) return NoTab;

    const { fields } = json;
    const { label, title, content, contents = [] } = fields;

    return {
      label,
      title,
      content: contentMapper ? contentMapper(content) : ({} as any),
      contents: contentMapper ? contents.map(contentMapper) : ([] as any),
      hidden: false,
    };
  } catch {
    console.warn(
      '[TabMapper] The content json structure received differed from expected',
    );

    return NoTab;
  }
};
export default TabMapper;
