// HorizontalCard.tsx
import React from 'react';
import SmartLink from 'presentation/components/organisms/SmartLink';
import Button from 'presentation/components/atoms/Button';
import useIsMobile from 'infrastructure/hooks/IsMobile';
import {
  CardContainer,
  BackgroundImage,
  Content,
  Title,
  Description,
  CarrouselSection,
  ButtonContainer,
} from './HorizontalCard.style';
import { HorizontalCardProps } from './HorizontalCard.def';

const HorizontalCard = (props: HorizontalCardProps) => {
  const {
    title = 'Default Title',
    description = 'Default Description',
    image,
    link,
    id,
    mode = 'light',
    onLoad = () => {},
    onClick = (target: string) => {},
  } = props;
  const linkId = `link-${id}`;
  const { mobile, desktop } = image;
  const isMobile = useIsMobile(['xs', 'sm']);

  return (
    <CarrouselSection mode={mode}>
      <CardContainer
        image={isMobile ? mobile.url : desktop.url}
        onLoad={onLoad}>
        <Content mode={mode}>
          <Title>{title}</Title>
          <Description>{description}</Description>
          <ButtonContainer>
            <SmartLink
              url={link.url}
              mode="stock"
              target="adaptable"
              id={linkId}>
              <Button
                variant={mode === 'dark' ? 'darkmode' : 'primary'}
                onClick={() => {
                  onClick('button');
                }}
                isFullWidth={isMobile}
                size="L">
                {link.text}
              </Button>
            </SmartLink>
          </ButtonContainer>
        </Content>
        <BackgroundImage />
      </CardContainer>
    </CarrouselSection>
  );
};

export default HorizontalCard;
