import styled from 'styled-components';

export const CarrouselSection = styled.div<{ mode: 'dark' | 'light' }>`
  display: flex;
  justify-content: center;
  background-color: ${props =>
    props.mode === 'dark' ? 'var(--color-bg-darkmode)' : 'var(--color-gray)'};
  &::after {
    content: '';
    position: absolute;
    bottom: -4em;
    left: 0;
    width: 100%;
    height: 4em;
    background-color: ${props =>
      props.mode === 'dark' ? 'var(--color-bg-darkmode)' : 'var(--color-gray)'};
    z-index: -1;
  }
`;

export const CardContainer = styled.div<{ image: string }>`
  display: flex;
  justify-content: center;
  border-radius: 24px;
  width: 80%;
  height: 400px;
  margin: 4em 0 0;
  box-shadow: 1px 3px 7px 1px #0000000d;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media (min-width: 1700px) {
    width: 68%;
  }
  @media (min-width: 1900px) {
    width: 60%;
  }
  @media (max-width: 460px) {
    width: 90%;
    flex-direction: column-reverse;
    height: auto;
    background-size: contain;
    background-position: inherit;
  }

  @media (max-width: 320px) {
    height: 26em;
  }
`;

export const Content = styled.div<{ mode: 'dark' | 'light' }>`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: -1px;
  position: relative;
  backdrop-filter: blur(70px);
  padding: 4em;
  border-radius: 24px;
  min-height: 282px;
  background-color: ${props =>
    props.mode === 'dark'
      ? 'var(--surface-fill-overlay400, #000000B2)'
      : 'rgba(255, 255, 255, 0.7)'};

  h3 {
    color: ${props =>
      props.mode === 'dark' ? 'var(--color-white)' : '#333537'};
  }
  p {
    color: ${props => (props.mode === 'dark' ? 'var(--color-white)' : '#555')};
  }

  @media (max-width: 460px) {
    width: 100%;
    border-radius: 24px;
    padding: 1em;
  }
`;

export const BackgroundImage = styled.div`
  width: 50%;
  display: flex;

  @media (max-width: 460px) {
    width: 100%;
    height: 200px; // Ajusta la altura según sea necesario
  }
`;

export const Title = styled.h3`
  font-size: 40px;
  font-weight: normal;
  color: #333537;
  @media (max-width: 460px) {
    font-size: 28px;
  }
`;

export const Description = styled.p`
  margin: 0.9em 0 1.1em;
  color: #555;
  font-size: 20px;
`;

export const ButtonContainer = styled.div`
  button {
    width: auto;
    min-width: 200px;
    font-weight: 500;
  }
  @media (max-width: 460px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
