import { ILinksGroup } from 'domain/model/content';
import LinksGroupMapper from './LinksGroupMapper';

export default function InterestLinksMapper(json: any): ILinksGroup[] {
  try {
    return json.map(LinksGroupMapper);
  } catch {
    console.warn(
      '[InterestLinksMapper] The content json structure received differed from expected',
    );

    return [];
  }
}
