import contentful from 'infrastructure/services/contentFul';

// Mappers
import {
  CouponMapper,
  CouponLegalMapper,
  CouponUseTextMapper,
} from '../mappers';

// Service
const {
  entriesResource: { getEntry },
} = contentful;

// Vars
const CouponsEntryId = process.env.REACT_APP_CONTENTFUL_DATA_COUPONS;

const LoadsCoupons = async () => {
  try {
    const data = await getEntry<any>(CouponsEntryId, {
      'sys.id': CouponsEntryId,
      include: 10,
    });

    return {
      code: data.fields?.code,
      coupons: CouponMapper(data.fields?.coupons),
      couponUseText: CouponUseTextMapper(data.fields?.couponUseText?.fields),
      legal: CouponLegalMapper(data.fields?.legal),
    };
  } catch (error) {
    console.error(error);
  }
};

export default LoadsCoupons;
