import { IconName } from 'presentation/assets/icons/Icon';
import React from 'react';

// styles
import * as Styled from './Fab.styles';

type FabProps = {
  buttonText: string;
  nameIcon: IconName;
  onClick: () => void;
};

const Fab = ({ buttonText, nameIcon, onClick }: FabProps) => {
  return (
    <Styled.Fab onClick={onClick}>
      <Styled.Container>
        <Styled.IconButon name={nameIcon} />
        <Styled.Text>{buttonText}</Styled.Text>
      </Styled.Container>
    </Styled.Fab>
  );
};

export default Fab;
