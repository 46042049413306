import * as React from 'react';
import styled from 'styled-components';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { INLINES, BLOCKS, MARKS } from '@contentful/rich-text-types';

// Domain content
import { IRichtext } from 'domain/model/content';

// Renderers
import {
  EntryLinkRender,
  LinkRender,
  ParagraphRender,
  StrongRender,
  RenderOptions,
} from './renderers';

// Definitions
export type RichTextProps = {
  content: IRichtext;
  options?: RenderOptions;
};

const renderComponents = (document: IRichtext, options: RenderOptions) => {
  const { nodeType } = document;
  if (!nodeType) return '';

  const renderOptions = {
    renderMark: {
      [MARKS.BOLD]: StrongRender(options),
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: ParagraphRender,
      [INLINES.HYPERLINK]: LinkRender(options),
      [INLINES.ENTRY_HYPERLINK]: EntryLinkRender(options),
    },
  };

  return documentToReactComponents(document, renderOptions);
};

const RichText = (props: RichTextProps) => {
  const { content, options = {} } = props;
  return <Wrapper>{renderComponents(content, options)}</Wrapper>;
};

const Wrapper = styled.div`
  ul li p,
  ol li p {
    margin-bottom: 0;
    text-align: left;
  }
  ul,
  ol {
    margin-bottom: 10px;
  }
  h5 {
    font-weight: 300;
    margin-bottom: 10px;
  }
`;

export default RichText;
