const value = [
  {
    name: 'facebook',
    url: 'https://www.facebook.com/cmrpuntosperu?utm_source=mini_sitio_tottus&utm_medium=cpc&utm_campaign=puntos&utm_term=acumulacion_con_rut',
  },
  {
    name: 'instagram',
    url: 'https://www.instagram.com/cmrpuntospe',
  },
  {
    name: 'youtube',
    url: 'https://www.youtube.com/channel/UCMWhjUzsieP58tFSUO5P6lg',
  },
];

export default value;
