// Country configuration
import cl from './cl';
import co from './co';
import pe from './pe';

// Configurations map
const Configurations = {
  cl,
  co,
  pe,
};
const COUNTRY = process.env.REACT_APP_COUNTRY as keyof typeof Configurations;

export default Configurations[COUNTRY];
