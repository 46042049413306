import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';

// Components
import Icon from 'presentation/assets/icons';

// Hooks
import useIsMobile from 'infrastructure/hooks/IsMobile';

import { CarouselProps } from './Carousel.types';

import Container, { WrapperNavigation } from './Carousel.style';

const Carousel = (props: CarouselProps) => {
  const {
    id,
    style,
    customNavigation,
    children,
    swiperOptions,
    handleActiveIndexChange,
  } = props;
  const { isNavigation = false, nextEl, prevEl } = customNavigation || {};

  // Hooks
  const isMobile = useIsMobile();
  const options = {
    ...swiperOptions,
    preventClicks: false,
    preventClicksPropagation: false,
  };

  return (
    <Container
      id={id}
      data-testid={id}
      className={isMobile ? 'mobile' : ''}
      style={style}>
      <Swiper {...options} onActiveIndexChange={handleActiveIndexChange}>
        {React.Children.map(
          children as React.ReactElement<any>,
          child => child && <SwiperSlide>{child}</SwiperSlide>,
        )}
      </Swiper>

      {isNavigation && (
        <WrapperNavigation idCarousel={id}>
          <div className={nextEl}>
            <Icon name="arrowSliderRightWhite" />
          </div>
          <div className={prevEl}>
            <Icon name="arrowSliderLeftWhite" />
          </div>
        </WrapperNavigation>
      )}
    </Container>
  );
};

export default Carousel;
