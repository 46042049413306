import LocaleConfig from 'domain/configuration/locale';

const { locale, currency } = LocaleConfig;
const formatter = new Intl.NumberFormat(locale, {
  style: 'currency',
  currency,
});

const currencyFormat = (value: number): string => {
  return formatter.format(value);
};

export default currencyFormat;
