export interface IExchangeByTranchesGroup {
  enable: boolean;
  name: string;
  cards: IExchangeByTranches[];
}

export interface IExchangeByTranches {
  title: string;
  initialText?: string;
  fromPoints?: number;
  upToPoints?: number;
  link: string;
  isBetween?: boolean;
}

export const NoExchangeByTranchesGroup: IExchangeByTranchesGroup = {
  enable: false,
  name: '',
  cards: [],
};

export const NoExchangeByTranches: IExchangeByTranches = {
  title: '',
  initialText: '',
  fromPoints: 0,
  upToPoints: 0,
  link: '',
  isBetween: false,
};
