import React, { useEffect, useCallback, useMemo } from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Redirect, Route } from 'react-router-dom';

// Providers
import { useAuth } from 'infrastructure/providers/Auth';

// Infrastructure
import useRouter from 'infrastructure/hooks/Router';

type PrivateHashRouteProps = {
  hash: string;
  component: React.FC<{ open: boolean; onClose: () => void }>;
};

const PRIVATE_HASH_LIST: Record<string, boolean> = {
  '#datos-personales': true,
};

const PrivateHashRoute = ({
  hash,
  component: Component,
}: PrivateHashRouteProps) => {
  const {
    location: { hash: hashRoute },
  } = useRouter();

  const {
    data: { logged },
  } = useAuth();

  const SaveRedirectHash = useCallback(
    () => localStorage.setItem('redirectTo', hashRoute),
    [hashRoute],
  );

  useEffect(() => {
    if (!logged.in) PRIVATE_HASH_LIST[hashRoute] && SaveRedirectHash();
  }, [logged.in, hashRoute, SaveRedirectHash]);

  const matchHashRoute = useMemo(
    () => hashRoute === `#${hash}`,
    [hashRoute, hash],
  );
  const showComponent = useMemo(
    () => !!logged.in && matchHashRoute,
    [logged.in, matchHashRoute],
  );
  return (
    <>
      <Route
        render={({ location, history }) => {
          if (!logged.in && matchHashRoute) return <Redirect to="/#ingresar" />;
          return (
            <Component
              open={showComponent}
              onClose={() => {
                history.replace(location.pathname);
              }}
            />
          );
        }}
      />
    </>
  );
};

export default PrivateHashRoute;
