import shared from './Shared';

// Formatters for specific countries
import chile from './country/cl';
import peru from './country/pe';
import colombia from './country/co';

const formatters = {
  dni: {
    cl: chile.dni,
    pe: peru.dni,
    co: colombia.dni,
  },
};

// Definitions
type Formatter = (value: string) => string;
type Collection = {
  dni: Formatter;
  phone: Formatter;
  currency: (value: number) => string;
};

// Methods
const methods = () => {
  const country = process.env.REACT_APP_COUNTRY as never;
  const formatted = Object.keys(formatters).reduce((iterator, key) => {
    const updated = { ...iterator };
    const currentKey = key as keyof typeof formatters;
    updated[currentKey] = formatters[currentKey][country];
    return updated;
  }, {} as Collection);
  return { ...shared, ...formatted };
};

const Methods = methods();
const Defaults = {
  ...shared,
  ...formatters,
};

export default Defaults;
export { Methods };
