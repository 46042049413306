import { ICategoryBenefits, NoCategoryBenefits } from 'domain/model/content';
import CardsGroupMapper from './CardsGroupMapper';
import ContentSectionMapper from './ContentSectionMapper';

const CategoryBenefitsMapper = (json: any): ICategoryBenefits => {
  try {
    const { fields } = json;
    const { name, category, currentCategoryBenefits, nextCategoryBenefits } =
      fields;

    return {
      name,
      category,
      currentCategoryBenefits:
        currentCategoryBenefits &&
        ContentSectionMapper(currentCategoryBenefits, CardsGroupMapper),
      nextCategoryBenefits:
        nextCategoryBenefits &&
        ContentSectionMapper(nextCategoryBenefits, CardsGroupMapper),
    };
  } catch {
    // eslint-disable-next-line no-console
    console.warn(
      '[CategoryBenefitsMapper] The content json structure received differed from expected',
    );

    return NoCategoryBenefits;
  }
};
export default CategoryBenefitsMapper;
