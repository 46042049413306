// Validators
import Validations from 'domain/validations';
import Formatters from 'domain/formatters';

// Definitions
import { FormList } from './Login.defs';
import {
  FormSeparator,
  FormButton,
  FormInput,
  FormText,
  FormDef,
  Type,
  Mode,
} from '../Form.defs';

// Specific validations & formatters
const { dni: dniValidation, number: numberValidation } = Validations;
const { dni: dniFormatter, number: numberFormatter } = Formatters;

// Form list
const form: FormList = {
  menu: {
    title: [{ text: 'Ingresa a tu cuenta' }],
    subtitle: [{ text: 'Revisa tus puntos, canjes y más' }],
    components: [
      {
        id: 'bank',
        icon: 'bankCmr',
        name: 'bank',
        text: 'Clientes',
        testId: 'input-type-bf',
        color: 'primary',
      } as FormButton,
      {
        id: 'separator',
        text: 'Otros clientes',
      } as FormSeparator,
      {
        id: 'cmr',
        name: 'cmr',
        text: 'Cuenta CMR Puntos',
        testId: 'input-type-cmr',
        color: 'secondary',
      } as FormButton,
      {
        id: 'recovery',
        text: [
          {
            text: 'Crea o recupera tu clave CMR Puntos',
            url: '/clave',
            target: 'route',
            testId: 'recover-pass-cmr',
          },
        ],
      } as FormText,
    ],
  } as FormDef,
  bank: {
    title: [{ text: 'Ingresa a tu cuenta' }],
    subtitle: [{ text: 'Revisa tus puntos, canjes y más' }],
    components: [
      {
        id: 'separator',
        text: 'Clientes',
        icon: 'bankCmrColor',
      } as FormSeparator,
      {
        id: 'dni',
        name: 'dni',
        placeholder: 'RUT',
        type: Type.text,
        testId: 'rut-bf',
        mode: Mode.text,
        maxLength: { value: 10, message: 'RUT no válido' },
        minLength: { value: 5, message: 'RUT no válido' },
        validator: dniValidation.cl,
        formatter: dniFormatter.cl,
      } as FormInput,
      {
        id: 'password',
        name: 'password',
        placeholder: 'Clave Internet',
        type: Type.password,
        testId: 'password-bf',
        mode: Mode.numeric,
        maxLength: {
          value: 6,
          message: 'Tu Clave Internet debe contener 6 dígitos',
        },
        minLength: {
          value: 6,
          message: 'Tu Clave Internet debe contener 6 dígitos',
        },
        validator: numberValidation,
        formatter: numberFormatter,
      } as FormInput,
      {
        id: 'recovery',
        text: [
          {
            text: 'Crea o recupera tu Clave Internet',
            url: 'https://www.bancofalabella.cl/autoadhesion/',
            target: 'blank',
            testId: 'recover-pass-bank',
          },
        ],
      } as FormText,
    ],
    feedback: [
      {
        code: '00',
        title: '¡Ups!',
        subtitle: [
          {
            text: 'En este momento no es posible iniciar sesión, por favor inténtalo más tarde.',
          },
        ],
        type: 'message',
      },
      {
        code: '01',
        title: 'Verifica tus datos',
        subtitle: [
          {
            text: 'El RUT o la clave no es válida.',
          },
          {
            text: 'Recuerda que en un número determinado de intentos, tu Clave Internet será bloqueada.',
          },
        ],
        type: 'message',
      },
      {
        code: '03',
        title: 'Tu Clave Internet ha sido bloqueada',
        subtitle: [
          {
            text: 'Por motivos de seguridad, tu clave ha sido bloqueada, recupérala en ',
          },
          {
            text: 'bancofalabella.cl',
            url: 'https://www.bancofalabella.cl/autoadhesion/',
          },
        ],
        type: 'message',
      },
    ],
  } as FormDef,
  points: {
    title: [{ text: 'Ingresa a tu cuenta' }],
    subtitle: [{ text: 'Revisa tus puntos, canjes y más' }],
    components: [
      {
        id: 'separator',
        text: 'Cuenta',
        icon: 'cmr',
      } as FormSeparator,
      {
        id: 'dni',
        name: 'dni',
        placeholder: 'RUT',
        type: Type.text,
        testId: 'rut-cmr',
        mode: Mode.text,
        maxLength: { value: 10, message: 'RUT no válido' },
        minLength: { value: 5, message: 'RUT no válido' },
        validator: dniValidation.cl,
        formatter: dniFormatter.cl,
      } as FormInput,
      {
        id: 'password',
        name: 'password',
        placeholder: 'Clave de 6 dígitos',
        type: Type.password,
        testId: 'password-cmr',
        mode: Mode.numeric,
        maxLength: {
          value: 6,
          message: 'Tu Clave CMR Puntos debe contener 6 dígitos',
        },
        minLength: {
          value: 6,
          message: 'Tu Clave CMR Puntos debe contener 6 dígitos',
        },
        validator: numberValidation,
        formatter: numberFormatter,
      } as FormInput,
      {
        id: 'recovery',
        text: [
          {
            text: 'Crea o recupera tu clave CMR Puntos',
            url: '/clave',
            target: 'route',
            testId: 'recover-pass-cmr',
          },
        ],
      } as FormText,
    ],
    feedback: [
      {
        code: '00',
        title: '¡Ups!',
        subtitle: [
          {
            text: 'En este momento no es posible iniciar sesión, por favor inténtalo más tarde.',
          },
        ],
        type: 'message',
      },
      {
        code: '01',
        title: 'Verifica tus datos',
        subtitle: [
          {
            text: 'El RUT o la clave no es válida.',
          },
          {
            text: 'Recuerda que en un número determinado de intentos, tu Clave CMR Puntos será bloqueada.',
          },
        ],
        type: 'message',
      },
      {
        code: '03',
        title: 'Tu Clave CMR Puntos ha sido bloqueada',
        subtitle: [
          {
            text: 'Por motivos de seguridad, tu Clave CMR Puntos ha sido bloqueada.',
          },
        ],
        type: 'message',
      },
    ],
  } as FormDef,
  unified: {
    title: [{ text: 'Ingresa a tu cuenta' }],
    subtitle: [{ text: 'Revisa tus puntos, canjes y más' }],
    components: [
      {
        id: 'separator',
        text: '',
        icon: 'falabellaGroupLogin',
      } as FormSeparator,
      {
        id: 'dni',
        name: 'dni',
        label: 'Ingresa tu RUT',
        placeholder: 'RUT',
        type: Type.text,
        testId: 'rut-cmr',
        mode: Mode.text,
        maxLength: { value: 10, message: 'RUT no válido' },
        minLength: { value: 5, message: 'RUT no válido' },
        validator: dniValidation.cl,
        formatter: dniFormatter.cl,
      } as FormInput,
      {
        id: 'password',
        name: 'password',
        label: 'Ingresa tu Clave de Internet',
        placeholder: 'Tu clave es de 6 dígitos',
        type: Type.password,
        testId: 'password-cmr',
        mode: Mode.numeric,
        maxLength: {
          value: 6,
          message: 'Tu Clave de Internet debe contener 6 dígitos',
        },
        minLength: {
          value: 6,
          message: 'Tu Clave de Internet debe contener 6 dígitos',
        },
        validator: numberValidation,
        formatter: numberFormatter,
      } as FormInput,
      {
        id: 'recovery',
        text: [
          {
            text: 'Crea o recupera la clave',
            url: '/clave',
            target: 'route',
            testId: 'recover-pass-cmr',
          },
        ],
      } as FormText,
    ],
    feedback: [
      {
        code: '00',
        title: 'En este momento no es posible iniciar sesión',
        subtitle: [
          {
            text: 'Por favor inténtalo más tarde.',
          },
        ],
        type: 'message',
      },
      {
        code: '01',
        title: 'Verifica tus datos',
        subtitle: [
          { text: 'El RUT o la Clave Internet no son válidos.' },
          {
            text: 'Recuerda que en un número determinado de intentos, tu Clave Internet será bloqueada.',
          },
        ],
        type: 'message',
      },
      {
        code: '03',
        title: 'Tu Clave Internet ha sido bloqueada',
        subtitle: [
          {
            text: 'Por motivos de seguridad, tu clave ha sido bloqueda. No te preocupes, puedes recuperarla.',
          },
        ],
        type: 'message',
      },
    ],
  } as FormDef,
};

export default form;
