import { ICouponUseText, NoCouponUseText } from 'domain/model/content/Coupon';
import ImageResponsiveMapper from './ImageResponsiveMapper';

const CouponUseTextMapper = (json: ICouponUseText) => {
  try {
    return {
      ...json,
      secondaryLogo:
        json.secondaryLogo &&
        json.secondaryLogo.map(item => ImageResponsiveMapper(item)),
    };
  } catch {
    console.warn(
      '[CouponMapper] The content json structure received differed from expected',
    );

    return NoCouponUseText;
  }
};

export default CouponUseTextMapper;
