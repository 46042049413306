import { ISpecialContent, NoSpecialContent } from 'domain/model/content';
import ImageResponsiveMapper from './ImageResponsiveMapper';
import LinkMapper from './LinkMapper';

const SpecialContentMapper = (json: any): ISpecialContent => {
  try {
    const { fields } = json;
    const { name, image, text, link } = fields;

    return {
      name,
      image: ImageResponsiveMapper(image),
      text,
      link: link && LinkMapper(link),
    };
  } catch {
    console.warn(
      '[SpecialContentMapper] The content json structure received differed from expected',
    );

    return NoSpecialContent;
  }
};

export default SpecialContentMapper;
