import React from 'react';

// Defs
import { CouponUseProps } from './CouponUse.def';

// Icons
import { ArrowRight, Coupon, CirclePercentage } from '../../icons';

// Styled Components
import CouponUseContainer, {
  WrapperContent,
  WrapperDescription,
} from './CouponUse.styled';

const CouponUse = ({ code, isCountry }: CouponUseProps) => {
  return (
    <CouponUseContainer>
      <h2>¿Cómo usar tu cupón?</h2>

      <WrapperContent>
        <WrapperDescription>
          <Coupon />
          <p>
            <span>Haz clic en el cupón</span> para ver los productos
            seleccionados e <span>inicia sesión</span> para comprar
          </p>
        </WrapperDescription>

        <WrapperDescription>
          <ArrowRight />
          <p>
            Al momento de pagar, agrega el{' '}
            <span>{!isCountry ? `código ${code}` : 'código de tu cupón'}</span>
          </p>
        </WrapperDescription>

        <WrapperDescription>
          <CirclePercentage />
          <p>Disfruta tu descuento al finalizar tu compra</p>
        </WrapperDescription>
      </WrapperContent>
    </CouponUseContainer>
  );
};

export default React.memo(CouponUse);
