import { IEventMessage } from 'domain/eventBus';

export enum EventTopicEnum {
  UI = 'UI',
  Operation = 'Operation',
  Error = 'Error',
}

export interface UiEventMessage extends IEventMessage {
  action: string;
  target: string;
}

export interface OperationEventMessage extends IEventMessage {}

export interface ErrorEventMessage extends IEventMessage {
  message: string;
}
