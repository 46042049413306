import React from 'react';
import styled from 'styled-components';

// Utils
import {
  TextDefaultFontMixin,
  TextWeightMixin,
} from 'presentation/utils/styles/Mixins';
import {
  ColorsHelper,
  ColorsHelperProps,
} from 'presentation/utils/styles/Helpers';

// Definitions
export type Props = {
  children?: any;
};

type RenderProps = { bold?: boolean; semiBold?: boolean } & ColorsHelperProps;

export type StrongProps = Props & RenderProps;

const Strong = (props: StrongProps) => {
  const { children, ...renderProps } = props;

  return <Render {...renderProps}>{children}</Render>;
};

// Styles
const Render = styled.strong<RenderProps>`
  ${TextDefaultFontMixin}
  ${TextWeightMixin('regular')};

  ${({ bold }) => bold && TextWeightMixin('bold')}
  ${({ semiBold }) => semiBold && TextWeightMixin('semi-bold')}

  ${ColorsHelper};
`;

export default Strong;
