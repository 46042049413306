import { memo } from 'react';
import { useLocation } from 'react-router-dom';

// Event Bus
import {
  EventTopicEnum,
  UiEventMessage,
  useEventBus,
} from 'infrastructure/eventBus';

// GA
import analyticsMethods from 'infrastructure/utils/analytics';

// Domain content
import { utmMedium } from 'domain/configuration';
import { IReedemCard, NoContentSection } from 'domain/model/content';

// Hooks
import useIsMobile from 'infrastructure/hooks/IsMobile';
import useWindowSize from 'infrastructure/hooks/WindowResize';

// Components
import Button from 'presentation/components/atoms/Button';
import ErrorLoadData from 'presentation/components/molecules/ErrorLoadData';
import ExchangeExperiences from 'presentation/components/organisms/ExchangeExperiences';
import SmartLink from 'presentation/components/organisms/SmartLink';
import Standard from 'presentation/components/organisms/TabCmrActions/template/Standard';

// Events
import {
  RedeemEvents,
  RedeemEventsLandingExchangePage,
} from '../../Redeem.events';

// Definitions
type Props = {
  data?: any;
};

const RedeemContent = (props: Props) => {
  // Props
  const { data = NoContentSection.contents } = props;
  const isData = data.length > 0;
  const isDesktop = !useIsMobile(['xs', 'sm']);
  const { userInteraction } = analyticsMethods;

  // Hooks
  const { publish } = useEventBus<UiEventMessage>(EventTopicEnum.UI);
  const { width } = useWindowSize();
  const { hash, pathname } = useLocation();

  // Variables
  const isLandingExchangePage = pathname === '/canje/';

  // Methods
  const methods = {
    handleOnClick: (titleTab: string) => {
      data?.callToAction &&
        !isLandingExchangePage &&
        publish(RedeemEvents.openAll(data?.callToAction.text));

      data?.callToAction &&
        isLandingExchangePage &&
        publish(
          RedeemEventsLandingExchangePage.openAll(
            data?.callToAction.text,
            titleTab,
            hash.replace('#', ''),
          ),
        );
    },
    handleOnSelect: (item: IReedemCard, titleTab: string) => {
      const { title } = item;
      userInteraction(title || '', 'home_public_site', 'redeem card');
      const { name, text } = item;
      const target = text ?? name;
      !isLandingExchangePage && publish(RedeemEvents.open(target));
      isLandingExchangePage &&
        publish(
          RedeemEventsLandingExchangePage.open(
            target,
            titleTab,
            hash.replace('#', ''),
          ),
        );
    },
  };

  return (
    <>
      {!isData && (
        <ErrorLoadData message="No existen canjes disponibles en este momento" />
      )}

      {isData &&
        data.map((item: any, index: number) => (
          <Standard
            key={`${index + 1}`}
            title={item?.title}
            contentOptions={{
              hasWhiteBackground: false,
              textAlignLeft: false,
            }}>
            <ExchangeExperiences
              id={item?.title
                ?.toLowerCase()
                .replace(/ /g, '-')
                .replace(/[^a-zA-Z0-9 ]/g, '')}
              exchangeCards={item?.content?.cards}
              onSelect={event => methods.handleOnSelect(event, item?.title)}
              utmMedium={utmMedium.home.sectionRedeem}
              windowSize={width}
            />
            {item?.callToAction && (
              <SmartLink
                url={item?.callToAction.url || ''}
                mode="stock"
                target={item?.callToAction.external ? 'adaptable' : 'route'}>
                <Button
                  id="reedem"
                  variant="primary"
                  size="L"
                  className="redeem-button"
                  isFullWidth={!isDesktop}
                  width={285}
                  onClick={() => methods.handleOnClick(item?.title)}>
                  {item?.callToAction.text}
                </Button>
              </SmartLink>
            )}
          </Standard>
        ))}
    </>
  );
};

export default memo(RedeemContent);
