import React from 'react';
import { ICustomerCategory } from 'domain/model';

import SmartLink from 'presentation/components/organisms/SmartLink/SmartLink';
import Icon from 'presentation/assets/icons/Icon';

import costoDeAdministracion from '../../utils/costoDeAdministracion';

// Styled
import Styled from '../Category/Category.style';

export type Props = {
  data: ICustomerCategory;
  showCongrat?: boolean;
  frontTo?: React.ReactNode;
};

const COUNTRY = process.env
  .REACT_APP_COUNTRY as keyof typeof costoDeAdministracion;

const CardInfo = ({ data, showCongrat, frontTo }: Props) => {
  const AccProdCode = data.accumulationCategory.id + data.productCategory.id;
  const accuWithoutCardCombination: string[] = ['0104', '0204', '01', '02']; // si es acumulacion premium o elite y en producto es fan, normal o no tiene
  const offerOpenCard: boolean =
    accuWithoutCardCombination.includes(AccProdCode); // Ofrezco abrir tarjeta

  const accuAndCardCombination: string[] = [
    '0100',
    '0101',
    '0102',
    '0200',
    '0201',
    '0202',
  ];

  const showDiscount: boolean = accuAndCardCombination.includes(AccProdCode); // Muestra descuento en la administracion

  return (
    <>
      {offerOpenCard && (
        <Styled.MessageUpdate>
          <h4>Por ser cliente {data.currentCategory.name}</h4>
          <p>
            Abre tu CMR con{' '}
            <b>
              {data.accumulationCategory.id === '02' ? '100%' : '50%'} de dcto.
            </b>{' '}
            en {costoDeAdministracion[COUNTRY].costoDeAdministracion} por 1 año.
          </p>
          <p>
            <SmartLink
              url={costoDeAdministracion[COUNTRY].cardUrl}
              target="blank">
              <strong>
                Apertura tarjeta CMR <Icon name="arrowRight" />
              </strong>
            </SmartLink>
          </p>
        </Styled.MessageUpdate>
      )}

      {showDiscount && !showCongrat && (
        <Styled.MessageUpdate>
          <h4>¡Sigue así!</h4>
          <p>
            Por acumulación de CMR Puntos, obtuviste el{' '}
            <b>
              {data.accumulationCategory.id === '02' ? '100%' : '50%'} de dcto.
            </b>{' '}
            en {costoDeAdministracion[COUNTRY].costoDeAdministracion} de tu
            tarjeta CMR desde el {frontTo}.
          </p>
        </Styled.MessageUpdate>
      )}
    </>
  );
};

export default CardInfo;
