const utils = {
  getColor: (variant = '', theme: any = {}) => {
    if (variant === 'dark') {
      return theme?.colors?.background?.contrast ?? 'inherit';
    }
    return '#ffffff';
  },
};

export default utils;
