import styled from 'styled-components';

const Styled = {
  Container: styled.section`
    width: 100%;
    padding: 48px 0;
    background-color: var(--color-gray);
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    .redeem-button {
      margin-top: 2.5em;
      font-weight: 500;
      text-transform: none;
      width: auto;
      padding: 0 3em;
    }
    .swiper-slide > div > a > div {
      width: 360px;
      @media ${() => '(max-width: 460px)'} {
        height: 460px;
      }
      @media ${() => '(max-width: 370px)'} {
        width: 338px;
      }
    }

    @media ${({ theme }) => theme.devices.tablet} {
      padding: 48px 55px 48px 55px;
    }

    @media ${({ theme }) => theme.devices.desktop} {
      padding: 56px 84px 56px 84px;
    }

    @media ${({ theme }) => theme.devices.wideDesktop} {
      padding: 56px 82px 56px 82px;
    }
  `,
};

export default Styled;
