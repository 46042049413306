import {
  IExchangeCategoriesCardsGroup,
  NoExchangeCategoriesCardsGroup,
} from 'domain/model/content';

import ResourceMapper from './ResourceMapper';

const ExchangeCategoriesCardsGroupMapper = (
  json: any,
  enable: boolean,
): IExchangeCategoriesCardsGroup => {
  try {
    const { fields } = json;
    const { name, cards } = fields;

    return {
      enable,
      name,
      cards: cards.map(ResourceMapper),
    };
  } catch {
    console.warn(
      '[ExchangeCategoriesCardsGroupMapper] The content json structure received differed from expected',
    );

    return NoExchangeCategoriesCardsGroup;
  }
};

export default ExchangeCategoriesCardsGroupMapper;
