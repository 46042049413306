import { useAuth } from 'infrastructure/providers/Auth';
import { Redirect, Route } from 'react-router-dom';

type Props = {
  component: any;
  path: string;
  [x: string]: any;
};

const PublicRoute = (props: Props) => {
  const { component: Component, path, ...rest } = props;

  const {
    data: { logged },
  } = useAuth();

  return (
    <Route
      {...rest}
      render={props =>
        !logged.in ? <Component {...props} /> : <Redirect to="/mi-cuenta" />
      }
    />
  );
};

export default PublicRoute;
