import { ISlide, NoSlide } from 'domain/model/content';
import ImageResponsiveMapper from './ImageResponsiveMapper';
import LinkMapper from './LinkMapper';

// Infrastructure

const SlideMapper = (json: any): ISlide => {
  try {
    const { fields } = json;
    const { id } = json.sys;
    const {
      image,
      description,
      color,
      title,
      name,
      link,
      visibility,
      ecosystem,
    } = fields;

    return {
      id,
      image: ImageResponsiveMapper(image),
      title,
      name,
      description,
      color: color === 'blanco' ? 'light' : 'dark',
      link: link && LinkMapper(link),
      visibility,
      ecosystem,
    };
  } catch (error) {
    console.warn(
      '[SlideMapper] The content json structure received differed from expected',
    );
    return NoSlide;
  }
};
export default SlideMapper;
