import { IReedemCardsGroup, NoReedemCardsGroup } from 'domain/model/content';
import ReedemCardMapper from './ReedemCardMapper';

const ReedemCardsGroupMapper = (json: any): IReedemCardsGroup => {
  try {
    const { fields } = json;
    const { name, cards } = fields;

    return {
      name,
      cards: cards.map(ReedemCardMapper),
    };
  } catch {
    console.warn(
      '[ReedemCardsGroupMapper] The content json structure received differed from expected',
    );

    return NoReedemCardsGroup;
  }
};

export default ReedemCardsGroupMapper;
