import { useState } from 'react';
import * as React from 'react';
import styled from 'styled-components';

// Models
import { ILink, ILinksGroup } from 'domain/model/content';

// Components
import LinksGroup from 'presentation/components/molecules/LinksGroup';
import Styled from '../../Exchanges/Exchanges.styles';

// Definitions
type Props = {
  content: ILinksGroup[];
  onClick?: (link: ILink) => void;
};

const DesktopContent = (props: Props) => {
  // Props
  const { content, onClick = () => {} } = props;
  const [showAll, setShowAll] = useState(false);

  return (
    <Wrapper>
      {content.map(group => {
        const handleShowMore = () => setShowAll(!showAll);

        const visibleLinks = showAll ? group.links : group.links.slice(0, 4);

        return (
          <div key={group.title}>
            <LinksGroup
              content={{ ...group, links: visibleLinks }}
              variant="dark"
              onClick={onClick}
            />
            {group.links.length > 4 && (
              <Styled.ShowMore onClick={handleShowMore} className="showMore">
                {showAll ? 'Ver menos' : 'Ver más'}
                <Styled.ChevronDown showMore={showAll} className="showMore" />
              </Styled.ShowMore>
            )}
          </div>
        );
      })}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  & > * {
    min-width: 256px;
    max-width: 270px;
    margin-right: 32px;
    margin-bottom: 40px;
  }
`;

export default DesktopContent;
