import { lazy, FunctionComponent, ReactNode } from 'react';

// Domain
import { INotificationMessage } from 'domain/model/content';

// Templates
const Standard = lazy(() => import('./Standard'));
const Banco = lazy(() => import('./BancoApp'));
const Commerce = lazy(() => import('./Commerce'));

type TemplateProps = {
  header?: boolean;
  points?: boolean;
  notification?: INotificationMessage;
  children: ReactNode;
};

type KeyTemplate = 'standard' | 'banco' | 'commerce';

type TemplateByClients = {
  [index in KeyTemplate]: FunctionComponent<TemplateProps>;
};

const templateByClients: TemplateByClients = {
  standard: Standard,
  commerce: Commerce,
  banco: Banco,
};

const getTemplate = (key: string): FunctionComponent<TemplateProps> | null => {
  const currentTemplate = templateByClients[key as KeyTemplate];

  if (currentTemplate) return currentTemplate;

  return null;
};

export default getTemplate;
