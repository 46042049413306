import * as React from 'react';
import { createContext, useContext, useEffect, useMemo } from 'react';

import { Configuration } from 'domain/configuration/client';
import ConfigurationManager from 'infrastructure/stores/Configuration';

import useUtils from './Configuration.utils';

// Definitions
type ProviderValue = {
  isLoad: boolean;
  configuration: Configuration;
};

type Props = {
  onReady?: () => void;
  children: any;
};

export const Context = createContext<ProviderValue | null>(null);

/**
 * Component provider
 * @return {*}
 * @constructor
 * @param props
 */
const ConfigurationProvider = (props: Props): any => {
  const { onReady, children } = props;
  const { isLoad, fetchConfiguration } = useUtils();

  const value = useMemo(
    () => ({
      isLoad,
      configuration: ConfigurationManager.get() ?? ({} as any),
      manager: ConfigurationManager,
    }),
    [isLoad],
  );

  // On init
  useEffect(() => {
    fetchConfiguration();
  }, []);

  // On load configuration
  useEffect(() => {
    if (isLoad) onReady?.();
  }, [isLoad]);

  return (
    <Context.Provider value={value}>{isLoad && children}</Context.Provider>
  );
};

/**
 * Component hook
 */
export const useConfiguration = () => {
  const context = useContext(Context);

  if (context === null) {
    throw new Error(
      'useConfiguration must be used within a ConfigurationProvider',
    );
  }
  return context;
};

export default ConfigurationProvider;
