import * as React from 'react';
import { lazy } from 'react';
import { Route } from 'react-router-dom';

// Components
const CmrPartnersModal = lazy(
  () => import('presentation/components/organisms/CmrPartnersModal'),
);

// Definitions
type Props = {
  to: 'cmr' | 'bank' | 'other';
};

const PartnerRoute = (props: Props) => {
  // Props
  const { to } = props;

  return (
    <Route
      render={({ location, history }) => {
        return (
          <CmrPartnersModal
            to={to}
            open={location.hash === `#cmr-partners-modal-${to}`}
            onClose={() => {
              history.replace(location.pathname);
            }}
          />
        );
      }}
    />
  );
};

export default PartnerRoute;
