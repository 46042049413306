import React from 'react';

// Components
import Image from 'presentation/components/atoms/Image';

//  Styled Components
import CouponUseHereContainer, {
  ContentPrimary,
  ContentSecondary,
  ContentText,
  WrapperLogosGroup,
} from './CouponUseHere.styled';

//  Defs
import { CouponUseHereProps } from './CouponUseHere.def';

const CouponUseHere = ({ couponUseText }: CouponUseHereProps) => {
  const { primaryText, primaryLogo, secondaryText, secondaryLogo } =
    couponUseText;
  const { fields } = primaryLogo;

  return (
    <CouponUseHereContainer>
      <ContentText>
        <ContentPrimary>
          <p>{primaryText}</p>
          <Image source={{ title: fields.title, url: fields.file?.url }} />
        </ContentPrimary>

        <ContentSecondary>
          <p>{secondaryText}</p>

          <WrapperLogosGroup>
            {secondaryLogo &&
              secondaryLogo?.map(item => {
                return (
                  <Image
                    key={item.name}
                    source={{
                      name: item.name,
                      mobile: item.mobile,
                      desktop: item.desktop,
                    }}
                  />
                );
              })}
          </WrapperLogosGroup>
        </ContentSecondary>
      </ContentText>
    </CouponUseHereContainer>
  );
};

export default CouponUseHere;
