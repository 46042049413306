import * as React from 'react';
import parser from 'html-react-parser';

const htmlParser = (
  text: string,
): React.ReactElement | string | object | void | undefined | null | false => {
  return parser(text);
};

export default htmlParser;
