import styled from 'styled-components';

const CouponsContainer = styled.div`
  width: 100%;
  padding-top: 3px;
  position: relative;

  @media ${({ theme }) => theme.devices.tablet} {
    padding-top: 23px;
  }

  @media ${({ theme }) => theme.devices.desktop} {
    padding-top: 0px;
  }
`;

export const WrapperLoader = styled.div`
  height: calc(85vh - 72px);
  display: flex;
  align-items: center;

  @media ${({ theme }) => theme.devices.tablet} {
    height: calc(60vh - 72px);
  }

  @media ${({ theme }) => theme.devices.desktop} {
    height: calc(85vh - 97px);
  }
`;

export default CouponsContainer;
