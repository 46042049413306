import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { IQuestion, NoQuestion } from 'domain/model/content';

const FaqQuestionMapper = (questionGroup: any): IQuestion => {
  try {
    const { question, answer: contentFulRichText } = questionGroup.fields;
    return {
      question,
      answer: documentToHtmlString(contentFulRichText),
    };
  } catch (error) {
    console.warn(
      '[FaqQuestioMapper] The content json structure received differed from expected',
    );

    return NoQuestion;
  }
};

export default FaqQuestionMapper;
