import STEPS_POINTS_ACCUMULATION from './detailPointsAccumulation';
import STEPS_MOVEMENTS_DETAILS from './movementsDetail';
import STEPS_FIDELITY_PRODUCTS from './lowFidelityProducts';
import STEPS_POINTS_DETAILS from './pointsDetail';
import STEPS_CHANGE_PASSWORD from './changePassword';
import STEPS_POINTS_EXPIRATION from './detailPointsExpiration';
import STEPS_FIDELITY_COUPONS from './lowFidelityCoupons';
import STEPS_FIDELITY_GIFTCARD from './lowFidelityGiftCard';

const STEPS_WALKTHROUGH = {
  movementsDetail: STEPS_MOVEMENTS_DETAILS,
  pointsDetail: STEPS_POINTS_DETAILS,
  changePassword: STEPS_CHANGE_PASSWORD,
  detailPointsExpiration: STEPS_POINTS_EXPIRATION,
  detailPointsAccumulation: STEPS_POINTS_ACCUMULATION,
  lowFidelityProducts: STEPS_FIDELITY_PRODUCTS,
  lowFidelityCoupons: STEPS_FIDELITY_COUPONS,
  lowFidelityGiftCard: STEPS_FIDELITY_GIFTCARD,
};

export default STEPS_WALKTHROUGH;
