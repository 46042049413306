import {
  parsePhoneNumberFromString,
  isValidPhoneNumber,
  CountryCode,
} from 'libphonenumber-js';
import LocaleConfig from 'domain/configuration/locale';

const { country } = LocaleConfig;
const COUNTRY_CODE = country as CountryCode;

export const phoneFormat = (value: string): string => {
  return (
    parsePhoneNumberFromString(value, COUNTRY_CODE)?.number.toString() ?? value
  );
};

export const phoneNationalFormat = (value: string): string => {
  return (
    parsePhoneNumberFromString(
      value,
      COUNTRY_CODE,
    )?.nationalNumber.toString() ?? value
  );
};

export const phoneValidate = (value: string): boolean => {
  return isValidPhoneNumber(value, COUNTRY_CODE);
};
