import styled from 'styled-components';
import { SlideProps } from 'presentation/components/molecules/ReedemCard/ReedemCard.def';

const Styled = {
  ExchangeExperiencesSection: styled.section`
    text-align: center;
    max-width: 100%;
    margin: 0 auto;
    @media ${() => '(min-width: 1280px)'} {
      max-width: 954px;
    }
    @media ${() => '(min-width: 1360px)'} {
      max-width: 1170px;
    }
    @media ${() => '(min-width: 1930px)'} {
      max-width: 1434px;
    }
  `,
  CarouselContainer: styled.div`
    width: 100%;
    .swiper {
      height: 33em;
      @media ${() => '(max-width: 460px)'} {
        height: 31em;
      }
      .swiper-pagination {
        bottom: -2px;
        .swiper-pagination-bullet {
          width: 6px;
          height: 6px;
          background-color: var(--color-btn-disabled);
          border-radius: 15px;
          margin-left: 8px;
          cursor: pointer;
          opacity: 1;
          transition: opacity 0.3s, background-color 0.3s, width 0.3s;
          transition-delay: 0.3s, 0.3s, 0s;

          &.swiper-pagination-bullet-active {
            background: #bdd004;
            width: 24px;
            transition-delay: 0s;
          }
        }
      }
      .swiper-wrapper {
        display: flex;
        justify-content: space-between;
        margin-left: 1em;
        @media ${() => '(max-width: 1024px)'} {
          margin-left: 2.8em;
        }
        @media ${() => '(max-width: 460px)'} {
          margin-left: 2em;
        }
        @media ${() => '(max-width: 375px)'} {
          margin-left: 1em;
        }
        .swiper-slide {
          width: 370px !important;
          height: 500px;
          margin-right: 1.2em;
          @media ${() => '(max-width: 1024px)'} {
            margin-right: 2.5em;
          }
          @media ${() => '(max-width: 460px)'} {
            width: 349px !important;
            height: 460px;
            margin-right: 2em;
          }
          @media ${() => '(max-width: 370px)'} {
            width: 338px !important;
            height: 460px;
            margin-right: 1.2em;
          }
        }
      }
    }
  `,
  WrapperCard: styled.div<SlideProps>`
    height: 100%;
    height: 500px;
  `,
};

export default Styled;
