import { createContext, useContext, useState } from 'react';

import { IMovement } from 'domain/model/movement';

// Stores
import UseMovementsStore from 'infrastructure/stores/Movements';

// Providers
import { useAuth } from '../Auth';

// Definitions
import { MovementsProviderValue } from './Movements.def';

// Defaults values
const Default: MovementsProviderValue = {
  data: {
    errorMovements: false,
    errorDownloadFile: false,
  },
  methods: {
    LoadTransactions: (date: Date, page: number) => {},
    GetTransactions: (date: Date, page: number) => [],
    GetEndPageDate: (date: Date) => 0,
    Reset: () => {},
    DownloadExcel: (transactions: IMovement[]) => new Blob(),
    DownloadPdf: (transactions: IMovement[]) => Promise.resolve(''),
    GetPagination: () => {},
  },
};

const Provider = () => {
  const {
    transactions,
    nextPageDate,
    endPageDate,
    loadTransactions,
    reset,
    exportToExcel,
    exportToPdf,
    nexPageRefBack,
    previousPageRefBack,
  } = UseMovementsStore();

  // States
  const [errorMovements, setErrorMovements] = useState(false);
  const [errorDownloadFile, setErrorFiles] = useState(false);

  // Providers
  const {
    data: { logged },
  } = useAuth();

  return {
    data: {
      errorMovements,
      errorDownloadFile,
    },
    methods: {
      LoadTransactions: async (
        date: Date,
        page: number = 1,
        nextPageRef?: string,
        previousPageRef?: string,
      ) => {
        try {
          await loadTransactions(
            logged?.data?.accessToken,
            date,
            page,
            nextPageDate[date.toISOString().substring(0, 7)],
            nextPageRef,
            previousPageRef,
          );
          setErrorMovements(false);
        } catch (error) {
          setErrorMovements(true);
        }
      },
      GetPagination: () => {
        try {
          return { nexPageRefBack, previousPageRefBack };
        } catch (error) {
          setErrorMovements(true);
          return '';
        }
      },
      GetTransactions: (date: Date, page: number = 1) => {
        try {
          return transactions[date.toISOString().substring(0, 7)][page - 1]
            ? transactions[date.toISOString().substring(0, 7)][page - 1]
            : [];
        } catch (error) {
          setErrorMovements(true);
          return [];
        }
      },
      GetEndPageDate: (date: Date) => {
        try {
          return endPageDate[date.toISOString().substring(0, 7)];
        } catch (error) {
          setErrorMovements(true);
          return 0;
        }
      },
      Reset: () => {
        try {
          reset();
        } catch (error) {
          setErrorMovements(true);
        }
      },
      DownloadExcel: async (transactions: IMovement[]) => {
        try {
          const file = await exportToExcel(
            logged?.data?.accessToken,
            transactions,
          );
          if ((file as Blob).type === 'text/html') {
            throw new Error('Error Extension');
          }

          return file;
        } catch (error) {
          setErrorFiles(true);
        }
      },
      DownloadPdf: async (
        transactions: IMovement[],
      ): Promise<string | undefined> => {
        try {
          const file = await exportToPdf(
            logged?.data?.accessToken,
            transactions,
          );
          if (!file) {
            throw new Error('Error Extension');
          }
          return Promise.resolve(file);
        } catch (error) {
          setErrorFiles(true);
        }
      },
    },
  };
};

const Context = createContext(Default);

/**
 * Component provider
 * @param children
 * @return {*}
 * @constructor
 */
const MovementsProvider = ({ children }: any): any => {
  const value = Provider();

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

/**
 * Component hook
 */
export const useMovements = () => useContext(Context);

export default MovementsProvider;
