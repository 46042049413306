import { IExchangeCard, NoExchangeCard } from 'domain/model/content';
import ImageResponsiveMapper from './ImageResponsiveMapper';
import LinkMapper from './LinkMapper';
import BadgeMapper from './BadgeMapper';
import ExchangeCardCategoryMapper from './ExchangeCategoryCardMapper';

const ExchangeCardMapper = (json: any): IExchangeCard => {
  try {
    const { fields } = json;
    const { name, image, text, points, link, showOnlyImage, badges, category } =
      fields;

    return {
      name,
      image: ImageResponsiveMapper(image),
      text,
      points,
      link: link && LinkMapper(link),
      showOnlyImage,
      badges: badges && badges.map(BadgeMapper),
      category: category && ExchangeCardCategoryMapper(category),
    };
  } catch {
    console.warn(
      '[ExchangeCardMapper] The content json structure received differed from expected',
    );

    return NoExchangeCard;
  }
};

export default ExchangeCardMapper;
