export interface ITab<TContent = any> {
  label: string;
  title?: string;
  content: TContent;
  contents?: TContent[];
  hidden: boolean;
}

export const NoTab: ITab = {
  label: '',
  content: {},
  contents: [],
  hidden: true,
};

export interface ITabsGroup {
  name: string;
  tabs: ITab[];
}

export const NoTabsGroup: ITabsGroup = {
  name: '',
  tabs: [],
};
