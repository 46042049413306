import { useState, useEffect, useMemo, useCallback } from 'react';
import styled from 'styled-components';

// Domain configuration
import Configuration from 'domain/configuration/form/login';
import { FormText } from 'domain/configuration/form';

// Components
import Button from 'presentation/components/atoms/Button';

// Utils
import Utils from '../LoginForm/FormUnified/FormUnified.utils';

// Definitions
export enum CODE {
  ERROR = '00',
  VERIFY = '01',
  BLOCKED = '03',
}

type Description = {
  text: string;
  url?: string;
};

type Props = {
  code: CODE;
  step?: 'unified';
  onBack?: () => void;
  onRecover?: () => void;
  recoverLink?: () => void;
  recoveryOnNewPage?: boolean;
};
const Feedback = (props: Props) => {
  // Props
  const {
    code,
    step = 'unified',
    onBack = () => {},
    onRecover = () => {},
    recoverLink = () => {},
    recoveryOnNewPage,
  } = props;

  // State
  const [title, setTitle] = useState<string>();
  const [subtitle, setSubtitle] = useState<Description[]>([]);
  const [descriptions, setDescriptions] = useState<string[]>(['']);

  // Utils
  const { render } = Utils;

  const showRecovery = useMemo(() => {
    if (code === CODE.BLOCKED && step === 'unified') {
      return true;
    }

    return false;
  }, [code, step]);

  const getFeedBack = useCallback(
    (fallback: (code: CODE) => any) => {
      const feedback = fallback(code);
      if (feedback) {
        const { title, subtitle, description } = feedback;
        setTitle(title as string);

        setSubtitle(
          (subtitle as FormText[])?.map(item => {
            return {
              text: item.text as string,
              url: item.url,
            } as Description;
          }),
        );

        if (description) {
          setDescriptions(
            (subtitle as FormText[])?.map(item => item.text as string),
          );
        }
      }
    },
    [code],
  );

  useEffect(() => {
    const { getFeedback } = Configuration(step).methods;
    getFeedBack(getFeedback);
  }, [code, step, getFeedBack]);

  const recoverPassword = () => {
    onRecover();
  };

  return (
    <Container>
      <Title>{title}</Title>

      <WrapperText>
        {subtitle?.map((item: Description) => {
          if (!item.url) return <Text key={item.text}>{item.text}</Text>;
          return (
            <Link key={item.text} href={item.url} target="_blank">
              {item.text}
            </Link>
          );
        })}

        {descriptions.map((text: string) => (
          <Text key={text}>{text}</Text>
        ))}
      </WrapperText>

      <Button
        data-testid={`feedback-boton-${showRecovery ? 'recovery' : 'volver'}`}
        variant={showRecovery ? 'primary' : 'tertiary'}
        onClick={showRecovery ? recoverPassword : onBack}
        style={{ marginBottom: `${showRecovery ? '0px' : '32px'}` }}
        isFullWidth>
        {showRecovery ? 'RECUPERAR CLAVE' : 'Volver'}
      </Button>

      {code === '01' && (
        <Group>{render.text('recovery', recoverLink, recoveryOnNewPage)}</Group>
      )}
    </Container>
  );
};
/* TODO: Revisar diseño contra Peru */
const Container = styled.div`
  background: #ffffff;
  border-radius: 6px;
  color: #5c6166;
`;

const Title = styled.h4`
  font-size: 24px;
  font-weight: 300;
  line-height: 140%;
  letter-spacing: -0.01em;
  color: #5c6166;
  margin-bottom: 24px;
`;

const WrapperText = styled.div`
  margin-bottom: 24px;
`;

const Text = styled.p`
  font-size: 15px;
  line-height: 175%;
  font-weight: 400;
  letter-spacing: -0.01em;
`;

const Link = styled.a`
  font-size: 18px;
  line-height: 21px;
  letter-spacing: -0.3375px;
  color: var(--color-border-focus);
  cursor: pointer;
  text-decoration: none;

  @media only screen and (max-width: 416px) {
    font-size: 16px;
    letter-spacing: -0.3375px;
    text-align: left;
  }
`;

const Group = styled.div`
  text-align: center;
`;

export default Feedback;
