import { useState } from 'react';
import { parse } from 'query-string';

import ConfigurationManager, {
  LoadsConfiguration,
} from 'infrastructure/stores/Configuration';

// Gets client's integration id
export const getClientId = (): string => {
  const currentClientID = sessionStorage.getItem('clientID');
  const query = parse(window.location.search);
  const newClientID = query.integratorID as string;

  if (newClientID && newClientID !== currentClientID) return newClientID;

  return currentClientID ?? 'default';
};

const storeClientId = (clientID: string) => {
  if (clientID === 'default ') return;

  sessionStorage.setItem('clientID', clientID);
};

// Gets the referrer url used to get into the web
const getReferrer = (): string => {
  const currentReferrer = sessionStorage.getItem('referrer');

  return currentReferrer ?? window.location.href;
};

const storeReferrer = (referrer: string) => {
  if (!referrer) return;

  sessionStorage.setItem('referrer', referrer);
};

const useUtils = () => {
  const [isLoad, setIsLoad] = useState(false);

  return {
    isLoad,
    fetchConfiguration: async () => {
      try {
        if (ConfigurationManager.isLoad) {
          setIsLoad(true);
          return;
        }

        const clientId = getClientId();
        const referrer = getReferrer();

        await LoadsConfiguration(clientId, { referrer });

        storeClientId(clientId);
        storeReferrer(referrer);
        setIsLoad(true);
      } catch (error) {
        console.error('Site configuration could not be downloaded.');
      }
    },
  };
};

export default useUtils;
