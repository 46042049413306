import { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ScreenClassProvider, setConfiguration } from 'react-grid-system';

// Infrastructure
import Router from 'infrastructure/router';
import AuthProvider from 'infrastructure/providers/Auth';
import ThemeProvider from 'infrastructure/providers/Theme';
import CustomerProvider from 'infrastructure/providers/Customer';
import AnalyticsProvider from 'infrastructure/providers/Analytics';
import MovementsProvider from 'infrastructure/providers/Movements';
import ContentfulProvider from 'infrastructure/providers/Contentful';
import ConfigurationProvider from 'infrastructure/providers/Configuration';
import Datadog from 'infrastructure/tools/Datadog';
import WalkthroughProvider from 'infrastructure/providers/Walkthrough';
import { LegalProvider } from 'infrastructure/providers/Legal';

// Grid configuration
setConfiguration({ defaultScreenClass: 'sm' });

function App() {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <>
      {!isLoaded && <></>}
      <Datadog />
      <ConfigurationProvider onReady={() => setIsLoaded(true)}>
        <AnalyticsProvider>
          <BrowserRouter>
            <AuthProvider>
              <MovementsProvider>
                <ContentfulProvider>
                  <CustomerProvider>
                    <ScreenClassProvider>
                      <ThemeProvider>
                        <WalkthroughProvider>
                          <LegalProvider>
                            <Router />
                          </LegalProvider>
                        </WalkthroughProvider>
                      </ThemeProvider>
                    </ScreenClassProvider>
                  </CustomerProvider>
                </ContentfulProvider>
              </MovementsProvider>
            </AuthProvider>
          </BrowserRouter>
        </AnalyticsProvider>
      </ConfigurationProvider>
    </>
  );
}

export default App;
