// Countries configuration
import cl from './cl';
import co from './co';
import pe from './pe';

// Form configuration map
const ConfigurationMap = {
  cl,
  co,
  pe,
};
const COUNTRY = process.env.REACT_APP_COUNTRY as keyof typeof ConfigurationMap;

export default ConfigurationMap[COUNTRY];
