import styled from 'styled-components';
import Images from 'presentation/assets/images';

const { backgroundSSO } = Images;

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100vh;
  background-image: url(${backgroundSSO});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-top: 30px;

  @media ${({ theme }) => theme.devices.desktop} {
    padding-top: 95px;
  }
`;

export const MainSection = styled.section`
  width: 100%;
  height: auto;
  max-width: 480px;
  min-height: 256px;
  padding: 32px 24px 40px;
  margin: 0 auto;
  border-radius: 6px;
  background-color: white;
  overflow-y: auto;
`;

export const CopyrightSection = styled.section`
  height: 50px;
  margin-top: 32px;
`;

export const Copyright = styled.p`
  width: 100%;
  text-align: center;
  display: -ms-flexbox;
  -ms-flex-pack: center;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
`;
