import { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

// React-pdf
import { Document, Page } from 'react-pdf/dist/umd/entry.webpack';

// Hooks
import useIsMobile from 'infrastructure/hooks/IsMobile';
import LegalContext from 'infrastructure/providers/Legal';
import { useConfiguration } from 'infrastructure/providers/Configuration';
import { useContentful } from 'infrastructure/providers/Contentful';

// Components
import Icon from 'presentation/assets/icons';
import ErrorLoadData from 'presentation/components/molecules/ErrorLoadData';
import LegalInformation from 'presentation/components/organisms/LegalInformation';
import Footer from 'presentation/components/organisms/Footer';
import LoadingPdf from './LoadingPdf';

// Styled
import Styled from './LegalDocuments.style';

const LegalDocuments = () => {
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const isMobile = useIsMobile();
  const { id } = useParams<{ id: string }>();
  const [numPages, setNumPages] = useState<number | null>(null);
  const [scrollFixed, setScrollFixed] = useState<boolean>(false);

  const {
    configuration: { features },
  } = useConfiguration();

  const {
    data: { common },
  } = useContentful();
  const commonUI = features.ui.common;

  const {
    legalDocsState,
    getActionLegalDocById,
    legalDocByIdState,
    errorGetDocs,
    loading,
    loadingActionTexts,
  } = useContext(LegalContext);

  const fileName = useMemo(
    () => legalDocsState?.find(item => item.id === id),
    [id, legalDocsState],
  );

  useEffect(() => {
    if (id && !loadingActionTexts) getActionLegalDocById(id, legalDocsState);
  }, [id, loadingActionTexts]);

  useEffect(() => {
    let isMounted = true;
    const handleScroll = () => setScrollFixed(window.scrollY >= 41);
    if (isMounted) window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      isMounted = false;
    };
  }, []);

  const handleDocLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
  };

  return (
    <>
      <Styled.Container isLoading={loading}>
        {loading && <LoadingPdf />}

        {errorGetDocs && (
          <Styled.WrapperError>
            <ErrorLoadData
              message="Estamos presentando problemas. Vuelve a intentarlo más tarde."
              iconName="loadFailed"
            />
          </Styled.WrapperError>
        )}

        {!!legalDocByIdState && !loading && !isSafari && (
          <Styled.WrapperPdf>
            {!!fileName && (
              <Styled.NavbarPdf scrollFixed={scrollFixed}>
                <h1>{fileName.description}</h1>

                <a
                  href={legalDocByIdState}
                  download={`${fileName.description
                    .toLowerCase()
                    .replace(/\s/g, '-')}-${id}`}>
                  <Icon name="download" />
                  Descargar Archivo
                </a>
              </Styled.NavbarPdf>
            )}

            <Styled.WrapperContent>
              <Styled.WrapperDocument>
                <Document
                  loading=""
                  noData=""
                  file={legalDocByIdState}
                  onLoadSuccess={handleDocLoadSuccess}>
                  {Array.from(new Array(numPages), (el, index) => (
                    <Page
                      loading=""
                      noData=""
                      key={`page_${index + 1}`}
                      pageNumber={index + 1}
                      width={1200}
                      scale={1}
                    />
                  ))}
                </Document>
              </Styled.WrapperDocument>
            </Styled.WrapperContent>
          </Styled.WrapperPdf>
        )}
      </Styled.Container>

      {commonUI.legalInformation && (
        <LegalInformation
          content={common.interestLinksContent}
          style={!isMobile ? { padding: '40px 80px 0' } : undefined}
        />
      )}

      {commonUI.footer && (
        <Footer
          content={common.interestLinksContent}
          copyright={common.copyRightContent.text}
          companyData={common.companyDataContent}
        />
      )}
    </>
  );
};

export default LegalDocuments;
