import { isAndroid, isIOS, isDesktop } from 'react-device-detect';
import ConfigurationManager from 'domain/configuration/client';
import clientMap from './clients';

const COUNTRY = process.env.REACT_APP_COUNTRY;

const DEFAULT_CONFIGURATION = 'loyalty.json';

const ClientsIdMap = clientMap as { [key: string]: string };

const Configuration = new ConfigurationManager();

type ConfigurationOptions = {
  referrer: string;
};

export const LoadsConfiguration = async (
  clientId: string,
  options: Partial<ConfigurationOptions> = {},
): Promise<void> => {
  const { referrer = '' } = options;
  const source = ClientsIdMap[clientId] ?? DEFAULT_CONFIGURATION;
  const configData = await import(`./clients/${COUNTRY}/${source}`);

  configData.device = 'mobile';

  if (isAndroid) configData.device = 'android';
  if (isIOS) configData.device = 'ios';
  if (isDesktop) configData.device = 'desktop';

  configData.referrer = referrer;

  Configuration.initialize(configData);
};

export default Configuration;
