import Modal from 'presentation/components/molecules/Modal';

import Styled from './ModalExpirationTooltip.style';

export type ExpirationModalProps = {
  isOpenModal: boolean;
  onClose: () => void;
};

export default function ModalExpirationTooltip({
  isOpenModal,
  onClose,
}: ExpirationModalProps) {
  return (
    <Modal
      id="modal-my-category-expiration-tooltip"
      open={isOpenModal}
      width="400px"
      rightIcon="close"
      onClose={onClose}
      requireCloseAction={['rightIcon']}
      title="Vencimiento de categoría">
      <Styled.WrapperContentModal>
        <Styled.HeadTextModal>Por acumulación</Styled.HeadTextModal>
        <Styled.DescriptionModal style={{ marginBottom: '54px' }}>
          Tu categoría actual tiene fecha de vencimiento ya que fue asegurada
          por un año al cumplir los puntos necesarios para mantenerla. Para
          asegurarla un año más, es necesario que acumules los puntos restantes.
        </Styled.DescriptionModal>

        <Styled.HeadTextModal>Tarjeta CMR</Styled.HeadTextModal>
        <Styled.DescriptionModal>
          Si tienes Tarjeta CMR Platinum o Signature, tu categoría no vence
          mientras tengas tu cuenta el día.
        </Styled.DescriptionModal>
      </Styled.WrapperContentModal>
    </Modal>
  );
}
