const value = [
  {
    name: 'facebook',
    url: 'https://www.facebook.com/cmrpuntoscolombia',
  },
  {
    name: 'instagram',
    url: 'https://www.instagram.com/cmrpuntoscol/',
  },
  {
    name: 'youtube',
    url: 'https://www.youtube.com/channel/UC0VVI-J4zCJPXn7JXAYm6aQ/videos',
  },
];

export default value;
