import { ILink } from './Common';

export interface ILinksGroup {
  name: string;
  title: string;
  links: ILink[];
  isHidden?: true;
}

export const NoLinksGroup: ILinksGroup = {
  name: '',
  title: '',
  links: [],
  isHidden: true,
};
