import styled from 'styled-components';
import { TextSizeMixin } from 'presentation/utils/styles/Mixins';

export const Wrapper = styled.div`
  position: fixed;
  background-color: #f3f5fc;
  z-index: 9;
  bottom: 0;
  width: 100%;
`;

export const WrapperButton = styled.div`
  width: 15%;
  margin-left: 40px;
  @media screen and (max-width: 769px) {
    width: 100%;
    margin-left: 0px;
  }
`;

export const Container = styled.div`
  width: 100%;
  padding: 20px;
  max-width: var(--container-width);
  margin: auto;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 769px) {
    flex-direction: column;
    padding: 16px;
    p {
      ${TextSizeMixin('small')};
    }
  }
  button: {
    margin: auto;
  }
`;

export const Gradient = styled.div`
  height: 3px;
  background: rgb(67, 176, 42);
  background: linear-gradient(
    90deg,
    rgba(67, 176, 42, 1) 0%,
    rgba(0, 114, 206, 1) 33%,
    rgba(228, 0, 70, 1) 66%,
    rgba(255, 182, 0, 1) 100%
  );
`;
