import * as React from 'react';
import { NodeRenderer } from '@contentful/rich-text-react-renderer';

// Components
import Anchor from 'presentation/components/atoms/Anchor';

// Definitions
import { RenderOptions } from './Renderers.def';

// Hyperlink renderer
const LinkRender = (options: RenderOptions) => {
  const { onLinkClick = () => {} } = options;

  const renderer: NodeRenderer = (node, children) => {
    const { data } = node;
    const { uri } = data;
    const linkName = children?.toString().toLowerCase() ?? '';

    return (
      <Anchor
        url={uri}
        target="blank"
        mode="inline"
        onClick={() => onLinkClick(linkName)}>
        {children}
      </Anchor>
    );
  };

  return renderer;
};

export default LinkRender;
