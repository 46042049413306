import { createRef, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

// Providers
import { useAuth } from 'infrastructure/providers/Auth';
import useWalkthrough from 'infrastructure/hooks/Walkthrough';

// Event Bus
import {
  EventTopicEnum,
  UiEventMessage,
  useEventBus,
} from 'infrastructure/eventBus';

// Components
import Captcha, { RefProps } from 'presentation/components/atoms/Captcha';
import { FormUnified, FormBankDef, FormPointsDef } from './LoginForm';
import Feedback, { CODE } from './Feedback';
import Loading from './Loading';

// Events
import LoginEvents from './LoginSteps.events';

// Definitions
export type Forms = {
  bank?: FormBankDef;
  points?: FormPointsDef;
  unified?: FormPointsDef;
};

export enum STEP {
  LOADING,
  FORM_UNIFIED,
  FEEDBACK,
}

type Props = {
  isSso?: boolean;
  isDisabled?: boolean;
  onSuccess?: () => void;
  onClose?: () => void;
};

const LoginSteps = (props: Props) => {
  // Props
  const {
    isSso = false,
    isDisabled = false,
    onSuccess = (token?: string) => undefined,
    onClose = () => undefined,
  } = props;

  // Hooks
  const {
    data: { logged },
    methods: { Login },
  } = useAuth();

  const history = useHistory();

  const { setCurrentStepAuth, closeWalkthrough } = useWalkthrough();

  // States
  const [feedback, setFeedback] = useState<'unified'>('unified');
  const [code, setCode] = useState(CODE.ERROR);
  const [step, setStep] = useState(STEP.FORM_UNIFIED);
  const [prevStep, setPrevStep] = useState<STEP>();
  const [form, setForms] = useState<Forms>({ unified: undefined });
  const recoveryOnNewPage = !!isSso;

  // Hooks
  const { publish } = useEventBus<UiEventMessage>(EventTopicEnum.UI);

  // References of captcha
  const reference = createRef<RefProps>();

  const handleLoggedError = useCallback(() => {
    resetCaptcha();
    if (logged.error === 500) setCode(CODE.ERROR);
    else if (logged.error === 423) setCode(CODE.BLOCKED);
    else setCode(CODE.VERIFY);
    setStep(STEP.FEEDBACK);
  }, [logged.error]);

  const handleOnSuccess = useCallback(
    (value?: string) => {
      value ? onSuccess(value) : onSuccess();

      // walkthrough flow
      setCurrentStepAuth();
    },

    [onSuccess],
  );

  // On change logged status
  useEffect(() => {
    if (logged.in) handleOnSuccess();
    if (logged.ssoSuccess) handleOnSuccess(logged.data?.payload);
    if (logged.error) handleLoggedError();
  }, [logged]);

  // On unmount component
  useEffect(() => {
    setStep(STEP.FORM_UNIFIED);
    setPrevStep(undefined);

    return () => {
      setStep(STEP.FORM_UNIFIED);
      setPrevStep(undefined);
    };
  }, []);

  const executeCaptcha = () => reference?.current?.execute();
  const resetCaptcha = () => reference?.current?.reset();

  const validate = async (tokenCaptcha: string) => {
    const to: 'Loyalty' = 'Loyalty';
    const current = form[feedback];

    if (current) {
      const send = {
        dni: current.dni,
        type: current.dniType,
        password: current.password,
        to,
        isSso,
      };
      await Login(send, tokenCaptcha);
    }
  };

  const submitPoints = (form: FormPointsDef) => {
    setForms({ unified: form });
    setStep(STEP.LOADING);
    setFeedback('unified');
    setPrevStep(STEP.FORM_UNIFIED);
    executeCaptcha();
  };

  const back = () => {
    setStep(prevStep ?? STEP.FORM_UNIFIED);
    resetCaptcha();
  };

  const recoverLink = () => {
    closeWalkthrough();
    publish(LoginEvents.click('recuperar clave'));
  };

  const recover = () => {
    publish(LoginEvents.click('recuperar clave'));
    if (isSso) {
      sessionStorage.removeItem('clientID');
      window.open('/clave');
      setStep(STEP.FORM_UNIFIED);
      resetCaptcha();
      setForms({ unified: undefined });
    } else {
      history.push('/clave');
    }
  };

  return (
    <Container id="content-login-steps">
      {step === STEP.LOADING && <Loading />}
      {step === STEP.FORM_UNIFIED && (
        <FormUnified
          recoveryOnNewPage={recoveryOnNewPage}
          onSubmit={submitPoints}
          onClose={onClose}
          onRecover={recoverLink}
          disabled={isDisabled}
        />
      )}
      {step === STEP.FEEDBACK && (
        <Feedback
          code={code}
          step={feedback}
          onRecover={recover}
          recoverLink={recoverLink}
          onBack={back}
          recoveryOnNewPage={recoveryOnNewPage}
        />
      )}
      <Captcha
        ref={reference}
        onValidate={tokenCaptcha => validate(tokenCaptcha as string)}
      />
    </Container>
  );
};

const Container = styled.div``;

export default LoginSteps;
