import contentful from 'infrastructure/services/contentFul';

// Model
import { ICustomerCategory } from 'domain/model';
// Mappers
import CategoryMapper from '../mappers/CategoryBenefitsMapper';

// Service
const {
  entriesResource: { getEntries },
} = contentful;

const LoadsCategoryBenefits = async (category: ICustomerCategory) => {
  // distinto de categoria
  try {
    const entryId = await getEntries<any>({
      content_type: 'categoryBenefitsEvolution',
      'fields.category[match]': category.currentCategory.name,
      include: 10,
    });

    return {
      categoryBenefits: CategoryMapper(entryId.items[0]),
    };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('ERROR - ', error);
  }
};

export default LoadsCategoryBenefits;
