export enum CustomerType {
  Loyalty = 'Loyalty',
  Bank = 'Banco',
  NoValidate = 'NoValidate',
  NoType = '',
}

export enum CustomerCategoryCode {
  Normal = '00',
  Premium = '01',
  Elite = '02',
  Fan = '04',
}

export enum CustomerCategoryName {
  Normal = 'normal',
  Fan = 'fan',
  Elite = 'elite',
  Premium = 'premium',
}

export interface ICustomerCategory {
  currentCategory: {
    id: CustomerCategoryCode;
    name: CustomerCategoryName;
    origin: 'Transactions' | 'Product';
    icon?: CustomerCategoryName;
  };
  productCategory: {
    id: CustomerCategoryCode;
    name: CustomerCategoryName;
    startDate?: Date;
    endAlertBenefitDate?: Date;
  };
  accumulationCategory: {
    id: CustomerCategoryCode;
    name: CustomerCategoryName;
    startDate?: Date;
    endDate?: Date;
    requiredPoints: number;
    requiredPointsToMaintain: number;
    endAlertBenefitDate?: Date;
    startAlertKeepCatDate?: Date;
    nextCategory1?: INextCategoryAcumulation;
    nextCategory2?: INextCategoryAcumulation;
  };
}

export interface INextCategoryAcumulation {
  id: string;
  name: CustomerCategoryName;
  requiredPoints: number;
  requiredPointsToUpgrade: number;
}

export interface IPointsExpiration {
  month: string;
  shortMonth: string;
  points: number;
}

export interface ICustomerPoints {
  total: number;
  expiration: IPointsExpiration[];
  earned: number;
}

export interface ICustomerStatus {
  type: CustomerType;
}

export interface INextCategory {
  requiredPoints: number;
  requiredPointsToUpgrade: number;
  category: CustomerCategoryName;
}

export interface ICustomer {
  firstName: string;
  lastName: string;
  type: CustomerType;
  category: ICustomerCategory;
  nextCategory: INextCategoryAcumulation;
  points: ICustomerPoints;
  benefits?: IBenefits[];
}

export interface IBenefits {
  id: number;
  resourceName: string;
  reedeemCode: string;
  origin: string;
  description: string;
  isRedeemed: boolean;
  createdAt: string;
  expirationDate: string;
}

export interface ICustomerUpdate {
  code: string;
  email?: string;
  phone?: string;
  token: string;
}

export interface IReturnCustomerUpdate {
  status: string;
  data: {
    message: string;
  };
}

export interface IReturnCustomerData {
  status: string;
  data: {
    firstName: string;
    middleName: string;
    lastSurname: string;
    additionalSurname: string;
    gender: string;
    birthdate: string;
    telephone: string;
    email: string;
  };
}
