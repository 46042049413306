import styled from 'styled-components';
import Images from 'presentation/assets/images';

const { confeti } = Images;

type WrapperContent = {
  isMobile: boolean;
};

const Styled = {
  WrapperContentModal: styled.div<WrapperContent>`
    text-align: ${({ isMobile }) => (isMobile ? 'left' : 'center')};
    padding: ${({ isMobile }) => (isMobile ? '20px' : '20px 60px 60px 60px')};
    h1 {
      color: ${({ isMobile }) =>
        isMobile ? 'var(--color-primary)' : '#323537'};
    }
    .premium {
      b {
        color: #808080;
      }
    }
    .fan {
      b {
        color: #eb0045;
      }
    }
    .elite {
      b {
        color: #000000;
      }
    }
  `,
  Header: styled.div`
    position: relative;
  `,
  WrapperIcon: styled.div`
    text-align: center;
    position: absolute;
    width: 100%;
    bottom: -30px;
  `,
  WrapperText: styled.div`
    margin-bottom: 30px;
    line-height: 30px;
    font-size: 20px;
    font-weight: 300;
  `,
  HeaderImage: styled.div`
    background-image: url(${confeti});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 180px;
  `,
};
export default Styled;
