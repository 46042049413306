import styled from 'styled-components';

export const Wrapper = styled.div<{ width: number }>`
  height: ${props => `${props.width}px`};
  width: ${props => `${props.width}px`};
  position: relative;
`;

export const Content = styled.div<{ width: number }>`
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  width: ${props => `${props.width}px`};
`;
