import React from 'react';

// Components
import HtmlParser from 'presentation/components/molecules/HtmlParser';

// Defs
import { LegalTextProps } from './LegalText.def';

// Styled Components
import LegalTextContainer from './LegalText.styled';

const parserOptions = {
  allowedTags: ['p', 'b'],
};

const LegalText = ({ children }: LegalTextProps) => {
  return (
    <LegalTextContainer>
      <HtmlParser text={children} options={parserOptions} />
    </LegalTextContainer>
  );
};

export default React.memo(LegalText);
