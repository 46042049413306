import * as React from 'react';

// Models
import { ILink, ILinksGroup } from 'domain/model/content';

// Components
import Accordion, {
  AccordionItem,
} from 'presentation/components/molecules/Accordion';
import LinksGroup from 'presentation/components/molecules/LinksGroup';

// Definitions
type Props = {
  content: ILinksGroup[];
  onClick?: (link: ILink) => void;
};

const MobileContent = (props: Props) => {
  // Props
  const { content, onClick = () => {} } = props;

  return (
    <Accordion variant="light">
      {content.map(group => {
        return (
          <AccordionItem key={group.title} heading={group.title}>
            <LinksGroup
              content={group}
              variant="light"
              showTitle={false}
              onClick={onClick}
              style={{ marginLeft: '-24px' }}
            />
          </AccordionItem>
        );
      })}
    </Accordion>
  );
};

export default MobileContent;
