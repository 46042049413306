import styled from 'styled-components';

const Styled = {
  Section: styled.div`
    background-color: var(--color-gray);
  `,
  Header: styled.div`
    padding: 4em 24px 0;
    color: #323537;

    @media ${({ theme }) => theme.devices.tablet} {
      text-align: center;
    }

    @media ${({ theme }) => theme.devices.desktop} {
      text-align: center;
    }

    @media ${({ theme }) => theme.devices.wideDesktop} {
      text-align: center;
    }
  `,
  Title: styled.h2`
    font-weight: 400;
    font-size: 40px;
    color: #44474b;
    margin: 0 auto 0.5em;
    text-align: center;

    @media (max-width: 460px) {
      font-size: 32px;
    }

    @media ${({ theme }) => theme.devices.tablet} {
      font-weight: 300;
      text-align: center;
      font-size: 32px;
      margin: 0 auto 0.5em;
    }

    @media ${({ theme }) => theme.devices.desktop} {
      font-weight: 400;
      text-align: center;
      font-size: 40px;
      margin: 0 auto 0.5em;
    }

    @media ${({ theme }) => theme.devices.wideDesktop} {
      font-weight: 400;
      text-align: center;
      font-size: 40px;
      margin: 0 auto 0.5em;
    }
  `,
  Subtitle: styled.p`
    font-size: 22px;
    font-weight: 300;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: #63696e;
    font-weight: 500;
    max-width: 100%;
    text-align: center;

    @media ${({ theme }) => theme.devices.tablet} {
      text-align: center;
      font-size: 22px;
      margin: 0 auto;
    }

    @media ${({ theme }) => theme.devices.desktop} {
      text-align: center;
      font-size: 22px;
      margin: 0 auto;
    }
  `,
  Content: styled.div`
    padding: 3.5em 24px 0px;
    overflow: hidden;
    .swiper {
      overflow: visible;

      .swiper-wrapper {
        min-height: 100%;
      }

      .swiper-slide {
        height: auto;
      }
    }
    max-width: 100%;
    margin: 0 auto;

    @media ${() => '(min-width: 1280px)'} {
      max-width: 1188px;
    }

    @media ${() => '(min-width: 1360px)'} {
      max-width: 1170px;
    }

    @media ${() => '(min-width: 1930px)'} {
      max-width: 1380px;
    }
  `,
  Footer: styled.footer`
    width: fit-content;
    margin: 0 auto;
    text-align: center;
    padding: 41px 24px 3em 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    button {
      font-weight: 500;
    }
  `,
  DiscleimerPremiumCategory: styled.p`
    font-weight: 450;
    font-size: 14px;
    line-height: 150%;

    text-align: center;
    letter-spacing: -0.01em;

    color: #474c4e;
  `,
  Alert: styled.div`
    display: flex;
    align-items: center;
    padding: 16px;
    width: fit-content;
    background: #f3f5fc;
    border: 0.5px solid #f3f5fc;
    border-radius: 8px;
    margin-top: 16px;
    text-align: left;
  `,
};

export default Styled;
