import { UiEventMessage } from 'infrastructure/eventBus';

const createEvent = (options: {
  subject: string;
  target: string;
  exchangeActive: string;
}): UiEventMessage => {
  const { subject, target, exchangeActive } = options;

  const MODULE_NAME =
    !!exchangeActive || exchangeActive !== ''
      ? `Landing_canje_${exchangeActive}`
      : 'Que_quieres_canjear';

  return {
    module: MODULE_NAME,
    action: 'card_clicked',
    subject,
    target,
  };
};

const events = {
  cardClicked: (target: string, exchangeActive: string) => {
    return createEvent({
      subject: `Card ${target} has been clicked`,
      target,
      exchangeActive,
    });
  },
};

export default events;
