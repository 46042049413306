import {
  IExchangeCardCategory,
  NoExchangeCardCategory,
} from 'domain/model/content';
import ImageMapper from './ImageMapper';

const ExchangeCardCategoryMapper = (json: any): IExchangeCardCategory => {
  try {
    const { fields } = json;
    const { name, icon, color } = fields;

    return {
      name,
      icon: ImageMapper(icon),
      color,
    };
  } catch {
    console.warn(
      '[ExchangeCardCategoryMapper] The content json structure received differed from expected',
    );

    return NoExchangeCardCategory;
  }
};

export default ExchangeCardCategoryMapper;
