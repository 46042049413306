import { AxiosRequestConfig } from 'axios';
import { Aes, Rsa } from 'infrastructure/utils/crypto';

// Definitions
import { ExtendConfig } from '../definitions';

const EncryptInterceptor = (config: AxiosRequestConfig) => {
  const extend: ExtendConfig = { ...config };
  if (extend.encrypt) {
    const { data, encrypt } = extend;
    const { secret, iv, encode, encryptValues, ignoreKeys } = encrypt;
    if (!secret || !iv) throw new Error('Secret or iv not found');
    const options =
      iv || encode || encryptValues || ignoreKeys
        ? { encode, iv, encryptValues, ignoreKeys }
        : undefined;
    extend.headers['x-session-key'] = Rsa.encrypt({ AESKey: secret, iv });
    extend.headers['x-signature'] = Aes.signature(data, secret, options);
    if (data) extend.data = { userData: Aes.encrypt(data, secret, options) };
    return extend;
  }
  return config;
};

export default EncryptInterceptor;
