import React, { CSSProperties } from 'react';
import styled from 'styled-components';

import Tooltip from 'presentation/components/atoms/Tooltip';
import { StyledExtendable } from 'presentation/utils/styles';

// Definitions
type HelpTipSize = 'small' | 'medium' | 'normal';
type HelpTipIconColor = 'green' | 'dark';
type HelpTipPlacement = 'top' | 'right' | 'bottom' | 'left' | 'topleft';

type ThemingOptions = {
  size?: HelpTipSize;
  color?: HelpTipIconColor;
};

export type Props = {
  id?: string;
  value: string;
  placement?: HelpTipPlacement;
  label?: string;
  style?: CSSProperties;
} & ThemingOptions &
  StyledExtendable;

/**
 * Atom HelpTip
 * @version 1.0.0
 * @param {string} id 'used for id and data-testid'
 * @param {string} value
 * @param {HelpTipPlacement} [placement='top'] top | right | bottom | left
 * @param {HelpTipSize} [size='normal'] small | normal
 * @param {HelpTipIconColor} [colorIcon='dark'] dark | green
 */
const HelpTip = (props: Props) => {
  const {
    id,
    value,
    placement = 'top',
    size = 'normal',
    color = 'dark',
    label,
    className,
    style = {},
  } = props;
  const tooltipId = id && `${id}-helptip`;

  return (
    <Tooltip id={tooltipId} text={value} placement={placement}>
      <StyledHelpTip
        id={id}
        data-testid={id}
        size={size}
        color={color}
        className={className}
        label={label}
        style={style}>
        {label}
      </StyledHelpTip>
    </Tooltip>
  );
};

const StyledHelpTip = styled.span<{
  size: string;
  color: string;
  label?: string;
  style: CSSProperties;
}>`
  display: inline-block;
  background-repeat: no-repeat;
  width: ${({ size, label }) => {
    if (label) return 'auto';
    if (size === 'small') return '16px';
    if (size === 'medium') return '18px';
    if (size === 'normal') return '20px';
  }};
  padding-right: ${({ label }) => label && '18px'};
  height: ${({ size }) => {
    if (size === 'small') return '16px';
    if (size === 'medium') return '18px';
    if (size === 'normal') return '20px';
  }};
  background-image: ${({ color }) => `var(--tooltips-${color}-icon)`};
  background-size: ${({ size }) => {
    if (size === 'small') return '16px 16px';
    if (size === 'medium') return '18px 18px';
    if (size === 'normal') return '20px 20px';
  }};
  background-position: right;
  font-weight: 600;
  vertical-align: middle;
  cursor: pointer;
`;

export default HelpTip;
