import {
  EventTopicEnum,
  UiEventMessage,
  useEventBus,
} from 'infrastructure/eventBus';

// Events
import Events from './Exchanges.events';

const useExchangesUtils = () => {
  // Event bus
  const { publish } = useEventBus<UiEventMessage>(EventTopicEnum.UI);

  // Handlers
  const handleCardClick = async (cardText: string, exchangeActive: string) => {
    publish(Events.cardClicked(cardText, exchangeActive));
  };

  return {
    methods: {
      on: {
        cardClick: handleCardClick,
      },
    },
  };
};

export default useExchangesUtils;
