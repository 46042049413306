import React from 'react';
import styled from 'styled-components';

// utils
import {
  TextDefaultFontMixin,
  TextSizeMixin,
  TextWeightMixin,
} from 'presentation/utils/styles/Mixins';
import { ariaAttr } from 'presentation/utils/aria';

// defs
import { BadgeProps, StyleProps } from './Badge.defs';
import variants from './Badge.variants';

const Badge = ({ id, variant, children }: BadgeProps) => {
  const { backgroundColor, textColor, borderColor } = getVariant(variant);
  let ariaAtributes;

  const testId = id && `${id}-badge`;

  if (typeof children === 'string') {
    ariaAtributes = ariaAttr('label', children);
  }

  return (
    <Styled.Badge
      id={id}
      data-testid={testId}
      backgroundColor={backgroundColor}
      textColor={textColor}
      borderColor={borderColor}
      role="status"
      {...ariaAtributes}>
      {children}
    </Styled.Badge>
  );
};

const getVariant = (variant: BadgeProps['variant']): StyleProps => {
  let theme = variants[variant];

  if (!theme) {
    theme = variants.white;
  }

  return theme;
};

const Styled = {
  Badge: styled.span<StyleProps>`
    ${TextDefaultFontMixin}
    ${TextSizeMixin('small')};
    ${TextWeightMixin('semi-bold')};

    background: ${props => props.backgroundColor};
    border-color: ${props => props.borderColor};
    border-radius: 8px;
    border-style: solid;
    border-width: 0.5px;
    color: ${props => props.textColor};
    display: inline-block;
    padding: 4px 8px;
    text-align: center;
    line-height: 17px;
    padding-top: 5px;
  `,
};

export default Badge;
