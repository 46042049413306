import { useMemo } from 'react';
// Hooks
import useWindowSize from 'infrastructure/hooks/WindowResize';

// Components
import CircleProgressBar from 'presentation/components/molecules/CircleProgressBar';
import Icon, { IconName } from 'presentation/assets/icons';

// Def
import { CardCategoryProps } from './CardCategory.def';

// Styled
import Styled from './CardCategory.style';

const CardCategory = ({
  title,
  points,
  icon,
  color,
  progressBar,
  progressPoints,
  children,
}: CardCategoryProps) => {
  const { width } = useWindowSize();
  const isSizeDesktop = useMemo(() => width && width >= 1024, [width]);

  let iconName = `${icon}Only`;
  if (icon === 'normal') iconName = 'cmr';

  return (
    <Styled.Container data-testid={`card-category-${iconName}`}>
      <Styled.WrapperIcon>
        {!progressBar && icon && (
          <Icon
            name={iconName as IconName}
            size={isSizeDesktop ? 'large' : 'small'}
          />
        )}

        {progressBar && (
          <CircleProgressBar
            duration={1.5}
            progress={progressPoints ?? 0}
            width={isSizeDesktop ? 64 : 50}
            stroke={5}
            color={color}
            background="#B2C0C8">
            <Styled.WrapperProgressContent>
              {icon && (
                <Icon
                  name={iconName as IconName}
                  size={isSizeDesktop ? 'small' : 'tiny'}
                />
              )}
            </Styled.WrapperProgressContent>
          </CircleProgressBar>
        )}
      </Styled.WrapperIcon>

      <Styled.WrapperContent>
        <Styled.WrapperTitle>
          <Styled.Title>{title}</Styled.Title>
          <Styled.Points>{points}</Styled.Points>

          {points && <Icon name="cmr" size="small" />}
        </Styled.WrapperTitle>

        <Styled.WrapperTitle>
          <Styled.Description>{children}</Styled.Description>
        </Styled.WrapperTitle>
      </Styled.WrapperContent>
    </Styled.Container>
  );
};

export default CardCategory;
