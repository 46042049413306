// Domain content
import { IImage, IImageResponsive } from 'domain/model/content';

// Definitions
export type Device = 'mobile' | 'desktop';
export type CardSize = 'default' | 'small';
export type CardDimensions = {
  width: number;
  height: number;
};
export type AnchorConfigResponse = {
  url: string;
  target: 'self' | 'route';
};
export type GetAnchorConfigProps = {
  client: string;
  url: string;
  external?: boolean;
  utmMedium?: string;
};

/**
 * Constants
 * Obs: Known sizes definition for cards
 */
const SIZES = {
  default: {
    mobile: {
      width: 158,
      height: 198,
    },
    desktop: {
      width: 308,
      height: 198,
    },
    radius: 16,
  },
  small: {
    mobile: {
      width: 194,
      height: 200,
    },
    desktop: {
      width: 194,
      height: 200,
    },
    radius: 12,
  },
};

const useUtils = () => {
  return {
    methods: {
      getCardDimensionsBySize: (
        size: CardSize,
        device: Device,
      ): CardDimensions => {
        return {
          width: SIZES[size][device].width,
          height: SIZES[size][device].height,
        };
      },
      getRadiusBySize: (size: CardSize): number => {
        return SIZES[size].radius;
      },
      getImageTitle: (image: IImage | IImageResponsive, device: Device) => {
        if ((image as IImage).title) return (image as IImage).title;
        return (image as IImageResponsive)[device].title;
      },
    },
  };
};

export default useUtils;
