import { ICustomerCategoryCard } from 'domain/model/content';
import LinkMapper from './LinkMapper';

const CustomerCategoryMapper = (json: any): Array<ICustomerCategoryCard> => {
  try {
    const { fields } = json;
    const { cards } = fields;

    return cards.map((item: any) => {
      const { fields } = item;
      return {
        categoryCode: fields.categoryCode,
        name: fields.name,
        iconName: fields.iconName,
        benefist: fields.benefits,
        text: fields.text,
        cta: LinkMapper(fields.link),
      } as ICustomerCategoryCard;
    });
  } catch {
    console.warn(
      '[CustomerCategoryMapper] The content json structure received differed from expected',
    );

    return [];
  }
};

export default CustomerCategoryMapper;
