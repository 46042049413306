import WalkthroughSteps from '../walkthroughConfig.defs';

const STEPS: WalkthroughSteps = {
  mainPath: '/movements',
  isPublic: false,
  desktop: [
    {
      selector: '#login-content',
      content: 'Inicia tu sesión de CMR Puntos.',
      highlightedSelectors: ['#login-content'],
      mutationObservables: ['#login-content'],
      resizeObservables: ['#login-content'],
      stepInteraction: true,
    },
    {
      selector: '#user-menu',
      content: 'Revisa el total de tus puntos acumulados.',
      resizeObservables: ['#user-menu'],
    },
    {
      selector: '#data-table-section-id',
      highlightedSelectors: ['#data-table-section-id'],
      mutationObservables: ['#data-table-section-id'],
      resizeObservables: ['#data-table-section-id', '#message-box-wrapper-id'],
      content:
        'Revisa si se hizo el canje de tu Gift Card y se descontaron tus CMR Puntos.',
      position: 'top',
    },
    {
      selector: '#data-table-section-id',
      highlightedSelectors: ['#data-table-section-id'],
      mutationObservables: ['#data-table-section-id'],
      resizeObservables: ['#data-table-section-id', '#message-box-wrapper-id'],
      content: 'Si no ves tu movimiento de canje, es porque este no se hizo.',
      position: 'top',
    },
  ],
  mobile: [
    {
      selector: '#login-content',
      content: 'Inicia tu sesión de CMR Puntos.',
      highlightedSelectors: ['#content-login-steps'],
      mutationObservables: ['#content-login-steps'],
      resizeObservables: ['#content-login-steps'],
    },
    {
      selector: '#user-menu',
      content: 'Revisa el total de tus puntos acumulados.',
      resizeObservables: ['#user-menu'],
    },
    {
      selector: '#data-table-section-id',
      highlightedSelectors: ['#data-table-section-id'],
      mutationObservables: [
        '#data-table-section-id',
        '#message-box-wrapper-id',
      ],
      resizeObservables: ['#data-table-section-id', '#message-box-wrapper-id'],
      content:
        'Revisa si se hizo el canje de tu Gift Card y se descontaron tus CMR Puntos.',
      position: 'top',
    },
    {
      selector: '#data-table-section-id',
      highlightedSelectors: ['#data-table-section-id'],
      mutationObservables: [
        '#data-table-section-id',
        '#message-box-wrapper-id',
      ],
      resizeObservables: ['#data-table-section-id', '#message-box-wrapper-id'],
      content: 'Si no ves tu movimiento de canje, es porque este no se hizo.',
      position: 'top',
    },
  ],
};

export default STEPS;
