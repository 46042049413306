import * as React from 'react';

// Styles
import {
  Wrapper,
  Heading,
  Panel,
  getVariantTokens,
} from './AccordionItem.styles';

// Definitions
export type Props = {
  variant?: 'light' | 'dark';
  heading: string;
  index?: number;
  expanded?: boolean;
  children: React.ReactNode;
  onClick?: (index: number) => void;
};

const AccordionItem = (props: Props) => {
  const {
    variant = 'dark',
    index,
    heading,
    expanded,
    children,
    onClick,
  } = props;

  const variantTokens = getVariantTokens(variant);

  return (
    <Wrapper>
      <Heading
        id={`accordion-item__heading-${index}`}
        tokens={variantTokens}
        role="heading"
        className="accordion-item__heading"
        expanded={!!expanded}
        aria-level={3}
        onClick={() => index && onClick && onClick(index)}>
        {heading}
      </Heading>
      <Panel
        role="region"
        className="accordion-item__content"
        tokens={variantTokens}
        aria-labelledby={`accordion-item__heading-${index}`}
        aria-hidden={!expanded}>
        {children}
      </Panel>
    </Wrapper>
  );
};

export default AccordionItem;
