import { IBadge, NoBadge } from 'domain/model/content';

const BadgeMapper = (json: any): IBadge => {
  try {
    const { fields } = json;
    const { name, text, color } = fields;

    return {
      name,
      text,
      color,
    };
  } catch {
    console.warn(
      '[BadgeMapper] The content json structure received differed from expected',
    );

    return NoBadge;
  }
};

export default BadgeMapper;
