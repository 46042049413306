import * as React from 'react';
import { useState, useEffect } from 'react';

// Components
import Image from 'presentation/components/atoms/Image';
import SmartLink from 'presentation/components/organisms/SmartLink';
import HtmlParser from 'presentation/components/molecules/HtmlParser';

// Domain content
import { ISpecialContent } from 'domain/model/content';

// Styles
import {
  Container,
  IconLogin as Icon,
  CloseButton,
  ImageWrapper,
  LinkWrapper,
} from './LoginIncentive.styles';

// Definitions
export type Props = {
  data: ISpecialContent;
  allowClose?: boolean;
};

const DELAYED_TIME = 300;

const LoginIncentive = (props: Props) => {
  const { allowClose, data } = props;
  const { image, text, link } = data;

  const [show, setShow] = useState(true);
  const [isMounted, setIsMounted] = useState(true);

  const handleOnClose = () => {
    setShow(false);
  };

  useEffect(() => {
    if (!allowClose) return;

    if (!show) {
      setTimeout(() => setIsMounted(false), DELAYED_TIME);
    }
  }, [allowClose, show]);

  return (
    <>
      {isMounted && (
        <Container
          show={show}
          duration={DELAYED_TIME}
          data-testid="incentive-test">
          {image && (
            <ImageWrapper>
              <Image width={37} height={35} source={image} autoFit={false} />
            </ImageWrapper>
          )}
          {link && (
            <LinkWrapper>
              <SmartLink
                url={link.url}
                target="route"
                mode="stock"
                deepLink="redirect"
                style={{ display: 'inline-block', padding: '0 10px 0 0' }}>
                {text && <HtmlParser text={text} />}
              </SmartLink>
            </LinkWrapper>
          )}
          {allowClose && (
            <CloseButton
              icon={<Icon name="close" size="tiny" />}
              size="S"
              onClick={handleOnClose}
            />
          )}
        </Container>
      )}
    </>
  );
};

export default LoginIncentive;
