import * as React from 'react';
import { MouseEvent } from 'react';
import { Link } from 'react-router-dom';

// Styles
import { AnchorType, StyledAnchor, StyledLinkWrapper } from './Anchor.style';

// Definitions
export type Props = {
  id?: string;
  children?: any;
  url: string;
  dyDecisionId?: string;
  dySlotId?: string;
  /**
   *  - blank: External navigation in a new tab.
   *  - route: Internal react router navigation.
   *  - self: External navigation in the same tab.
   */
  target?: 'route' | 'self' | 'blank' | 'parent' | 'top';
  rel?:
    | 'alternate'
    | 'author'
    | 'bookmark'
    | 'external'
    | 'help'
    | 'license'
    | 'next'
    | 'nofollow'
    | 'noopener'
    | 'noreferrer'
    | 'prev'
    | 'search'
    | 'tag';
  referrer?:
    | 'no-referrer-when-downgrade'
    | 'no-referrer'
    | 'origin-when-cross-origin'
    | 'origin'
    | 'same-origin'
    | 'strict-origin-when-cross-origin'
    | 'unsafe-url';
  mode?: AnchorType;
  width?: string;
  height?: string;
  style?: React.CSSProperties;
  onClick?: (event?: MouseEvent<HTMLAnchorElement>) => void;
};

/**
 * @param id
 * @param children
 * @param url
 * @param target - route | self | blank | parent | top
 * @param dyDecisionId - dynamic yield data-attributes for decisionid
 * @param dySlotId - dynamic yield data attributes for sliot id
 * @param rel - alternate | author | bookmark | external | help | license | next | nofollow | noopener | noreferrer | prev | search | tag
 * @param referrer no-referrer-when-downgrade | no-referrer | origin-when-cross-origin | origin | same-origin | strict-origin-when-cross-origin | unsafe-url
 * @param mode inline | stock | default
 * @param width string
 * @param height string
 * @param style CSSProperties
 * @param onClick event: MouseEvent<HTMLAnchorElement>
 */
const Anchor = (props: Props) => {
  // Props
  const {
    children,
    height,
    id,
    mode = 'default',
    onClick,
    referrer,
    rel,
    style,
    target = 'self',
    url,
    width,
    dyDecisionId,
    dySlotId,
  } = props;

  return (
    <>
      {target === 'route' ? (
        <StyledLinkWrapper height={height} mode={mode} width={width}>
          <Link
            data-testid={id}
            id={id}
            style={style}
            onClick={onClick}
            referrerPolicy={referrer}
            rel={rel}
            data-dy-decision-id={dyDecisionId}
            data-dy-slot-id={dySlotId}
            to={url}>
            {children}
          </Link>
        </StyledLinkWrapper>
      ) : (
        <StyledAnchor
          data-testid={id}
          height={height}
          href={url}
          id={id}
          mode={mode}
          onClick={onClick}
          referrerPolicy={referrer}
          rel={rel}
          style={style}
          data-dy-decision-id={dyDecisionId}
          data-dy-slot-id={dySlotId}
          target={`_${target}`}
          width={width}>
          {children}
        </StyledAnchor>
      )}
    </>
  );
};

export default Anchor;
