import { ICampaignCard, NoCampaignCard } from 'domain/model/content';
import ImageResponsiveMapper from './ImageResponsiveMapper';
import LinkMapper from './LinkMapper';

const CampaingCardMapper = (json: any): ICampaignCard => {
  try {
    const { fields } = json;

    const { image, name, link, visible, visibility } = fields;
    return {
      image: ImageResponsiveMapper(image),
      name,
      link: link && LinkMapper(link),
      visible,
      visibility,
    };
  } catch {
    console.warn(
      '[CampaingCardMapper] The content json structure received differed from expected',
    );

    return NoCampaignCard;
  }
};

export default CampaingCardMapper;
