import * as React from 'react';
import { NodeRenderer } from '@contentful/rich-text-react-renderer';

// Content Mapper
import LinkMapper from 'infrastructure/stores/Contentful/mappers/LinkMapper';

// Components
import Anchor from 'presentation/components/atoms/Anchor';

// Definitions
import { RenderOptions } from './Renderers.def';

// Link entry renderer
const EntryLinkRender = (options: RenderOptions) => {
  const { onLinkClick = () => {} } = options;

  const renderer: NodeRenderer = (node, children) => {
    const { data } = node;
    const link = LinkMapper(data.target);
    const linkName = children?.toString().toLowerCase() ?? '';

    return (
      <Anchor
        url={link.url}
        target={link.external ? 'blank' : 'route'}
        mode="inline"
        onClick={() => onLinkClick(linkName)}>
        {children}
      </Anchor>
    );
  };

  return renderer;
};

export default EntryLinkRender;
