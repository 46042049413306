import styled from 'styled-components';

interface CarouselCouponsContainerProps {
  couponCounter: number;
}

const CarouselCouponsContainer = styled.div<CarouselCouponsContainerProps>`
  width: 100%;
  padding-left: 20px;
  margin-bottom: 40px;
  position: relative;

  padding-right: ${({ couponCounter }) => (couponCounter === 1 ? '20px' : 0)};

  .swiper-container {
    padding-bottom: 30px;

    .swiper-pagination-bullets {
      bottom: -3px !important;

      .swiper-pagination-bullet {
        background: #b2b2b2;
        opacity: 0.9;

        &.swiper-pagination-bullet-active {
          background: #939393;
          transform: scale(1.3);
          opacity: 1;
        }
      }
    }

    .swiper-wrapper {
      .swiper-slide {
        display: grid;
        grid-auto-flow: column;
        gap: 0 16px;

        max-width: ${({ couponCounter }) =>
          couponCounter === 1 ? '90%' : '100%'};

        margin: ${({ couponCounter }) =>
          couponCounter === 1 ? '0 auto 0 auto !important' : 'inherit'};
      }
    }
  }
`;

export default CarouselCouponsContainer;
