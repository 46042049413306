import * as React from 'react';
import styled from 'styled-components';

// Components
import Spinner from 'presentation/components/atoms/Spinner';
import { Paragraph } from 'presentation/components/atoms/Text';

const Loading = () => {
  return (
    <Container style={{ padding: '43px 0' }}>
      <div style={{ textAlign: 'center' }}>
        <Paragraph
          style={{
            fontSize: '20px',
            marginBottom: '32px',
            lineHeight: '25px',
            letterSpacing: '-0.3px',
          }}>
          Iniciando sesión
        </Paragraph>
        <Spinner />
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default Loading;
