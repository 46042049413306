import { memo } from 'react';
import { format } from 'date-fns';

// Components
import { Title } from 'presentation/components/atoms';
import Icon from 'presentation/assets/icons';

// Def
import { HeadboardProps } from './Headboard.def';

// Styled
import Styled from './Headboard.style';

const Headboard = ({ dataCategory, handleModal }: HeadboardProps) => {
  return (
    <>
      <Title
        level="1"
        size="large"
        style={{ margin: 0, display: 'flex' }}
        strong>
        {dataCategory?.currentCategory.name.toLowerCase() === 'normal'
          ? 'Cliente CMR Puntos'
          : `Cliente ${dataCategory?.currentCategory.name}`}
      </Title>
      <Styled.Subtitle>
        <Title level="2">Categoría</Title>
        {dataCategory?.currentCategory.name.toLowerCase() !== 'normal' &&
          dataCategory?.accumulationCategory.endDate && (
            <Styled.ExpirationWrapper>
              <Styled.ExpirationDate>
                Vence{' 31/'}
                {format(
                  new Date(dataCategory?.accumulationCategory.endDate),
                  'MM/yyyy',
                )}
              </Styled.ExpirationDate>
              <Styled.WrapperIcon
                data-testid="handle-modal"
                onClick={handleModal}>
                <Icon name="question" size="tiny" />
              </Styled.WrapperIcon>
            </Styled.ExpirationWrapper>
          )}
      </Styled.Subtitle>
    </>
  );
};

export default memo(Headboard);
