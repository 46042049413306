const PdfMapper = (json: any): string => {
  try {
    const { fields } = json;
    const {
      resource: {
        fields: {
          file: { url },
        },
      },
    } = fields;

    return url;
  } catch {
    console.warn(
      '[PdfMapper] The content json structure received differed from expected',
    );

    return '';
  }
};

export default PdfMapper;
