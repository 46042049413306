const commerces = [
  {
    name: 'banco',
    url: 'https://www.bancofalabella.com.co',
    displayText: 'Banco',
  },
  {
    name: 'falabella',
    url: 'https://www.falabella.com.co/falabella-co',
    displayText: 'Falabella',
  },
  {
    name: 'homecenter',
    url: 'https://www.homecenter.com.co/homecenter-co',
    displayText: 'Homecenter',
  },
  {
    name: 'linio',
    url: 'https://www.linio.com.co',
    displayText: 'Linio',
  },
  {
    name: 'seguros',
    url: 'https://web.segurosfalabella.com/co',
    displayText: 'Seguros',
  },
  {
    name: 'viajes',
    url: 'https://www.viajesfalabella.com.co',
    displayText: 'Viajes',
  },
  {
    name: 'mallplaza',
    url: 'https://mallplaza.co',
    displayText: 'Mallplaza',
  },
];

export default commerces;
