// utils
import Formatters from 'domain/formatters/Shared';
import truncateText from 'presentation/utils/truncateText';

// components
import Icon from 'presentation/assets/icons';
import Image from 'presentation/components/atoms/Image';
import SmartLink from 'presentation/components/organisms/SmartLink';
import { useMemo } from 'react';
import ListBadges from './ListBadges';
import Category, { CategoryProps } from './Category';

// styles
import * as Styled from './ReedemCard.style';

// defs
import { ReedemCardProps, LinkProps } from './ReedemCard.def';

const MAX_LENGTH_TITLE = 38;
const MAX_LENGTH_TEXT = 84;

export const LinkContainer = (linkProps: LinkProps) => {
  const {
    link: customLink,
    children: customChildren,
    utm: utmMedium,
  } = linkProps;

  if (customLink) {
    const { url } = customLink;
    return (
      <SmartLink
        url={url}
        target={customLink.external ? 'adaptable' : 'route'}
        mode="stock"
        utm={{ medium: utmMedium }}
        deepLink="redirect">
        {linkProps.children}
      </SmartLink>
    );
  }
  return <>{customChildren}</>;
};

const ReedemCard = ({
  image,
  partnerLogo,
  badges = [],
  category,
  link,
  utmMedium,
  content = {},
  onClick,
}: ReedemCardProps) => {
  const { title, text, currentPoints, points } = content;

  const hasContent = useMemo(
    () => Boolean(title || text || points),
    [points, text, title],
  );

  const hasImagePartnerLogo = useMemo(
    () => Boolean(partnerLogo?.title || partnerLogo?.url),
    [partnerLogo?.title, partnerLogo?.url],
  );

  return (
    <LinkContainer link={link} utm={utmMedium}>
      <Styled.CardContainer onClick={onClick}>
        <Styled.CardHead>
          {category && (
            <Category
              variant={category.color as CategoryProps['variant']}
              icon={category.icon}
            />
          )}

          <Image
            source={image}
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />

          <ListBadges badges={badges} />
        </Styled.CardHead>

        {hasContent && (
          <Styled.CardBody>
            {partnerLogo && partnerLogo?.url && (
              <Styled.CardPartnerLogo>
                <Image
                  source={partnerLogo}
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                />
              </Styled.CardPartnerLogo>
            )}

            {title && (
              <Styled.CardTitle isCardPartnerLogo={hasImagePartnerLogo}>
                {truncateText(title, MAX_LENGTH_TITLE)}
              </Styled.CardTitle>
            )}

            {text && (
              <Styled.CardDescription>
                {truncateText(text, MAX_LENGTH_TEXT)}
              </Styled.CardDescription>
            )}

            {points && (
              <Styled.CardPoints>
                {currentPoints && (
                  <Styled.CardPointsBefore>
                    <p>{Formatters.points(currentPoints)}</p>
                    <Icon name="cmr" />
                  </Styled.CardPointsBefore>
                )}

                <Styled.CardPointsAfter>
                  <p>{Formatters.points(points)}</p>
                  <Icon name="cmr" />
                </Styled.CardPointsAfter>
              </Styled.CardPoints>
            )}
          </Styled.CardBody>
        )}
      </Styled.CardContainer>
    </LinkContainer>
  );
};

export default ReedemCard;
