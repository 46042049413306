import {
  DetailedHTMLProps,
  FormHTMLAttributes,
  ForwardedRef,
  forwardRef,
} from 'react';

// Definitions
export interface Props
  extends DetailedHTMLProps<
    FormHTMLAttributes<HTMLFormElement>,
    HTMLFormElement
  > {
  preventSubmit?: boolean;
  children?: any;
}

const Form = forwardRef((props: Props, ref?: ForwardedRef<HTMLFormElement>) => {
  const { preventSubmit = false, children, ...formProps } = props;

  return (
    <form
      ref={ref && ref}
      onSubmit={event => preventSubmit && event.preventDefault()}
      {...formProps}
      noValidate>
      {children}
    </form>
  );
});

export default Form;
