/* eslint-disable array-callback-return */
import {
  IContentByTypeExchange,
  NoContentByTypeExchange,
} from 'domain/model/content';

import ExchangeByTranchesGroupMapper from './ExchangeByTranchesGroupMapper';
import ExchangeCategoriesCardsGroupMapper from './ExchangeCategoriesCardsGroupMapper';
import OutstandingBrandsGroupMapper from './OutstandingBrandsGroupMapper';
import ResourceMapper from './ResourceMapper';
import RecomendedItemsMapper from './RecomendedItemsMapper';

const ContentByTypeExchangeMapper = (data: []): IContentByTypeExchange => {
  try {
    const fieldsData = data.filter((item: any) => item.fields && item.fields);

    const typeSection = fieldsData.reduce((acc: any, cur: any) => {
      const {
        fields: {
          typeSection,
          pageTitle,
          exchangeCategories,
          enableExchangeCategories,
          ItemRecommendations,
          enableItemRecommendations,
          ExchangesByTranche,
          enableExchangesByTranche,
          outstandingBrands,
          enableOutstandingBrands,
          exchangeInstructions,
          enableExchangeInstructions,
        },
      } = cur;

      return {
        ...acc,
        [typeSection as string]: {
          pageTitle: pageTitle as string,
          exchangeCategories: ExchangeCategoriesCardsGroupMapper(
            exchangeCategories,
            enableExchangeCategories,
          ),
          outstandingExchangesTab: RecomendedItemsMapper(
            ItemRecommendations,
            enableItemRecommendations,
          ),
          exchangeByTranches: ExchangeByTranchesGroupMapper(
            ExchangesByTranche,
            enableExchangesByTranche,
          ),
          outstandingBrands: OutstandingBrandsGroupMapper(
            outstandingBrands,
            enableOutstandingBrands,
          ),
          exchangeInstructions: ResourceMapper(
            exchangeInstructions,
            enableExchangeInstructions,
          ),
        },
      };
    }, {});

    return typeSection;
  } catch {
    console.warn(
      '[ContentByTypeExchangeMapper] The content json structure received differed from expected',
    );

    return NoContentByTypeExchange;
  }
};

export default ContentByTypeExchangeMapper;
