import { CardCampaign } from 'presentation/components/molecules/ChallengeCardSection/ChallengeCardSection.def';
import LinkMapper from './LinkMapper';
import ImageMapper from './ImageMapper';

const ChallengeCardMapper = (json: any): Array<CardCampaign> => {
  try {
    const { fields } = json;
    const { cards } = fields;

    return cards.map((item: any) => {
      const { fields } = item;
      return {
        title: fields.title,
        subtitle: fields.subtitle,
        id: fields.id,
        image: ImageMapper(fields.image),
        badge: ImageMapper(fields.badge),
        link: LinkMapper(fields.link),
      } as CardCampaign;
    });
  } catch {
    console.warn(
      '[ChallengeCardMapper] The content json structure received differed from expected',
    );

    return [];
  }
};

export default ChallengeCardMapper;
