import { InitialState, LegalDocsData, LegalDocsValues } from './Legal.def';

type Action =
  | { type: 'INITIAL_VALUES' | 'GET_ACTION_TEXTS_FAIL' | 'GET_DOCS_FAIL' }
  | { type: 'GET_ACTION_TEXTS'; payload: LegalDocsData }
  | { type: 'GET_DOCS'; payload: string }
  | { type: 'IS_LOADING'; payload: boolean }
  | { type: 'IS_LOADING_ACTION_TEXTS'; payload: boolean };

export const initialState: InitialState = {
  legalDocsState: [] as LegalDocsValues[],
  legalDocsOptin: [] as LegalDocsValues[],
  legalDocByIdState: '',
  errorGetText: false,
  errorGetDocs: false,
  loading: true,
  loadingActionTexts: true,
};

const storeLegalReduder = (
  state: InitialState,
  action: Action,
): InitialState => {
  switch (action.type) {
    case 'INITIAL_VALUES':
      return initialState;
    case 'IS_LOADING':
      return { ...state, loading: action.payload };
    case 'IS_LOADING_ACTION_TEXTS':
      return { ...state, loadingActionTexts: action.payload };
    case 'GET_ACTION_TEXTS_FAIL':
      return {
        ...state,
        legalDocsState: [] as LegalDocsValues[],
        errorGetText: true,
        loading: false,
      };
    case 'GET_DOCS_FAIL':
      return {
        ...state,
        legalDocByIdState: '',
        errorGetDocs: true,
        loading: false,
      };
    case 'GET_ACTION_TEXTS':
      return {
        ...state,
        legalDocsState: action.payload.legalDocsState,
        legalDocsOptin: action.payload.legalDocsOptin,
        errorGetText: false,
        loading: false,
      };
    case 'GET_DOCS':
      return {
        ...state,
        legalDocByIdState: action.payload,
        errorGetDocs: false,
      };
    default:
      return state;
  }
};

export default storeLegalReduder;
