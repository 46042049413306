// Country configuration
import UserMenuChile from './cl';
import UserMenuColombia from './co';
import UserMenuPeru from './pe';

// Configuration list
const List = {
  cl: UserMenuChile,
  co: UserMenuColombia,
  pe: UserMenuPeru,
};

function GetConfiguration() {
  const country = process.env.REACT_APP_COUNTRY as keyof typeof List;

  return List[country];
}

export default GetConfiguration();
