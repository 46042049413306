import qs from 'query-string';

// Definitions
type FeatureUI = {
  common: {
    navbar: boolean;
    header: boolean;
    legalInformation: boolean;
    footer: boolean;
    cookies?: boolean;
  };
  home: {
    slider: boolean;
    points: boolean;
    tabs: boolean;
    categoryBenefits: {
      current: boolean;
      next: boolean;
    };
    categories: boolean;
    giftcard: boolean;
  };
  exchange: {
    slider: boolean;
    tabs: boolean;
  };
  helpCenter: {
    goBack: boolean;
  };
  registry: {
    promotions: boolean;
  };
  walkthrough: {
    showFab: boolean;
  };
};

export type UTM = {
  source: string;
  medium?: string;
  campaign?: string;
  term?: string;
  content?: string;
};

export type DeepLink = {
  back: string;
  giftcard: string;
  redirect: string;
};

type Tools = {
  hotjar: boolean;
  alcoWidget: boolean;
};

export type Configuration = {
  id: string;
  client: string;
  layout: string;
  platform: 'web' | 'app';
  device: 'ios' | 'android' | 'desktop';
  pageName: string;
  referrer?: string;
  features: {
    ui: FeatureUI;
  };
  utm: UTM;
  deepLink: DeepLink;
  tools: Tools;
};

class ConfigurationManager {
  private configuration: Configuration | null = null;

  initialize(current: Configuration) {
    this.configuration = { ...current };
  }

  get isLoad(): boolean {
    return !!this.configuration;
  }

  get isEmbedded(): boolean {
    return this.configuration?.client !== 'cmrpuntos';
  }

  get() {
    return Object.freeze(this.configuration);
  }

  parseUtm(merge?: Partial<UTM>): any {
    if (!this.configuration) return;

    const { utm, client, platform } = this.configuration;
    const utmSourceConfig = `${client}_${platform}`;
    const mergedUtm = { ...utm, ...merge };

    if (client === 'cmrpuntos') {
      mergedUtm.source = utmSourceConfig;
    } else {
      mergedUtm.source = merge?.source
        ? `${utmSourceConfig}_${merge.source}`
        : `${utmSourceConfig}_cmrpuntos`;
    }

    const parsedUtmObject = Object.entries(mergedUtm).reduce(
      (parsed, [utmKey, utmValue]) => {
        if (!utmValue) return parsed;
        const parsedObjectKey = `utm_${utmKey}`;
        return { ...parsed, [parsedObjectKey]: utmValue };
      },
      {},
    );

    return parsedUtmObject;
  }

  parseUtmToQueryString(merge?: Partial<UTM>): string {
    const parsedUtmObject = this.parseUtm(merge);

    if (!parsedUtmObject) return '';

    return qs.stringify(parsedUtmObject);
  }
}

export default ConfigurationManager;
