import React, { CSSProperties } from 'react';
import styled from 'styled-components';

import { Render as TitleRender } from '../../atoms/Title';

// Definitions
type Props = {
  children?: any;
  style?: CSSProperties;
};

const Title = (props: Props) => {
  const { children, style } = props;
  return <Render style={style}>{children}</Render>;
};

const Render = styled(TitleRender)`
  margin: 8px 6px 10px;
  font-size: 30px;
  line-height: 60px;
  letter-spacing: -0.741762px;
  font-weight: inherit;
  @media only screen and (max-width: 416px) {
    text-align: center;
  }
  @media (min-width: 751px) {
    margin: 0;
  }
`;

export default Title;
