import LocaleConfig from 'domain/configuration/locale';

const { locale } = LocaleConfig;
const formatter = new Intl.DateTimeFormat(locale);

const dateFormat = (isoDate: string): string => {
  return formatter.format(new Date(isoDate));
};

export default dateFormat;
