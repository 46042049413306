import styled from 'styled-components';
import { BenefitsCarouselPropsStyled } from './BenefitsCarousel.def';

const Styled = {
  Container: styled.div<BenefitsCarouselPropsStyled>`
    width: 100%;
    height: auto;

    .swiper {
      padding-bottom: 20px;

      .swiper-wrapper {
        padding-left: 5px;

        &:last-child {
          margin-right: 20px;
        }
      }

      .swiper-pagination {
        height: auto;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        bottom: 0px;
      }
    }

    .swiper-wrapper {
      height: 100%;
      .swiper-slide {
        height: auto;
      }
    }

    @media ${({ theme }) => theme.devices.tablet} {
      #carousel-benefits {
        .swiper {
          .swiper-wrapper {
            padding-bottom: 25px;
            padding-top: 5px;
          }
        }
      }
    }

    @media ${({ theme }) => theme.devices.desktop} {
      margin: 0 auto;
      padding: 0;

      #carousel-benefits {
        .swiper {
          padding-bottom: 0;
          .swiper-wrapper {
            display: flex;
            justify-content: center;
          }
        }
      }
    }

    @media (min-width: 1280px) {
      #carousel-benefits {
        .swiper {
          .swiper-wrapper {
            padding-bottom: 15px;
          }
        }
      }
    }
  `,
};

export default Styled;
