const utmMedium = {
  privateMenu: 'menu_navegacion_privado',
  home: {
    carrouselBanner: 'carrusel_banner',
    carrouselExchanges: 'calugas_home_tipo_de_canje',
    currentCategory: 'beneficios_categoria_actual',
    nextCategory: 'beneficios_proxima_categoria',
    sectionAccumulate: 'seccion_acumula',
    sectionRedeem: 'canjes_destacados_home',
    categoryBanner: 'banner_categorias',
  },
  footer: 'footer',
  exchange: {
    cardsProducts: 'calugas_productos',
    catalogueStore: 'catalogos_tiendas',
    cardsCoupons: 'calugas_cupones',
    categoryCoupons: 'categorias_cupones',
    cardsGiftcard: 'calugas_giftcards',
  },
};

export default utmMedium;
