import { ITextResponsive, NoTextResponsive } from 'domain/model/content';

const TextResponsiveMapper = (json: any): ITextResponsive => {
  try {
    const { fields } = json;
    const { desktop, mobile } = fields;

    return { desktop, mobile };
  } catch {
    console.warn(
      '[TextResponsiveMapper] The content json structure received differed from expected',
    );

    return NoTextResponsive;
  }
};

export default TextResponsiveMapper;
