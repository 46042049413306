import * as React from 'react';
import { useState, useEffect, ReactNode, CSSProperties } from 'react';

// Styles
import {
  Container,
  Content,
  Title,
  Text,
  IconMessageBox as Icon,
  CloseButton,
} from './MessageBox.styles';

// Definitions
export type MessageType = 'warning' | 'info' | 'alert' | 'success';

export type Props = {
  type: MessageType;
  variant?: 'small' | 'normal';
  title?: string;
  allowClose?: boolean;
  style?: CSSProperties;
  children?: ReactNode;
  onClose?: () => void;
};

const ICONS = {
  info: 'suggestions',
  warning: 'warning',
  alert: 'alert',
  success: 'success',
};

const DELAYED_TIME = 300;

const MessageBox = (props: Props) => {
  const {
    type,
    title,
    variant = 'normal',
    allowClose,
    children,
    style,
    onClose = () => {},
  } = props;

  const [show, setShow] = useState(true);
  const [isMounted, setIsMounted] = useState(true);
  const nameIcon = ICONS[type];

  const handleOnClose = () => {
    setShow(false);
    onClose();
  };

  useEffect(() => {
    if (!allowClose) return;

    if (!show) {
      setTimeout(() => setIsMounted(false), DELAYED_TIME);
    }
  }, [allowClose, show]);

  return (
    <>
      {isMounted && (
        <Container
          type={type}
          variant={variant}
          role="alert"
          aria-labelledby={title && 'message-title'}
          style={style}
          show={show}
          duration={DELAYED_TIME}>
          <Icon name={nameIcon as any} size="tiny" />
          <Content variant={variant}>
            {title && <Title id="message-title">{title}</Title>}
            {children}
          </Content>
          {allowClose && (
            <CloseButton
              icon={<Icon name="close" size="tiny" />}
              size="S"
              onClick={handleOnClose}
            />
          )}
        </Container>
      )}
    </>
  );
};

export { Text };
export default MessageBox;
