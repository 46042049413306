import * as React from 'react';
import { forwardRef } from 'react';
import ReCAPTCHA from 'recaptcha-loy';

// Definitions
export type RefProps = {
  reset(): void;
  execute(): void;
  executeAsync(): Promise<string | null>;
  getValue(): string | null;
  getWidgetId(): number | null;
};

type Props = {
  onValidate: (token?: any) => void;
};

const RECAPTCHA_KEY = process.env.REACT_APP_CAPTCHA_KEY as string;

const Captcha = forwardRef((props: Props, ref: any) => {
  // Props
  const { onValidate } = props;

  // Vars
  return (
    <ReCAPTCHA
      size="invisible"
      ref={ref}
      sitekey={RECAPTCHA_KEY}
      onExpired={() => ref?.current?.reset()}
      onChange={(response: any) => {
        if (response) onValidate(response);
      }}
      onErrored={() =>
        console.error(
          'Error de red, no es posible cargar la libreria recaptcha',
        )
      }
    />
  );
});

Captcha.displayName = 'Captcha';

export default Captcha;
