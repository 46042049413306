import * as React from 'react';

// Definitions
import { AccordionItemProps } from './AccordionItem';

type Props = {
  variant?: 'light' | 'dark';
  children:
    | React.ReactElement<AccordionItemProps>
    | React.ReactElement<AccordionItemProps>[];
};

const Accordion = (props: Props) => {
  // Props
  const { variant = 'dark', children } = props;

  // State
  const [expandedIndex, setExpandedIndex] = React.useState<number>(-1);

  const handleItemClick = (itemIndex: number) => {
    const hasIndexChanged = itemIndex !== expandedIndex;

    if (!hasIndexChanged) {
      setExpandedIndex(-1);
      return;
    }

    setExpandedIndex(itemIndex);
  };

  const items = React.useMemo(() => {
    return React.Children.toArray(children).map((item, index) => {
      const { props, ...itemComponentData } =
        item as React.ReactElement<AccordionItemProps>;
      const itemIndex = index + 1;

      return {
        props: {
          ...props,
          variant,
          index: itemIndex,
          expanded: itemIndex === expandedIndex,
          onClick: handleItemClick,
        },
        ...itemComponentData,
      };
    });
  }, [expandedIndex, children]);

  return <div>{items}</div>;
};

export default Accordion;
