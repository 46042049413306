/* eslint-disable @typescript-eslint/no-unused-vars */
import { createContext, useCallback, useEffect, useReducer } from 'react';

// Services
import Services from 'infrastructure/services/loyalty';

// Reducer
import storeLegalReduder, { initialState } from './LegalReducer';

// Def
import { ContextProps, LegalDocsValues, ProviderProps } from './Legal.def';

// createContext
const LegalContext = createContext<ContextProps>({} as ContextProps);

// Endpoint
const { Legal } = Services;

const COUNTRY = process.env.REACT_APP_COUNTRY?.toLowerCase() as string;

const URL_DOCUMENT_CL =
  'https://assets.ctfassets.net/ynglr8ha4zwh/2OIxSUM1OWOViAJpY9MAwP/df860e3f9926fa96648173c11d11bb2a/Reglamento_CMR_puntos_marzo_2023.pdf';

export const LegalProvider = ({ children }: ProviderProps) => {
  const [legalStore, dispatch] = useReducer(storeLegalReduder, initialState);

  useEffect(() => {
    getActionTextLegal();
  }, []);

  const isLoadingAction = (value: boolean) => {
    dispatch({ type: 'IS_LOADING', payload: value });
  };

  const resetValues = () => {
    dispatch({ type: 'INITIAL_VALUES' });
  };

  const getActionTextLegal = async () => {
    dispatch({ type: 'IS_LOADING_ACTION_TEXTS', payload: true });
    try {
      const { data: response } = await Legal.legalDocuments();
      if (!response.data) return;

      dispatch({
        type: 'GET_ACTION_TEXTS',
        payload: {
          legalDocsState: response.data?.legalDocuments || [],
          legalDocsOptin: response.data?.legalDocumentsOptin || [],
        },
      });
    } catch (error) {
      dispatch({ type: 'GET_ACTION_TEXTS_FAIL' });
    } finally {
      dispatch({ type: 'IS_LOADING_ACTION_TEXTS', payload: false });
    }
  };

  const validateDocRegulationCL = (
    id: string,
    legalDocsState?: LegalDocsValues[],
  ) => {
    if (COUNTRY === 'cl') {
      return legalDocsState?.find(
        x => x.description === 'Reglamento Programa CMR Puntos' && x.id === id,
      );
    }
  };

  const getActionLegalDocById = useCallback(
    async (id: string, legalDocsState: LegalDocsValues[] | undefined) => {
      dispatch({ type: 'IS_LOADING', payload: true });

      const documentRegulation = validateDocRegulationCL(id, legalDocsState);

      try {
        if (documentRegulation) {
          dispatch({ type: 'GET_DOCS', payload: URL_DOCUMENT_CL });
        }

        if (!documentRegulation) {
          const { data: response } = await Legal.legalDocumentById(id);
          dispatch({ type: 'GET_DOCS', payload: response.data.legalText });
        }
        dispatch({ type: 'IS_LOADING', payload: false });
      } catch (error) {
        dispatch({ type: 'GET_DOCS_FAIL' });
      }
    },
    [],
  );

  const store = {
    ...legalStore,
    URL_DOCUMENT_CL,
    resetValues,
    getActionTextLegal,
    getActionLegalDocById,
    isLoadingAction,
    validateDocRegulationCL,
  };

  return (
    <LegalContext.Provider value={store}>{children}</LegalContext.Provider>
  );
};

export default LegalContext;
