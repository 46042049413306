import { ISlidesGroup, NoSlidesGroup, ISlideField } from 'domain/model/content'; // mport SlideSetter from './SlideMapper';
import { getClientId } from 'infrastructure/providers/Configuration/Configuration.utils';
import clientMap from 'infrastructure/stores/Configuration/clients';
import SlideMapper from './SlideMapper';

const commerceId = getClientId();
const ClientsIdMap = clientMap as { [key: string]: string };
const commerceName = ClientsIdMap[commerceId] || 'cmrpuntos';

const SlidesGroupMapper = (json: any): ISlidesGroup => {
  try {
    const { fields } = json;
    const { slides, id } = fields;

    const filterSlides = slides.filter((slide: ISlideField) => {
      return (
        slide.fields.ecosystem === undefined ||
        slide.fields.ecosystem.includes(commerceName) ||
        slide.fields.ecosystem.includes('All')
      );
    });

    return {
      name: id,
      slides: filterSlides.map(SlideMapper),
      commerceName,
      commerceId,
    };
  } catch (error) {
    console.warn(
      '[CardsGroupMapper] The content json structure received differed from expected',
    );

    return NoSlidesGroup;
  }
};
export default SlidesGroupMapper;
