// Analytics utils
import Analytics from 'infrastructure/utils/analytics';

import { AnalyticsMarkCall, AnalyticsMarks } from '../Analytics.def';

const { setEventOP, setError } = Analytics;

// Definitions
type SubscribeOPMarks = {
  submitted: AnalyticsMarkCall;
  submittedProfile: AnalyticsMarkCall;
  success: AnalyticsMarkCall;
  successUpdateProfile: AnalyticsMarkCall;
  approvedCreditCard: AnalyticsMarkCall;
  approvedCurrentAccount: AnalyticsMarkCall;
  withoutApproval: AnalyticsMarkCall;
};

type SubscribeErrorMarks = {
  unexpected: AnalyticsMarkCall;
  userIsRegister: AnalyticsMarkCall;
  phoneIsRegister: AnalyticsMarkCall;
  emailIsRegister: AnalyticsMarkCall;
  emailAndPhoneIsRegister: AnalyticsMarkCall;
  invalidDocument: AnalyticsMarkCall;
  invalidPhone: AnalyticsMarkCall;
  invalidEmail: AnalyticsMarkCall;
  userIsCmr: AnalyticsMarkCall;
  userIsBank: AnalyticsMarkCall;
  invalidCompleteProfile: AnalyticsMarkCall;
};

export type SubscribeAnalyticsMarks = AnalyticsMarks<
  {},
  SubscribeOPMarks,
  SubscribeErrorMarks
>;

// Constants
const SUBSCRIBE_ANALYTICS_CATEGORY = 'Subscribe';

const marks: SubscribeAnalyticsMarks = {
  ui: {},
  op: {
    submitted: () => {
      setEventOP(
        SUBSCRIBE_ANALYTICS_CATEGORY,
        'Petición de subscripción ha sido enviada',
      );
    },
    submittedProfile: () => {
      setEventOP(
        SUBSCRIBE_ANALYTICS_CATEGORY,
        'Solicitud de completar perfil ha sido enviada',
      );
    },
    success: () => {
      setEventOP(
        SUBSCRIBE_ANALYTICS_CATEGORY,
        'Petición de subscripción ha sido exitosa',
      );
    },
    successUpdateProfile: () => {
      setEventOP(
        SUBSCRIBE_ANALYTICS_CATEGORY,
        'Solicitud de completar perfil ha sido exitosa',
      );
    },
    approvedCreditCard: () => {
      setEventOP(
        SUBSCRIBE_ANALYTICS_CATEGORY,
        'Solicitud evaluación pre-aprobado tarjeta CMR ha sido exitosa',
      );
    },
    approvedCurrentAccount: () => {
      setEventOP(
        SUBSCRIBE_ANALYTICS_CATEGORY,
        'Solicitud evaluación pre-aprobado Cuenta corriente o ahorro ha sido exitosa',
      );
    },
    withoutApproval: () => {
      setEventOP(
        SUBSCRIBE_ANALYTICS_CATEGORY,
        'Solicitud evaluación pre-aprobado rechazada',
      );
    },
  },
  error: {
    userIsRegister: () => {
      setError('Petición de subscripción fallada por usuario registrado');
    },
    phoneIsRegister: () => {
      setError('Petición de subscripción fallada por teléfono registrado');
    },
    emailIsRegister: () => {
      setError('Petición de subscripción fallada por correo registrado');
    },
    emailAndPhoneIsRegister: () => {
      setError(
        'Petición de subscripción fallada por teléfono y correo registrado',
      );
    },
    invalidDocument: () => {
      setError(
        'Petición de subscripción fallada por documento de identidad invalido',
      );
    },
    invalidPhone: () => {
      setError(
        'Petición de subscripción fallada por número de celular inválido',
      );
    },
    invalidEmail: () => {
      setError('Petición de subscripción fallada por correo invalido');
    },
    userIsCmr: () => {
      setError(
        'Petición de subscripción fallada por usuario ya registrado como CMR',
      );
    },
    userIsBank: () => {
      setError(
        'Petición de subscripción fallada por usuario ya registrado como Banco',
      );
    },
    unexpected: () => {
      setError('Petición de subscripción fallada por error inesperado');
    },
    invalidCompleteProfile: () => {
      setError('Solicitud de completar perfil fallida por error inesperado');
    },
  },
};

export default marks;
