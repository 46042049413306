import {
  currencyFormat,
  dateFormat,
  numberFormat,
  phoneFormat,
  phoneNationalFormat,
} from 'domain/locale';

const Formatters = {
  number: (value: string) => value.replace(/\D/g, ''),
  points: (value: number) => {
    return numberFormat(value);
  },
  date: (value: string) => {
    return dateFormat(value);
  },
  phone: (value: string) => {
    return phoneFormat(value);
  },
  removePhoneCountryCode: (value: string) => {
    return phoneNationalFormat(value);
  },
  currency: (value: number) => {
    return currencyFormat(value);
  },
  characterLimiter: (count: number) => (value: string) => {
    if (value.length <= count) return value;
    return value.slice(0, -1);
  },
  lettersAndNumbers: (value: string) => value.replace(/[^a-zA-Z0-9\s]/g, ''),
  nameAndSurname: (value: string) => {
    return value
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  },
};

export default Formatters;
