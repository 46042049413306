export interface ITextResponsive {
  mobile: string;
  desktop: string;
}

export const NoTextResponsive: ITextResponsive = {
  mobile: '',
  desktop: '',
};

export interface IImage {
  title: string;
  url: string;
}

export const NoImage: IImage = {
  title: '',
  url: '',
};

export interface IImageResponsive {
  name: string;
  nombre?: string;
  mobile: IImage;
  desktop: IImage;
}

export const NoImageResponsive: IImageResponsive = {
  name: '',
  nombre: '',
  mobile: NoImage,
  desktop: NoImage,
};

export interface ILink {
  url: string;
  text: string;
  external?: boolean;
}

export const NoLink: ILink = {
  url: '',
  text: '',
  external: false,
};
