import { CustomerCategoryName } from 'domain/model';

import { categoryAndBenefitsProps } from './categoryAndBenefits.def';

const categoryAndBenefits = {
  categoryList: [
    {
      type: CustomerCategoryName.Fan,
      name: 'Fan',
      icon: 'fan',
      points: '3.000',
      glosa: 'en nuestras tiendas o comprando $4.000.000 en ellas',
      benefitsList: [
        {
          id: 1,
          icon: 'shipping',
          sizeIcon: 'tiny',
          text: 'Redención productos, experiencias y Gift Cards',
        },
        {
          id: 2,
          icon: 'offersWhite',
          sizeIcon: 'tiny',
          text: 'Beneficios en el mes de tu cumpleaños Bonos en Falabella y Homecenter',
        },
        {
          id: 4,
          icon: 'fanLine',
          sizeIcon: 'small',
          outlineIcon: true,
          text: 'Y más beneficios de la Categoría Fan:',
          link: {
            url: 'https://www.cmrpuntos.com.co/categorias',
            target: 'blank',
            text: 'Descubre todo aquí',
          },
        },
      ],
    } as categoryAndBenefitsProps,
    {
      type: CustomerCategoryName.Premium,
      name: 'Premium',
      icon: 'premium',
      points: '10.000',
      glosa: 'en todas tus compras',
      benefitsList: [
        {
          id: 1,
          icon: 'date',
          sizeIcon: 'tiny',
          text: 'Tus CMR Puntos duran 2 años',
        },
        {
          id: 2,
          icon: 'physicalCard',
          sizeIcon: 'tiny',
          text: '50% de dcto en cuota de manejo de la Tarjeta de Crédito CMR Falabella*',
        },
        {
          id: 3,
          icon: 'premiumLine',
          sizeIcon: 'small',
          outlineIcon: true,
          text: 'Y más beneficios de la Categoría Premium:',
          link: {
            url: 'https://www.cmrpuntos.com.co/categorias',
            target: 'blank',
            text: 'Descubre todo aquí',
          },
        },
      ],
    } as categoryAndBenefitsProps,
    {
      type: CustomerCategoryName.Elite,
      name: 'Elite',
      icon: 'elite',
      points: '20.000',
      glosa: 'en todas tus compras o abriendo tu Tarjeta CMR Black',
      benefitsList: [
        {
          id: 1,
          icon: 'product',
          sizeIcon: 'tiny',
          text: 'Costo $0 en cuota de manejo de la Tarjeta de Crédito CMR Falabella*',
        },
        {
          id: 2,
          icon: 'eliteLine',
          sizeIcon: 'small',
          outlineIcon: true,
          text: 'Y más beneficios de la Categoría Elite:',
          link: {
            url: 'https://www.cmrpuntos.com.co/categorias',
            target: 'blank',
            text: 'Descubre todo aquí',
          },
        },
      ],
      cta: {
        text: 'Elige ser Elite',
        url: 'https://www.bancofalabella.com.co/tarjetas-credito-cmr/black',
        target: 'blank',
      },
    } as categoryAndBenefitsProps,
  ],
};

export default categoryAndBenefits;
