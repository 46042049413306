import AppleTV from './appletv.png';
import BackgroundSSO from './backgroundSSO.jpg';
import CmrPointsLogo from './cmrPointsLogo.png';
import CmrOgLogo from './og-logo.png';
import Confetti from './confetti.svg';
import CreatePasswordBackgroundDesktop from './createPasswordBackgroundDesktop.jpg';
import CreatePasswordBackgroundMobile from './createPasswordBackgroundMobile.jpg';
import Down from './down.png';
import GradientStripe from './gradient-stripe.svg';
import HamburgerMenu from './hamburgerMenu.png';
import Logo from './logo.svg';
import LogoDark from './logoDark.svg';
import LogoLight from './logoLight.svg';
import Sparks from './sparks.svg';
import Up from './up.png';
import PrivateSiteDesktop from './sitioprivado-desktop.svg';
import PrivateSiteMobile from './sitioprivado-mobile.svg';
import Confeti from './confeti.svg';
import RegistrationBackgroundDesktop from './registrationBackgroundDesktop.jpg';
import RegistrationBackgroundDesktopPE from './registrationBackgroundDesktopPE.png';
// Clip images
import LinearClip from './linearClip.svg';

// Definitions
export type ImageName = typeof Images;

const Images = {
  up: Up,
  logo: Logo,
  cmrOgLogo: CmrOgLogo,
  logoLight: LogoLight,
  logoDark: LogoDark,
  down: Down,
  hamburgerMenu: HamburgerMenu,
  cmrPointsLogo: CmrPointsLogo,
  sparks: Sparks,
  createPasswordBackgroundDesktop: CreatePasswordBackgroundDesktop,
  createPasswordBackgroundMobile: CreatePasswordBackgroundMobile,
  clips: {
    linear: LinearClip,
  },
  confetti: Confetti,
  gradientStripe: GradientStripe,
  backgroundSSO: BackgroundSSO,
  appleTv: AppleTV,
  privateSiteDesktop: PrivateSiteDesktop,
  privateSiteMobile: PrivateSiteMobile,
  confeti: Confeti,
  registrationBackgroundDesktop: RegistrationBackgroundDesktop,
  registrationBackgroundDesktopPE: RegistrationBackgroundDesktopPE,
};

export default Images;
