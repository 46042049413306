import React from 'react';

// Format Date
import Format from 'date-fns/format';
import { es } from 'date-fns/locale';

// Components
import SmartLink from 'presentation/components/organisms/SmartLink';
import Image from 'presentation/components/atoms/Image';

// defs
import { CouponProps, LinkProps } from './Coupon.def';

// Styled
import CouponContainer, {
  HeadContent,
  ContentLogo,
  ContentIcon,
  BodyContent,
  ColumnLeft,
  ColumnRight,
  BadgeDate,
  LogoWrapper,
  WrapperCode,
} from './Coupon.styled';

const LinkContainer = (linkProps: LinkProps) => {
  const {
    link: customLink,
    children: customChildren,
    utm: utmMedium,
    onClick = () => {},
  } = linkProps;

  if (customLink) {
    const { url } = customLink;

    return (
      <SmartLink
        url={url}
        target={customLink.external ? 'adaptable' : 'route'}
        mode="stock"
        utm={{ medium: utmMedium }}
        deepLink="redirect"
        onClick={onClick}>
        {customChildren}
      </SmartLink>
    );
  }

  return <>{customChildren}</>;
};

const Coupon = ({
  isCountry,
  code,
  title,
  icon = { fields: { file: { url: '' } } },
  color,
  detail,
  expiration,
  logo = { fields: { file: { url: '' } } },
  logoCommerce = { fields: { file: { url: '' } } },
  link,
  onClick = () => {},
}: CouponProps) => {
  const {
    fields: { file: fileLogo },
  } = logo;

  const {
    fields: { file: fileLogoCommerce },
  } = logoCommerce;

  const {
    fields: { file: fileIcon },
  } = icon;

  let currenDate = 'No Disponible';

  if (expiration) {
    const date = new Date(expiration);
    currenDate = Format(date, 'dd/MM/yyyy', { locale: es });
  }

  return (
    <LinkContainer link={link} onClick={() => onClick({ title, detail })}>
      <CouponContainer>
        <HeadContent colorBox={color}>
          <ContentLogo>
            <Image source={{ url: fileLogo?.url, title: `logo-${title}` }} />

            {isCountry && (
              <WrapperCode>
                <h4>Codigo:</h4>
                <p>{code}</p>
              </WrapperCode>
            )}
          </ContentLogo>

          <ContentIcon>
            <Image source={{ url: fileIcon?.url, title: `logo-${title}` }} />
          </ContentIcon>
        </HeadContent>

        <BodyContent>
          <ColumnLeft>
            <p>{detail}</p>

            <BadgeDate>{`vence: ${currenDate}`}</BadgeDate>

            <LogoWrapper>
              <Image
                source={{ url: fileLogoCommerce?.url, title: `logo-${title}` }}
              />
            </LogoWrapper>
          </ColumnLeft>

          <ColumnRight>
            <div>
              <span>{title}</span>
            </div>
          </ColumnRight>
        </BodyContent>
      </CouponContainer>
    </LinkContainer>
  );
};

export default React.memo(Coupon);
