import React from 'react';

// Components
import Image from 'presentation/components/atoms/Image';
import Button from 'presentation/components/atoms/Button';
import Title from 'presentation/components/atoms/Title';
import SmartLink from 'presentation/components/organisms/SmartLink';
import useIsMobile from 'infrastructure/hooks/IsMobile';
import RichText from 'presentation/components/molecules/RichText';

// Definitions
import { TextImageBannerProps } from './TextImageBanner.def';

// Styles
import {
  Hero,
  Container,
  ImageWrapper,
  InfoWrapper,
  TextWrapper,
} from './TextImageBanner.styles';

const TextImageBanner = (props: TextImageBannerProps) => {
  // Hooks
  const isMobile = useIsMobile();
  const { data, id, style, alignText } = props;
  const { title, image, description, link, bgColor, itemsAlign } = data;
  const haveImage =
    Boolean(image?.desktop.url.length) || Boolean(image?.mobile.url.length);
  const linkId = `link-${id}`;

  return (
    <Hero bgColor={bgColor} data-testid={id} style={style}>
      <Container itemsAlign={itemsAlign} haveImage={haveImage}>
        {haveImage && (
          <ImageWrapper>
            {image && <Image source={image} autoFit={false} />}
          </ImageWrapper>
        )}
        <InfoWrapper alignText={alignText}>
          <Title level="2">{title}</Title>
          <TextWrapper>
            {description && <RichText content={description} />}
          </TextWrapper>
          {link && (
            <SmartLink
              url={link.url}
              mode="stock"
              target={link.external ? 'adaptable' : 'route'}
              id={linkId}>
              <Button variant="primary" size="L" isFullWidth={isMobile}>
                {link.text}
              </Button>
            </SmartLink>
          )}
        </InfoWrapper>
      </Container>
    </Hero>
  );
};

export default TextImageBanner;
