import {
  IExchangeByTranchesGroup,
  NoExchangeByTranchesGroup,
} from 'domain/model/content';
import ExchangeByTranchesMapper from './ExchangeByTranchesMapper';

const ExchangeByTranchesGroupMapper = (
  json: any,
  enableExchangesByTranche: boolean,
): IExchangeByTranchesGroup => {
  try {
    const { fields } = json;
    const { name, cards } = fields;

    return {
      enable: enableExchangesByTranche,
      name,
      cards: cards.map(ExchangeByTranchesMapper),
    };
  } catch {
    console.warn(
      '[ExchangeByTranchesGroupMapper] The content json structure received differed from expected',
    );

    return NoExchangeByTranchesGroup;
  }
};

export default ExchangeByTranchesGroupMapper;
