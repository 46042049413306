import contentful from 'infrastructure/services/contentFul';

// Mappers
import { LandingsMapper } from '../mappers';

// Service
const {
  entriesResource: { getEntry },
} = contentful;

// Vars
const LandingsEntryId = process.env.REACT_APP_CONTENTFUL_DATA_LANDINGS;

const LoadsLandings = async () => {
  try {
    const data = await getEntry<any>(LandingsEntryId, {
      'sys.id': LandingsEntryId,
      include: 10,
    });
    return {
      pageLandings: LandingsMapper(data.fields?.pageLandings),
    };
  } catch (error) {
    console.error(error);
  }
};

export default LoadsLandings;
