// Analytics utils
import Analytics from 'infrastructure/utils/analytics';

import { AnalyticsMarkCall, AnalyticsMarks } from '../Analytics.def';

const { setEventOP } = Analytics;

// Definitions
type AccountMenuOPMarks = {
  logout: AnalyticsMarkCall;
};

export type AccountMenuAnalyticsMarks = AnalyticsMarks<
  {},
  AccountMenuOPMarks,
  {}
>;

// Constants
const ACCOUNT_MENU_ANALYTICS_CATEGORY = 'Account Menu';
const marks: AccountMenuAnalyticsMarks = {
  ui: {},
  op: {
    logout: () => {
      setEventOP(
        ACCOUNT_MENU_ANALYTICS_CATEGORY,
        'Petición de cerrar sesión iniciada',
      );
    },
  },
  error: {},
};

export default marks;
