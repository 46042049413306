import { ICustomerCategory, CustomerCategoryCode } from 'domain/model';
import DataConfigMyCategory from 'domain/configuration/pages/myCategory';
import { ModalUpgradeCategoryData } from '../components/ModalUpgradeCategory/ModalUpgradeCategory';

export type UpgradeCategoryData = {
  modal: ModalUpgradeCategoryData;
  congratMessage: string;
  congratBy: string;
};

const resolveUpgradeCategory = (category: ICustomerCategory) => {
  const {
    productCategory: {
      id: productId,
      endAlertBenefitDate: productEndAlertBenefitDate,
    },
    accumulationCategory: {
      id: accumulationId,
      endAlertBenefitDate: accumulationEndAlertBenefitDate,
    },
  } = category;

  const resolveDate = (date?: Date): boolean => {
    const currentDate = new Date();
    if (date) {
      return new Date(date) > currentDate;
    }
    return false;
  };

  const defaultData: UpgradeCategoryData =
    DataConfigMyCategory.updateCategory.default;

  const AccuProductCode: string = accumulationId + productId; // codigo de acumulacion mas codigo de producto
  let showModal = false;
  let data: UpgradeCategoryData = defaultData;

  //    = sin producto
  // 00 = Normal
  // 04 = Fan
  // 01 = Premium
  // 02 = Elite
  const showDateAccu = resolveDate(accumulationEndAlertBenefitDate);
  const showDateProd = resolveDate(productEndAlertBenefitDate);

  switch (AccuProductCode) {
    case '00':
    case '0000':
      showModal = false;
      break;
    case '0001':
    case '0002':
      if (showDateProd) {
        showModal = true;
        if (productId === CustomerCategoryCode.Premium) {
          data =
            DataConfigMyCategory.updateCategory
              .premiumByCardWithoutAccumulation;
        }
        if (productId === CustomerCategoryCode.Elite) {
          data =
            DataConfigMyCategory.updateCategory.eliteByCardWithoutAccumulation;
        }
      }
      break;
    case '04':
    case '01':
    case '02':
    case '0400':
    case '0100':
    case '0200':
      if (showDateAccu) {
        showModal = true;
        if (accumulationId === CustomerCategoryCode.Fan) {
          data = DataConfigMyCategory.updateCategory.fanByAccumulation;
        }
        if (accumulationId === CustomerCategoryCode.Premium) {
          data =
            DataConfigMyCategory.updateCategory
              .premiumByAccumulationWithoutCard;
        }
        if (accumulationId === CustomerCategoryCode.Elite) {
          data =
            DataConfigMyCategory.updateCategory.eliteByAccumulationWithoutCard;
        }
      }
      break;
    case '0401':
    case '0402':
    case '0101':
    case '0102':
    case '0201':
    case '0202':
      if (accumulationId === CustomerCategoryCode.Fan) {
        if (showDateProd) {
          showModal = true;
          if (productId === CustomerCategoryCode.Premium) {
            data =
              DataConfigMyCategory.updateCategory
                .premiumByCardWithoutAccumulation;
          }
          if (productId === CustomerCategoryCode.Elite) {
            data =
              DataConfigMyCategory.updateCategory
                .eliteByCardWithoutAccumulation;
          }
        }
      } else if (showDateAccu) {
        showModal = true;
        if (accumulationId === CustomerCategoryCode.Premium) {
          data =
            DataConfigMyCategory.updateCategory.premiumByCardAndAccumulation;
        }
        if (accumulationId === CustomerCategoryCode.Elite) {
          data = DataConfigMyCategory.updateCategory.eliteByCardAndAccumulation;
        }
      } else if (showDateProd) {
        showModal = true;
        if (productId === CustomerCategoryCode.Premium) {
          data =
            DataConfigMyCategory.updateCategory
              .premiumByCardWithoutAccumulation;
        }
        if (productId === CustomerCategoryCode.Elite) {
          if (accumulationId === CustomerCategoryCode.Premium) {
            data =
              DataConfigMyCategory.updateCategory
                .eliteByCardAccumulationPremium;
          } else {
            data =
              DataConfigMyCategory.updateCategory
                .eliteByAccumulationWithoutCard;
          }
        }
      }
      break;
    default:
      showModal = false;
      data = defaultData;
      break;
  }

  return { showModal, data };
};

export default resolveUpgradeCategory;
