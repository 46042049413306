import DataConfigMyCategory from 'domain/configuration/pages/myCategory';

const updateCategory = (categoryName: string) => {
  if (categoryName === 'Normal') {
    DataConfigMyCategory.cardsBenefits[0].status = 'desbloqueado';
    DataConfigMyCategory.cardsBenefits[1].status = 'bloqueado';
    DataConfigMyCategory.cardsBenefits[2].status = 'bloqueado';
    DataConfigMyCategory.cardsBenefits[3].status = 'bloqueado';

    return DataConfigMyCategory.cardsBenefits;
  }

  if (categoryName === 'Fan') {
    DataConfigMyCategory.cardsBenefits[0].status = 'desbloqueado';
    DataConfigMyCategory.cardsBenefits[1].status = 'desbloqueado';
    DataConfigMyCategory.cardsBenefits[2].status = 'bloqueado';
    DataConfigMyCategory.cardsBenefits[3].status = 'bloqueado';

    return DataConfigMyCategory.cardsBenefits;
  }

  if (categoryName === 'Premium') {
    DataConfigMyCategory.cardsBenefits[0].status = 'desbloqueado';
    DataConfigMyCategory.cardsBenefits[1].status = 'desbloqueado';
    DataConfigMyCategory.cardsBenefits[2].status = 'desbloqueado';
    DataConfigMyCategory.cardsBenefits[3].status = 'bloqueado';

    return DataConfigMyCategory.cardsBenefits;
  }

  if (categoryName === 'Elite') {
    DataConfigMyCategory.cardsBenefits[0].status = 'desbloqueado';
    DataConfigMyCategory.cardsBenefits[1].status = 'desbloqueado';
    DataConfigMyCategory.cardsBenefits[2].status = 'desbloqueado';
    DataConfigMyCategory.cardsBenefits[3].status = 'desbloqueado';

    return DataConfigMyCategory.cardsBenefits;
  }

  return DataConfigMyCategory.cardsBenefits;
};

export default updateCategory;
