import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import { EnableDataDogLoader } from 'infrastructure/stores/Contentful/loaders';
import { IEnableDataDog } from 'domain/model/content';

import serializeJs from 'presentation/utils/serialize';

const DD_RUM_INIT = {
  clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
  applicationId: process.env.REACT_APP_DATADOG_APPLICATIONID,
  site: 'datadoghq.com',
  service: process.env.REACT_APP_DATADOG_SERVICE,
  env: 'prod',
  version: '1.0.0',
  premiumSampleRate: 0,
  defaultPrivacyLevel: 'mask-user-input',
};

const Datadog = () => {
  const [infoDataDog, setInfoDataDog] = useState<IEnableDataDog>();
  useEffect(() => {
    (async () => {
      const data = await EnableDataDogLoader();
      setInfoDataDog(data);
    })();
  }, []);

  return (
    <>
      {infoDataDog?.enable && (
        <Helmet async>
          <script type="text/javascript">
            {`
             (function(h,o,u,n,d) {
                h=h[d]=h[d]||{q:[],onReady:function(c){h.q.push(c)}}
                d=o.createElement(u);d.async=1;d.src=n
                n=o.getElementsByTagName(u)[0];n.parentNode.insertBefore(d,n)
              })(window,document,'script','https://www.datadoghq-browser-agent.com/datadog-rum-v4.js','DD_RUM')
                DD_RUM.onReady(function() {
                DD_RUM.init(${serializeJs(
                  {
                    ...DD_RUM_INIT,
                    ...infoDataDog.config,
                  },
                  { isJSON: true },
                )});
                DD_RUM.startSessionReplayRecording();
              })
          `}
          </script>
        </Helmet>
      )}
    </>
  );
};

export default Datadog;
