import {
  CustomerType,
  ICustomer,
  ICustomerUpdate,
  IPointsExpiration,
  IReturnCustomerData,
  IReturnCustomerUpdate,
} from 'domain/model';

export enum CustomerStatusHttpErrors {
  NotLoyaltyCustomerError = 422,
  GenericError = 500,
  InvalidData = 400,
}

export type CustomerErrors =
  | 'NotLoyaltyCustomerError'
  | 'ServiceError'
  | 'InvalidData';

export type CustomerProviderValue = {
  data: {
    loading: boolean;
    customer: ICustomer;
    error?: CustomerErrors;
  };
  methods: {
    getType: (
      documentType: string,
      documentNumber: string,
      token: string,
      phone?: string,
    ) => Promise<CustomerType | undefined>;
    reset: () => void;
    getExpirationPoints: () => IPointsExpiration[];
    customerUpdate: (
      params: ICustomerUpdate,
      token: string | undefined,
    ) => Promise<IReturnCustomerUpdate | undefined>;
    getCustomerData: (
      token: string | undefined,
    ) => Promise<IReturnCustomerData | undefined>;
  };
};
