import styled from 'styled-components';
import { AccumulationCardsContainerProps } from './Category.def';

const Styled = {
  Container: styled.div``,
  WrapperContent: styled.div`
    max-width: 1000px;
    margin: auto;
    display: flex;
    flex-direction: column;
    @media ${({ theme }) => theme.devices.desktop} {
      flex-direction: row;
    }
  `,
  AccumulationCardsContainer: styled.div<AccumulationCardsContainerProps>`
    flex: 1;
    width: ${({ isMobile }) => (isMobile ? '100%' : '50%')};
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &:only-child {
      flex: 2;
      width: 100%;
    }
  `,
  MessageUpdate: styled.p`
    width: 100%;
    font-weight: 450;
    font-size: 14px;
    line-height: 175%;
    letter-spacing: -0.01em;
    color: #474c4e;
    background: #fff;
    padding: 20px;
    border-radius: 12px;
    margin-top: 20px;
    strong {
      color: var(--color-primary);
    }
    a {
      text-decoration: none;
    }

    @media ${({ theme }) => theme.devices.tablet} {
      width: 50%;
      margin-top: 0;
      font-size: 16px;
      background: transparent;
      padding: 0;
    }
  `,
};

export default Styled;
