import { IBadge } from './Badge';

import { ILink, IImage, NoImage } from './Common';

export interface IReedemCardCategory {
  name: string;
  icon: IImage;
  color: string;
}

export const NoReedemCardCategory: IReedemCardCategory = {
  name: '',
  icon: NoImage,
  color: '',
};

export interface IReedemCard {
  name: string;
  image: IImage;
  partnerLogo?: IImage;
  title?: string;
  text?: string;
  currentPoints?: number;
  points?: number;
  link?: ILink;
  showOnlyImage: boolean;
  badges?: IBadge[];
  category?: IReedemCardCategory;
}

export const NoReedemCard: IReedemCard = {
  name: '',
  image: NoImage,
  title: '',
  text: '',
  points: NaN,
  showOnlyImage: true,
  badges: [],
  category: NoReedemCardCategory,
};

export interface IReedemCardsGroup {
  name: string;
  cards: IReedemCard[];
}

export const NoReedemCardsGroup = {
  name: '',
  cards: [],
};
