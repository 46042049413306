import { CustomerCategoryName } from 'domain/model/customer';
import styled, { css } from 'styled-components';

const Styled = {
  WrapperCard: styled.div`
    height: 100%;
    position: relative;
    min-width: 230px;
    @media ${({ theme }) => theme.devices.tablet} {
      width: 360px;
    }

    @media ${({ theme }) => theme.devices.desktop} {
      width: 360px;
    }

    @media ${({ theme }) => theme.devices.wideDesktop} {
      width: 360px;
    }
    .white-icon {
      filter: brightness(0) invert(1);
    }
  `,
  Card: styled.div`
    height: 100%;
    border: 0.5px solid #c0ced6;
    border-color: '#cfdbe6';
    box-shadow: 0px 4px 2px rgba(0, 51, 102, 0.05);
    border-radius: 16px;
    overflow: hidden;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
  `,

  Header: styled.header<{ type: CustomerCategoryName }>`
    height: auto;
    padding: 24px 24px 24px;
    display: flex;
    justify-content: center;
    ${({ type }) =>
      type === CustomerCategoryName.Fan &&
      css`
        background-color: var(--color-btn-darkmode);
        color: #ffffff;
      `}
    ${({ type }) =>
      type === CustomerCategoryName.Premium &&
      css`
        background-color: #747e81;
        color: #ffffff;
      `}
      ${({ type }) =>
      type === CustomerCategoryName.Elite &&
      css`
        background-color: #000000;
        color: #ffffff;
      `};
  `,
  AccumulatingText: styled.p`
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.01em;
    text-align: left;
  `,
  WrapperPoints: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  Points: styled.p`
    font-weight: 500;
    font-size: 40px;
    line-height: 140%;
    letter-spacing: -0.02em;
    margin-right: 10px;
  `,
  AcumulationInformationText: styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    letter-spacing: -0.01em;

    @media (max-width: 424px) {
      font-size: 14px;
    }
  `,
  BadgeCategory: styled.div`
    display: flex;
    align-items: center;
    padding: 10px 24px;
    gap: 8px;
    position: absolute;
    width: auto;
    height: 64px;
    top: -30px;
    background: #ffffff;
    border: 1px solid #c0ced6;
    box-shadow: 0px 1px 1px rgba(0, 51, 102, 0.05),
      0px 3px 6px rgba(0, 51, 102, 0.05);
    border-radius: 32px;
    font-size: 32px;
    font-weight: 300;
    line-height: 140%;
    letter-spacing: -0.01em;
  `,
  Category: styled.div`
    font-weight: 500;
    display: flex;
    align-items: left;
    width: auto;
    font-size: 32px;
    line-height: 140%;
    letter-spacing: -0.01em;
  `,
  Content: styled.div`
    padding: 20px 16px 16px 16px;
  `,
  BenefitsList: styled.ul``,
  BenefitsItem: styled.li<{ country: string; type: CustomerCategoryName }>`
    display: flex;
    min-height: 48px;
    align-items: center;
    gap: 16px;
    margin: 12px 0;
  `,
  WrapperIcon: styled.div<{
    type?: CustomerCategoryName;
    outlineIcon?: boolean;
  }>`
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  `,
  BenefitsText: styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.01em;
    color: #323537;

    svg {
      margin: 0 5px;
    }

    @media (max-width: 424px) {
      font-size: 14px;
      svg {
        width: 74px;
        margin-left: 0;
      }
    }

    a {
      font-weight: 450;
      font-size: 14px;
      line-height: 150%;
      letter-spacing: -0.01em;
      color: #474c4e;
      margin-left: 5px;
    }
  `,
  DescriptionItem: styled.span`
    display: block;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: -0.02em;
    color: #474c4e;
    @media (max-width: 424px) {
      font-size: 12px;
    }
  `,
  Icon: styled.img`
    min-width: 40px;
  `,
  Footer: styled.footer`
    width: 100%;
    padding: 0 16px 24px;
    margin-top: auto;
    text-align: center;
    a {
      width: 80%;
    }

    button {
      width: auto;
      border-radius: 128px;
      text-transform: inherit;
      font-weight: 400;
      font-size: 14px;
      line-height: 171.4%;
      letter-spacing: -0.01em;
      background-color: white;
      color: #000000;
      border: 0.5px solid #c0ced6;
    }
  `,
};

export default Styled;
