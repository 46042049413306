import styled from 'styled-components';

export const Container = styled.footer`
  background-color: var(--color-bg-dark);
`;

export const Content = styled.div`
  @media screen and ${({ theme }) => theme.devices.tablet} {
    padding: 40px 0 0 80px;
    min-height: 100px;
    justify-content: space-between;
  }
  @media (min-width: 1024px) and (max-width: 1400px) {
    padding: 40px 0em;
  }
  @media (min-width: 1700px) {
    padding: 40px 14em 0 14em;
  }
  @media (min-width: 1800px) {
    padding: 40px 20em;
  }
`;

export const Complementary = styled.aside`
  padding: 32px 24px;

  & > * {
    margin-right: 16px;
  }

  @media screen and ${({ theme }) => theme.devices.tablet} {
    float: right;
  }
`;

export const Copyright = styled.div`
  padding: 32px 24px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;

  div {
    align-self: center;
  }

  img {
    height: 64px;
  }
  small {
    font-size: 12px;
    color: var(--color-white);
    margin-left: 16px;
    white-space: pre-line;
    display: block;

    &:nth-child(2) {
      margin-top: 6px;
    }
  }

  @media screen and ${({ theme }) => theme.devices.tablet} {
    padding: 0px 25px;
    border-top: #475059 0.5px solid;
  }
  @media (max-width: 500px) {
    justify-content: space-between;
    padding: 0px 1.5em;
  }
  @media (min-width: 1440px) {
    justify-content: space-between;
    padding: 0px 10em;
  }
  @media (min-width: 1700px) {
    justify-content: space-between;
    padding: 0px 18em;
  }
  @media (min-width: 1800px) {
    justify-content: space-around;
  }
`;
