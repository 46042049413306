import { ITextImage, NoTextImage } from 'domain/model/content';
import ImageResponsiveMapper from './ImageResponsiveMapper';
import LinkMapper from './LinkMapper';

const LandingImageMapper = (json: any): ITextImage => {
  try {
    const { fields } = json;
    const { title, image, link } = fields;

    return {
      title,
      image: ImageResponsiveMapper(image),
      link: link && LinkMapper(link),
    };
  } catch {
    console.warn(
      '[LandingImageMapper] The content json structure received differed from expected',
    );

    return NoTextImage;
  }
};

export default LandingImageMapper;
