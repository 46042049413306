import { IImage, NoImage } from 'domain/model/content';

const ImageMapper = (json: any, optimize = true): IImage => {
  const useWebPFormat =
    process.env.REACT_APP_CONTENTFUL_USE_IMAGE_WEBP &&
    (globalThis as any).isWebpSupported;

  try {
    const { fields } = json;
    const { title, file } = fields;
    const { url } = file;

    const transformedUrl = useWebPFormat && optimize ? `${url}?fm=webp` : url;

    return { title, url: transformedUrl };
  } catch {
    console.warn(
      '[ImageMapper] The content json structure received differed from expected',
    );

    return NoImage;
  }
};

export default ImageMapper;
