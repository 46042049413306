import React, { CSSProperties } from 'react';
import styled from 'styled-components';

// Components
import Wrapper from './Wrapper';
import Line from './Line';

// Definitions
const SpacingValues = {
  none: 0,
  S: 8,
  M: 16,
  L: 24,
};

type SeparatorSpacing = keyof typeof SpacingValues;

type Props = {
  spacing?: SeparatorSpacing;
  children?: any;
  variant?: 'light' | 'dark' | 'none';
  alignElements?: 'left' | 'center' | 'right' | 'flex-start' | 'flex-end';
  style?: CSSProperties;
};

type ContentProps = {
  align?: string;
};

const Separator = (props: Props) => {
  const {
    spacing = 'S',
    variant = 'light',
    alignElements = 'center',
    children,
    style = {},
  } = props;
  const spacingValue = SpacingValues[spacing];

  return (
    <Wrapper spacing={spacingValue} style={style}>
      <Line variant={variant} />
      {children && <Content align={alignElements}>{children}</Content>}
    </Wrapper>
  );
};

const Content = styled.div<ContentProps>`
  display: flex;
  justify-content: ${({ align }) => align ?? 'center'};
  align-items: center;
  position: relative;
  z-index: 1;
  letter-spacing: 1.2px;
  text-transform: uppercase;

  & > * {
    padding: ${({ align }) => (align === 'center' ? '0 5px' : '0px')};
    background-color: #ffffff;
    letter-spacing: 1.2px;
  }
`;

export default Separator;
