import styled from 'styled-components';

const LegalTextContainer = styled.div`
  width: 100%;
  text-align: justify;
  padding: 32px 26px 65px 26px;

  @media ${({ theme }) => theme.devices.tablet} {
    padding: 64px 54px 74px 54px;
  }

  @media ${({ theme }) => theme.devices.desktop} {
    padding: 64px 72px 74px 72px;
  }

  @media ${({ theme }) => theme.devices.wideDesktop} {
    padding: 52px 164px 72px 164px;
  }

  p {
    font-size: 14px;
    font-weight: 300;
    line-height: 154.2%;
    color: #4a4a4a;
  }
`;

export default LegalTextContainer;
