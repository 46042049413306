import { IImageResponsive, ILink, NoLink, NoImageResponsive } from './Common';

export interface ISlide {
  id: string;
  name: string;
  title: string;
  description?: string;
  color: 'light' | 'dark';
  visibility: 'public' | 'private' | 'all';
  link: ILink;
  image: IImageResponsive;
  ecosystem: string[];
}

export const NoSlide: ISlide = {
  id: '',
  name: '',
  title: '',
  description: '',
  color: 'dark',
  visibility: 'public',
  link: NoLink,
  image: NoImageResponsive,
  ecosystem: [],
};
export interface ISlidesGroup {
  name: string;
  slides: ISlide[];
  commerceName?: string;
  commerceId?: string;
}

export const NoSlidesGroup = {
  name: '',
  slides: [],
  commerceName: '',
  commerceId: '',
};

export interface ISlideFields {
  fields: ISlidesGroup;
}

export const NoSlideFields: ISlideFields = {
  fields: NoSlidesGroup,
};

export interface ISlideField {
  fields: ISlide;
}

export const NoSlideField: ISlideField = {
  fields: NoSlide,
};
