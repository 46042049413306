import { ICard } from 'domain/model/content';
import Image from 'presentation/components/atoms/Image';

// hooks
import useIsMobile from 'infrastructure/hooks/IsMobile';
import truncateText from 'presentation/utils/truncateText';
import { useState } from 'react';
import SmartLink from '../SmartLink/SmartLink';

// Styles
import Styled, { Colorvariants } from './Exchanges.styles';

// utils
import useExchangesUtils from './Exchanges.utils';
import { exchangeName } from './Exchanges.def';

const MAX_LENGTH_TITLE = 22;

// Definitions
type Props = {
  cards?: ICard[];
  title?: string;
  description?: string;
  exchangeSelected?: string;
};

const Exchanges = (props: Props) => {
  // Props
  const { cards, title, exchangeSelected, description } = props;

  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const isMobile = useIsMobile();
  // Utils
  const { methods } = useExchangesUtils();

  const MAX_LENGTH_TEXT = isMobile ? 60 : 90;

  // Handlers
  const handleCardClick = (card: string) => {
    methods.on.cardClick(card, exchangeSelected || '');
  };

  return (
    <Styled.Container>
      <Styled.WrapperSection numberOfCards={cards?.length || 0}>
        <Styled.Title>{title || '¿Qué quieres canjear hoy?'}</Styled.Title>
        {description ? (
          <Styled.ExchangeDescription>{description}</Styled.ExchangeDescription>
        ) : null}
        <Styled.CardContainer>
          {cards?.map((card, index) => (
            <SmartLink
              key={card.name}
              url={card.link?.url || ''}
              target={card.link?.external ? 'adaptable' : 'route'}
              mode="stock">
              <Styled.Card
                exchangeActive={
                  exchangeSelected ===
                  card.name.toLowerCase().replace(/\s+/g, '')
                }
                exchangeName={
                  card.name.toLowerCase().replace(/\s+/g, '') as exchangeName
                }
                onClick={() => handleCardClick(card.name)}
                data-testid={card.name}
                showMore={showMore}
                className={!showMore && index >= 3 ? 'hidden' : ''}>
                <Styled.LeftColumnCard>
                  <Image
                    source={card.image}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'fill',
                    }}
                  />
                </Styled.LeftColumnCard>
                <Styled.RightColumnCard>
                  <Styled.CardTitle
                    background={(card?.color as Colorvariants) || 'black'}>
                    {truncateText(card.name, MAX_LENGTH_TITLE)}
                  </Styled.CardTitle>
                  <Styled.DescriptionCard>
                    {truncateText(card.description, MAX_LENGTH_TEXT)}
                  </Styled.DescriptionCard>
                </Styled.RightColumnCard>
              </Styled.Card>
            </SmartLink>
          ))}
        </Styled.CardContainer>
        <Styled.ShowMore onClick={toggleShowMore}>
          {showMore ? 'Ver menos' : 'Ver más'}
          <Styled.ChevronDown showMore={showMore} />
        </Styled.ShowMore>
      </Styled.WrapperSection>
    </Styled.Container>
  );
};

export default Exchanges;
