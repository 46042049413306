import {
  IExchangeByTranchesGroup,
  NoExchangeByTranchesGroup,
} from './ExchangeByTranches';
import {
  IExchangeCategoriesCardsGroup,
  NoExchangeCategoriesCardsGroup,
} from './ExchangeCategories';
import {
  IOutstandingBrandsGroup,
  NoOutstandingBrandsGroup,
} from './OutstandingBrands';
import { IRecommendedItems, NoRecommendedItems } from './RecommendedItems';
import { IResource, NoResource } from './Resource';

export interface IContentByTypeExchange {
  [x: string]: {
    pageTitle: string;
    exchangeCategories: IExchangeCategoriesCardsGroup;
    outstandingExchangesTab: IRecommendedItems;
    exchangeByTranches: IExchangeByTranchesGroup;
    outstandingBrands: IOutstandingBrandsGroup;
    exchangeInstructions: IResource;
  };
}

export const NoContentByTypeExchange = {
  noTypeSection: {
    pageTitle: '',
    exchangeCategories: NoExchangeCategoriesCardsGroup,
    outstandingExchangesTab: NoRecommendedItems,
    exchangeByTranches: NoExchangeByTranchesGroup,
    outstandingBrands: NoOutstandingBrandsGroup,
    exchangeInstructions: NoResource,
  },
};
