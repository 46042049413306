import { IImageResponsive, NoImageResponsive } from './Common';

export interface IRelatedCommercesImages {
  cmr: IImageResponsive;
  bank: IImageResponsive;
  other: IImageResponsive;
}

export const NoRelatedCommercesImages: IRelatedCommercesImages = {
  cmr: NoImageResponsive,
  bank: NoImageResponsive,
  other: NoImageResponsive,
};
