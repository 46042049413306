import { lazy, useEffect, useLayoutEffect } from 'react';

// Infrastructure
import { useConfiguration } from 'infrastructure/providers/Configuration';
import { useContentful } from 'infrastructure/providers/Contentful';
import { useAnalytics } from 'infrastructure/providers/Analytics';
import { useAuth } from 'infrastructure/providers/Auth';
import useIsMobile from 'infrastructure/hooks/IsMobile';
import useWalkthrough from 'infrastructure/hooks/Walkthrough';

// Components
import LegalInformation from 'presentation/components/organisms/LegalInformation';
import Exchanges from 'presentation/components/organisms/Exchanges';
import Redeem from 'presentation/components/organisms/Redeem';
import LoginIncentive from 'presentation/components/molecules/LoginIncentive';
import TextImageBanner from 'presentation/components/organisms/TextImageBanner';
import CategoryAndBenefitsSection from 'presentation/components/organisms/CategoryAndBenefitsSection/CategoryAndBenefitsSection';
import MainSlider from 'presentation/components/organisms/MainSlider/MainSlider';

// Utils
import setPageTitle from 'presentation/utils/title';

// Styled Components
import FullImageText from 'presentation/components/organisms/fullImageText';
import Styled from './Home.styled';

// Lazy component
const Footer = lazy(() => import('presentation/components/organisms/Footer'));
const Gifcards = lazy(
  () => import('presentation/components/organisms/Giftcards'),
);

const Home = () => {
  // Hooks
  const {
    configuration: { features },
  } = useConfiguration();

  const {
    data: { home, common, mainSlider },
    methods: { LoadHome },
  } = useContentful();

  const {
    data: { logged },
  } = useAuth();

  const { openWalkthrough } = useWalkthrough();

  const isMobile = useIsMobile();

  // Analytics
  const analytics = useAnalytics();

  const commonUI = features.ui.common;
  const homeUI = features.ui.home;

  // On DOM Render
  useLayoutEffect(() => {
    setPageTitle(
      'Inscríbete, acumula pagando como quieras y canjea miles de productos',
    );
  }, []);

  // On load
  useEffect(() => {
    LoadHome();
    analytics.common.setPageLoad(window.location.pathname);
  }, [LoadHome, analytics.common, common]);

  // trigger walkthrough
  useEffect(() => {
    openWalkthrough();
  }, []);
  return (
    <>
      {mainSlider.isLoad && homeUI.slider && !logged.in && (
        <Styled.WrapperSlider>
          <MainSlider id="fif-slider" content={mainSlider.content} />
        </Styled.WrapperSlider>
      )}

      {home.isLoad && (
        <Exchanges
          title={home.newExchangesSection.name}
          cards={home.newExchangesSection.cards}
        />
      )}
      {!logged.in && common.loginIncentive?.text && (
        <LoginIncentive allowClose data={common.loginIncentive} />
      )}
      {home.isLoad && (
        <Redeem data={{ redeem: home.outstandingExchangesTab }} />
      )}

      {homeUI.giftcard && home.isLoad && (
        <Gifcards
          notificationData={home.giftcardNotification}
          onClick={() => {
            const route = process.env
              .REACT_APP_DEEPLINK_BANCO_APP_GIFTCARD as string;
            window.open(route, '_self', 'noopener noreferrer');
          }}
        />
      )}

      {home.isLoad && home.sectionAcumulatesBannerNew.length && (
        <>
          <Styled.SectionTitle>
            Aprende todo sobre tus CMR Puntos
          </Styled.SectionTitle>
          {home.sectionAcumulatesBannerNew.map(item => (
            <Styled.WraperSection key={item.title || 'default-key'}>
              <TextImageBanner
                id={item.title || 'id'}
                alignText={item.descriptionAlign}
                data={item}
              />
            </Styled.WraperSection>
          ))}
        </>
      )}

      {home.isLoad && (
        <CategoryAndBenefitsSection
          title={home.categoryAndBenefits?.title}
          description={home.categoryAndBenefits?.description}
          categoryAndBenefits={home.categoryAndBenefits.categoryAndBenefits}
          cta={home.categoryAndBenefits.cta}
        />
      )}

      {home.isLoad && (
        <FullImageText
          key={home.fullTextImageBanner.title || 'default-key'}
          id={home.fullTextImageBanner.title || 'id'}
          alignText={home.fullTextImageBanner.descriptionAlign}
          data={home.fullTextImageBanner}
        />
      )}

      {commonUI.legalInformation && (
        <LegalInformation
          content={common.interestLinksContent}
          style={!isMobile ? { padding: '40px 80px 0' } : undefined}
        />
      )}
      {commonUI.footer && (
        <Footer
          content={common.interestLinksContent}
          copyright={common.copyRightContent.text}
          companyData={common.companyDataContent}
        />
      )}
    </>
  );
};

export default Home;
