import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: calc(95vh - 180px);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 68px 13px 113px 13px;

  h3 {
    margin-bottom: 48px;
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    color: #585d61;
  }

  svg {
    margin-bottom: 10px;
    width: 100%;
    max-width: 74px;
    max-height: 74px;
  }

  p {
    padding: 0 25px;
  }

  @media ${({ theme }) => theme.devices.tablet} {
    height: calc(65vh - 180px);

    svg {
      max-width: 115px;
      max-height: 115px;
    }

    p {
      font-size: 26px;
      line-height: 33px !important;
    }
  }

  @media ${({ theme }) => theme.devices.desktop} {
    height: calc(90vh - 180px);

    svg {
      max-width: 144px;
      max-height: 144px;
    }
  }

  @media ${({ theme }) => theme.devices.wideDesktop} {
    height: calc(85vh - 180px);
  }
`;

export default Container;
