import { ITextImage, NoTextImage } from 'domain/model/content';
import ImageResponsiveMapper from './ImageResponsiveMapper';
import LinkMapper from './LinkMapper';

const TextImageBannerMapper = (json: any): ITextImage => {
  try {
    const { fields } = json;
    const {
      title,
      image,
      description,
      bgColor,
      itemsAlign,
      link,
      descriptionAlign,
    } = fields;

    return {
      title,
      image: ImageResponsiveMapper(image),
      description,
      descriptionAlign,
      bgColor,
      itemsAlign,
      link: link && LinkMapper(link),
    };
  } catch {
    console.warn(
      '[TextImageBannerMapper] The content json structure received differed from expected',
    );

    return NoTextImage;
  }
};

export default TextImageBannerMapper;
