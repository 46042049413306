import {
  IReedemCardCategory,
  NoReedemCardCategory,
} from 'domain/model/content';
import ImageMapper from './ImageMapper';

const ReedemCategoryCardMapper = (json: any): IReedemCardCategory => {
  try {
    const { fields } = json;
    const { name, icon, color } = fields;

    return {
      name,
      icon: ImageMapper(icon),
      color,
    };
  } catch {
    console.warn(
      '[ReedemCardCategoryMapper] The content json structure received differed from expected',
    );

    return NoReedemCardCategory;
  }
};

export default ReedemCategoryCardMapper;
