import { AES, enc, mode } from 'crypto-js';

// Libraries
import Hash from './hash';

// Definitions
type Element = string | object;
type Configuration = { iv: any; mode: any };
type Options = {
  encode?: boolean;
  iv?: string;
  encryptValues?: boolean;
  ignoreKeys?: string[];
};

// Encrypt methods
const encrypt = {
  base: (element: any, secret: any, config?: any) => {
    return AES.encrypt(element, secret, config).toString();
  },
  clean: (element: any) => {
    return element ? JSON.parse(JSON.stringify(element)) : undefined;
  },
  string: (element: string, secret: string, options?: Options) => {
    if (options) {
      const { encode, iv } = options;
      if (iv) {
        const config: Configuration = { iv, mode: mode.CBC };
        let customSecret = secret as any;
        if (encode) {
          config.iv = enc.Utf8.parse(iv);
          customSecret = enc.Utf8.parse(secret);
        }
        return encrypt.base(element, customSecret, config);
      }
      if (encode) return encrypt.base(element, enc.Utf8.parse(secret));
    }
    return encrypt.base(element, secret);
  },
  object: (element: object, secret: string, options?: Options) => {
    const cleaned = encrypt.clean(element) as object;
    if (Object.values(cleaned).length === 0)
      throw new Error('Object require values');
    if (options && options.encryptValues) {
      const { ignoreKeys } = options;
      return Object.entries(cleaned).reduce((saved, current) => {
        const [key, value] = current;
        if (!value) return saved;
        return ignoreKeys && ignoreKeys.includes(key)
          ? saved
          : { ...saved, [key]: encrypt.string(value, secret, options) };
      }, {});
    }
    return encrypt.string(JSON.stringify(cleaned), secret, options);
  },
};

const Aes = {
  encrypt: (element: Element, secret: string, options?: Options) => {
    if (!element || !secret)
      throw new Error('Element to encrypt and secret is required');
    const cleaned = encrypt.clean(options);
    if (typeof element === 'string')
      return encrypt.string(element, secret, cleaned);
    if (typeof element === 'object')
      return encrypt.object(element, secret, cleaned);
    throw new Error('Element to encrypt is not object or string');
  },
  signature: (payload: object, secret: string, options?: Options) => {
    const hashed = Hash.sha256(JSON.stringify(payload));
    const cleaned = encrypt.clean(options);
    return encrypt.string(hashed, secret, cleaned);
  },
};

export default Aes;
