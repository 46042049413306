import { IResource, NoResource } from 'domain/model/content';

const ResourceMapper = (json: any, enable: boolean): IResource => {
  try {
    const { fields } = json;
    const { name, text, url } = fields;

    return {
      enable,
      name,
      text,
      url,
    };
  } catch {
    console.warn(
      '[ResourceMapper] The content json structure received differed from expected',
    );

    return NoResource;
  }
};

export default ResourceMapper;
