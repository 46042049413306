import WalkthroughSteps from '../walkthroughConfig.defs';

const STEPS: WalkthroughSteps = {
  mainPath: '/clave',
  isPublic: true,
  hideActions: true,
  desktop: [
    {
      selector: '#change-password',
      highlightedSelectors: ['#change-password'],
      mutationObservables: ['#change-password'],
      resizeObservables: ['#my-account-button', '#change-password'],
      content:
        'Ingresa tu RUT para que podamos crear o recuperar tu clave CMR Puntos.',
      position: 'left',
      stepInteraction: true,
    },
    {
      selector: '#change-password',
      highlightedSelectors: ['#change-password'],
      mutationObservables: ['#change-password'],
      resizeObservables: ['#my-account-button', '#change-password'],
      content: 'Crea tu nueva clave CMR Puntos de 6 dígitos.',
      position: 'left',
      stepInteraction: true,
    },
    {
      selector: '#change-password',
      highlightedSelectors: ['#change-password'],
      mutationObservables: ['#change-password'],
      resizeObservables: ['#my-account-button', '#change-password'],
      content:
        'Por último, solo queda que ingreses el código de autorización que enviamos a tu celular.',
      position: 'left',
      stepInteraction: true,
    },
  ],
  mobile: [
    {
      selector: '#login-content',
      highlightedSelectors: ['#change-password'],
      mutationObservables: ['#change-password'],
      resizeObservables: ['#change-password'],
      content:
        'Ingresa tu RUT para que podamos crear o recuperar tu clave CMR Puntos.',
    },
    {
      selector: '#password-form',
      highlightedSelectors: ['#password-form'],
      resizeObservables: ['#password-form'],
      content: 'Crea tu nueva clave CMR Puntos de 6 dígitos.',
      navDotAriaLabel: 'hide-actions',
    },
    {
      selector: '#change-password',
      highlightedSelectors: ['#change-password'],
      resizeObservables: ['#change-password'],
      content:
        'Por último, solo queda que ingreses el código de autorización que enviamos a tu celular.',
      navDotAriaLabel: 'hide-actions',
    },
  ],
};

export default STEPS;
