import { useScreenClass } from 'react-grid-system';

// Hook
function useIsMobile(sizes: string[] = ['xs', 'sm']) {
  const screenSize = useScreenClass();

  return sizes.includes(screenSize);
}

export default useIsMobile;
