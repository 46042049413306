import WalkthroughSteps from '../walkthroughConfig.defs';

const STEPS: WalkthroughSteps = {
  mainPath: '/movements',
  isPublic: false,
  desktop: [
    {
      selector: '#login-content',
      content: 'Inicia tu sesión de CMR Puntos.',
      highlightedSelectors: ['#login-content'],
      mutationObservables: ['#login-content'],
      resizeObservables: ['#login-content'],
      stepInteraction: true,
    },
    {
      selector: '#expiration-id',
      highlightedSelectors: ['#expiration-points-id'],
      mutationObservables: ['#expiration-points-id'],
      resizeObservables: ['#expiration-points-id', '#expiration-id'],
      content: 'Revisa tus CMR Puntos que estén más cerca de vencer.',
    },
  ],
  mobile: [
    {
      selector: '#login-content',
      content: 'Inicia tu sesión de CMR Puntos.',
      highlightedSelectors: ['#content-login-steps'],
      mutationObservables: ['#content-login-steps'],
      resizeObservables: ['#content-login-steps'],
    },
    {
      selector: '#user-menu',
      highlightedSelectors: ['#expiration-points-id'],
      mutationObservables: ['#expiration-points-id'],
      resizeObservables: ['#expiration-points-id', '#expiration-id'],
      content: 'Revisa tus CMR Puntos que estén más cerca de vencer.',
    },
  ],
};

export default STEPS;
