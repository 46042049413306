import { ILink } from './Common';

export interface IContentSection<TContent = any> {
  name: string;
  title: string;
  subtitle?: string;
  description?: string;
  content: TContent;
  contents?: TContent[];
  callToAction?: ILink;
  footer?: string;
}

export const NoContentSection: IContentSection = {
  name: '',
  title: '',
  subtitle: '',
  description: '',
  content: {},
  contents: [],
  footer: '',
};
