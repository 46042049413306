import clientMapCL from './cl/clientMap.json';
import clientMapCO from './co/clientMap.json';
import clientMapPE from './pe/clientMap.json';

const resolveClientMap = () => {
  switch (process.env.REACT_APP_COUNTRY) {
    case 'cl':
      return clientMapCL;
    case 'co':
      return clientMapCO;
    case 'pe':
      return clientMapPE;
    default:
      return [];
  }
};

export default resolveClientMap();
