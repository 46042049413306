import { ICardImageLink } from './CardImageLink';

export interface IOutstandingBrandsGroup {
  enable: boolean;
  name: string;
  cards: ICardImageLink[];
}

export const NoOutstandingBrandsGroup = {
  enable: false,
  name: '',
  cards: [],
};
