import React from 'react';

// Infrastructure
import useRouter from 'infrastructure/hooks/Router';

// Components
import Icon from 'presentation/assets/icons';
import { Paragraph, Strong } from 'presentation/components/atoms/Text';
import Button from 'presentation/components/atoms/Button';

// Styles
import * as Styled from './ErrorPage.style';

const ErrorPage = () => {
  const {
    history: { push },
  } = useRouter();

  return (
    <Styled.Wrapper>
      <Styled.Container>
        <Icon name="fourOhFour" />
        <Strong bold> Error 404</Strong>
        <Paragraph>
          Lo sentimos, la página que buscas no está disponible.
        </Paragraph>
        <Button isFullWidth variant="tertiary" onClick={() => push('/')}>
          Volver
        </Button>
      </Styled.Container>
    </Styled.Wrapper>
  );
};

export default ErrorPage;
