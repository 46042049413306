import {
  IRelatedCommercesImages,
  NoRelatedCommercesImages,
} from 'domain/model/content';
import ImageResponsiveMapper from './ImageResponsiveMapper';

const RelatedCommercesImagesMapper = (json: any): IRelatedCommercesImages => {
  try {
    const { fields } = json;
    const { cmr, bank, other } = fields;

    return {
      cmr: ImageResponsiveMapper(cmr),
      bank: ImageResponsiveMapper(bank),
      other: ImageResponsiveMapper(other),
    };
  } catch {
    console.warn(
      '[RelatedCommercesImagesMapper] The content json structure received differed from expected',
    );

    return NoRelatedCommercesImages;
  }
};

export default RelatedCommercesImagesMapper;
