import styled from 'styled-components';

// Components
import Icon from 'presentation/assets/icons';

export const Fab = styled.button`
  cursor: pointer;
  background: #38a121;
  border: 0.5px solid #c9d4df;
  box-shadow: 0px 1px 1px rgba(0, 51, 102, 0.05),
    0px 6px 12px -3px rgba(0, 51, 102, 0.075);
  border-radius: 50px;
  text-align: center;
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 20px;
  right: 20px;
  z-index: 100;
  -webkit-transition: width 0.5s;
  transition: width 0.5s;
  transition: all 0.4s ease 0s;

  @media ${({ theme }) => theme.devices.desktop} {
    width: 60px;
    height: 60px;
  }

  &:hover,
  :active {
    width: 200px;
    svg {
      animation: rotation 2s;
    }

    span {
      color: #ffffff;
      max-width: 230px;
    }

    @media ${({ theme }) => theme.devices.desktop} {
      width: 230px;
    }
  }
`;

export const Text = styled.span`
  color: #38a121;
  font-size: 1rem;
  max-width: 0;
  white-space: nowrap;
  overflow: hidden;
`;
export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IconButon = styled(Icon)`
  path {
    fill: #ffffff;
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;
