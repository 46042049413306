const expirationPoints = {
  tooltip: {
    text: '¿Por qué vencen mis puntos?',
    elite:
      'Por ser cliente elite tus puntos duran 2 años desde el día que los acumulaste',
    fan: 'Por ser cliente fan tus puntos duran 1 año desde el día que los acumulaste',
    premium:
      'Por ser cliente premium tus puntos duran 2 años desde el día que los acumulaste',
    default:
      'Por ser cliente CMR Puntos, tus puntos duran 1 año desde el día que los acumulaste',
  },
};

export default expirationPoints;
