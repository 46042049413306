import contentful from 'infrastructure/services/contentFul';

// Mappers
import { CardsGroupMapper, ContentByTypeExchangeMapper } from '../mappers';

// Service
const {
  entriesResource: { getEntry },
} = contentful;

// Vars
const ExchangeExperiencieEntryId =
  process.env.REACT_APP_CONTENTFUL_DATA_EXCHANGE_EXPERIENCE;

const LoadsExchangeExperience = async () => {
  try {
    const data = await getEntry<any>(ExchangeExperiencieEntryId, {
      'sys.id': ExchangeExperiencieEntryId,
      include: 10,
    });

    return {
      exchangesSection: CardsGroupMapper(data.fields?.exchangesSection),
      typeSection: ContentByTypeExchangeMapper(
        data.fields?.contentByTypeExchange,
      ),
    };
  } catch (error) {
    console.error(error);
  }
};

export default LoadsExchangeExperience;
