import { categoryAndBenefitsProps } from 'domain/configuration';

export interface IShortCustomInformation {
  title: string;
  description: string;
  categoryAndBenefits: categoryAndBenefitsProps[];
  cta: callToActionInterface;
}

interface callToActionInterface {
  url: string;
  target: string;
  text: string;
}
export const NoShortCustomInformation = {
  title: '',
  description: '',
  categoryAndBenefits: [],
  cta: {
    url: '',
    target: '',
    text: '',
  },
};
