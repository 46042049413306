import * as React from 'react';
import { Redirect, Route } from 'react-router-dom';

type Props = {
  component: any;
  [x: string]: any;
};

const SsoRoute = (props: Props) => {
  const { component: Component, ...rest } = props;

  const urlCallBack = 'url_callback';
  const integratorID = 'integratorID';
  return (
    <Route
      {...rest}
      render={({ history }) => {
        const searchParams = new URLSearchParams(history.location.search);

        const hasUrlCallBack = searchParams.has(urlCallBack);
        const getUrlCallBack = searchParams.get(urlCallBack);
        const hasIntegratorID = searchParams.has(integratorID);
        const getIntegratorID = searchParams.get(integratorID);

        let isDisabled = false;
        let goToSso = true;

        if (!hasUrlCallBack) isDisabled = true;
        if (!getUrlCallBack) isDisabled = true;
        if (!hasIntegratorID) goToSso = false;
        if (!getIntegratorID) goToSso = false;

        return goToSso ? (
          <Component {...{ props, isDisabled }} />
        ) : (
          <Redirect to="/" />
        );
      }}
    />
  );
};

export default SsoRoute;
