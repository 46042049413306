// Definitions
import { ValidationResponse } from '../Validations.defs';

const Validation = {
  dni: (value: string): ValidationResponse => {
    return { valid: true, message: '' };
  },
  password:
    (dni: string) =>
    (value: string): ValidationResponse => {
      // PASSWORD VALIDATIONS RULES

      // Character repeated 4 times or more
      function notRepeatedCharacters(value: string) {
        const pattern = /([a-zA-Z0-9])(?=(.*?\1){3,})/;

        return !pattern.test(value);
      }

      // 3 consecutives numbers
      function notConsecutiveNumbers(value: string) {
        const pattern = /(012|123|234|345|456|567|678|789)/;

        return !pattern.test(value);
      }

      // Password is contained in the document number
      function notDniSubSequence(value: string, dni: string) {
        if (!(dni && value)) {
          return true;
        }

        if (value.length < 6) {
          return true;
        }

        return dni.indexOf(value) === -1;
      }

      const response = {
        valid:
          notRepeatedCharacters(value) &&
          notConsecutiveNumbers(value) &&
          notDniSubSequence(value, dni),
        message: '',
      };

      return response;
    },
};

export default Validation;
