// Domain
import Formatters from 'domain/formatters';
// Components
import Icon, { IconName } from 'presentation/assets/icons';
import { useMemo } from 'react';

// Def
import { CardBenefitsProps } from './CardBenefits.def';

// Styled
import Styled from './CardBenefits.style';

const CardBenefits = ({ data }: CardBenefitsProps) => {
  const {
    iconName,
    iconNameBlock,
    title,
    status,
    details,
    legalMessage,
    colorCategory,
    pointsToReach,
  } = data;

  const isPE = useMemo(() => process.env.REACT_APP_COUNTRY === 'pe', []);

  return (
    <Styled.Container status={status} country={isPE}>
      <Styled.Status>
        <Icon size="tiny" name={status === 'bloqueado' ? 'lock' : 'unLock'} />
      </Styled.Status>
      <Styled.Header>
        <Styled.WrapperTitle>
          <Icon
            size="tiny"
            name={
              status === 'bloqueado'
                ? (iconNameBlock as IconName)
                : (iconName as IconName)
            }
          />
          <Styled.Title style={{ color: `${colorCategory}` }}>
            {title}
          </Styled.Title>
          {pointsToReach !== 0 && (
            <Styled.WrapperPoints>
              <Styled.PointsToReach>
                {Formatters.points(pointsToReach)}
              </Styled.PointsToReach>
              <Icon size="tiny" name="cmr" />
            </Styled.WrapperPoints>
          )}
        </Styled.WrapperTitle>
      </Styled.Header>

      <Styled.Content>
        <Styled.List>
          {details &&
            details.map((item: any) => {
              return (
                <Styled.Item status={status} key={item.id}>
                  {item.description}
                </Styled.Item>
              );
            })}
        </Styled.List>

        <br />
        {legalMessage &&
          legalMessage.map((item: any) => {
            return (
              <Styled.LegalMessage status={status} key={item.id}>
                {item.description}
              </Styled.LegalMessage>
            );
          })}
      </Styled.Content>
    </Styled.Container>
  );
};

export default CardBenefits;
