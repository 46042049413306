import React from 'react';

import styled from 'styled-components';

// Components
import Icon, { IconName } from 'presentation/assets/icons';
import Anchor from 'presentation/components/atoms/Anchor';
import ButtonIcon from 'presentation/components/atoms/ButtonIcon';

// Utils
import utils from './AppBar.utils';

// Definitions
export type IconConfigTypes = {
  icon?: IconName;
  variant: 'light' | 'dark';
  type: 'button' | 'link';
  href?: string;
};

export type TitleConfigTypes = {
  text: string;
  variant: 'light' | 'dark';
};

export type Props = {
  id?: string;
  height?: string;
  padding?: string;
  titleConfig: TitleConfigTypes;
  leftIconConfig?: IconConfigTypes;
  rightIconConfig?: IconConfigTypes;
  onClickLeftIcon?: () => void;
  onClickRightIcon?: () => void;
  appPosition?: 'absolute' | 'normal';
};

type ContainerProps = {
  height?: string;
  padding?: string;
  position?: string;
};

type TextProps = {
  variant: string;
};

const AppBar = (props: Props) => {
  const {
    id,
    height = '64px',
    padding = '1.5rem 1rem 0',
    titleConfig,
    leftIconConfig,
    rightIconConfig,
    onClickLeftIcon = () => {},
    onClickRightIcon = () => {},
    appPosition,
  } = props;

  // Methods
  const methods = {
    clickLeftButton: () => {
      if (leftIconConfig?.icon) onClickLeftIcon();
    },
    clickRightButton: () => {
      if (rightIconConfig?.icon) onClickRightIcon();
    },
  };

  return (
    <Container id={id} height={height} padding={padding} position={appPosition}>
      {leftIconConfig?.type === 'button' && (
        <ButtonIcon
          icon={<Icon name={leftIconConfig?.icon} />}
          onClick={methods.clickLeftButton}
        />
      )}
      {leftIconConfig?.type === 'link' && (
        <Anchor url={leftIconConfig?.href ?? '#'} width="32px" height="32px">
          <Icon name={leftIconConfig?.icon} />
        </Anchor>
      )}
      {!leftIconConfig?.icon && !leftIconConfig?.type && <Default />}

      {titleConfig.text && (
        <Text variant={titleConfig.variant}>{titleConfig.text}</Text>
      )}

      {rightIconConfig?.type === 'button' && (
        <ButtonIcon
          data-testid={id}
          size="M"
          onClick={methods.clickRightButton}
          icon={<Icon name={rightIconConfig?.icon} />}
        />
      )}
      {rightIconConfig?.type === 'link' && (
        <Anchor url={rightIconConfig.href ?? '#'} width="32px" height="32px">
          <Icon name={rightIconConfig?.icon} />
        </Anchor>
      )}
      {!rightIconConfig?.icon && !rightIconConfig?.type && <Default />}
    </Container>
  );
};

const Default = styled.div`
  width: 32px;
  height: 32px;
`;

const Container = styled.div<ContainerProps>`
  height: ${({ height }) => height ?? 'inherit'};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ padding }) => padding ?? 'inherit'};
  width: 100%;
  position: ${({ position }) =>
    position === 'absolute' ? 'absolute' : 'unset'};
  z-index: 1;
`;

const Text = styled.h3<TextProps>`
  font-size: 1rem;
  font-weight: 400;
  text-transform: inherit;
  padding: 0;
  margin: 0 auto;
  display: block;
  height: initial;
  color: ${({ variant, theme }) => utils.getColor(variant, theme)};
  font-family: ${({ theme }) => theme?.font?.family ?? 'inherit'};
`;

export default AppBar;
