import { Redirect, Route } from 'react-router-dom';

type Props = {
  component: any;
  hash: string;
  redirectRoute: string;
  [x: string]: any;
};

const HashRouteToPage = (props: Props) => {
  const { component: Component, hash, redirectRoute, ...rest } = props;

  return (
    <Route
      {...rest}
      render={({ location }) =>
        location?.hash === `#${hash}` ? <Redirect to={redirectRoute} /> : null
      }
    />
  );
};

export default HashRouteToPage;
