// Domain
import { IFaqQuestion, NoFaqGroup } from 'domain/model/content';
import FaqQuestionMapper from './FaqQuestionMapper';

export default function FaqGroupMapper(
  data: any[],
): IFaqQuestion[] | typeof NoFaqGroup {
  try {
    return data.map(item => {
      const {
        title,
        subtitle,
        questions: collection,
        imageIcon: {
          fields: {
            file: { url: image },
          },
        },
      } = item.fields;

      const questions = collection?.map(FaqQuestionMapper) || [];

      return {
        title,
        subtitle,
        image,
        questions,
      };
    });
  } catch (error) {
    console.warn(
      '[FaqGroupMapper] The content json structure received differed from expected',
    );
    return NoFaqGroup;
  }
}
