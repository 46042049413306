import { IReedemCard, NoReedemCard } from 'domain/model/content';
import LinkMapper from './LinkMapper';
import BadgeMapper from './BadgeMapper';
import ReedemCardCategoryMapper from './ReedemCategoryCardMapper';
import ImageMapper from './ImageMapper';

const ReedemCardMapper = (json: any): IReedemCard => {
  try {
    const { fields } = json;
    const {
      name,
      image,
      partnerLogo,
      title,
      text,
      currentPoints,
      points,
      link,
      showOnlyImage,
      badges,
      category,
    } = fields;

    return {
      name,
      image: ImageMapper(image),
      partnerLogo: ImageMapper(partnerLogo),
      title,
      text,
      currentPoints,
      points,
      link: link && LinkMapper(link),
      showOnlyImage,
      badges: badges && badges.map(BadgeMapper),
      category: category && ReedemCardCategoryMapper(category),
    };
  } catch {
    console.warn(
      '[ReedemCardMapper] The content json structure received differed from expected',
    );

    return NoReedemCard;
  }
};

export default ReedemCardMapper;
