import { ILinksGroup, NoLinksGroup } from 'domain/model/content';
import LinkMapper from './LinkMapper';

export default function LinksGroupMapper(json: any): ILinksGroup {
  try {
    const { fields } = json;
    const { name, title, links, isHidden } = fields;

    return {
      name,
      title,
      links: links.map(LinkMapper),
      isHidden,
    };
  } catch {
    console.warn(
      '[LinksGroupMapper] The content json structure received differed from expected',
    );

    return NoLinksGroup;
  }
}
