import * as React from 'react';
import { CSSProperties } from 'react';

// Domain content
import { ILink, ILinksGroup } from 'domain/model/content';

// Components
import SmartLink from 'presentation/components/organisms/SmartLink';

// Styles
import {
  Nav,
  Heading,
  Group,
  GroupItem,
  getVariantTokens,
} from './LinksGroup.style';

// Definitions
export type Props = {
  content: ILinksGroup;
  variant?: 'light' | 'dark';
  showTitle?: boolean;
  utmMedium?: string;
  style?: CSSProperties;
  onClick?: (link: ILink) => void;
};

const LinksGroup = (props: Props) => {
  // Props
  const {
    content,
    showTitle = true,
    variant = 'dark',
    utmMedium,
    style,
    onClick = () => {},
  } = props;

  // Variables
  const { title, links } = content;
  const utm = utmMedium ? { medium: utmMedium } : undefined;
  const variantTokens = getVariantTokens(variant);

  return (
    <Nav tokens={variantTokens} aria-label={title}>
      {!!showTitle && <Heading tokens={variantTokens}>{title}</Heading>}

      <Group style={style}>
        {links.map(item => (
          <GroupItem
            key={item.text}
            tokens={variantTokens}
            data-testid={`footer-link-${item.text}`}>
            <SmartLink
              url={item.url}
              target={item.external ? 'adaptable' : 'route'}
              utm={utm}
              mode="stock"
              onClick={() => onClick(item)}>
              {item.text}
            </SmartLink>
          </GroupItem>
        ))}
      </Group>
    </Nav>
  );
};

export default LinksGroup;
