import { AxiosResponse, CancelTokenSource } from 'axios';

// Utils
import Random from 'infrastructure/utils/random';

// Client
import { httpClient, token as clientToken } from '../../client';

// Definitions
import { ExtendConfig } from '../../definitions';
import {
  CustomerStatusRequest,
  CustomerStatusResponse,
  CustomerPointsResponse,
  CustomerResponse,
  CustomerUpdateRequest,
  CustomerUpdateResponse,
  CustomerDataResponse,
} from './customer.defs';

// Dynamic token source
let source: CancelTokenSource;

const Customer = {
  cancel: () => source?.cancel('Customer service cancelled'),
  customerType: (
    request: CustomerStatusRequest,
  ): Promise<AxiosResponse<CustomerStatusResponse>> => {
    source = clientToken.source();

    const {
      documentType,
      documentNumber,
      tokenCaptcha: token,
      phone,
    } = request;

    const data = { documentNumber, documentType, phone, token };

    const encrypt = {
      secret: Random.string(32),
      iv: Random.string(16),
      encode: true,
    };
    const commerceHeader = { 'x-commerce': 'CMR', 'x-channel': 'Web' };

    return httpClient.post('v1/customer/validate', data, {
      cancelToken: source.token,
      encrypt,
      headers: commerceHeader,
    } as ExtendConfig);
  },
  me: (authToken: string): Promise<AxiosResponse<CustomerResponse>> => {
    source = clientToken.source();

    const commerceHeader = { 'x-commerce': 'CMR', 'x-channel': 'Web' };

    return httpClient.get<CustomerResponse>('v2/customer', {
      cancelToken: source.token,
      headers: commerceHeader,
      authToken,
    } as ExtendConfig);
  },
  points: (
    authToken: string,
  ): Promise<AxiosResponse<CustomerPointsResponse>> => {
    source = clientToken.source();

    return httpClient.get<CustomerPointsResponse>('v1/points', {
      cancelToken: source.token,
      authToken,
    } as ExtendConfig);
  },
  customerData: (
    authToken: string | undefined,
  ): Promise<AxiosResponse<CustomerDataResponse>> => {
    return httpClient.get<CustomerDataResponse>('v1/customer/data', {
      cancelToken: source.token,
      authToken,
    } as ExtendConfig);
  },
  customerUpdate: (
    request: CustomerUpdateRequest,
    authToken: string | undefined,
  ): Promise<AxiosResponse<CustomerUpdateResponse>> => {
    const encrypt = {
      secret: Random.string(32),
      iv: Random.string(16),
      encode: true,
    };

    return httpClient
      .patch<CustomerUpdateResponse>('v1/customer', request, {
        cancelToken: source.token,
        encrypt,
        authToken,
      } as ExtendConfig)
      .catch(error => {
        throw error.response.data;
      });
  },
};

export default Customer;
