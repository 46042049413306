import contentful from 'infrastructure/services/contentFul';

// Mappers
import { ITextImage } from 'domain/model/content';
import { TextImageBannerMapper } from '../mappers';

// Service
const {
  entriesResource: { getEntry },
} = contentful;

// Vars
const MyCategoryContentEntryId =
  process.env.REACT_APP_CONTENTFUL_DATA_MYCATEGORY;

const LoadsMyCategoryPageContent = async () => {
  try {
    const data = await getEntry<any>(MyCategoryContentEntryId, {
      'sys.id': MyCategoryContentEntryId,
      include: 10,
    });

    return {
      title: data.fields?.title,
      subtitle: data.fields?.subtitle,
      updateCategory:
        data.fields.updateCategory &&
        data.fields.updateCategory.map((item: ITextImage) =>
          TextImageBannerMapper(item),
        ),
    };
  } catch (error) {
    console.error(error);
  }
};

export default LoadsMyCategoryPageContent;
