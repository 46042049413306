import React, { CSSProperties } from 'react';
import styled from 'styled-components';

// Utils
import { StyledExtendable } from 'presentation/utils/styles';
import {
  TextDefaultFontMixin,
  TextSizeMixin,
  TextWeightMixin,
  TextWeight,
  TextSize,
} from '../../../utils/styles/Mixins';
import {
  TextAlignmentHelper,
  TextWeightHelper,
  TextSizeHelper,
  ColorsHelper,
  ColorsHelperProps,
} from '../../../utils/styles/Helpers';

// Definitions
export type Props = {
  color?: ColorsHelperProps['color'];
  size?: TextSize;
  weight?: TextWeight;
  align?: 'left' | 'center' | 'right';
  children?: any;
  style?: CSSProperties;
} & StyledExtendable;

type RenderProps = Omit<Props, 'align'> & {
  left?: boolean;
  center?: boolean;
  right?: boolean;
};

const Paragraph = (props: Props) => {
  const { size, weight, align, color, children, style, className } = props;
  const methods = {
    isAlign: (currentAlign?: Props['align']) => align === currentAlign,
  };
  return (
    <Render
      size={size}
      weight={weight}
      style={style}
      color={color}
      left={methods.isAlign('left')}
      center={methods.isAlign('center')}
      right={methods.isAlign('right')}
      className={className}>
      {children}
    </Render>
  );
};

// Style
export const Render = styled.p<RenderProps>`
  /* Defaults */
  ${TextDefaultFontMixin}
  ${TextSizeMixin('regular')};
  ${TextWeightMixin('regular')};

  ${TextWeightHelper};
  ${TextSizeHelper};
  ${TextAlignmentHelper};
  ${ColorsHelper};

  /* Others */
  strong {
    font-weight: bold;
  }
`;

export default Paragraph;
