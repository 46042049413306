import { IImageResponsive } from './Common';

export interface ICoupon {
  code?: string;
  title: string;
  icon: string;
  color: string;
  detail: string;
  expiration: string;
  logo: string;
  logoCommerce: string;
  link: string;
}

export const NoCouponCard: ICoupon = {
  code: '',
  title: '',
  icon: '',
  color: '',
  detail: '',
  expiration: '',
  logo: '',
  logoCommerce: '',
  link: '',
};

export interface ICouponsGroup {
  fields: ICoupon[];
}

export const NoCouponsGroup = [];

export interface ICouponUseText {
  name: string;
  primaryText: string;
  primaryLogo: {
    fields: {
      description?: string;
      file: {
        url: string;
        details: {
          image: { width: number; height: number };
          size: number;
        };
        fileName: string;
        contentType: string;
      };
      title: string;
    };
  };
  secondaryText?: string;
  secondaryLogo?: IImageResponsive[];
}

export const NoCouponUseText: ICouponUseText = {
  name: '',
  primaryText: '',
  primaryLogo: {
    fields: {
      description: '',
      file: {
        url: '',
        details: {
          image: { width: 0, height: 0 },
          size: 0,
        },
        fileName: '',
        contentType: '',
      },
      title: '',
    },
  },
  secondaryText: '',
  secondaryLogo: [],
};

export type ILegalText = string;
export const NoLegalText = '';
