import * as React from 'react';
import { CSSProperties } from 'react';
import styled from 'styled-components';

// Style utils
import { TextSize } from 'presentation/utils/styles/Mixins';
import {
  ColorsHelper,
  TextSizeHelper,
} from 'presentation/utils/styles/Helpers';

// Components
import Icon from 'presentation/assets/icons';

// Definitions
export type Props = {
  size?: TextSize;
  children: string;
  color?: string;
};

const IconStyle: CSSProperties = {
  width: '.5em',
  marginLeft: '0.25em',
  verticalAlign: 'middle',
};

const ActionText = (props: Props) => {
  const { size, children, color = 'text-accent' } = props;

  return (
    <Wrapper size={size} color={color}>
      {children} <Icon name="arrowRight" style={IconStyle} />
    </Wrapper>
  );
};

const Wrapper = styled.span<{ size?: TextSize }>`
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  text-decoration: none;

  ${TextSizeHelper};
  ${ColorsHelper}
`;

export default ActionText;
