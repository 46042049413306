import * as React from 'react';
import styled from 'styled-components';

// Models
import { ILink, ILinksGroup } from 'domain/model/content';

// Components
import LinksGroup from 'presentation/components/molecules/LinksGroup';

// Definitions
type Props = {
  content: ILinksGroup[];
  onClick?: (link: ILink) => void;
};

const DesktopContent = (props: Props) => {
  // Props
  const { content, onClick = () => {} } = props;

  return (
    <Wrapper>
      {content.map(group => {
        return (
          <LinksGroup
            key={group.title}
            content={group}
            variant="light"
            onClick={onClick}
          />
        );
      })}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  & > * {
    min-width: 256px;
    margin-right: 32px;
    margin-bottom: 40px;
    flex: 1 1 0;
  }
`;

export default DesktopContent;
