type ReturnType = {
  addContextUser: (hash: string) => void;
  addCustomEvent: (eventCustom: string, extraMetaData?: {}) => void;
  addCustomError: (error: Error, eventName: string, extraMetaData?: {}) => void;
};

/**
 * @name useDatadogEvents
 * @param {function} addContextUser monitoring action for setUser('<SET_DATA_USER_OBJECT>');
 * @param {function} addCustomEvent monitoring action for addAction('<ACTION_NAME>', '<EXTRA_DATA_OBJECT>');
 * @param {function} addCustomError monitoring action for addError('<MESSAGE_ERROR>', '<ERROR_NAME>', '<EXTRA_DATA_OBJECT>');
 *
 * @returns {
 *  addContextUser,
 *  addCustomEvent,
 *  addCustomError
 * }
 */

const useDatadogEvents = (): ReturnType => {
  const addContextUser = (hash: string): void => {
    if ((window as any).DD_RUM !== undefined) {
      (window as any).DD_RUM.onReady(() => {
        (window as any).DD_RUM.addRumGlobalContext('global', { hashID: hash });
        (window as any).DD_RUM.setUser({ id: hash });
      });
    }
  };

  const addCustomEvent = (actionName: string, contextData?: {}): void => {
    const metaData = { value: actionName, ...contextData };

    if ((window as any).DD_RUM !== undefined) {
      (window as any).DD_RUM.onReady(() => {
        (window as any).DD_RUM.addAction(actionName, metaData);
      });
    }
  };

  const addCustomError = (
    error: Error,
    eventName: string,
    contextData?: {},
  ): void => {
    const metaData = { value: eventName, ...contextData };

    if ((window as any).DD_RUM !== undefined) {
      (window as any).DD_RUM.onReady(() => {
        (window as any).DD_RUM.addError(error, metaData);
      });
    }
  };

  return {
    addContextUser,
    addCustomEvent,
    addCustomError,
  };
};

export default useDatadogEvents;
