// Countries configurations
import cl from './cl';
import co from './co';
import pe from './pe';

const CountryMap = {
  cl,
  co,
  pe,
};
const COUNTRY = process.env.REACT_APP_COUNTRY as keyof typeof CountryMap;

export default CountryMap[COUNTRY];
