import React from 'react';
import styled from 'styled-components';

// Components
import Icon, { IconName } from 'presentation/assets/icons';
import { Paragraph } from 'presentation/components/atoms/Text';

// Definitions
type Props = {
  icon?: IconName;
  children: any;
};

const Information = ({ icon, children }: Props) => {
  return (
    <Render>
      {icon && <Icon name={icon} />}
      <Paragraph style={{ marginLeft: '8px' }}>{children}</Paragraph>
    </Render>
  );
};

const Render = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
`;

export default Information;
