import * as React from 'react';
import { ReactNode } from 'react';

// Components
import { Strong } from 'presentation/components/atoms/Text';

// Definitions
import { RenderOptions } from './Renderers.def';

// Bold text renderer
const StrongRender = (options: RenderOptions) => {
  const { strongColor = 'dark' } = options;

  const renderer = (text: ReactNode) => {
    return <Strong color={strongColor}>{text}</Strong>;
  };

  return renderer;
};

export default StrongRender;
