import styled, { css, keyframes } from 'styled-components';
import {
  CardStyledProps,
  ContainerProps,
  maxCenteredCards,
} from './Exchanges.def';

const rotateDown = keyframes`
  from {
    transform: rotate(45deg);
  }
  to {
    margin: -0.5px 8px;
    transform: rotate(-135deg);
  }
`;

const rotateUp = keyframes`
  from {
    transform: rotate(-135deg);
  }
  to {
    transform: rotate(45deg);
  }
`;

const Styled = {
  Container: styled.section`
    width: 100%;
    // padding: 48px 0px 0 16px;
    background-color: var(--color-white);
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;

    @media ${({ theme }) => theme.devices.tablet} {
      padding: 48px 10px 28px 10px;
    }

    @media ${({ theme }) => theme.devices.desktop} {
      padding: 56px 84px 36px 84px;
    }

    @media ${({ theme }) => theme.devices.wideDesktop} {
      padding: 40px 82px 36px 82px;
    }
  `,
  CardContainer: styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    transition: height 0.5s ease-in-out, opacity 0.5s ease-in-out;

    @media ${() => '(max-width: 460px)'} {
      justify-content: center;
      margin: 0 0 2em;
    }
    @media ${() => '(max-width: 370px)'} {
      justify-content: center;
      margin: 0 1em 2em;
    }
  `,
  WrapperSection: styled.div<ContainerProps>`
    max-width: 100%;
    margin: 0 auto;

    @media ${() => '(min-width: 1280px)'} {
      max-width: 954px;
    }

    @media ${() => '(min-width: 1360px)'} {
      max-width: 1194px;
    }

    @media ${() => '(min-width: 1930px)'} {
      max-width: 1434px;
    }

    .swiper-wrapper {
      ${({ numberOfCards }) =>
        numberOfCards &&
        numberOfCards <= maxCenteredCards.desktop &&
        css`
          @media ${({ theme }) =>
              theme.devices.desktop || theme.devices.wideDesktop} {
            justify-content: space-between;
          }
        `}
    }
  `,
  Title: styled.h1`
    font-weight: 400;
    font-size: 40px;
    color: #44474b;
    margin: 0.5em auto 24px;

    @media ${() => '(max-width: 460px)'} {
      text-align: center;
      font-size: 32px;
      margin: 2em 0 1em;
    }

    @media ${({ theme }) => theme.devices.tablet} {
      font-weight: 400;
      text-align: center;
      font-size: 32px;
      margin: 0 auto 32px;
    }

    @media ${({ theme }) => theme.devices.desktop} {
      font-weight: 400;
      text-align: center;
      font-size: 40px;
      margin: 0.5em auto 32px;
    }

    @media ${({ theme }) => theme.devices.wideDesktop} {
      font-weight: 400;
      text-align: center;
      font-size: 40px;
      margin: 0.5em auto 32px;
    }
  `,

  ExchangeDescription: styled.p`
    font-size: 18px;
    font-weight: 300;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: #323537;
    margin-bottom: 24px;
    max-width: 80%;
    text-align: start;

    @media ${({ theme }) => theme.devices.tablet} {
      text-align: center;
      font-size: 24px;
      margin: 0 auto 32px;
    }

    @media ${({ theme }) => theme.devices.desktop} {
      text-align: center;
      font-size: 24px;
      margin: 0 auto 32px;
    }
  `,
  Card: styled.div<CardStyledProps>`
    display: flex;
    flex: 1 1 calc(33.333% - 20px);
    justify-content: start;
    align-items: start;
    background: #ffffff;
    border: 1px solid #ccd6e0;
    border-radius: 18px;
    max-width: 360px;
    height: 170px;
    max-height: 170px;
    width: 100%;
    margin: 1em;
    opacity: 1;


    @media ${({ theme }) => theme.devices.tablet} {
      max-width: 360px;
      height: 180px;
    }

    @media ${({ theme }) => theme.devices.desktop} {
      max-width: 360px;
      height: 180px;
    }
    @media ${() => '(max-width: 768px)'} {
      max-width: 330px;
    }
    @media ${() => '(max-width: 460px)'} {
      max-width: 360px;
      max-height: 130px;
      margin: 0.5em 0;
      &.hidden {
      display: none;
    }
  `,
  LeftColumnCard: styled.div`
    width: 100%;
    height: 100%;
    flex: 1.6;
    img {
      width: 100%;
      height: 100%;
      object-fit: fill;
      border-radius: 18px 0 0 18px;
    }
    @media ${() => '(max-width: 460px)'} {
      flex: 1.2;
      img {
        object-fit: none !important;
      }
    }
  `,
  RightColumnCard: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem;
    gap: 0.5rem;
    flex: 3;
  `,
  CardTitle: styled.div<StyleTitleCardProps>`
    font-size: 16px;
    color: #347b23;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: -0.01em;

    @media ${({ theme }) => theme.devices.tablet} {
      font-size: 20px;
    }

    @media ${({ theme }) => theme.devices.desktop} {
      font-size: 22px;
    }
  `,
  ShowMore: styled.a`
    color: var(--color-primary);
    display: none;
    transition: transform 0.3s, margin 0.3s;
    @media ${() => '(max-width: 460px)'} {
      display: table;
      margin: -1em auto 2em;
    }
    &.showMore {
      display: block;
      color: #fff;
    }
  `,
  ChevronDown: styled.div<{ showMore: boolean }>`
    display: inline-block;
    width: 8px;
    height: 8px;
    border-right: 2px solid green;
    border-bottom: 2px solid green;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    margin: 2.5px 8px;
    transition: transform 0.3s;
    ${({ showMore }) =>
      showMore
        ? css`
            animation: ${rotateDown} 0.3s forwards;
          `
        : css`
            animation: ${rotateUp} 0.3s forwards;
          `}
    &.showMore {
      border-right: 2px solid white;
      border-bottom: 2px solid white;
    }
  `,
  DescriptionCard: styled.p`
    font-weight: 450;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.01em;
    width: 100%;
    color: #474c4e;

    @media ${({ theme }) => theme.devices.tablet} {
      font-size: 16px;
    }

    @media ${({ theme }) => theme.devices.desktop} {
      font-size: 16px;
    }
  `,
};

export default Styled;

export type Colorvariants =
  | 'orange'
  | 'green'
  | 'blue'
  | 'white'
  | 'skyblue'
  | 'purple'
  | 'yellow'
  | 'black';
type StyleTitleCardProps = {
  background: Colorvariants;
};
