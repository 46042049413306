import styled from 'styled-components';
import { ContainerStyledProps, NavbarStyledProps } from './LegalDocuments.def';

const Styled = {
  Container: styled.div<ContainerStyledProps>`
    width: 100%;
    height: ${({ isLoading }) => (isLoading ? '100vh' : '100%')};
    min-height: 80vh;
    background-color: #525659;
    padding: ${({ isLoading }) => (isLoading ? '24px' : '0px')};
  `,
  WrapperError: styled.div`
    padding-top: 76px;
    padding-bottom: 92px;
    svg {
      margin-bottom: 26px;

      path {
        fill: white;
      }
    }

    p {
      color: white;
    }
  `,
  WrapperPdf: styled.div`
    button {
      font: inherit;
    }
  `,
  NavbarPdf: styled.div<NavbarStyledProps>`
    background-color: #323639;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
    padding: 20px;
    color: white;

    display: grid;
    justify-content: space-between;
    align-items: center;
    grid-template-columns: auto auto;

    position: sticky;
    top: 72px;
    z-index: 10;
    width: 100%;

    a {
      text-decoration: none;
      color: white;

      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        color: #d9d9d9;

        svg {
          path {
            fill: #d9d9d9;
          }
        }
      }

      svg {
        margin-right: 5px;
        path {
          fill: white;
        }
      }
    }

    h1 {
      font-size: inherit;
      margin: 0;
    }

    @media ${({ theme }) => theme.devices.desktop} {
      position: ${({ scrollFixed }) => (scrollFixed ? 'sticky' : 'initial')};
      top: ${({ scrollFixed }) => (scrollFixed ? '56px' : '97px')};
    }
  `,
  WrapperContent: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 24px;
  `,
  WrapperDocument: styled.div`
    padding: 24px 0;

    .react-pdf__Document {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .react-pdf__Page {
      max-width: calc(100% - 2em);
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
      margin: 1em;
    }

    .react-pdf__Page canvas {
      max-width: 100%;
      height: auto !important;
    }

    .react-pdf__message {
      padding: 20px;
      color: white;
    }
  `,
};

export default Styled;
