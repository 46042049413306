import * as React from 'react';
import { useLayoutEffect, useRef } from 'react';

// Components
import { Wrapper, Content } from './CircleProgressBar.style';

// Defs
import Props from './CircleProgressBar.defs';

const CircleProgressBar = (props: Props) => {
  const {
    background = '#E5E5E5',
    color = '#000000',
    duration = 1.5,
    progress,
    width = 133,
    stroke = 10,
    children,
  } = props;

  let finalProgress = progress;

  if (finalProgress < 0) finalProgress = 0;
  if (finalProgress > 100) finalProgress = 100;

  const circle = useRef() as React.MutableRefObject<SVGCircleElement>;
  const radius = width / 2;
  const normalizedRadius = radius - stroke / 1.5;
  const circumference = normalizedRadius * 2 * Math.PI;

  useLayoutEffect(() => {
    const setProgress = (progress: number) => {
      const newOffset = circumference - (progress / 100) * circumference;
      circle.current.style.strokeDashoffset = newOffset.toString();
    };

    const showAnimation = (show: boolean) => {
      circle.current.style.transition = show
        ? `stroke-dashoffset ${duration}s`
        : '';
    };

    showAnimation(false);
    setProgress(0);

    const id = setTimeout(() => {
      showAnimation(true);
      setProgress(finalProgress);
    }, 300);

    return () => clearTimeout(id);
  }, [finalProgress, circumference, duration]);

  return (
    <Wrapper role="figure" width={width}>
      <svg height={width} width={width}>
        <circle
          fill="none"
          stroke={background}
          strokeWidth={stroke}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
        <circle
          ref={circle as React.MutableRefObject<SVGCircleElement>}
          stroke={color}
          fill="none"
          strokeDasharray={`${circumference}`}
          strokeWidth={stroke}
          strokeLinecap="round"
          r={normalizedRadius}
          cx={radius}
          cy={radius}
          transform={`rotate(90 ${radius} ${radius})`}
        />
      </svg>
      {children && <Content width={width}>{children}</Content>}
    </Wrapper>
  );
};

export default CircleProgressBar;
