import {
  IExchangeCardsGroup,
  NoExchangeCardsGroup,
} from 'domain/model/content';
import ExchangeCardMapper from './ExchangeCardMapper';

const ExchangeCardsGroupMapper = (json: any): IExchangeCardsGroup => {
  try {
    const { fields } = json;
    const { name, cards } = fields;

    return {
      name,
      cards: cards.map(ExchangeCardMapper),
    };
  } catch {
    console.warn(
      '[ExchangeCardsGroupMapper] The content json structure received differed from expected',
    );

    return NoExchangeCardsGroup;
  }
};

export default ExchangeCardsGroupMapper;
