import Icon from 'presentation/assets/icons';
import SmartLink from 'presentation/components/organisms/SmartLink';
import { categoryAndBenefitsProps } from 'domain/configuration/categoryAndBenefits';
import Button from 'presentation/components/atoms/Button';
import Styled from './CategoryAndBenefitsCard.style';

const country = process.env.REACT_APP_COUNTRY;
const CategoryAndBenefitsCard = (props: categoryAndBenefitsProps) => {
  const { type, name, icon, points, glosa, benefitsList, cta } = props;

  return (
    <Styled.WrapperCard>
      <Styled.Card>
        <Styled.Header type={type}>
          <div>
            <Styled.Category>
              {name}
              <Icon
                size="medium"
                name={icon}
                style={{ height: 35 }}
                className="white-icon"
              />
            </Styled.Category>
            <Styled.AccumulatingText>
              Acumulando <b>{points} CMR Puntos</b> {glosa}
            </Styled.AccumulatingText>
          </div>
        </Styled.Header>
        <Styled.Content>
          <Styled.BenefitsList>
            Beneficios {name}
            {benefitsList &&
              benefitsList.map(item => (
                <Styled.BenefitsItem
                  key={item.id}
                  country={country || ''}
                  type={type}>
                  <Styled.WrapperIcon
                    type={type}
                    outlineIcon={item.outlineIcon}>
                    {process.env.REACT_APP_COUNTRY !== 'pe' ? (
                      <Styled.Icon src={item.icon} alt="Card Image" />
                    ) : (
                      <Icon
                        size="medium"
                        name={item.icon}
                        style={{ height: 40 }}
                      />
                    )}
                  </Styled.WrapperIcon>
                  <Styled.BenefitsText>
                    {item.text}
                    {item.hasIconFcom ? <Icon name="fcom" /> : null}
                    {item.secondText && item.secondText}
                    {item.description && (
                      <Styled.DescriptionItem>
                        {item.description}
                      </Styled.DescriptionItem>
                    )}

                    {item.link ? (
                      <SmartLink url={item.link.url} target="blank">
                        {item.link.text}
                      </SmartLink>
                    ) : null}
                  </Styled.BenefitsText>
                </Styled.BenefitsItem>
              ))}
          </Styled.BenefitsList>
        </Styled.Content>
        {cta ? (
          <Styled.Footer>
            <SmartLink url={cta.url} target="blank">
              <Button>{cta.text}</Button>
            </SmartLink>
          </Styled.Footer>
        ) : null}
      </Styled.Card>
    </Styled.WrapperCard>
  );
};

export default CategoryAndBenefitsCard;
