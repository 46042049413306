// Providers

// Component
import Image from 'presentation/components/atoms/Image';
import Button from 'presentation/components/atoms/Button';
import Title from 'presentation/components/atoms/Title';
import SmartLink from 'presentation/components/organisms/SmartLink';
import RichText from 'presentation/components/molecules/RichText';
import { isMobile } from 'react-device-detect';
import { TextImageBannerProps } from '../TextImageBanner/TextImageBanner.def';
import {
  Container,
  ImageWrapper,
  InfoWrapper,
  TextWrapper,
} from './FullImageText.style';

const FullImageText = (props: TextImageBannerProps) => {
  // Props
  const { data, id, alignText } = props;
  const { title, image, description, link, itemsAlign } = data;
  const linkId = `link-${id}`;

  return (
    <Container itemsAlign={itemsAlign}>
      <ImageWrapper>
        {image && <Image source={image} autoFit={false} />}
      </ImageWrapper>
      <InfoWrapper alignText={alignText}>
        <Title level="2">{title}</Title>
        <TextWrapper>
          {description && <RichText content={description} />}
        </TextWrapper>
        {link && (
          <SmartLink
            url={link.url}
            mode="stock"
            target={link.external ? 'adaptable' : 'route'}
            id={linkId}>
            <Button variant="tertiary" size="L" isFullWidth={isMobile}>
              {link.text}
            </Button>
          </SmartLink>
        )}
      </InfoWrapper>
    </Container>
  );
};

export default FullImageText;
