import { ICardsGroup, NoCardsGroup } from 'domain/model/content';
import CardMapper from './CardMapper';

const CardsGroupMapper = (json: any): ICardsGroup => {
  try {
    const { fields } = json;
    const { name, cards, description, visible } = fields;

    return {
      name,
      cards: cards.map(CardMapper),
      description,
      visible,
    };
  } catch {
    console.warn(
      '[CardsGroupMapper] The content json structure received differed from expected',
    );

    return NoCardsGroup;
  }
};

export default CardsGroupMapper;
