import * as React from 'react';

// styles
import Styled from './ButtonIcon.style';

// Definitons
import { ButtonIconProps } from './ButtonIcon.defs';

const ButtonIcon = (props: ButtonIconProps) => {
  const {
    icon,
    id,
    size = 'M',
    isDisabled = false,
    type = 'button',
    ...rest
  } = props;
  const testId = id && `${id}-button-icon`;

  return (
    <Styled.ButtonIcon
      data-testid={testId}
      disabled={isDisabled}
      id={id}
      size={size}
      type={type}
      {...rest}>
      {icon}
    </Styled.ButtonIcon>
  );
};

export default ButtonIcon;
