import styled, { CSSProperties } from 'styled-components';

// Constants
const BAR_HEIGHT = '64px';

// Definitions
type ContainerProps = {
  open: boolean;
  fullscreen: boolean;
  offsetTop?: number;
  width?: string;
  height?: string;
  index: number;
  style?: CSSProperties;
};

type ContentProps = {
  withBar?: boolean;
  height?: string;
  style?: CSSProperties;
};

type BackgroundProps = {
  index: number;
};

export const Background = styled.div<BackgroundProps>`
  position: fixed;
  z-index: ${({ index }) => `6${index}0`};
  left: 0;
  top: 0;
  width: 100vw;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  overflow-y: hidden;
`;

export const Container = styled.div<ContainerProps>`
  font-family: ${({ theme }) => theme?.font?.family ?? 'inherit'};
  background-color: #fefefe;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  z-index: ${({ index }) => `6${index}1`};
  position: fixed;
  overflow: hidden;
  ${props => {
    const { open, width, height, offsetTop, fullscreen } = props;

    if (fullscreen) {
      return `
        width: 100vw;
        height: ${height || 'calc(var(--vh, 1vh) * 88)'};
        left:0;
        bottom: 0;
        animation-name: ${open ? 'fadeInUp' : 'fadeOutDown'};
        animation-duration: 0.5s;
        animation-fill-mode: both;
        opacity: ${open ? '100%' : '0'};
      `;
    }

    return `
      width: ${width ?? 'initial'};
      max-width: ${width ?? 'initial'};
      height: ${height ?? 'initial'};
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      left: 50%;
      top: ${offsetTop}px;
      transform: translateX(-50%);
      animation-name: ${open ? 'fadeIn' : 'fadeOut'};
      animation-duration: 0.5s;
      animation-fill-mode: both;
      opacity: ${open ? '100%' : '0'};
    `;
  }};
`;

export const Content = styled.div<ContentProps>`
  padding: 16px 24px 40px;
  padding-top: ${({ withBar }) => !withBar && '2rem'};
  height: ${({ withBar }) => (withBar ? `calc(100% - ${BAR_HEIGHT})` : '100%')};
  overflow-y: auto;
`;
