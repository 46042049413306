// Analytics utils
import Analytics from 'infrastructure/utils/analytics';

import { AnalyticsMarkCall, AnalyticsMarks } from '../Analytics.def';

const { setEventUI, setEventOP, setError } = Analytics;

// Definitions
type PasswordUIMarks = {
  clickValidateDniButton: AnalyticsMarkCall;
  clickValidatePasswordButton: AnalyticsMarkCall;
  clickSubmitOtpButton: AnalyticsMarkCall;
  clickResendOtpSmsButton: AnalyticsMarkCall;
};

type PasswordOPMarks = {
  isLoyaltyCustomer: AnalyticsMarkCall;
  otpSmsSent: AnalyticsMarkCall;
  otpSmsResent: AnalyticsMarkCall;
  createdSuccess: AnalyticsMarkCall;
};

type PasswordErrorMarks = {
  isNotLoyaltyCustomer: AnalyticsMarkCall;
  passwordInvalid: AnalyticsMarkCall;
  otpInvalid: AnalyticsMarkCall;
  validateDniFails: AnalyticsMarkCall;
  validatePasswordFails: AnalyticsMarkCall;
  submitOtpFails: AnalyticsMarkCall;
  generic: AnalyticsMarkCall;
  validateDataFail: AnalyticsMarkCall;
};

export type PasswordAnalyticsMarks = AnalyticsMarks<
  PasswordUIMarks,
  PasswordOPMarks,
  PasswordErrorMarks
>;

// Constants
const PASSWORD_ANALYTICS_CATEGORY = 'Password';

const marks: PasswordAnalyticsMarks = {
  ui: {
    clickValidateDniButton: () => {
      setEventUI(
        PASSWORD_ANALYTICS_CATEGORY,
        'Clic validar si es cliente Loyalty',
      );
    },
    clickValidatePasswordButton: () => {
      setEventUI(PASSWORD_ANALYTICS_CATEGORY, 'Clic validar contraseña');
    },
    clickSubmitOtpButton: () => {
      setEventUI(PASSWORD_ANALYTICS_CATEGORY, 'Clic enviar código Otp');
    },
    clickResendOtpSmsButton: () => {
      setEventUI(PASSWORD_ANALYTICS_CATEGORY, 'Clic reenviar código Otp');
    },
  },
  op: {
    isLoyaltyCustomer: () => {
      setEventOP(PASSWORD_ANALYTICS_CATEGORY, 'Cliente es usuario Loyalty');
    },
    otpSmsSent: () => {
      setEventOP(PASSWORD_ANALYTICS_CATEGORY, 'SMS con código Otp enviado');
    },
    otpSmsResent: () => {
      setEventOP(PASSWORD_ANALYTICS_CATEGORY, 'SMS con código Otp reenviado');
    },
    createdSuccess: () => {
      setEventOP(PASSWORD_ANALYTICS_CATEGORY, 'Contraseña creada exitosamente');
    },
  },
  error: {
    isNotLoyaltyCustomer: () => {
      setError('Cliente no es usuario Loyalty');
    },
    passwordInvalid: () => {
      setError('Nueva contraseña inválida');
    },
    otpInvalid: () => {
      setError('Otp enviado inválido');
    },
    validateDniFails: () => {
      setError('Falló el servicio validar Dni del cliente');
    },
    validatePasswordFails: () => {
      setError('Falló el servicio validar nueva contraseña');
    },
    submitOtpFails: () => {
      setError('Falló el servicio enviar código Otp');
    },
    generic: () => {
      setError('Falló el flujo de creación de contraseña');
    },
    validateDataFail: () => {
      setError('Validación de documento o teléfono por datos invalidos');
    },
  },
};

export default marks;
