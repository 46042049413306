// Definitions
import { ValidatorFunc, ValidatorFuncFactory } from 'domain/validations';
import { IconName as Icon } from 'presentation/assets/icons';
import { Mode, Type } from 'presentation/components/atoms/Input';

type FormOption = {
  id: string;
  value?: string;
  text: string;
  selected?: boolean;
  disabled?: boolean;
  isDisabled?: boolean;
};

export type FormActionButton = {
  id: string;
  text: string;
  url: string;
  target: string;
  type?: string;
  icon?: string;
};

export type FormText = {
  id?: string;
  text: string | FormText[];
  strong?: boolean;
  url?: string;
  target?: 'route' | 'self' | 'blank' | 'parent' | 'top' | 'adaptable';
  testId?: string;
  actionButton?: FormActionButton | null | undefined;
};

export type FormError = {
  code: string;
  description: string | FormText[];
  icon?: Icon;
};

export type FormFeedbackAction = {
  id: string;
  action: string;
  text: string;
  url?: string;
  target?: string;
};

export type FormFeedback = {
  code: string;
  id?: string;
  title?: string | FormText | FormText[];
  subtitle?: string | FormText | FormText[];
  description?: string | FormText | FormText[];
  icon?: Icon;
  primaryAction?: string | FormText | FormFeedbackAction;
  secundaryAction?: string | FormText | FormFeedbackAction;
  tertiaryAction?: string | FormText | FormFeedbackAction;
  type: 'message' | 'alert';
};

export type FormInput = {
  id?: string;
  testId?: string;
  inputId?: string;
  name: string;
  label?: string;
  hintText?: string | FormText | FormText[];
  placeholder: string;
  type: Type;
  mode: Mode;
  disabled?: boolean;
  validator?: ValidatorFunc | ValidatorFuncFactory;
  formatter?: (value: string | number) => any;
  maxLength?: {
    value: number;
    message: string;
  };
  minLength?: {
    value: number;
    message: string;
  };
  value?: string | number;
  help?: string;
  addonText?: string;
  errorMessage?: string;
  typeField?: 'FormInput';
  autoFocus?: boolean;
  autoComplete?: string;
};

export type FormSelect = {
  id?: string;
  testId?: string;
  label?: string;
  hintText?: string | FormText | FormText[];
  placeHolder?: string;
  name: string;
  defaultValue?: string | number;
  disabled?: boolean;
  options: FormOption[];
  typeField?: 'FormSelect';
  autoFocus?: boolean;
  autoComplete?: string;
};

export type FormCheckBox = {
  id?: string;
  testId?: string;
  name: string;
  text: FormText[];
  disabled?: boolean;
  checked?: boolean;
  typeField?: 'FormCheckBox';
  hintText?: string | FormText | FormText[];
  label?: string;
  autoComplete?: string;
};

export type FormTextArea = {
  id: string;
  testId: string;
  name: string;
  label?: string;
  hintText?: string | FormText | FormText[];
  placeholder?: string;
  disabled?: boolean;
  maxLength?: {
    value: number;
    message: string;
  };
  minLength?: {
    value: number;
    message: string;
  };
  readonly?: boolean;
  cols?: string | number;
  rows?: string | number;
  formatter?: (value: string | number) => any;
  typeField?: 'FormTextArea';
  autoFocus?: boolean;
  autoComplete?: string;
};

export type FormDatePicker = {
  id?: string;
  testId?: string;
  name: string;
  label?: string;
  hintText?: string | FormText | FormText[];
  placeholder?: string;
  typeField?: 'FormDatePicker';
  autoFocus?: boolean;
  autoComplete?: string;
};

export type FormInputFile = {
  id?: string;
  testId?: string;
  name: string;
  label?: string;
  hintText?: string | FormText | FormText[];
  placeholder?: string;
  accept?: string;
  typeField?: 'FormInputFile';
};

export type FormButton = {
  id?: string;
  testId?: string;
  name: string;
  text: string | FormText[];
  disabled?: boolean;
  icon?: Icon;
  // TODO: importar estilos desde Button
  color?: 'primary' | 'secondary';
};

export type FormSeparator = {
  id?: string;
  text: string | FormText[];
  icon?: Icon;
};

export type FormDef = {
  id?: string;
  title: FormText | FormText[];
  subtitle?: FormText | FormText[];
  titleCompleteProfile?: FormText | FormText[];
  subtitleCompleteProfile?: FormText | FormText[];
  titleDocumentType?: FormText | FormText[];
  titleDocumentNumber?: FormText | FormText[];
  titlePhone?: FormText | FormText[];
  titleEmail?: FormText | FormText[];
  fields?: (
    | FormSelect
    | FormInput
    | FormTextArea
    | FormDatePicker
    | FormInputFile
  )[];
  components: (
    | FormCheckBox
    | FormButton
    | FormSelect
    | FormInput
    | FormSeparator
    | FormText
    | FormTextArea
    | FormDatePicker
    | FormInputFile
  )[];
  feedback: FormFeedback[];
  completeProfileForm?: (
    | FormText
    | FormInput
    | FormSelect
    | FormDatePicker
    | FormButton
  )[];
  errors: FormError[];
};

export type MethodsDef = {
  getText: (
    type:
      | 'title'
      | 'subtitle'
      | 'titleCompleteProfile'
      | 'subtitleCompleteProfile'
      | 'titleDocumentType'
      | 'titleDocumentNumber'
      | 'titlePhone'
      | 'titleEmail',
  ) => FormText | FormText[] | undefined;
  getComponent: (
    id: string,
  ) =>
    | FormCheckBox
    | FormButton
    | FormSelect
    | FormInput
    | FormText
    | FormSeparator
    | FormTextArea
    | FormDatePicker
    | FormInputFile
    | undefined;
  getCompleteProfileForm: (
    id: string,
  ) =>
    | FormButton
    | FormSelect
    | FormInput
    | FormText
    | FormSeparator
    | FormDatePicker
    | undefined;
  getFeedback: (code: string) => FormFeedback | undefined;
  getError: (code: string) => FormError | undefined;
};

export { Mode, Type };
