import { format } from 'rut.js';

const Formatter = {
  dni: (value: string) => {
    if (!value || value === '-') return '';

    return format(value, { dots: false });
  },
};

export default Formatter;
