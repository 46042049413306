import { IImageResponsive, ILink, NoImageResponsive } from './Common';
import { IRichtext } from './RichText';

export interface ITextImage {
  title?: string;
  image?: IImageResponsive;
  text?: string;
  link?: ILink;
  bgColor?: string;
  itemsAlign?: string;
  haveImage?: boolean;
  description?: IRichtext;
  descriptionAlign?: 'left' | 'center' | 'right';
}

export const NoTextImage: ITextImage = {
  title: '',
  image: NoImageResponsive,
};
