import { UiEventMessage } from 'infrastructure/eventBus';

const LoginEvent: UiEventMessage = {
  subject: 'click button in modal',
  module: 'Modal_login',
  action: 'login',
  target: '',
};

const LoginEvents = {
  click: (target: string) => ({
    ...LoginEvent,
    target,
  }),
};

export default LoginEvents;
