import { useMemo } from 'react';

// Components
import Badge, { BadgeProps } from 'presentation/components/atoms/Badge';

// Defs
import { PropsListBadges } from './ListBadges.def';

// Styles
import Styled from './ListBadges.style';

const ListBadges = ({ badges = [] }: PropsListBadges) => {
  const hasBadges = useMemo(() => badges.length > 0, [badges]);
  if (!hasBadges) return null;

  return (
    <Styled.ListBadges>
      {badges.map(badge => (
        <Badge key={badge.name} variant={badge.color as BadgeProps['variant']}>
          {badge.text}
        </Badge>
      ))}
    </Styled.ListBadges>
  );
};

export default ListBadges;
