import {
  IChallengeCardSection,
  NoChallengeCardSection,
} from 'domain/model/content';

import CustomerCategoryMapper from './CustomerCategoryMapper';
import ChallengeCardMapper from './ChallengeCardMapper';
import ShortCustomInformationMapper from './ShortCustomInformationMapper';

const ChallengeCardSectionMapper = (data: any): IChallengeCardSection => {
  try {
    const [shortCustomInformationData, customerCategoryData, challengeCards] =
      data;

    return {
      shortCustomInformation: ShortCustomInformationMapper(
        shortCustomInformationData,
      ),
      customerCategory: CustomerCategoryMapper(customerCategoryData),
      challengeCards: ChallengeCardMapper(challengeCards),
    };
  } catch {
    console.warn(
      '[ChallengeCardSectionMapper] The content json structure received differed from expected',
    );

    return NoChallengeCardSection;
  }
};

export default ChallengeCardSectionMapper;
