import { useEffect, useMemo, useState } from 'react';

// Hooks

import useIsMobile from 'infrastructure/hooks/IsMobile';
import { useAnalytics } from 'infrastructure/providers/Analytics';
import { useContentful } from 'infrastructure/providers/Contentful';
import { useConfiguration } from 'infrastructure/providers/Configuration';
import { useCustomer } from 'infrastructure/providers/Customer';

// Components
import MessageBox from 'presentation/components/molecules/MessageBox';
import ErrorLoadData from 'presentation/components/molecules/ErrorLoadData';

import LegalInformation from 'presentation/components/organisms/LegalInformation';
import Footer from 'presentation/components/organisms/Footer';
import GoBack from 'presentation/components/molecules/GoBack/GoBack';
import { Title } from 'presentation/components/atoms';
import { Paragraph } from 'presentation/components/atoms/Text';
import TextImageBanner from 'presentation/components/organisms/TextImageBanner';
import Benefits from './components/Benefits/Benefits';
import Category from './components/Category';
import Headboard from './components/Headboard/Headboard';
import ModalExpirationTooltip from './components/ModalExpirationTooltip/ModalExpirationTooltip';
import ModalUpgradeCategory from './components/ModalUpgradeCategory/ModalUpgradeCategory';

// Utils
import resolveModalUpdateCategory from './utils/resolveUpgradeCategory';

// Styled
import Styled from './MyCategory.style';

const MyCategory = () => {
  // Hooks
  const {
    configuration: { features },
  } = useConfiguration();

  const {
    data: { common, myCategoryPageContent },
    methods: { LoadHome, LoadMyCategoryPageContent },
  } = useContentful();
  const commonUI = features.ui.common;

  const {
    data: { customer, error: errorCustomer },
  } = useCustomer();

  const modalUpdateCategoryData = useMemo(
    () => resolveModalUpdateCategory(customer.category),
    [customer],
  );

  const { showModal, data: updateCategoryData } = modalUpdateCategoryData;

  const analytics = useAnalytics();
  const isMobile = useIsMobile();

  const [showCongrat, setShowCongrat] = useState(false);

  const [isOpenExpirationTooltipModal, setIsOpenExpirationTooltipModal] =
    useState(false);

  const [isOpenUpgradeCategoryModal, setIsOpenUpgradeCategoryModal] =
    useState(false);

  useEffect(() => {
    LoadHome();
    LoadMyCategoryPageContent();
    analytics.common.setPageLoad(window.location.pathname);
  }, [LoadHome, analytics.common, LoadMyCategoryPageContent]);

  const handleModal = () => {
    setIsOpenExpirationTooltipModal(!isOpenExpirationTooltipModal);
  };

  useEffect(() => {
    setIsOpenUpgradeCategoryModal(showModal);
    // Card Felicidades se muestra cuando se muestra el modal
    setShowCongrat(showModal);
  }, [customer]);

  return (
    <>
      {errorCustomer ? (
        <Styled.WrapperError>
          <ErrorLoadData
            message="Estamos presentando problemas. Vuelve a intentarlo más tarde."
            iconName="loadFailed"
          />
        </Styled.WrapperError>
      ) : (
        <>
          <GoBack pageTitle="Mi Categoría" />
          <Styled.FullWidthColor
            categoryName={customer?.category?.currentCategory?.name.toLowerCase()}>
            <Styled.Container>
              <Headboard
                dataCategory={customer.category}
                handleModal={handleModal}
              />
              {customer?.category?.currentCategory.name && (
                <Category
                  showCongrat={showCongrat}
                  data={updateCategoryData}
                  currentPoints={customer?.points.total}
                  dataCategory={customer?.category}
                  dataNextCategory={customer?.nextCategory}
                />
              )}
            </Styled.Container>
          </Styled.FullWidthColor>

          {myCategoryPageContent.isLoad && (
            <Styled.Container>
              <Title
                level="1"
                style={{
                  textAlign: 'center',
                  fontSize: '40px',
                  marginTop: '40px',
                }}>
                {myCategoryPageContent?.title}
              </Title>
              <Paragraph style={{ textAlign: 'center', fontSize: '22px' }}>
                {myCategoryPageContent?.subtitle}
              </Paragraph>
              {myCategoryPageContent?.updateCategory?.length &&
                myCategoryPageContent?.updateCategory.map(item => {
                  return (
                    <TextImageBanner
                      key={item.title}
                      id={item.title || 'id'}
                      alignText={item.descriptionAlign}
                      data={item}
                    />
                  );
                })}
            </Styled.Container>
          )}

          <Styled.FullWidthColor
            categoryName={customer?.category?.currentCategory?.name.toLowerCase()}>
            <Styled.Container>
              <Title level="2" style={{ marginBottom: '20px' }}>
                Tus logros
              </Title>
              <Benefits
                id="carousel-benefits"
                handleModal={handleModal}
                dataCategory={customer.category}
              />
              <MessageBox
                type="info"
                style={{
                  borderRadius: '8px',
                  marginBottom: '44px',
                  background: '#fff',
                }}>
                *Beneficios disponibles al alcanzar la acumulación de puntos
                necesarios por cada categoría.
              </MessageBox>
            </Styled.Container>
          </Styled.FullWidthColor>
        </>
      )}

      <ModalExpirationTooltip
        isOpenModal={isOpenExpirationTooltipModal}
        onClose={() => setIsOpenExpirationTooltipModal(false)}
      />

      <ModalUpgradeCategory
        data={updateCategoryData.modal}
        isOpenModal={isOpenUpgradeCategoryModal}
        onClose={() => setIsOpenUpgradeCategoryModal(false)}
      />

      {commonUI.legalInformation && (
        <LegalInformation
          content={common.interestLinksContent}
          style={!isMobile ? { padding: '40px 80px 0' } : undefined}
        />
      )}

      {commonUI.footer && (
        <Footer
          content={common.interestLinksContent}
          copyright={common.copyRightContent.text}
          companyData={common.companyDataContent}
        />
      )}
    </>
  );
};

export default MyCategory;
