import styled from 'styled-components';
import { BenefitsPropsStyled } from './Benefits.def';

const Styled = {
  Container: styled.div<BenefitsPropsStyled>`
    position: relative;
    width: 100%;
    margin-bottom: 29px;

    @media (min-width: 1024px) {
      margin-bottom: 0;
    }
  `,
};

export default Styled;
