// Definitions
import { MethodsDef } from '../Form.defs';
import { FormList } from './Login.defs';

// Country forms
import FormChile from './cl';
import FormColombia from './co';
import FormPeru from './pe';

// Form configuration list
const List = {
  cl: FormChile,
  co: FormColombia,
  pe: FormPeru,
};

function GetConfiguration(code: keyof FormList) {
  const country = process.env.REACT_APP_COUNTRY as keyof typeof List;
  const form = List[country][code];
  const methods: MethodsDef = {
    getText: (
      type:
        | 'title'
        | 'subtitle'
        | 'titleCompleteProfile'
        | 'subtitleCompleteProfile'
        | 'titleDocumentType'
        | 'titleDocumentNumber'
        | 'titlePhone'
        | 'titleEmail',
    ) => form[type],
    getComponent: (id: string) =>
      form.components.find(component => component.id === id),
    getCompleteProfileForm: (id: string) =>
      form.completeProfileForm?.find(input => input.id === id) ?? undefined,
    getFeedback: (code: string) =>
      form.feedback?.find(error => error.code === code),
    getError: (code: string) => form.errors?.find(error => error.code === code),
  };
  return { form, methods };
}

export default GetConfiguration;
