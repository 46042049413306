// Domain model
import { DocumentTypeCode } from 'domain/model';

// Country configuration
import co from './co';
import pe from './pe';

// Country values map
const Values = {
  co,
  pe,
};

const DEFAULT_DOCUMENT_TYPE_VALUE = '1';
const country = process.env.REACT_APP_COUNTRY as keyof typeof Values;

const getDocumentTypeValue = (code?: DocumentTypeCode): string => {
  const values = Values[country];

  if (!code || !values) return DEFAULT_DOCUMENT_TYPE_VALUE;

  return values[code];
};

export default getDocumentTypeValue;
