import auth from './resources/auth';
import customer from './resources/customer';
import password from './resources/password';
import helpCenter from './resources/helpCenter';
import otpCode from './resources/otpCode';
import myExchanges from './resources/myExchanges';
import legal from './resources/legal';

const modules = {
  Auth: auth,
  Customer: customer,
  Password: password,
  HelpCenter: helpCenter,
  OtpCode: otpCode,
  MyExchanges: myExchanges,
  Legal: legal,
};

export default modules;
