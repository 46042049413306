import WalkthroughSteps from '../walkthroughConfig.defs';

const STEPS: WalkthroughSteps = {
  mainPath: '/',
  isPublic: true,
  desktop: [
    {
      selector: '#container-accumulation-id',
      highlightedSelectors: [
        '#my-account-button',
        '#container-accumulation-id',
      ],
      mutationObservables: ['#my-account-button', '#container-accumulation-id'],
      resizeObservables: ['#container-accumulation-id'],
      content: 'Conoce cuánto acumulas con cada forma de pago.',
      position: 'top',
      styles: {
        popover: () => ({
          display: 'none',
        }),
        maskArea: base => ({ ...base, rx: 20 }),
      },
    },
  ],
  mobile: [
    {
      selector: '#container-accumulation-id',
      resizeObservables: ['#container-accumulation-id'],
      content: 'Conoce cuánto acumulas con cada forma de pago.',
      position: 'top',
      styles: {
        popover: () => ({
          display: 'none',
        }),
        maskArea: base => ({ ...base, rx: 20 }),
      },
    },
  ],
};

export default STEPS;
