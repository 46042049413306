import * as React from 'react';
import { ReactNode } from 'react';
import styled from 'styled-components';

// Components
import { Title as TitleOriginal } from 'presentation/components/atoms';
import { Paragraph } from 'presentation/components/atoms/Text';
import Button from 'presentation/components/atoms/Button';
import ActionText from 'presentation/components/atoms/ActionText';

// Definitions
export type Action = {
  id?: string;
  text: string;
  action?: 'recover' | 'back' | 'close' | 'login' | 'complete-profile';
  onClick?: () => void;
};

type Props = {
  title: string | ReactNode;
  subtitle?: string | ReactNode;
  primaryAction?: Action;
  secundaryAction?: Action;
  decorationImage?: ReactNode;
  children: ReactNode;
  marginTop?: number;
};

const Feedback = (props: Props) => {
  // Props
  const {
    title,
    subtitle,
    primaryAction,
    secundaryAction,
    decorationImage: DecorationImage,
    children,
    marginTop,
  } = props;

  return (
    <>
      {DecorationImage}
      <Container>
        <Title
          level="1"
          size="medium"
          style={{ marginTop: marginTop && marginTop }}>
          {title}
        </Title>

        <Content>
          {subtitle && <Paragraph size="large">{subtitle}</Paragraph>}
          {children}
        </Content>

        <Footer>
          {primaryAction && (
            <Button
              id={primaryAction.id}
              variant="primary"
              onClick={primaryAction.onClick}
              isFullWidth>
              {primaryAction.text}
            </Button>
          )}

          {secundaryAction && (
            <SecundaryButton
              id={secundaryAction.id}
              isFullWidth
              onClick={secundaryAction.onClick}>
              <ActionText>{secundaryAction.text}</ActionText>
            </SecundaryButton>
          )}
        </Footer>
      </Container>
    </>
  );
};

const Title = styled(TitleOriginal)`
  margin-bottom: 26px;
  text-align: left;
  font-weight: 200;
`;

const Container = styled.div`
  min-height: 218px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Content = styled.div`
  padding: 0px;
  margin-bottom: 44px;
  text-align: left;
`;

export const Footer = styled.div`
  margin-bottom: 45px;
`;

const SecundaryButton = styled(Button)`
  background-color: transparent;
  color: inherit;
  text-transform: inherit;
  letter-spacing: inherit;
  margin-top: 8px;
`;

export default Feedback;
