import { IContentSection, NoContentSection } from 'domain/model/content';
import LinkMapper from './LinkMapper';

const ContentSectionMapper = <TContent>(
  json: any,
  contentMapper?: (json: any) => TContent,
): IContentSection<TContent> => {
  try {
    const { fields } = json;
    const {
      name,
      title,
      subtitle,
      description,
      content,
      contents = [],
      callToAction,
      footer,
    } = fields;
    return {
      name,
      title,
      subtitle,
      description,
      content: (content && contentMapper && contentMapper(content)) || {},
      contents: contents && contentMapper && contents.map(contentMapper),
      callToAction: callToAction && LinkMapper(callToAction),
      footer,
    };
  } catch {
    console.warn(
      '[ContentSectionMapper] The content json structure received differed from expected',
    );

    return NoContentSection;
  }
};

export default ContentSectionMapper;
