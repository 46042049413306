import { IImage } from 'domain/model/content';
import React from 'react';
import styled from 'styled-components';

// Components
import Image from 'presentation/components/atoms/Image';

// Utils
import variants from './Category.variants';

export type CategoryProps = {
  icon: IImage;
  variant: 'blue' | 'red' | 'orange' | 'green';
};

export type StyleCategoryProps = {
  background: string;
};

const Category = (props: CategoryProps) => {
  const { icon, variant } = props;
  const { background } = getVariant(variant);

  return (
    <Styled.Category background={background}>
      <Image source={icon} />
    </Styled.Category>
  );
};

const getVariant = (variant: CategoryProps['variant']): StyleCategoryProps => {
  let theme = variants[variant];

  if (!theme) {
    theme = variants.gray;
  }

  return theme;
};

const Styled = {
  Category: styled.div<StyleCategoryProps>`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    padding: 12px;
    max-width: 100%;
    max-height: 100%;
    top: 0;
    left: 16px;
    background: ${props => props.background};
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    box-shadow: 0px 1px 1px rgba(0, 51, 102, 0.05),
      0px 6px 12px -3px rgba(0, 51, 102, 0.075);

    svg,
    img {
      width: 100%;
      max-width: 28px;
    }

    @media ${({ theme }) => theme.devices.tablet} {
      width: 57px;
      height: 57px;
      left: 19px;

      svg,
      img {
        max-width: 34px;
      }
    }

    @media ${({ theme }) => theme.devices.wideDesktop} {
      width: 61px;
      left: 20px;

      svg,
      img {
        max-width: 35px;
      }
    }
  `,
};

export default Category;
