import {
  IOutstandingBrandsGroup,
  NoOutstandingBrandsGroup,
} from 'domain/model/content';

import CardImageLinkMapper from './CardImageLinkMapper';

const OutstandingBrandsGroupMapper = (
  json: any,
  enable: boolean,
): IOutstandingBrandsGroup => {
  try {
    const { fields } = json;
    const { name, cards } = fields;

    return {
      enable,
      name,
      cards: cards.map(CardImageLinkMapper),
    };
  } catch {
    console.warn(
      '[OutstandingBrandsGroupMapper] The content json structure received differed from expected',
    );

    return NoOutstandingBrandsGroup;
  }
};

export default OutstandingBrandsGroupMapper;
