import contentful from 'infrastructure/services/contentFul';

// Mappers
import { SlidesGroupMapper } from '../mappers';

// Service
const {
  entriesResource: { getEntry },
} = contentful;

// Vars
const MainSlidesEntryId = process.env.REACT_APP_CONTENTFUL_DATA_SLIDER;

const LoadsMainSlides = async () => {
  try {
    const data = await getEntry<any>(MainSlidesEntryId, {
      'sys.id': MainSlidesEntryId,
      include: 10,
    });
    return {
      content: SlidesGroupMapper(data),
    };
  } catch (error) {
    console.error(error);
  }
};

export default LoadsMainSlides;
