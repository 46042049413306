import { useMemo } from 'react';

// Components
import Carousel from 'presentation/components/molecules/Carousel';
import updateCategory from '../../utils/updateCategory';
import CardBenefits from '../CardBenefits/CardBenefits';

import { BenefitsCarouselProps, dataCardProps } from './BenefitsCarousel.def';

// Styled
import Styled from './BenefitsCarousel.style';

const BenefitsCarousel = ({ id, nameCategory }: BenefitsCarouselProps) => {
  const newData = useMemo(() => updateCategory(nameCategory), [nameCategory]);
  const isPE = useMemo(() => process.env.REACT_APP_COUNTRY === 'pe', []);

  return (
    <Styled.Container country={isPE}>
      <Carousel
        id={id}
        style={{ position: 'relative' }}
        swiperOptions={{
          initialSlide: 0,
          slidesPerView: 'auto',
          spaceBetween: 14,
          speed: 300,
          autoplay: false,
          pagination: { clickable: true },
          breakpoints: {
            320: { slidesPerView: 1.2 },
            540: { slidesPerView: 1.5 },
            768: { slidesPerView: 2.3 },
            992: { slidesPerView: 2.8 },
            1024: { slidesPerView: 4 },
          },
        }}>
        {newData &&
          newData.map((item: dataCardProps) => (
            <CardBenefits key={item.id} data={item} />
          ))}
      </Carousel>
    </Styled.Container>
  );
};

export default BenefitsCarousel;
