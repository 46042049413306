import * as React from 'react';

// Components
import { Paragraph } from 'presentation/components/atoms/Text';
import Separator from 'presentation/components/atoms/Separator';

// Assets
import Icon, { IconName } from 'presentation/assets/icons';

// Utils
import { TextWeight } from 'presentation/utils/styles/Mixins';

type SeparatorTextOptions = {
  value: string;
  color?: string;
  weight?: TextWeight;
};

type Props = {
  text: SeparatorTextOptions;
  iconName?: IconName;
  variant?: 'dark' | 'light';
};

const SeparatorText = (props: Props) => {
  const { text: textOptions, iconName, variant } = props;
  const { value, color, weight = 'regular' } = textOptions;

  return (
    <Separator variant={variant}>
      <Paragraph
        color={color}
        weight={weight}
        style={{ letterSpacing: '1.2px' }}>
        {value}
      </Paragraph>
      {iconName && <Icon name={iconName} />}
    </Separator>
  );
};

export default SeparatorText;
