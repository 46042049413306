import { useState, useEffect } from 'react';

const getModalInstancesCount = () => {
  return document.querySelectorAll('[role="dialog"]').length;
};

/**
 * Custom hook for handling the index of modal instances
 */
const useModalManager = () => {
  const [index, setindex] = useState<number>(0);

  useEffect(() => {
    const instancesCount = getModalInstancesCount();
    setindex(instancesCount);
  }, []);

  return {
    index,
  };
};

export default useModalManager;
