export interface IResource {
  enable?: boolean;
  name: string;
  text: string;
  url: string;
}

export const NoResource = {
  enable: false,
  name: '',
  text: '',
  url: '',
};
