import { FormEvent, useState, useEffect, useMemo, useRef } from 'react';

// Components
import Form, { Group, Header } from 'presentation/components/organisms/Form';
import { Paragraph } from 'presentation/components/atoms/Text';
import Button from 'presentation/components/atoms/Button';
import HelpTip from 'presentation/components/atoms/HelpTip';

// Hooks
import useKeyPress from 'infrastructure/hooks/useKeyPress';
import useIsMobile from 'infrastructure/hooks/IsMobile/IsMobile';

// Utils
import Utils from './FormUnified.utils';
// Definitions
import { FormUnifiedDef, Props } from './FormUnified.def';

const defaultsProps = {
  recoveryOnNewPage: false,
  onSubmit: () => undefined,
  onClose: () => undefined,
  onRecover: () => undefined,
  disabled: false,
};

const FormUnified = ({
  recoveryOnNewPage,
  onSubmit,
  onClose,
  onRecover,
  disabled,
}: Props) => {
  const isMobile = useIsMobile();
  const elFormRef = useRef<HTMLFormElement>(null);

  const keyPressed = useKeyPress('Enter', elFormRef);

  // Utils
  const { hasDocumentType, render } = Utils;
  const useDocumentType = hasDocumentType('dni-type');
  const defaultDocumentTypeValue = useDocumentType ? 'CC' : '';

  // States
  const [isDisabled, setIsDisabled] = useState(true);
  const [form, setForm] = useState<FormUnifiedDef>({
    dniType: defaultDocumentTypeValue,
    dni: '',
    password: '',
  });

  const changeSelect = (id: string, value?: string) => {
    setForm(prev => ({ ...prev, dniType: value ?? '', dni: '' }));
  };

  const changeForm = (id: any, value?: string) => {
    const current = ['dni-national', 'dni-foreign'].includes(id) ? 'dni' : id;
    setForm(prev => ({ ...prev, [current]: value }));
  };

  const disableSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };

  const recoverPassword = () => {
    if (onRecover) onRecover();
    if (onClose) onClose();
  };

  const isValidForm = useMemo(
    () => !!(form.dni && form.password),
    [form.dni, form.password],
  );

  // On change form
  useEffect(() => {
    const isNotValid = !isValidForm;
    if (disabled) {
      setIsDisabled(disabled);
    } else {
      setIsDisabled(isNotValid);
    }
  }, [form, disabled, isValidForm]);

  useEffect(() => {
    if (isValidForm && keyPressed) {
      onSubmit(form);
    }
  }, [form, isValidForm, keyPressed, onSubmit]);

  return (
    <Form
      onSubmit={disableSubmit}
      ref={elFormRef}
      noValidate
      autoComplete="off">
      <Header
        title={render.specificText('title')}
        subtitle={render.specificText('subtitle')}
        options={{ alignText: 'center' }}
      />

      {useDocumentType && (
        <Group>
          {render.select({
            id: 'dni-type',
            onChange: changeSelect,
            disabled,
          })}
        </Group>
      )}

      <Group verticalSpacing="none" marginBottom="24px">
        <Paragraph
          weight="regular"
          size="large"
          align="left"
          style={{ marginBottom: '8px', color: '#323537' }}>
          {render.label('dni')}
        </Paragraph>

        {render.input({
          id: 'dni',
          visible: form.dniType === '',
          onChange: changeForm,
          disabled,
          autoComplete: 'new-dni',
          autoFocus: !isMobile,
        })}

        {render.input({
          id: 'dni-national',
          visible: form.dniType === 'CC',
          onChange: changeForm,
          disabled,
          autoComplete: 'new-dni',
          autoFocus: !isMobile,
        })}

        {render.input({
          id: 'dni-foreign',
          visible: form.dniType === 'CE',
          onChange: changeForm,
          disabled,
          autoComplete: 'new-dni',
          autoFocus: !isMobile,
        })}
      </Group>

      <Group verticalSpacing="none" marginBottom="16px">
        <Paragraph
          weight="regular"
          size="large"
          align="left"
          style={{
            marginBottom: '8px',
            color: '#323537',
            display: 'flex',
            justifyContent: 'flex-start',
          }}>
          {render.label('password')}

          <HelpTip
            value={`Si eres cliente de <b>Banco Falabella</b>, ingresa con tu clave de internet para la web o app de Banco Falabella.
            \n Si eres cliente <b>CMR Puntos</b> ingresa con tu clave de internet CMR Puntos.`}
            size="medium"
            placement="top"
            style={{ marginLeft: '14px' }}
          />
        </Paragraph>

        {render.separator('separator')}
      </Group>

      <Group verticalSpacing="none" marginBottom="24px">
        {render.input({
          id: 'password',
          onChange: changeForm,
          disabled,
          autoComplete: 'new-password',
        })}
      </Group>

      <Group verticalSpacing="none" marginBottom="32px">
        <Button
          id="login-cmr"
          variant="primary"
          isDisabled={isDisabled}
          isFullWidth
          size="L"
          onClick={() => onSubmit(form)}>
          INGRESAR
        </Button>
      </Group>

      <Group verticalSpacing="none">
        {render.text('recovery', recoverPassword, recoveryOnNewPage)}
      </Group>
    </Form>
  );
};

FormUnified.defaultProps = defaultsProps;

export default FormUnified;
