import * as React from 'react';
import styled from 'styled-components';

// Components
import Title from 'presentation/components/atoms/Title/Title';

// Definitions
type ContentOptions = {
  width?: string;
  height?: string;
  hasWhiteBackground?: boolean;
  textAlignLeft?: boolean;
};

type Props = {
  title: string;
  contentOptions?: ContentOptions;
  children: any;
};

const DEFAULT_CONTENT_OPTIONS: ContentOptions = {
  width: '100%',
  height: 'auto',
  hasWhiteBackground: true,
  textAlignLeft: false,
};

const Standard = (props: Props) => {
  // Props
  const { title, contentOptions = {}, children } = props;
  const { width, height, hasWhiteBackground, textAlignLeft } = {
    ...DEFAULT_CONTENT_OPTIONS,
    ...contentOptions,
  };

  return (
    <Container>
      <Title
        level="4"
        size="small"
        style={{ textAlign: textAlignLeft ? 'left' : 'inherit' }}>
        {title}
      </Title>
      <Content
        width={width}
        height={height}
        hasWhiteBackground={hasWhiteBackground}>
        {children}
      </Content>
    </Container>
  );
};

const Container = styled.div`
  text-align: center;
  margin-bottom: 64px;

  &:last-child {
    margin-bottom: 0px;
  }

  h4 {
    margin-bottom: 24px;
    font-weight: 400;
    font-size: 40px;
    line-height: 150%;
    letter-spacing: -0.01em;
    color: #44474b;
    @media (max-width: 460px) {
      font-size: 32px;
    }
  }

  @media ${({ theme }) => theme.devices.tablet} {
    margin-bottom: 70px;

    h4 {
      margin-bottom: 54px;
      font-weight: 400;
      font-size: 40px;
      line-height: 140%;
    }
  }

  @media ${({ theme }) => theme.devices.desktop} {
    margin-bottom: 65px;

    h4 {
      margin-bottom: 32px;
    }
  }
`;

const Content = styled.div<ContentOptions>`
  width: ${({ width }) => width};
  max-width: ${({ width }) => width};
  height: ${({ height }) => height};
  background-color: ${({ hasWhiteBackground }) =>
    hasWhiteBackground ? 'white' : 'transparent'};
  border-radius: 12px;
  margin: 0 auto;
`;

export default Standard;
