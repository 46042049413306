import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${({ theme }) => theme.devices.desktop} {
    align-items: flex-start;
  }
`;

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  width: 20rem;
  gap: 1rem;

  p,
  strong {
    font-size: 16px;
  }

  svg {
    width: 60px;
    height: 60px;
  }

  @media ${({ theme }) => theme.devices.desktop} {
    margin-top: 16rem;
    gap: 1.5rem;

    p,
    strong {
      font-size: 18px;
    }

    svg {
      width: 100px;
      height: 100px;
    }
  }
`;

export const Dividosos = styled.div``;
