import Configuration from 'infrastructure/stores/Configuration';

function setPageTitle(title: string) {
  const configuration = Configuration.get();

  if (!configuration) return '';

  const { pageName } = configuration;

  document.title = title ? `${pageName} | ${title}` : pageName;
}

export default setPageTitle;
