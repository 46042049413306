const expirationPoints = {
  tooltip: {
    text: '¿Por qué vencen mis puntos?',
    elite:
      '<b>Por ser cliente elite</b> tus puntos duran 2 años desde el día que los acumulaste',
    fan: '<b>Por ser cliente fan</b> tus puntos duran 1 año desde el día que los acumulaste',
    premium:
      '<b>Por ser cliente premium</b> tus puntos duran 2 años desde el día que los acumulaste',
    default:
      '<b>Por ser cliente CMR</b> Puntos, tus puntos duran 1 año desde el día que los acumulaste',
  },
};

export default expirationPoints;
