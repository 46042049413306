import styled, { css } from 'styled-components';
import { ContainerProps } from './MyCategory.def';

const Styled = {
  Container: styled.div`
    position: relative;
    width: 100%;
    max-width: var(--container-width);
    margin: auto;
    padding: 0 20px;
    @media ${({ theme }) => theme.devices.desktop} {
      padding: 0;
    }
  `,
  FullWidthColor: styled.div<ContainerProps>`
    width: 100%;
    padding: 40px 0;
    ${({ categoryName }) =>
      !categoryName &&
      css`
        background-color: #eff8ea;
      `}
    ${({ categoryName }) =>
      categoryName === 'normal' &&
      css`
        background-color: #eff8ea;
      `}
    ${({ categoryName }) =>
      categoryName === 'fan' &&
      css`
        background-color: #fff2f2;
      `}
    ${({ categoryName }) =>
      categoryName === 'premium' &&
      css`
        background-color: #f4f7f9;
      `}
    ${({ categoryName }) =>
      categoryName === 'elite' &&
      css`
        background-color: #e2e9ee;
      `}
  `,
  WrapperError: styled.div`
    margin-top: 76px;
    margin-bottom: 92px;
    svg {
      margin-bottom: 26px;
    }
  `,
  WrapperAction: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 24px;

    @media ${({ theme }) => theme.devices.desktop} {
      flex-direction: row;
      gap: 16px;

      &:last-child button {
        margin-top: 0px;
      }
    }
  `,
};

export default Styled;
