import styled from 'styled-components';

// Components
import Icon from 'presentation/assets/icons';
import ButtonIcon from 'presentation/components/atoms/ButtonIcon';

export const Container = styled.div<{
  show: boolean;
  duration: number;
}>`
  display: flex;
  align-items: center;
  padding: 28px 20px;
  width: 90%;
  max-width: 1240px;
  background: #ffffff;
  border: 1px solid #c9d4df;
  box-shadow: 0px 1px 1px rgba(0, 51, 102, 0.05),
    0px 6px 12px -3px rgba(0, 51, 102, 0.075);
  border-radius: 12px;
  margin: 20px auto 50px;
  opacity: ${({ show }) => (show ? '100%' : '0')};
  animation-name: ${({ show }) => (show ? 'fadeIn' : 'fadeOut')};
  animation-duration: ${({ duration }) => `${duration}ms`};
  animation-fill-mode: both;
`;

export const IconLogin = styled(Icon)`
  flex-shrink: 0;
`;

export const CloseButton = styled(ButtonIcon)`
  width: 5%;
  min-width: 40px;
  justify-content: flex-end;
`;

export const ImageWrapper = styled.div`
  width: 5%;
  min-width: 50px;
`;

export const LinkWrapper = styled.p`
  width: 90%;
  font-size: 15px;
  color: #44474b;
  line-height: 25px;
  @media ${({ theme }) => theme.devices.desktop} {
    font-size: 20px;
  }
`;
