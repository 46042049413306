import { ICustomerCategoryCard } from './CustomerCategoryCard';
import { ILink, IImage } from './Common';

import {
  IShortCustomInformation,
  NoShortCustomInformation,
} from './ShortCustomInformation';

export interface IChallengeCardSection {
  shortCustomInformation: IShortCustomInformation;
  customerCategory: Array<ICustomerCategoryCard>;
  challengeCards: Array<CardCampaign>;
}

export const NoChallengeCardSection = {
  shortCustomInformation: NoShortCustomInformation,
  customerCategory: [],
  challengeCards: [],
};

export type CardCampaign = {
  title: string;
  subtitle: string;
  id: string;
  image: IImage;
  badge?: IImage;
  link?: ILink;
};
export type Props = {
  title: string;
  description: string;
  challengeDetail: CardCampaign[];
};
