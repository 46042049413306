import React from 'react';

// Swipper
import SwiperCore, { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';

// Utils
import useUtils from './CarouselCoupons.utils';

// defs
import { CarouselProps } from './CarouselCoupons.def';

// Styled
import CarouselCouponsContainer from './CarouselCoupons.styled';

SwiperCore.use([Pagination, Autoplay]);

const CarouselCoupons = (props: CarouselProps) => {
  const {
    id,
    breakpoints: initialBreakpoints,
    itemsToShow: maxItemsToShow,
    autoPlay,
    speed = 200,
    spaceBetween = 0,
    onSlideChange = () => {},
    initialSlide = 0,
    children,
  } = props;

  // Variables
  const totalItems = React.Children.count(children);
  const itemsToShow = totalItems < maxItemsToShow ? totalItems : maxItemsToShow;

  // Utils
  const {
    init,
    breakpoints,
    innerWidth,
    // innerWrapperRef,
    updateInnerWrapperAfter,
  } = useUtils({
    breakpoints: initialBreakpoints,
    show: itemsToShow,
    spaceBetween,
    totalItems,
  });

  return (
    <CarouselCouponsContainer couponCounter={totalItems}>
      <Swiper
        id={id}
        key={`${id}-${innerWidth}`}
        data-testid={id}
        autoplay={autoPlay}
        breakpoints={breakpoints}
        initialSlide={initialSlide}
        spaceBetween={spaceBetween}
        speed={speed}
        slidesPerView={itemsToShow}
        pagination={false}
        onInit={init}
        onSlideChange={swiper => onSlideChange(swiper.realIndex)}
        onResize={updateInnerWrapperAfter}>
        {React.Children.map(children as React.ReactElement<any>, child => (
          <SwiperSlide>{child}</SwiperSlide>
        ))}
      </Swiper>
    </CarouselCouponsContainer>
  );
};

export default CarouselCoupons;
