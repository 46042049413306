import * as React from 'react';

// Definitions
type WithDelayUnmountingProps = { open: boolean; delayTime?: number };

const withDelayUnmounting = <TProps extends WithDelayUnmountingProps>(
  Component: React.FC<TProps>,
  config: { defaultDelayTime?: number } = {},
) => {
  const { defaultDelayTime = 350 } = config;

  return function WrappedComponent(props: TProps) {
    const { open, delayTime = defaultDelayTime } = props;

    const [isMounted, setIsMounted] = React.useState<boolean>();

    // On is mounted changes
    React.useEffect(() => {
      if (!open && isMounted) {
        setTimeout(() => setIsMounted(false), delayTime);
      } else if (open) {
        setIsMounted(true);
      }
    }, [open, delayTime]);

    return isMounted ? <Component {...props} /> : <></>;
  };
};

export default withDelayUnmounting;
