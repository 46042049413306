import React from 'react';

// Components
import CouponUseHere from './CouponUseHere';
import CouponUse from './CouponUse';
import LegalText from './LegalText';

// Def
import { LegalProps } from './Legal.def';

const Legal = ({ legalText, code, couponUseText, isCountry }: LegalProps) => {
  return (
    <>
      <CouponUseHere couponUseText={couponUseText} />
      <CouponUse code={code} isCountry={isCountry} />
      <LegalText>{legalText}</LegalText>
    </>
  );
};

export default React.memo(Legal);
