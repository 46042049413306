import { lazy, Suspense } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

// Assets
import { Styles } from 'presentation/assets';

// Routes
import HashRoute from 'infrastructure/router/routes/HashRoute';
import PartnerRoute from 'infrastructure/router/routes/PartnerRoute';
import PrivateRoute from 'infrastructure/router/routes/PrivateRoute';
import PrivateHashRoute from 'infrastructure/router/routes/PrivateHashRoute';

// Layout
import Layout from 'presentation/layout';

// Components
import Home from 'presentation/components/pages/Home';
import AuthSSO from 'presentation/components/pages/AuthSSO';
import Coupons from 'presentation/components/pages/Coupons';
import ErrorPage from 'presentation/components/molecules/ErrorPage/ErrorPage';
import MyCategory from 'presentation/components/pages/MyCategory';
import LegalDocuments from 'presentation/components/pages/LegalDocuments';

// Plugins
import ScrollRestoration from './plugins/ScrollRestoration';
import SsoRoute from './routes/SsoRoute';
import PublicRoute from './routes/PublicRoute/PublicRoute';
import HashRouteToPage from './routes/HashRouteToPage';

// Lazy components
const Faq = lazy(() => import('presentation/components/pages/Faq'));
const Password = lazy(() => import('presentation/components/pages/Password'));
const Movements = lazy(() => import('presentation/components/pages/Movements'));
const Exchanges = lazy(() => import('presentation/components/pages/Exchanges'));
const Categories = lazy(
  () => import('presentation/components/pages/Categories'),
);
const StoresCatalogs = lazy(
  () => import('presentation/components/pages/StoresCatalogs'),
);
const PageLanding = lazy(
  () => import('presentation/components/pages/PageLanding'),
);
const LoginStepsModal = lazy(
  () => import('presentation/components/organisms/LoginStepsModal'),
);
const Configuration = lazy(
  () => import('presentation/components/pages/Configuration'),
);
const HelpCenter = lazy(
  () => import('presentation/components/pages/HelpCenter'),
);
const PersonalDataModal = lazy(
  () => import('presentation/components/organisms/PersonalDataModal'),
);
const MyExchangesPage = lazy(
  () => import('presentation/components/pages/MyExchanges'),
);

const PrivateHome = lazy(
  () => import('presentation/components/pages/PrivateHome'),
);

const RegistrationPage = lazy(
  () => import('presentation/components/pages/Registration'),
);
const RegistrationWithRewardPage = lazy(
  () =>
    import('presentation/components/pages/Registration/RegistrationWithReward'),
);

const showPersonalDataUpdate = process.env.REACT_APP_SHOW_PERSONAL_DATA_UPDATE;
const showCouponPage = process.env.REACT_APP_SHOW_COUPON_PAGE;
const COUNTRY = process.env.REACT_APP_COUNTRY?.toLowerCase() || '';

const Router = () => {
  return (
    <>
      <Styles />
      <ScrollRestoration />

      <Switch>
        {/* App Subscription Routes */}
        <Route path="/app/:path?">
          <Suspense fallback={<></>}>
            <Switch>
              {/*  para chile se muestra nueva pagina de registro */}
              <Route path="/app/registry" component={RegistrationPage} />
              <Redirect to="/app/registry" />
            </Switch>
          </Suspense>
        </Route>

        {/*  TODO: Colocar componente en Lazy */}
        {/* Route SSO */}
        <SsoRoute component={AuthSSO} path="/sso" />
        <Route path="/404" exact component={ErrorPage} />
        {/* Main Layout Routes */}
        <Route>
          <Layout>
            <Suspense fallback={<></>}>
              <Switch>
                <PublicRoute path="/" component={Home} exact />
                {/* TODO: RUTA /home DEPRECADA!! - FAVOR NO UTILIZAR PARA NUEVAS ENTREGAS A COMERCIOS. */}
                <PublicRoute path="/home" component={Home} />

                {/* TODO: RUTA SOLO DISPONIBLE PARA CHILE POR EL MOMENTO */}
                <Route
                  path={
                    COUNTRY === 'cl' ? '/inscribeteyactualiza' : '/inscribir'
                  }
                  component={RegistrationPage}
                  exact
                />
                {/* TODO: RUTA SOLO DISPONIBLE PARA CHILE POR EL MOMENTO */}
                {COUNTRY === 'cl' ? (
                  <Route
                    path="/inscribeteyactualizaconcurso"
                    component={RegistrationWithRewardPage}
                    exact
                  />
                ) : null}

                <Route path="/config" component={Configuration} exact />
                <Route path="/clave" component={Password} />
                <Route path="/canjes" component={Exchanges} />
                <Route path="/categorias" component={Categories} />
                <PrivateRoute
                  path="/mi-categoria"
                  component={MyCategory}
                  exact
                />
                <PrivateRoute path="/mi-cuenta" component={PrivateHome} exact />
                <PrivateRoute
                  path="/mis-canjes"
                  component={MyExchangesPage}
                  exact
                />
                <Route path="/catalogo-tiendas" component={StoresCatalogs} />
                <Route path="/faq" component={Faq} />
                {/* Esta ruta debe ser complementada con los hash #productos, #experiencias */}

                {/* TODO: RUTA /cupones SOLO DISPONIBLE PARA CHILE POR EL MOMENTO!!  */}
                {showCouponPage === 'true' && (
                  <Route path="/mis-cupones" component={Coupons} />
                )}

                <Route path="/centro-ayuda" component={HelpCenter} />

                <PrivateRoute component={Movements} path="/movements" exact />

                <Route
                  path="/documentos-legales/:id"
                  component={LegalDocuments}
                />

                <Route path="/:permalink" component={PageLanding} />
                <Redirect to="/" />
              </Switch>

              {/* Hash Routes */}
              {showPersonalDataUpdate && (
                <PrivateHashRoute
                  hash="datos-personales"
                  component={PersonalDataModal}
                />
              )}

              {/* Ruta no disponible para chile, se usa /inscribeteyactualiza */}
              <HashRouteToPage
                hash="inscribir"
                redirectRoute="/inscribeteyactualiza"
                component={RegistrationPage}
              />
              <HashRoute hash="ingresar" component={LoginStepsModal} />

              {/* TODO: HASH ROUTE datos-personales SOLO DISPONIBLE PARA CHILE POR EL MOMENTO!!  */}

              <PartnerRoute to="cmr" />
              <PartnerRoute to="bank" />
              <PartnerRoute to="other" />
            </Suspense>
          </Layout>
        </Route>
      </Switch>
    </>
  );
};

export default Router;
