import styled, { css } from 'styled-components';
import { WrapperNavigationProps } from './Carousel.types';

const Container = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 100%;

  .swiper-button-prev {
    margin-top: -1.5rem;
    padding: 1.5rem 1.5rem 1.5rem 1.5rem;
    left: 0px;
  }

  .swiper-button-next {
    margin-top: -1.5rem;
    padding: 1.5rem 1.5rem 1.5rem 1.5rem;
    right: 0px;
  }

  .swiper-button-prev,
  .swiper-button-next {
    border-radius: 2rem;
    background-color: #bac4cf;

    @media ${({ theme }) => theme.devices.wideDesktop} {
      padding: 1.62rem 1.62rem 1.62rem 1.62rem;
    }
  }

  .swiper-button-disabled {
    display: none;
  }

  &.mobile {
    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }
  }

  .swiper-pagination {
    /* height: 30px; */
    .swiper-pagination-bullet {
      width: 6px;
      height: 6px;
      background-color: var(--color-btn-disabled);
      border-radius: 15px;
      margin-left: 8px;
      cursor: pointer;
      transition: opacity 0.3s, background-color 0.3s, width 0.3s;
      transition-delay: 0.3s, 0.3s, 0s;

      &.swiper-pagination-bullet-active {
        background: var(--color-primary);
        width: 24px;
        transition-delay: 0s;
      }
    }
  }
`;

export const WrapperNavigation = styled.div<WrapperNavigationProps>`
  position: absolute;
  z-index: 2;
  width: 100%;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);

  @media ${() => '(max-width: 767px)'} {
    display: none;
  }

  ${props => {
    const { idCarousel } = props;

    if (idCarousel) {
      return css`
        .${idCarousel}-swiper-button-next, .${idCarousel}-swiper-button-prev {
          width: 40px;
          height: 40px;
          background: #bdd004;
          position: absolute;
          border-radius: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          &.swiper-button-disabled {
            display: none;
          }
        }

        @media ${({ theme }) => theme.devices.tablet} {
          .${idCarousel}-swiper-button-next {
            right: -35px;
            top: -25px;
          }

          .${idCarousel}-swiper-button-prev {
            left: -35px;
            top: -25px;
          }
        }

        @media ${({ theme }) => theme.devices.desktop} {
          .${idCarousel}-swiper-button-next {
            right: -7px;
            top: -25px;
          }

          .${idCarousel}-swiper-button-prev {
            left: -7px;
            top: -25px;
          }
        }
      `;
    }
  }}
`;

export default Container;
