import * as React from 'react';
import { NodeRenderer } from '@contentful/rich-text-react-renderer';

// Components
import { Paragraph } from 'presentation/components/atoms/Text';

//  Paragraph renderer
const ParagraphRender: NodeRenderer = (node, children) => {
  return <Paragraph>{children}</Paragraph>;
};

export default ParagraphRender;
