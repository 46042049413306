import styled, { css } from 'styled-components';

// Styles utils
import {
  PointerMixin,
  RoundBorderMixin,
  TextDefaultFontMixin,
  TextWeightMixin,
} from 'presentation/utils/styles/Mixins';

// Definitions
import { StyledButtonProps } from './Button.defs';

export const Button = styled.button<StyledButtonProps>`
  ${TextDefaultFontMixin}
  ${TextWeightMixin('semi-bold')}
  ${RoundBorderMixin}

  // TODO: Modify function to add not-allowed cursor option
  ${PointerMixin}

  // common styles
  align-items: center;
  border-style: hidden;
  display: inline-flex;
  justify-content: center;
  letter-spacing: normal;
  line-height: 16px;
  min-width: 134px;
  outline: none;
  text-align: center;
  text-decoration: none;
  // text-transform: ${props => (props.uppercase ? 'uppercase' : 'none')};
  max-width: ${props => (props.isFullWidth ? 'none' : '300px')};

  // size
  ${({ size }) =>
    size === 'S' &&
    css`
      padding: 0 24px;
      font-size: 12px;
      height: 32px;
    `}

  ${({ size }) =>
    size === 'M' &&
    css`
      padding: 0 24px;
      font-size: 13px;
      height: 48px;
    `}

    ${({ size }) =>
    size === 'L' &&
    css`
      padding: 0 32px;
      font-size: 14px;
      height: 48px;
    `}

  // variants
  ${({ variant }) =>
    variant === 'primary' &&
    css`
      background-color: var(--color-btn-primary);
      color: var(--color-btn-text-primary);
    `}

  ${({ variant }) =>
    variant === 'secondary' &&
    css`
      background-color: var(--color-btn-secondary);
      color: var(--color-btn-text-secondary);
    `}

  ${({ variant }) =>
    variant === 'tertiary' &&
    css`
      background-color: var(--color-btn-tertiary);
      color: var(--color-btn-text-tertiary);
      border: 1px solid;
      border-color: var(--color-border-tertiary);
    `}

  ${({ variant }) =>
    variant === 'darkmode' &&
    css`
      background-color: var(--color-btn-darkmode);
      color: var(--color-btn-tertiary);
    `}

  // rounded
    ${({ rounded }) =>
    rounded &&
    css`
      border-radius: 24px;
    `}

    // others
    ${({ isFullWidth }) =>
    isFullWidth &&
    css`
      width: 100%;
      max-width: none;
    `}

    ${({ isDisabled }) =>
    !!isDisabled &&
    css`
      background-color: var(--color-btn-disabled);
      color: var(--color-text-disabled);
      cursor: not-allowed;
    `}

    ${({ isFullWidth, width }) =>
    !isFullWidth &&
    width &&
    css`
      width: ${`${width}px`};
    `}
`;

export const ButtonIcon = styled.span<{
  spaceLeft?: number;
  spaceRight?: number;
}>`
  flex-shrink: 0;
  margin-left: ${props => `${props.spaceLeft ?? 0}px`};
  margin-right: ${props => `${props.spaceRight ?? 0}px`};
`;
