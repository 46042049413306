import { AxiosResponse, CancelTokenSource } from 'axios';

// Client
import { httpClient, token } from '../../client';
import { ExtendConfig } from '../../definitions';
import { IContent, HelpCenterResponse } from './content.defs';

let source: CancelTokenSource;

const Content: IContent = {
  helpCenter: (): Promise<AxiosResponse<HelpCenterResponse>> | undefined => {
    source = token.source();

    return httpClient.get<HelpCenterResponse>('v2/content/helpcenter', {
      cancelToken: source.token,
    } as ExtendConfig);
  },
};

export default Content;
