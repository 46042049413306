import React, { CSSProperties } from 'react';
import styled from 'styled-components';

// Definitions
type Props = {
  spacing?: number;
  children?: any;
  style?: CSSProperties;
};

const Wrapper = (props: Props) => {
  const { spacing = 8, style = {}, children } = props;

  return (
    <Render spacing={spacing} style={style}>
      {children}
    </Render>
  );
};

const Render = styled.div<{ spacing: number }>`
  position: relative;
  padding: ${({ spacing }) => `${spacing}px 0`};
`;

export default Wrapper;
