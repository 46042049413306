import React from 'react';

// Components
import Icon from 'presentation/assets/icons';
import { Paragraph } from 'presentation/components/atoms/Text';

// Defs
import { ErrorLoadDataProps } from './CouponsErrorLoadData.def';

// Style
import Container from './CouponsErrorLoadData.styled';

const CouponsErrorLoadData = ({
  title,
  message,
  iconName: name,
}: ErrorLoadDataProps) => {
  return (
    <Container>
      <Icon name={name === 'noData' ? 'noCmrPoints' : 'offline'} />
      <Paragraph
        color="gray-message-text"
        size="regular"
        weight="regular"
        align="center"
        style={{ letterSpacing: '-0.2px', lineHeight: '21px' }}>
        {message}
      </Paragraph>
    </Container>
  );
};

export default CouponsErrorLoadData;
