import styled, { css } from 'styled-components';

// utils
import { PointerMixin } from 'presentation/utils/styles/Mixins';

// Definitions
import { StyledButtonIconProps } from './ButtonIcon.defs';

const ButtonIcon = styled.button<StyledButtonIconProps>`
  ${PointerMixin}

  align-items: center;
  background-color: transparent;
  border-style: hidden;
  display: flex;
  justify-content: center;
  margin: 0;
  outline: none;
  padding: 0;

  // sizes
  ${({ size }) =>
    size === 'S' &&
    css`
      width: 16px;
      height: 16px;

      svg {
        width: 16px;
        height: 16px;
      }
    `}

  ${({ size }) =>
    size === 'M' &&
    css`
      width: 32px;
      height: 32px;

      svg {
        width: 32px;
        height: 32px;
      }
    `}

${({ size }) =>
    size === 'L' &&
    css`
      width: 64px;
      height: 64px;

      svg {
        width: 64px;
        height: 64px;
      }
    `}
`;

const Styled = { ButtonIcon };

export default Styled;
