import { IContentSection, NoContentSection } from './ContentSection';

export interface ICategoryBenefits {
  name: string;
  category: string;
  currentCategoryBenefits: IContentSection;
  nextCategoryBenefits: IContentSection;
}

export const NoCategoryBenefits: ICategoryBenefits = {
  name: '',
  category: '',
  currentCategoryBenefits: NoContentSection,
  nextCategoryBenefits: NoContentSection,
};
