import htmlParser from 'presentation/utils/htmlParser';
import sanitize from 'presentation/utils/sanitize';

export type Props = {
  text: string;
  options?: {
    allowedTags: ['b', 'i', 'em', 'strong'] | string[] | boolean;
    allowedAttributes?: false | string[] | boolean;
  };
};

const HtmlParser = (props: Props) => {
  const { text, options } = props;
  const textSanitized = sanitize(text, options);

  return <>{htmlParser(textSanitized)}</>;
};

export default HtmlParser;
