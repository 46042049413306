const utilData = {
  infoCategorias: [
    { id: 0, name: 'normal', color: '#323537' },
    { id: 1, name: 'fan', color: '#EB0045' },
    { id: 2, name: 'premium', color: '#808080' },
    { id: 3, name: 'elite', color: '#000000' },
  ],
  dataCustomer: {
    category: {
      currentCategory: {
        id: '04',
        name: 'Fan',
        origin: 'Transactions',
      },
      productCategory: {
        id: '00',
        name: 'Normal',
        startDate: '2021-08-29T14:17:17-04:00',
        endAlertBenefitDate: '2021-09-13T15:17:17-03:00',
      },
      accumulationCategory: {
        id: '04',
        name: 'Fan',
        startDate: '2022-11-07T15:17:17-03:00',
        endDate: '2024-12-31T00:00:00-03:00',
        requiredPoints: 5000,
        requiredPointsToMaintain: 0,
        endAlertBenefitDate: '2022-07-29T14:17:17-04:00',
        startAlertKeepCatDate: '2023-07-01T14:17:17-04:00',
        nextCategory1: {
          id: '01',
          name: 'Premium',
          requiredPoints: 25000,
          requiredPointsToUpgrade: 8500,
        },
        nextCategory2: {
          id: '02',
          name: 'Elite',
          requiredPoints: 60000,
          requiredPointsToUpgrade: 43500,
        },
      },
    },
    firstName: '',
    lastName: '',
    nextCategory: {
      requiredPoints: 5000,
      requiredPointsToUpgrade: 3000,
      category: 'Fan',
    },
    points: { total: 0, expiration: Array(4), earned: 0 },
    type: 'Loyalty',
  },
};

export default utilData;
