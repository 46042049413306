import React from 'react';
import styled from 'styled-components';

// Definitions
type Props = {
  align?: 'left' | 'center' | 'right';
  children?: any;
};

const Row = (props: Props) => {
  const { children, align } = props;
  return <Render align={align}>{children}</Render>;
};

const Render = styled.div<Props>`
  padding: 15px 0;
  ${props => {
    const { align } = props;
    if (align === 'left') return 'text-align: left';
    if (align === 'right') return 'text-align: right';
    return 'text-align: center';
  }}
`;

export default Row;
