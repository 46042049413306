import { StyleProps } from './Badge.defs';

const variants: { [key: string]: StyleProps } = {
  white: {
    backgroundColor: '#FFFFFF',
    textColor: '#44474B',
    borderColor: '#C9D4DF',
  },
  green: {
    backgroundColor: '#45A00A',
    textColor: '#FFFFFF',
    borderColor: '#2B8F14',
  },
  black: {
    backgroundColor: '#333333',
    textColor: '#FFFFFF',
    borderColor: '#333333',
  },
  red: {
    backgroundColor: '#EA2840',
    textColor: '#FFFFFF',
    borderColor: '#EA2840',
  },
};

export default variants;
