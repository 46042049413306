import { IBadge } from './Badge';
import {
  IImageResponsive,
  ILink,
  NoImageResponsive,
  IImage,
  NoImage,
} from './Common';

export interface IExchangeCardCategory {
  name: string;
  icon: IImage;
  color: string;
}

export const NoExchangeCardCategory: IExchangeCardCategory = {
  name: '',
  icon: NoImage,
  color: '',
};
export interface IExchangeCard {
  name: string;
  image: IImageResponsive;
  text?: string;
  points?: number;
  link?: ILink;
  showOnlyImage: boolean;
  badges?: IBadge[];
  category?: IExchangeCardCategory;
}

export const NoExchangeCard: IExchangeCard = {
  name: '',
  image: NoImageResponsive,
  text: '',
  points: NaN,
  showOnlyImage: true,
  badges: [],
  category: NoExchangeCardCategory,
};

export interface IExchangeCardsGroup {
  name: string;
  cards: IExchangeCard[];
}

export const NoExchangeCardsGroup = {
  name: '',
  cards: [],
};
