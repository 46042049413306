import styled from 'styled-components';

const CouponUseContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 43px 30px 37px 30px;
  background: #f4f7f8;

  h2 {
    font-size: 29px;
    line-height: 34px;
    text-align: center;
    color: #000000;
    font-weight: 100;
    margin-bottom: 38px;
  }

  @media ${({ theme }) => theme.devices.tablet} {
    padding: 34px 45px 45px 45px;
    h2 {
      font-size: 32px;
      line-height: 37px;
      margin-bottom: 34px;
    }
  }

  @media ${({ theme }) => theme.devices.desktop} {
    padding: 34px 75px 45px 75px;
  }

  @media ${({ theme }) => theme.devices.wideDesktop} {
    display: grid;
    grid-template-columns: auto auto;
    gap: 0 47px;
    justify-content: center;
    align-items: center;
    padding: 44px 106px 30px 106px;

    h2 {
      font-size: 40px;
      line-height: 47px;
      margin-bottom: 0px;
      text-align: left;
    }
  }
`;

export const WrapperContent = styled.div`
  width: 100%;
  display: grid;
  grid-template-rows: auto auto auto;

  @media ${({ theme }) => theme.devices.tablet} {
    grid-template-columns: 32% 35% auto;
    gap: 0 26px;
    flex-direction: row;
  }

  @media ${({ theme }) => theme.devices.desktop} {
    grid-template-columns: 32% 32% auto;
    align-items: flex-start;
  }

  @media ${({ theme }) => theme.devices.wideDesktop} {
    gap: 0 64px;
    grid-template-columns: 25% 25% 25%;
    justify-content: center;
  }
`;

export const WrapperDescription = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 32px;

  &:last-child {
    margin-bottom: 0px;
  }

  svg {
    width: 100%;
    max-width: 35px;
    min-width: 26px;
    flex-grow: 1;
    flex: 1 1 13%;
  }

  p {
    margin-left: 17px;
    font-size: 17px;
    line-height: 20px;
    font-weight: 300;
    color: #4a4a4a;

    span {
      font-weight: bold;
    }
  }

  @media ${({ theme }) => theme.devices.tablet} {
    grid-template-columns: auto;
    grid-template-rows: 52px auto;
    align-items: flex-start;
    margin-bottom: 0px;

    p {
      width: 100%;
      margin-left: 0px;
    }

    svg {
      width: 35px;
      height: 35px;
      margin-left: 11px;
      margin-bottom: 19px;
    }
  }

  @media ${({ theme }) => theme.devices.desktop} {
    justify-content: center;

    svg {
      margin-bottom: 8px;
    }
  }
`;

export default CouponUseContainer;
