import { isSafari, browserVersion, osVersion } from 'react-device-detect';

// eslint-disable-next-line import/prefer-default-export
export const getWebpSupported = () => {
  return !(
    isSafari &&
    (Number.parseInt(browserVersion, 10) < 14 ||
      Number.parseInt(osVersion.split('.')[0], 10) < 11)
  );
};
