import { NoPageLanding } from 'domain/model/content';

const LandingsMapper = (pageLandings: any) => {
  try {
    const landings = pageLandings.map((pageLanding: any) => {
      return pageLanding.fields;
    });
    return landings;
  } catch {
    console.warn(
      '[LinkMapper] The content json structure received differed from expected',
    );

    return NoPageLanding;
  }
};

export default LandingsMapper;
