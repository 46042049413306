import styled, { css } from 'styled-components';

// Styles utils
import {
  TextDefaultFontMixin,
  PointerMixin,
} from 'presentation/utils/styles/Mixins';

// Definitions
export type AnchorType = 'inline' | 'stock' | 'default';

type RenderDef = {
  mode?: AnchorType;
  width?: string;
  height?: string;
};

// Default style
const DefaultStyle = css`
  ${TextDefaultFontMixin};
  ${PointerMixin}

  font-size: 13px;
  line-height: 15px;
  text-decoration: underline;
  user-select: none;

  &:link,
  &:hover,
  &:active,
  &:visited {
    color: var(--color-default);
  }
`;

// Inline style
const InlineStyle = css`
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  text-decoration: underline;

  &:link,
  &:hover,
  &:active,
  &:visited {
    color: var(--color-green-alt);
  }
`;

// Stock style
const StockStyle = css`
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  text-decoration: none;

  &:link,
  &:hover,
  &:active,
  &:visited {
    color: inherit;
  }
`;

const AnchorStyle = (mode?: 'inline' | 'stock' | 'default') => css`
  ${DefaultStyle};

  ${mode === 'inline' && InlineStyle};
  ${mode === 'stock' && StockStyle.toString()};
`;

export const StyledAnchor = styled.a<RenderDef>`
  ${({ mode }) => AnchorStyle(mode)}
`;

export const StyledLinkWrapper = styled.span<RenderDef>`
  & {
    a {
      ${({ mode }) => AnchorStyle(mode)}
    }
  }
`;
