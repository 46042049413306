import RedeemContent from './content/ReedemContent';
import { RedeemProps } from './Redeem.def';
import Styled from './Redeem.styled';

const Redeem = ({ data }: RedeemProps) => {
  return (
    <Styled.Container id="canjes">
      <RedeemContent data={data?.redeem?.contents} />
    </Styled.Container>
  );
};

export default Redeem;
