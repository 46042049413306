import React, { useEffect, useCallback } from 'react';
import { useAuth } from 'infrastructure/providers/Auth';
import { Redirect, Route } from 'react-router-dom';

type Props = {
  component: any;
  path: string;
  [x: string]: any;
};

const PrivateRoute = (props: Props) => {
  const { component: Component, path, ...rest } = props;

  const {
    data: { logged },
  } = useAuth();

  const SaveRedirectRoute = useCallback(
    () => localStorage.setItem('redirectTo', path),
    [path],
  );

  useEffect(() => {
    if (!logged.in) {
      SaveRedirectRoute();
    }
  }, [SaveRedirectRoute, logged.in]);

  return (
    <Route
      {...rest}
      render={props =>
        logged.in ? <Component {...props} /> : <Redirect to="/#ingresar" />
      }
    />
  );
};

export default PrivateRoute;
