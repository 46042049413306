import React from 'react';

import { Render, RenderProps, Spin } from './Spinner.styles';

// Definitions
export type Props = RenderProps;

const Spinner = (props: Props) => {
  return (
    <Render {...props}>
      <Spin />
    </Render>
  );
};

export default Spinner;
