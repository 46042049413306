import { ITextBox, NoTextBox } from 'domain/model/content/TextBox';

const TextBoxMapper = (json: any): ITextBox => {
  try {
    const { fields } = json;
    const { title, text } = fields;

    return {
      title,
      text,
    };
  } catch {
    console.warn(
      '[TexBoxMapper] The content json structure received differed from expected',
    );

    return NoTextBox;
  }
};

export default TextBoxMapper;
