import * as React from 'react';

// Configuration
// TODO: Change this implementation to use a provider (ConfigurationProvider)
import { social as SocialConfiguration } from 'domain/configuration';
// Models
import { ILink, ILinksGroup } from 'domain/model/content';

// Event bus
import {
  useEventBus,
  EventTopicEnum,
  UiEventMessage,
} from 'infrastructure/eventBus';

// Hooks
import useIsMobile from 'infrastructure/hooks/IsMobile';

// Assets
import { Images } from 'presentation/assets';

// Components
import MobileContent from './Mobile';
import DesktopContent from './Desktop';
import Social from './Social';

// Styles
import { Container, Content, Complementary, Copyright } from './Footer.style';

// Events
import events from './Footer.events';

// Definitions
export type Props = {
  content: ILinksGroup[];
  copyright: string;
  companyData?: string;
};

const Footer = (props: Props) => {
  // Props
  const { content, companyData } = props;

  // Hooks
  const isMobile = useIsMobile();
  const { publish } = useEventBus<UiEventMessage>(EventTopicEnum.UI);

  // Constants

  const methods = {
    on: {
      socialMediaClick: (socialMedia: string) => {
        publish(events.social(socialMedia));
      },
      linkClick: (link: ILink) => {
        publish(events.legal(link.text));
      },
    },
  };

  return (
    <Container>
      <Content>
        {isMobile && (
          <MobileContent content={content} onClick={methods.on.linkClick} />
        )}
        {!isMobile && (
          <DesktopContent content={content} onClick={methods.on.linkClick} />
        )}
      </Content>
      <Copyright>
        <img src={Images.cmrOgLogo} alt="Logo CMR Puntos" />
        <div>{companyData && <small>{companyData}</small>}</div>
        <Complementary>
          <Social
            onClick={methods.on.socialMediaClick}
            data={SocialConfiguration}
          />
        </Complementary>
      </Copyright>
    </Container>
  );
};

export default Footer;
