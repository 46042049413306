import React from 'react';
import styled from 'styled-components';

// Definitions
type Props = {
  variant?: 'light' | 'dark' | 'none';
  children?: any;
};

const Line = (props: Props) => {
  const { children, variant = 'light' } = props;
  return <Render variant={variant}>{children}</Render>;
};

const BACKGROUND_COLORS: { [key: string]: string } = {
  dark: '--color-border-dark',
  light: '--color-border-default',
};

const Render = styled.div<{ variant: 'light' | 'dark' | 'none' }>`
  display: ${({ variant }) => (variant === 'none' ? 'none' : 'block')};
  background-color: ${({ variant }) =>
    variant === 'none' ? 'none' : `var(${BACKGROUND_COLORS[variant]})`};
  position: absolute;
  height: 1px;
  width: 100%;
  bottom: 50%;
  z-index: 0;
`;

export default Line;
