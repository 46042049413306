import styled from 'styled-components';

const CouponUseHereContainer = styled.div`
  width: 100%;
  padding: 0 20px;
  margin-bottom: 38px;

  font-size: 20px;
  line-height: 23px;
  font-weight: 300;
  text-align: center;

  color: #767676;

  @media ${({ theme }) => theme.devices.tablet} {
    width: 90%;
    margin: 0 auto;
    font-size: 26px;
    line-height: 30px;
    margin-bottom: 64px;
  }

  @media ${({ theme }) => theme.devices.desktop} {
    margin-bottom: 74px;
  }
`;

export const ContentText = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;

  @media ${({ theme }) => theme.devices.tablet} {
    margin-bottom: 0px;
  }

  @media ${({ theme }) => theme.devices.wideDesktop} {
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    gap: 0 12px;
  }
`;

export const ContentPrimary = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;

  p {
    margin-right: 9px;
  }

  img {
    width: 100%;
    max-width: 196px;
  }

  @media ${({ theme }) => theme.devices.tablet} {
    text-align: left;
    grid-template-columns: auto auto;
    margin-bottom: 10px;
    justify-content: center;

    p {
      margin-right: 12px;
    }
  }

  @media ${({ theme }) => theme.devices.wideDesktop} {
    margin-bottom: 0px;
  }
`;

export const ContentSecondary = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  justify-content: center;
  align-items: center;

  p {
    margin-right: 0px;
    margin-bottom: 10px;
    line-height: 32px;
    padding: 0 36px;
  }

  @media (min-width: 414px) {
    p {
      padding: 0 55px;
    }
  }

  @media ${({ theme }) => theme.devices.tablet} {
    grid-template-columns: auto auto;
    grid-template-rows: auto;

    p {
      margin-right: 13px;
      padding: 0;
      margin-bottom: 0;
    }
  }
`;

export const WrapperLogosGroup = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    max-width: 53px;
    margin-right: 10px;

    &:last-child {
      margin-right: 0px;
    }
  }

  @media ${({ theme }) => theme.devices.tablet} {
    img {
      max-width: 48px;
    }
  }
`;

export default CouponUseHereContainer;
