import {
  IShortCustomInformation,
  NoShortCustomInformation,
} from 'domain/model/content';
import CategoryAndBenefitsMapper from './categoryAndBenefitsMapper';

const ShortCustomInformationMapper = (json: any): IShortCustomInformation => {
  try {
    const { fields } = json;
    const { title, description, categoryAndBenefits, cta } = fields;

    return {
      title,
      description,
      categoryAndBenefits: CategoryAndBenefitsMapper(categoryAndBenefits),
      cta: {
        url: cta.fields.url,
        target: cta.fields.target,
        text: cta.fields.text,
      },
    };
  } catch {
    console.warn(
      '[ShortCustomInformationMapper] The content json structure received differed from expected',
    );

    return NoShortCustomInformation;
  }
};

export default ShortCustomInformationMapper;
