import styled from 'styled-components';
import { CardBenefitsStyledProps } from './CardBenefits.def';

const Styled = {
  Container: styled.div<CardBenefitsStyledProps>`
    width: inherit;
    height: 100%;
    min-height: 195px;
    padding: 16px 20px;
    background: ${({ status }) =>
      status === 'desbloqueado' ? '#ffffff' : '#F4F7F9'};
    border: 0.5px solid #cfdbe6;
    border-color: ${({ status }) =>
      status === 'desbloqueado' ? '#EAEFF4' : '#C0CED6'};
    box-sizing: border-box;
    box-shadow: 0px 1px 1px rgb(0 51 102 / 5%), 0px 6px 12px rgb(0 51 102 / 5%);
    border-radius: 16px;

    @media (max-width: 360px) {
      min-height: 220px;
    }

    @media ${({ theme }) => theme.devices.tablet} {
      height: 100%;
    }

    @media ${({ theme }) => theme.devices.desktop} {
      min-height: 180px;

      transition: transform 0.3s;

      &:hover {
        transform: scale(0.95);
        cursor: pointer;
      }
    }
  `,
  WrapperPoints: styled.div`
    margin-left: auto;
    display: flex;
    align-items: center;
  `,
  PointsToReach: styled.strong`
    font-weight: 600;
    font-size: 14px;
    margin-right: 5px;
  `,
  Header: styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    @media (max-width: 360px) {
      flex-direction: column;
      align-items: flex-start;
    }

    @media ${({ theme }) => theme.devices.desktop} {
      flex-direction: column;
      align-items: start;
    }

    @media (min-width: 1200px) {
      flex-direction: row;
      align-items: center;
    }
  `,
  WrapperTitle: styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  `,
  Title: styled.h4`
    font-weight: 400;
    font-size: 20px;
    line-height: 200%;
    letter-spacing: -0.01em;
    color: #323537;
    margin-left: 11px;
  `,
  Status: styled.span`
    display: block;
    font-style: italic;
    font-weight: 400;
    font-size: 12px;
    line-height: 175%;
    text-align: right;
    letter-spacing: -0.01em;
    color: #8c989c;
    text-transform: capitalize;
  `,
  Content: styled.div``,
  List: styled.ul`
    padding-left: 20px;
  `,
  Item: styled.li<CardBenefitsStyledProps>`
    font-weight: 400;
    font-size: 14px;
    line-height: 175%;
    letter-spacing: -0.02em;
    color: ${({ status }) =>
      status === 'desbloqueado' ? '#44474b' : '#63696E'};
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0px;
    }
  `,
  LegalMessage: styled.span<CardBenefitsStyledProps>`
    font-weight: 400;
    font-size: 14px;
    line-height: 172%;
    letter-spacing: -0.02em;
    color: ${({ status }) =>
      status === 'desbloqueado' ? '#44474b' : '#63696E'};
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0px;
    }
  `,
};

export default Styled;
