import styled from 'styled-components';

const Styled = {
  WrapperContentModal: styled.div`
    padding-top: 32px;
  `,
  HeadTextModal: styled.h3`
    font-weight: 500;
    font-size: 22px;
    line-height: 150%;
    letter-spacing: -0.01em;
    color: #347b23;
    margin-bottom: 12px;
  `,
  DescriptionModal: styled.p`
    font-weight: 400;
    font-size: 17px;
    line-height: 200%;
    letter-spacing: -0.01em;
    color: #323537;
  `,
};
export default Styled;
