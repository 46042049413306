type RetryOptions = {
  args?: any[];
  attemps: number;
};

const retry = async <T>(
  fn: (...args: any[]) => Promise<T>,
  options: RetryOptions,
): Promise<T> => {
  const { args = [], attemps } = options;

  if (attemps < 1) throw new Error('The attemps value can not be less than 1.');

  try {
    const response = await fn(...args);
    return response;
  } catch (error) {
    if (attemps === 1) {
      throw error;
    }

    return retry(fn, { args, attemps: attemps - 1 });
  }
};

export default retry;
