import { Entry, EntryCollection } from 'contentful';
import client from '../client';

const Resource = {
  async getEntry<T>(id = '', query?: any): Promise<Entry<T>> {
    return client.getEntry(id, query);
  },
  async getEntries<T>(query: any): Promise<EntryCollection<T>> {
    return client.getEntries(query);
  },
};

export default Resource;
