import * as React from 'react';
// Components
import Icon from 'presentation/assets/icons';
import Anchor from 'presentation/components/atoms/Anchor';

type socialMediaIcon = 'facebook' | 'instagram' | 'youtube';

type SocialData = {
  name: string;
  url: string;
};
type Props = {
  onClick: (social: string) => void;
  data: SocialData[];
};
const Social = (props: Props) => {
  const { onClick, data } = props;

  return (
    <>
      {data.map((socialMedia, key) => (
        <Anchor
          id={`link-footer-${socialMedia.name}`}
          key={`${key + 1}`}
          url={socialMedia.url}
          target="blank"
          mode="stock"
          onClick={() => onClick(socialMedia.name)}>
          <Icon name={socialMedia.name as socialMediaIcon} />
        </Anchor>
      ))}
    </>
  );
};

export default Social;
