import contentful from 'infrastructure/services/contentFul';

// Mappers
import { ITextImage } from 'domain/model/content';
import {
  TabMapper,
  ContentSectionMapper,
  CardsGroupMapper,
  ReedemCardsGroupMapper,
  SpecialContentMapper,
  FaqGroupMapper,
  TextImageBannerMapper,
  ChallengeCardSectionMapper,
  NotificationMessageMapper,
  ImageResponsiveMapper,
} from '../mappers';
import AccumulateImagesMapper from '../mappers/AccumulateImagesMapper';
import ShortCustomInformationMapper from '../mappers/ShortCustomInformationMapper';

// Service
const {
  entriesResource: { getEntry },
} = contentful;

// Vars
const HomeEntryId = process.env.REACT_APP_CONTENTFUL_DATA_HOME;

const LoadsHome = async () => {
  try {
    const data = await getEntry<any>(HomeEntryId, {
      'sys.id': HomeEntryId,
      include: 10,
    });

    return {
      exchangesSection: CardsGroupMapper(data.fields?.exchangesSection),
      outstandingExchangesTab: TabMapper(
        data.fields?.newSectionRedeems, // Field ID del content-type HomePage
        (json: any) => ContentSectionMapper(json, ReedemCardsGroupMapper),
      ),
      sectionAccumulates: AccumulateImagesMapper(
        data.fields?.sectionAccumulates,
      ),
      sectionSubscribe: CardsGroupMapper(data.fields?.sectionSubscribe),
      categorySection: SpecialContentMapper(data.fields?.categorySection),
      sectionAcumulatesBanner: TextImageBannerMapper(
        data.fields?.sectionAcumulatesBanner,
      ),
      sectionAcumulatesBannerNew: data.fields.sectionAcumulatesBannerNew.map(
        (item: ITextImage) => TextImageBannerMapper(item),
      ),
      faq: FaqGroupMapper(data.fields?.faq),
      brandSection: CardsGroupMapper(data.fields?.brandSection),
      challengeCardSection: ChallengeCardSectionMapper(
        data.fields?.challengeCardSection,
      ),
      alliesSection: CardsGroupMapper(data.fields?.alliesSection),
      giftcardNotification: NotificationMessageMapper(
        data.fields?.GiftcardNotification,
      ),
      ecosystemSection: CardsGroupMapper(data.fields?.ecosystemSection),
      ecosystemBanner: ImageResponsiveMapper(data.fields?.ecosystemBanner),
      categoryAndBenefits: ShortCustomInformationMapper(
        data.fields?.categoryAndBenefits,
      ),
      newExchangesSection: CardsGroupMapper(data.fields?.newExchangesSection),
      acumulationMorePointsSection: CardsGroupMapper(
        data.fields?.acumulationMorePointsSection,
      ),
      fullTextImageBanner: TextImageBannerMapper(
        data.fields?.fullTextImageBanner,
      ),
    };
  } catch (error) {
    console.error(error);
  }
};

export default LoadsHome;
