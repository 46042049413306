import { ITextImage } from 'domain/model/content/TextImage';
import { ISlideFields, NoSlideFields } from './Slider';
import { ITextBox, NoTextBox } from './TextBox';
import { IImageResponsive } from './Common';
import { IReedemCardsGroup } from './ReedemCard';

export interface IMainContent {
  fields?: ITextBox | IImageResponsive | ITextImage | IReedemCardsGroup;
  sys?: { contentType: { sys: { id: string } } };
}

export const NoMainContent: IMainContent = {
  fields: {},
  sys: { contentType: { sys: { id: '' } } },
};
export interface IPageLanding {
  name: string;
  title: string;
  permalink: string;
  seoDescription?: string;
  mainCarrusel?: ISlideFields;
  legal?: ITextBox;
  mainContent?: IMainContent[];
}

export const NoPageLanding: IPageLanding = {
  name: '',
  title: '',
  permalink: '',
  seoDescription: '',
  mainCarrusel: NoSlideFields,
  legal: NoTextBox,
  mainContent: NoMainContent as any,
};
