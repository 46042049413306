import {
  AccumulateImagesGroup,
  IImageResponsive,
  NoAccumulateImagesGroup,
} from 'domain/model/content';
import ImageMapper from './ImageMapper';

const AccumulateImagesMapper = (
  collectionImages: any,
): AccumulateImagesGroup => {
  try {
    const cleanDataImages = collectionImages.map(({ fields }: any) => fields);

    const newCollectionImages = cleanDataImages.map(
      (item: IImageResponsive) => {
        return {
          ...item,
          desktop: ImageMapper(item.desktop),
          mobile: ImageMapper(item.mobile),
        };
      },
    );

    return newCollectionImages;
  } catch {
    console.warn(
      '[AccumulateImagesMapper] The content json structure received differed from expected',
    );

    return NoAccumulateImagesGroup;
  }
};

export default AccumulateImagesMapper;
