import styled from 'styled-components';

const Styled = {
  GoBackSection: styled.section`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 16px 14px 0;
    margin: 0 0 10px 0;

    & > *:not(:first-child) {
      margin-left: 20px;
    }

    @media ${({ theme }) => theme.devices.tablet} {
      padding: 36px 50px 0;
    }

    @media ${({ theme }) => theme.devices.desktop} {
      padding: 36px 75px 0;
    }
  `,
};

export default Styled;
