import React from 'react';

const ArrowRight = () => {
  return (
    <svg viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.6078 9.14401L10.7511 1.28734C10.2791 0.815312 9.51374 0.815312 9.04173 1.28734C8.606 1.72307 8.57249 2.40869 8.94117 2.88286L9.04173 2.99673L14.8352 8.78998H1.4354C0.815523 8.78998 0.304635 9.25654 0.234819 9.85776L0.226685 9.99869C0.226685 10.6185 0.693298 11.1294 1.29444 11.1993L1.4354 11.2074H14.8352L9.04173 17.0007C8.606 17.4364 8.57249 18.122 8.94117 18.5962L9.04173 18.71C9.47748 19.1458 10.1631 19.1793 10.6372 18.8106L10.7511 18.71L18.6078 10.8534C19.0435 10.4176 19.077 9.73205 18.7083 9.25787L18.6078 9.14401L10.7511 1.28734L18.6078 9.14401ZM21.9836 17.8554V2.14204C21.9836 1.47449 21.4424 0.933319 20.7749 0.933319C20.1073 0.933319 19.5662 1.47449 19.5662 2.14204V17.8554C19.5662 18.5229 20.1073 19.0641 20.7749 19.0641C21.4424 19.0641 21.9836 18.5229 21.9836 17.8554Z"
        fill="#63696E"
      />
    </svg>
  );
};

export default ArrowRight;
