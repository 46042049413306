export type AriaAttribute =
  | 'label'
  | 'labelledby'
  | 'describedby'
  | 'hidden'
  | 'required'
  | 'disabled'
  | 'invalid'
  | 'errormessage'
  | 'expanded';

export const ariaAttr = (attr: AriaAttribute, value?: string | boolean) => {
  const attributeName = `aria-${attr}`;

  return { [attributeName]: value || undefined };
};
