import { ILandingVideo, NoLandigVideo } from 'domain/model/content';
import LinkMapper from './LinkMapper';

const LandingImageMapper = (json: any): ILandingVideo => {
  try {
    const { fields } = json;
    const { title, link, width, height } = fields;

    return {
      title,
      link: link && LinkMapper(link),
      width,
      height,
    };
  } catch {
    console.warn(
      '[LandingImageMapper] The content json structure received differed from expected',
    );

    return NoLandigVideo;
  }
};

export default LandingImageMapper;
