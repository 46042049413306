import styled from 'styled-components';

const Styled = {
  ExpirationWrapper: styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 5px;
  `,
  ExpirationDate: styled.span`
    font-weight: 400;
    font-size: 12px;
    line-height: 175%;
    text-align: right;
    letter-spacing: -0.01em;
    color: #474c4e;

    @media ${({ theme }) => theme.devices.tablet} {
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
    }
  `,
  WrapperIcon: styled.div`
    height: 100%;
    max-height: 24px;
    cursor: pointer;
  `,
  Subtitle: styled.div`
    display: flex;
    margin: 20px 0;
    justify-content: space-between;
  `,
};

export default Styled;
