import styled from 'styled-components';

const Container = styled.div<{ mode: 'dark' | 'light' }>`
  .swiper {
    padding-bottom: 4em;
    .swiper-button-prev {
      margin-left: 8.5%;
      padding: 20px;
      @media (min-width: 1700px) {
        margin-left: 13.5%;
      }
      @media (min-width: 1900px) {
        margin-left: 18.5%;
      }
    }
    .swiper-button-next {
      margin-right: 8.5%;
      padding: 20px;
      @media (min-width: 1700px) {
        margin-right: 13.5%;
      }
      @media (min-width: 1900px) {
        margin-right: 18.5%;
      }
    }
    .swiper-button-prev,
    .swiper-button-next {
      border-radius: 3rem;
      background-color: ${props =>
        props.mode === 'dark' ? 'var(--color-btn-darkmode)' : '#bdd004;'};
      color: #fff;
    }
    &.mobile {
      .swiper-button-prev,
      .swiper-button-next {
        display: none;
      }
    }

    .swiper-slide {
      transform: translateZ(0);
      backface-visibility: hidden;
    }

    .swiper-pagination {
      bottom: 6%;

      .swiper-pagination-bullet {
        width: 6px;
        height: 6px;
        background-color: var(--color-btn-disabled);
        border-radius: 15px;
        margin-left: 8px;
        cursor: pointer;
        opacity: 1;
        transition: opacity 0.9s, background-color 0.9s, width 0.9s;
        transition-delay: 0.3s, 0.3s, 0s;

        &.swiper-pagination-bullet-active {
          background: ${props =>
            props.mode === 'dark' ? 'var(--color-btn-darkmode)' : '#bdd004;'};
          width: 24px;
          transition-delay: 0s;
        }
      }
    }
  }
`;

export default Container;
