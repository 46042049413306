import Cookies from 'js-cookie';

import { useEffect, useMemo, useLayoutEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { stringifyUrl, parse } from 'query-string';

// Infrastructure
import { useAnalytics } from 'infrastructure/providers/Analytics';

// Services
import Loyalty from 'infrastructure/services/loyalty';

// Hooks
import useIsMobile from 'infrastructure/hooks/IsMobile';

// Components
import LoginSteps from 'presentation/components/organisms/LoginSteps/LoginSteps';
import Loading from 'presentation/components/organisms/LoginSteps/Loading';

// Utils
import setPageTitle from 'presentation/utils/title';
import getUrlCallback from './AuthSSO.utils';

import {
  Main,
  MainSection,
  CopyrightSection,
  Copyright,
} from './AuthSSO.style';

const country = process.env.REACT_APP_COUNTRY?.toLowerCase() || '';

export enum STEP {
  LOADING,
  FORM_UNIFIED,
  FEEDBACK,
}

export type Props = {
  isDisabled: boolean;
};

export default function AuthSSO(props: Props) {
  const { isDisabled } = props;
  const { Auth } = Loyalty;
  const history = useHistory();
  const analytics = useAnalytics();
  const [step, setStep] = useState(STEP.FORM_UNIFIED);
  const urlCallback = isDisabled ? '' : getUrlCallback(history.location.search);

  const isDisable = useMemo(() => {
    if (isDisabled) return isDisabled;
    return !urlCallback;
  }, [isDisabled, urlCallback]);

  // On DOM Render
  useLayoutEffect(() => {
    setPageTitle('CMR Puntos SSO');
  }, []);

  const OauthLogin = async (payloadSSO: string) => {
    try {
      const { data: response } = await Auth.oauth(payloadSSO, true);
      const { data: payload } = response;

      if (payload) {
        handleOnSuccess(payload.payload);
      }
      return;
    } catch (details: any) {
      console.error(details);
      const error = details.request.status === 0 ? 500 : details.request.status;

      // Analytics fail
      setStep(STEP.FORM_UNIFIED);
      if (error === 409) analytics.login.error.invalid();
      else if (error === 423) analytics.login.error.blocked();
      else analytics.login.error.unexpected();
    }
  };

  // On load
  useEffect(() => {
    analytics.common.setPageLoad(global.location.pathname);
    const { payload } = parse(window.location.search);

    localStorage.removeItem('logged');

    if (payload) {
      setStep(STEP.LOADING);
      OauthLogin(payload as string);
    }
  }, [global.location.pathname]);

  const isMobile = useIsMobile(['xs', 'sm']);

  const copyrightYear = new Date().getFullYear();
  const CopyrightText = (
    <Copyright id="legal">
      &copy; {copyrightYear} CMR Puntos. Todos los {isMobile && <br />}Derechos
      Reservados.
    </Copyright>
  );

  const handleOnSuccess = (token?: string) => {
    if (!urlCallback || !token) return;
    const redirectUrl = stringifyUrl({
      url: urlCallback,
      query: { payload: token },
    });
    localStorage.setItem('logged', 'true');
    setRedirecCookie();
    global.location.replace(redirectUrl);
  };

  const setRedirecCookie = () => {
    const { redirectPath, price } = parse(window.location.search);
    let path;
    if (redirectPath && typeof redirectPath === 'string') {
      const pathNameSearch = redirectPath.includes('/search');
      path =
        pathNameSearch && price
          ? `${redirectPath}&q=""&price=${price}`
          : redirectPath;
    }

    const domain = country === 'co' ? '.cmrpuntos.com.co' : '.cmrpuntos.pe';
    const inTwoMinutes = new Date(new Date().getTime() + 2 * 60 * 1000);

    if (path) {
      Cookies.set('redirect', path as string, {
        domain,
        expires: inTwoMinutes,
      });
    } else {
      console.error('error creating cookie redirect');
    }
  };

  return (
    <>
      <Main>
        <MainSection>
          {step === STEP.LOADING && <Loading />}
          {step === STEP.FORM_UNIFIED && (
            <LoginSteps
              isSso
              isDisabled={isDisable}
              onSuccess={handleOnSuccess}
            />
          )}
        </MainSection>
        <CopyrightSection>{CopyrightText}</CopyrightSection>
      </Main>
    </>
  );
}
