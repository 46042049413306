import JsEncrypt from 'jsencrypt';

// Environments
const pem = process.env.REACT_APP_PEM_PUBLIC_KEY as string;

// Definitions
type Element = string | object;

// Encrypt methods
const encrypt = {
  base: () => {
    const instance = new JsEncrypt();
    instance.setPublicKey(pem);
    return instance;
  },
  string: (element: string) => {
    return encrypt.base().encrypt(element);
  },
  object: (element: object) => {
    const cleaned = JSON.parse(JSON.stringify(element));
    return encrypt.string(JSON.stringify(cleaned));
  },
};

const Rsa = {
  encrypt: (element: Element) => {
    if (!element) throw new Error('Element is required');
    if (typeof element === 'string') return encrypt.string(element);
    if (typeof element === 'object') return encrypt.object(element);
    throw new Error('Element is not object or string');
  },
};

export default Rsa;
