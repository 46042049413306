import React, { lazy, useEffect, useMemo, useRef, useState } from 'react';

// Providers
import { useConfiguration } from 'infrastructure/providers/Configuration';

// Hooks
import useWindowSize from 'infrastructure/hooks/WindowResize';
import { useContentful } from 'infrastructure/providers/Contentful';
import {
  EventTopicEnum,
  UiEventMessage,
  useEventBus,
} from 'infrastructure/eventBus';

// Components
import Loader from 'presentation/components/atoms/Loader';
import Headlong from './components/Headlong';
import CouponList from './components/CouponList';
import Legal from './components/Legal';
import CouponsErrorLoadData from './components/CouponsErrorLoadData';

// Styled
import CouponsContainer, { WrapperLoader } from './Coupons.styled';

// Lazy component
const Footer = lazy(() => import('presentation/components/organisms/Footer'));

const Coupons = () => {
  const {
    data: { common, coupons },
    methods: { LoadCoupons },
  } = useContentful();

  const {
    configuration: { features },
  } = useConfiguration();

  const {
    ui: { common: commonUI },
  } = features;

  const size = useWindowSize();

  const { code, legal, isLoad } = coupons;
  const errorIconNames = ['noData', 'offService'];

  const countriesLayout = useMemo(() => ['co', 'pe'], []);
  const countryId = process.env.REACT_APP_COUNTRY;
  const isCountry = useRef(false);

  const countCoupons = coupons.coupons.length;
  const showCoupons = countCoupons > 0;
  const showCarousel = size.width !== undefined && size?.width >= 768;

  const [itemsToShow, setItemsToShow] = useState(1);
  const { publish } = useEventBus<UiEventMessage>(EventTopicEnum.UI);

  const openCouponEvent = (target: string) => ({
    module: 'Cupones',
    action: 'click cupon',
    subject: 'click on coupon',
    target,
  });

  useEffect(() => {
    if (countCoupons === 1 && size.width && size?.width < 768) {
      setItemsToShow(1);
    } else if (size.width && size?.width <= 320) {
      setItemsToShow(1.2);
    } else if (size.width && size?.width < 440) {
      setItemsToShow(1.4);
    } else if (size.width && size?.width < 640) {
      setItemsToShow(1.5);
    } else if (size.width && size?.width < 668) {
      setItemsToShow(1.6);
    } else {
      setItemsToShow(2.2);
    }
  }, [size, countCoupons]);

  useEffect(() => {
    LoadCoupons();
  }, [LoadCoupons]);

  useEffect(() => {
    if (countryId) {
      isCountry.current = countriesLayout.includes(countryId);
    }
  }, [countriesLayout, countryId]);

  return (
    <>
      <CouponsContainer>
        {!isLoad && countCoupons === 0 && (
          <WrapperLoader>
            <Loader />
          </WrapperLoader>
        )}

        {countCoupons === undefined && (
          <CouponsErrorLoadData
            message="Hemos tenido un inconveniente al cargar tus cupones CMR Puntos. Inténtalo más tarde"
            iconName={errorIconNames[1]}
          />
        )}

        {isLoad && countCoupons === 0 && (
          <CouponsErrorLoadData
            message="No tienes cupones disponibles en este momento"
            iconName={errorIconNames[0]}
          />
        )}

        {countCoupons !== undefined && countCoupons > 0 && (
          <>
            <Headlong
              countCoupons={countCoupons}
              code={code ?? ''}
              isCountry={isCountry.current}
            />
            {showCoupons && (
              <CouponList
                id="cupones"
                isCountry={isCountry.current}
                coupons={coupons.coupons}
                itemsToShow={itemsToShow}
                showCarousel={showCarousel}
                onClick={({ title }) => publish(openCouponEvent(title))}
              />
            )}
            <Legal
              legalText={legal}
              code={code ?? ''}
              couponUseText={coupons?.couponUseText}
              isCountry={isCountry.current}
            />
          </>
        )}
      </CouponsContainer>

      {commonUI.footer && (
        <Footer
          copyright={common.copyRightContent.text}
          content={common.interestLinksContent}
        />
      )}
    </>
  );
};

export default Coupons;
