import React from 'react';
import styled from 'styled-components';

// Utils
import {
  ColorsHelper,
  TextWeightHelper,
  TextWeightHelperProps,
} from 'presentation/utils/styles/Helpers';

// Definitions
type Props = {
  type: 'label' | 'icon';
  backgroundColor?: string;
  weight?: TextWeightHelperProps['weight'];
  color?: string;
  children?: any;
};

type SectionLabelProps = Omit<Props, 'type'>;

const Section = (props: Props) => {
  const { type, children, color, weight, backgroundColor } = props;
  return (
    <>
      {type === 'label' && (
        <SectionLabel
          color={color}
          weight={weight}
          backgroundColor={backgroundColor}>
          {children}
        </SectionLabel>
      )}
      {type === 'icon' && <SectionIcon>{children}</SectionIcon>}
    </>
  );
};

// Styles
const SectionLabel = styled.span<SectionLabelProps>`
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  background-color: #ffffff;
  ${({ color, backgroundColor: bg }) => ColorsHelper}
  ${({ weight }) => TextWeightHelper}
`;

const SectionIcon = styled.span`
  background-color: #ffffff;
`;

export default Section;
