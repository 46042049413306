import { AxiosResponse, CancelTokenSource } from 'axios';

// Utils
import Random from 'infrastructure/utils/random';

// Client
import { httpClient, token } from '../../client';

// Definitions
import { ExtendConfig } from '../../definitions';
import {
  OtpEmailResponseData,
  OtpPhoneResponseData,
  OtpTmpPhoneRequest,
  OtpTmpPhoneResponse,
  OtpTmpValidateRequest,
} from './otpCode.defs';

// Dynamic token source
let source: CancelTokenSource;

const otpCode = {
  email: (
    authToken: string | undefined,
  ): Promise<AxiosResponse<OtpEmailResponseData>> => {
    source = token.source();

    const headers = { 'Content-Type': 'application/json' };

    return httpClient.post<OtpEmailResponseData>('v1/otp/email', undefined, {
      cancelToken: source.token,
      headers,
      authToken,
    } as ExtendConfig);
  },
  phone: (
    authToken: string | undefined,
  ): Promise<AxiosResponse<OtpPhoneResponseData>> => {
    source = token.source();

    const headers = { 'Content-Type': 'application/json' };

    return httpClient.post<OtpPhoneResponseData>('v1/otp/sms', undefined, {
      cancelToken: source.token,
      headers,
      authToken,
    } as ExtendConfig);
  },
  phoneTmp: (
    request: OtpTmpPhoneRequest,
  ): Promise<AxiosResponse<OtpTmpPhoneResponse>> => {
    source = token.source();
    const encrypt = {
      secret: Random.string(32),
      iv: Random.string(16),
      encode: true,
    };

    return httpClient.post<OtpTmpPhoneResponse>('v1/otp/tmp/sms', request, {
      cancelToken: source.token,
      encrypt,
    } as ExtendConfig);
  },
  phoneTmpValidate: (
    request: OtpTmpValidateRequest,
  ): Promise<AxiosResponse<OtpTmpPhoneResponse>> => {
    source = token.source();
    const encrypt = {
      secret: Random.string(32),
      iv: Random.string(16),
      encode: true,
    };

    return httpClient.post<OtpTmpPhoneResponse>(
      'v1/otp/tmp/validate',
      request,
      {
        cancelToken: source.token,
        encrypt,
      } as ExtendConfig,
    );
  },
};

export default otpCode;
