import React from 'react';
import styled from 'styled-components';

// Definitions
type Props = {
  children?: any;
};

const Description = (props: Props) => {
  const { children } = props;
  return <Render>{children}</Render>;
};

const Render = styled.p`
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.26px;
  margin: 0 6px 10px;
  @media (min-width: 751px) {
    margin: 0 0 10px;
  }
`;

export default Description;
