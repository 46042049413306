const config = {
  cardsBenefits: [
    {
      id: 0,
      iconName: 'logoCmrpuntos',
      iconNameBlock: 'cmrPuntosCircle',
      categoryName: 'Normal',
      pointsToReach: 0,
      title: 'CMR Puntos',
      status: '',
      colorCategory: '#323537',
      details: [
        { id: '00', description: 'Acumulas CMR Puntos' },
        {
          id: '01',
          description: 'Canje de productos, experiencias y Gift Cards',
        },
      ],
    },
    {
      id: 1,
      iconName: 'fan',
      iconNameBlock: 'fanLine',
      categoryName: 'Fan',
      pointsToReach: 3000,
      title: 'Fan',
      status: '',
      colorCategory: '#EB0045',
      details: [
        {
          id: '10',
          description: 'Descuentos exclusivos en el mes de tu cumpleaños',
        },
        {
          id: '11',
          description: 'Promociones especiales',
        },
      ],
    },
    {
      id: 2,
      iconName: 'premium',
      iconNameBlock: 'premiumLine',
      categoryName: 'Premium',
      pointsToReach: 10000,
      title: 'Premium',
      status: '',
      colorCategory: '#808080',
      details: [
        {
          id: '20',
          description: 'Tarjeta CMR con 50% dcto. en costo de administración*',
        },
        {
          id: '21',
          description: 'La duración de tus puntos acumulados es de 2 años',
        },
      ],
    },
    {
      id: 3,
      iconName: 'elite',
      iconNameBlock: 'eliteLine',
      categoryName: 'Elite',
      pointsToReach: 25000,
      title: 'Elite',
      status: '',
      colorCategory: '#000000',
      details: [
        {
          id: '30',
          description: 'Tarjeta CMR con 100% dcto. en costo de administración*',
        },
        {
          id: '31',
          description: 'Costo $0 en mantención de tu Cuenta Corriente*',
        },
      ],
    },
  ],
  cardsCategory: [
    {
      id: 'cat-00',
      title: '¡Felicidades!',
      points: '',
      icon: 'fan',
      description: 'Eres cliente Fan por todo el próximo año',
      progressBar: false,
    },
    {
      id: 'cat-01',
      title: 'Te Faltan',
      points: '2000',
      icon: 'premium',
      description: 'Para subir de categoría a cliente Premium este año',
      progressBar: true,
    },
  ],
  updateCategory: {
    default: {
      modal: {
        id: '',
        icon: '',
        title: '',
        titleMobile: '',
        description: '',
        cta: '',
        url: '',
      },
      congratMessage: '',
      congratBy: '',
    },
    fanByAccumulation: {
      modal: {
        id: '000001',
        icon: 'fan',
        title: '¡Subiste de categoría!',
        titleMobile: '¡Subiste de categoría!',
        description:
          'Conoce todos los beneficios de ser <b>Fan</b> para disfrutar al máximo tu experiencia.',
        cta: 'Quiero saber más',
        url: '/categorias',
      },
      congratMessage:
        'Por acumulación de CMR Puntos, eres cliente <b>Fan</b> desde el ',
      congratBy: 'acumulacion',
    },
    premiumByAccumulationWithoutCard: {
      modal: {
        id: '000002',
        icon: 'premium',
        title: '¡Subiste de categoría!',
        titleMobile: '¡Subiste de categoría!',
        description:
          'Por acumulación de CMR Puntos, ahora eres Cliente <b>Premium</b>. Conoce tu nuevos beneficios y disfrutar al máximo tu experiencia.',
        cta: 'Quiero saber más',
        url: '/categorias',
      },
      congratMessage:
        'Por acumulación de CMR Puntos, eres cliente <b>Premium</b> desde el ',
      congratBy: 'acumulacion',
    },
    eliteByAccumulationWithoutCard: {
      modal: {
        id: '000003',
        icon: 'elite',
        title: '¡Subiste de categoría!',
        titleMobile: '¡Subiste de categoría!',
        description:
          'Por acumulación de CMR Puntos, ahora eres Cliente <b>Elite</b>. Conoce tu nuevos beneficios y disfrutar al máximo tu experiencia.',
        cta: 'Quiero saber más',
        url: '/categorias',
      },
      congratMessage:
        'Por acumulación de CMR Puntos, eres cliente <b>Elite</b> desde el ',
      congratBy: 'acumulacion',
    },
    premiumByCardWithoutAccumulation: {
      modal: {
        id: '00004',
        icon: 'premium',
        title: '¡Subiste de categoría!',
        titleMobile: '¡Subiste de categoría!',
        description:
          'Por obtener tu Tarjeta CMR Premium, ahora eres Cliente <b>Premium</b>. Conoce tu nuevos beneficios para disfrutar al máximo tu experiencia.',
        cta: 'Quiero saber más',
        url: '/categorias',
      },
      congratMessage:
        'Por obtener tu tarjeta CMR Premium, ahora eres cliente <b>Premium</b>',
      congratBy: 'producto',
    },
    eliteByCardWithoutAccumulation: {
      modal: {
        id: '00005',
        icon: 'elite',
        title: '¡Subiste de categoría!',
        titleMobile: '¡Subiste de categoría!',
        description:
          'Por obtener tu Tarjeta CMR Elite, ahora eres Cliente <b>Elite</b>. Conoce tu nuevos beneficios para disfrutar al máximo tu experiencia.',
        cta: 'Quiero saber más',
        url: '/categorias',
      },
      congratMessage:
        'Por obtener tu tarjeta CMR Elite, ahora eres cliente <b>Elite</b>',
      congratBy: 'producto',
    },
    eliteByCardAccumulationPremium: {
      modal: {
        id: '00006',
        icon: 'elite',
        title: 'Obtuviste el 50% de dcto. en el costo de membresia',
        titleMobile: '50% dcto. en costo de membresia',
        description:
          'Alcanzaste los CMR Puntos necesario para obtener el <b>50% de dcto</b>. en el costo de membresia de tu Tarjeta CMR.',
        cta: 'Quiero saber más',
        url: '/categorias',
      },
      congratMessage:
        'Por acumulación de CMR Puntos, obtuviste el <b>50% de dcto.</b> en el costo de membresia de tu Tarjeta CMR desde el ',
      congratBy: 'acumulacion',
    },
    premiumByCardAndAccumulation: {
      modal: {
        id: '00007',
        icon: 'premium',
        title: 'Obtuviste el 50% de dcto. en el costo de membresia',
        titleMobile: '50% dcto. en costo de membresia',
        description:
          'Alcanzaste los CMR Puntos necesario para obtener el <b>50% de dcto</b>. en el costo de membresia de tu Tarjeta CMR.',
        cta: 'Quiero saber más',
        url: '/categorias',
      },
      congratMessage:
        'Por acumulación de CMR Puntos, obtuviste el <b>50% de dcto</b>. en el costo de membresia de tu Tarjeta CMR desde el ',
      congratBy: 'acumulacion',
    },
    eliteByCardAndAccumulation: {
      modal: {
        id: '00008',
        icon: 'premium',
        title: 'Obtuviste el 100% de dcto. en el costo de membresia',
        titleMobile: '100% dcto. en costo de membresia',
        description:
          'Alcanzaste el puntaje necesario para obtener el 100% de dcto. en el costo de membresia de tu Tarjeta CMR.',
        cta: 'Quiero saber más',
        url: '/categorias',
      },
      congratMessage:
        'Por acumulación de CMR Puntos, obtuviste el <b>100% de dcto</b>. en el costo de membresia de tu Tarjeta CMR desde el',
      congratBy: 'acumulacion',
    },
  },
};

export default config;
