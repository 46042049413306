import { AxiosResponse } from 'axios';
import { httpClient } from '../../client';
import { LegalResponse, LegalResponseByID } from './legal.def';

const Legal = {
  legalDocuments: (): Promise<AxiosResponse<LegalResponse>> => {
    return httpClient.get<LegalResponse>('v1/legals');
  },
  legalDocumentById: (
    id: string,
  ): Promise<AxiosResponse<LegalResponseByID>> => {
    return httpClient.get<LegalResponseByID>(`v1/legals/${id}`);
  },
};

export default Legal;
