import { phoneValidate } from 'domain/locale';

// Definitions
import { ValidationResponse } from './Validations.defs';

const Validation = {
  email: (text: string): ValidationResponse => {
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const valid = regex.test(text);
    const message = valid ? '' : 'Formato de correo no válido';
    return { valid, message };
  },
  number: (text: string) => {
    const response = { valid: /^\d+$/.test(text), message: '' };
    if (!response.valid) response.message = 'Formato solo acepta dígitos';
    return response;
  },
  phone: (value: string) => {
    const valid = phoneValidate(value);
    const response = { valid };

    if (!valid) return { ...response, message: 'Número de celular no válido' };

    return response;
  },
  name: (text: string): ValidationResponse => {
    // Permite letras, espacios, guiones, apostrofes y caracteres acentuados.
    const regex = /^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ' -]+$/;
    const valid = regex.test(text);
    const message = valid ? '' : 'Nombre no válido';
    return { valid, message };
  },
  surname: (text: string): ValidationResponse => {
    // Permite letras, espacios, guiones, apostrofes y caracteres acentuados.
    const regex = /^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ' -]+$/;
    const valid = regex.test(text);
    const message = valid ? '' : 'Apellido no válido';
    return { valid, message };
  },
};

export default Validation;
