// Country configuration
import chile from './cl';
import colombia from './co';
import peru from './pe';

// Configuration list
const List = {
  cl: chile,
  co: colombia,
  pe: peru,
};

function GetUtm() {
  const country = process.env.REACT_APP_COUNTRY as keyof typeof List;
  return List[country];
}

export default GetUtm();
