import React, { CSSProperties } from 'react';
import styled from 'styled-components';

// Utils
import { TextDefaultFontMixin } from 'presentation/utils/styles/Mixins';
import { ColorsHelper } from 'presentation/utils/styles/Helpers';

// Definitions
export type Props = {
  color?: string;
  backgroundColor?: string;
  children?: any;
  style?: CSSProperties;
};

type RenderProps = Omit<Props, 'backgroundColor'> & {
  bg?: string;
};

const Label = (props: Props) => {
  const { color, backgroundColor, style, children } = props;
  return (
    <Render color={color} bg={backgroundColor} style={style}>
      {children}
    </Render>
  );
};

// Style
const Render = styled.label<RenderProps>`
  display: inline-block;
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.3px;
  ${TextDefaultFontMixin};
  ${ColorsHelper};
`;

export default Label;
