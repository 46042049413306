import DataConfigMyCategory from 'domain/configuration/pages/myCategory';
import { useEffect, useMemo, useState } from 'react';

// Components
import BenefitsCarousel from '../BenefitsCarousel/BenefitsCarousel';

// Def
import { BenefitsProps } from './Benefits.def';

// Styled
import Styled from './Benefits.style';

const Benefits = ({ id, dataCategory }: BenefitsProps) => {
  const isPE = useMemo(() => process.env.REACT_APP_COUNTRY === 'pe', []);

  const filterCards = useMemo(() => {
    return DataConfigMyCategory.cardsBenefits.find(
      (item: any) => item.categoryName === dataCategory?.currentCategory.name,
    );
  }, [dataCategory?.currentCategory.name]);

  const [namecategory, setNamecategory] = useState('');

  useEffect(() => {
    let mounted = true;
    if (mounted && filterCards?.categoryName)
      setNamecategory(filterCards.categoryName);

    return () => {
      mounted = false;
    };
  }, [filterCards?.categoryName]);

  return (
    <Styled.Container country={isPE}>
      <BenefitsCarousel id={id} nameCategory={namecategory} />
    </Styled.Container>
  );
};

export default Benefits;
