import * as React from 'react';

// styles
import * as Styled from './Button.style';

// Definitions
import { ButtonProps } from './Button.defs';

const Button = (props: ButtonProps) => {
  const {
    children,
    className,
    id,
    isDisabled,
    isFullWidth = false,
    iconSpacing = 8,
    leftIcon,
    rightIcon,
    rounded = true,
    size = 'M',
    type = 'button',
    variant = 'primary',
    width,
    uppercase = true,
    ...rest
  } = props;

  const testId = id && `${id}-button`;

  return (
    <Styled.Button
      className={className}
      data-testid={testId}
      disabled={isDisabled}
      id={id}
      isDisabled={isDisabled}
      isFullWidth={isFullWidth}
      rounded={rounded}
      size={size}
      type={type}
      variant={variant}
      width={width}
      uppercase={uppercase}
      {...rest}>
      {leftIcon && (
        <Styled.ButtonIcon spaceRight={iconSpacing}>
          {leftIcon}
        </Styled.ButtonIcon>
      )}
      {children}
      {rightIcon && (
        <Styled.ButtonIcon spaceLeft={iconSpacing}>
          {rightIcon}
        </Styled.ButtonIcon>
      )}
    </Styled.Button>
  );
};

export default Button;
