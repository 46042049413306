/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useCallback, useEffect, useState } from 'react';

import { useTour } from '@reactour/tour';
import useIsMobile from 'infrastructure/hooks/IsMobile';
import { useLocation } from 'react-router-dom';
import { TourGuideSteps } from './Walkthrough.defs';

type WalkthroughStepsProps = {
  data: TourGuideSteps;
  auth: boolean;
};

const WalkthroughSteps = ({ data, auth }: WalkthroughStepsProps) => {
  const isMobile = useIsMobile();
  const { pathname } = useLocation();
  const { isOpen, setIsOpen, currentStep, setSteps, setCurrentStep } =
    useTour();
  const [isAuthFlow, setIsAuthFlow] = useState<boolean>(false);

  const AuthSteps = (pathName: string) => {
    if (isAuthFlow) {
      setCurrentStep(1);
      return;
    }

    if (pathName === data.mainPath && !isAuthFlow) {
      setCurrentStep(1);
      setSteps(isMobile ? data.mobile : data.desktop);
    }

    if (pathName !== data.mainPath) {
      setIsOpen(false);
      setSteps([]);
    }
  };

  const UnauthSteps = (pathName: string) => {
    if (pathName === '/') {
      setCurrentStep(0);
      setSteps(isMobile ? data.mobile : data.desktop);
      setIsAuthFlow(true);
    }

    if (pathName !== '/') {
      setIsOpen(false);
      setSteps([]);
    }
  };

  const privateWalkthrough = (pathName: string) => {
    setTimeout(() => {
      auth && AuthSteps(pathName);
      !auth && UnauthSteps(pathName);
    }, 500);
  };

  const publicWalkthrough = (pathName: string) => {
    if (pathName === data.mainPath) {
      setTimeout(() => {
        setCurrentStep(currentStep === 0 ? 0 : currentStep);
        setSteps(isMobile ? data.mobile : data.desktop);
      }, 500);
    }

    if (pathName !== data.mainPath) {
      setIsOpen(false);
      setSteps([]);
    }
  };

  const loadSteps = useCallback(
    (pathName: string) => {
      data.isPublic && publicWalkthrough(pathName);
      !data.isPublic && privateWalkthrough(pathName);
    },
    [data.isPublic, publicWalkthrough, privateWalkthrough],
  );

  useEffect(() => {
    isOpen && loadSteps(pathname);
  }, [isOpen, pathname]);

  return null;
};

export default memo(WalkthroughSteps);
