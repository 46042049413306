import * as React from 'react';
import { CSSProperties } from 'react';

// Domain content
import { ILink, ILinksGroup } from 'domain/model/content';

// Event bus
import {
  useEventBus,
  EventTopicEnum,
  UiEventMessage,
} from 'infrastructure/eventBus';

// Hooks
import useIsMobile from 'infrastructure/hooks/IsMobile';

// Components
import MobileContent from './Mobile';
import DesktopContent from './Desktop';

// Definitions
export type Props = {
  content: ILinksGroup[];
  style?: CSSProperties;
  onClick?: (link: ILink) => void;
};

const MODULE_NAME = 'Seccion_informacion_legal';

const LegalInformation = (props: Props) => {
  // Props
  const { content, style, onClick = () => {} } = props;

  // Hooks
  const isMobile = useIsMobile();
  const { publish } = useEventBus<UiEventMessage>(EventTopicEnum.UI);

  const methods = {
    on: {
      linkClick: (link: ILink) => {
        publish({
          action: 'descarga pdf',
          module: MODULE_NAME,
          subject: 'Open legal information link',
          target: link.text,
        });

        onClick(link);
      },
    },
  };

  return (
    <section style={style}>
      {isMobile && (
        <MobileContent content={content} onClick={methods.on.linkClick} />
      )}
      {!isMobile && (
        <DesktopContent content={content} onClick={methods.on.linkClick} />
      )}
    </section>
  );
};

export default LegalInformation;
