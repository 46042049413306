import styled from 'styled-components';
import { TextWeightMixin } from 'presentation/utils/styles/Mixins';

type ContainerProps = {
  width?: number;
  gap?: number;
};

type ImageWrapperProps = {
  width?: number;
  height?: number;
  borderRadius?: number;
};

export const Container = styled.div<ContainerProps>`
  display: inline-block;
  width: ${({ width }) => width && `${width}px`};
  max-width: ${({ width }) => width && `${width}px`};
  border: ${({ gap }) => gap && `${gap}px solid transparent !important;`};
  box-sizing: initial;
`;

export const ImageWrapper = styled.div<ImageWrapperProps>`
  position: relative;
  width: 100%;
  padding-top: ${({ width, height }) => `calc(${height} / ${width} * 100%)`};
  border: 1px solid #c9d4df;
  border-radius: ${({ borderRadius }) =>
    borderRadius ? `${borderRadius}px` : '16px'};
  overflow: hidden;
  box-sizing: initial;

  & > img {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export const ContentWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 35%;
  left: 0;
  bottom: 0;
  text-align: left;
  background-color: #ffffff;
  overflow: hidden;
`;

export const Label = styled.p`
  ${TextWeightMixin('semi-bold')};

  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  text-align: center;
  margin-top: 20px;
`;
