// Country configuration
import SocialChile from './cl';
import SocialColombia from './co';
import SocialPeru from './pe';

// Configuration list
const List = {
  cl: SocialChile,
  co: SocialColombia,
  pe: SocialPeru,
};

function GetConfiguration() {
  const country = process.env.REACT_APP_COUNTRY as keyof typeof List;
  return List[country];
}

export default GetConfiguration();
