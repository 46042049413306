import { css } from 'styled-components';

import {
  TextSize,
  TextSizeMixin,
  TextWeight,
  TextWeightMixin,
} from '../Mixins';

export type ColorsHelperProps = {
  color?: string;
  bg?: string;
};

export const ColorsHelper = css<ColorsHelperProps>`
  ${({ color }) => color && `color: var(--color-${color});`}
  ${({ bg }) => bg && `background-color: var(--color-${bg});`}
`;

export type TextSizeHelperProps = { size?: TextSize };

export const TextSizeHelper = css<TextSizeHelperProps>`
  ${({ size }) => size && TextSizeMixin(size)};
`;

export type TextWeightHelperProps = { weight?: TextWeight };

export const TextWeightHelper = css<TextWeightHelperProps>`
  ${({ weight }) => weight && TextWeightMixin(weight)};
`;

export type TextAlignmentHelperProps = {
  left?: boolean;
  center?: boolean;
  right?: boolean;
};

export const TextAlignmentHelper = css<TextAlignmentHelperProps>`
  ${({ left }) => left && 'text-align: left;'}
  ${({ center }) => center && 'text-align: center;'}
  ${({ right }) => right && 'text-align: right;'};
`;
