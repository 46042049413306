import * as React from 'react';
import { ReactNode } from 'react';

// Domain content
import { IImage, IImageResponsive, ILink } from 'domain/model/content';

// Hooks
import useIsMobile from 'infrastructure/hooks/IsMobile';

// Components
import SmartLink from 'presentation/components/organisms/SmartLink';
import Image from 'presentation/components/atoms/Image';

// Hooks
import useUtils, { CardSize } from './ImageCard.utils';

// Styles
import {
  Container,
  ImageWrapper,
  ContentWrapper,
  Label,
} from './ImageCard.style';

// Definitions
type LinkProps = {
  children: ReactNode;
  link?: ILink;
  utm?: string;
};

export type Props = {
  image: IImage | IImageResponsive;
  label?: string;
  link?: ILink;
  size?: CardSize;
  width?: number;
  height?: number;
  gap?: number;
  children?: React.ReactNode;
  onClick?: () => void;
  utmMedium?: string;
};

// Component
const Link = (linkProps: LinkProps) => {
  const {
    link: customLink,
    children: customChildren,
    utm: utmMedium,
  } = linkProps;

  if (customLink) {
    const { url } = customLink;
    return (
      <SmartLink
        url={url}
        target={customLink.external ? 'adaptable' : 'route'}
        mode="stock"
        utm={{ medium: utmMedium }}
        style={{ display: 'inline-block', width: '100%' }}>
        {customChildren}
      </SmartLink>
    );
  }
  return <>{customChildren}</>;
};

const ImageCard = (props: Props) => {
  // Props
  const {
    image,
    label,
    link,
    size = 'default',
    width,
    height,
    gap,
    onClick,
    children,
    utmMedium,
  } = props;

  // Hooks
  const isDesktop = !useIsMobile();
  const { methods } = useUtils();

  // Vars
  const device = isDesktop ? 'desktop' : 'mobile';
  const cardWidth =
    width || methods.getCardDimensionsBySize(size, device).width;
  const cardHeight =
    height || methods.getCardDimensionsBySize(size, device).height;

  return (
    <Container width={cardWidth} gap={gap} onClick={onClick}>
      <Link link={link} utm={utmMedium}>
        <ImageWrapper
          width={cardWidth}
          height={cardHeight}
          borderRadius={methods.getRadiusBySize(size)}
          aria-label={!label ? methods.getImageTitle(image, device) : undefined}
          aria-labelledby={label && 'image-card-label'}>
          <Image source={image} />

          {children && <ContentWrapper>{children}</ContentWrapper>}
        </ImageWrapper>

        {label && <Label id="image-card-label">{label}</Label>}
      </Link>
    </Container>
  );
};

export default ImageCard;
