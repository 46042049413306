import styled from 'styled-components';

const HeaderContainer = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 24px;

  @media ${({ theme }) => theme.devices.tablet} {
    margin-bottom: 48px;
  }
`;

export const WrapperContent = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  margin-bottom: 0px;
  height: auto;

  @media ${({ theme }) => theme.devices.tablet} {
    height: 10.6rem;
    margin-bottom: 23px;
  }

  @media ${({ theme }) => theme.devices.desktop} {
    height: 100%;
    margin-bottom: 18px;
  }
`;

export const ContentConfetti = styled.div`
  width: 100%;

  svg {
    width: calc(100% + 10px);
    height: 79px;
  }

  @media ${({ theme }) => theme.devices.tablet} {
    svg {
      width: 100%;
      height: 113px;
    }
  }

  @media ${({ theme }) => theme.devices.desktop} {
    width: 92%;
    margin: 0 auto;

    svg {
      height: 185px;
    }
  }
`;

export const ContentLogo = styled.div`
  display: none;

  @media ${({ theme }) => theme.devices.tablet} {
    display: block;
    position: absolute;

    top: 32px;
    left: 50%;
    transform: translate(-50%, 0);

    svg {
      width: 140px;
    }
  }

  @media ${({ theme }) => theme.devices.desktop} {
    top: 47px;
    left: 50%;
    transform: translate(-50%, 0);
  }
`;

export const WrapperTextos = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  position: relative;

  font-weight: 300;
  text-align: center;

  @media ${({ theme }) => theme.devices.desktop} {
    align-items: center;
  }
`;

export const CuponsAvailable = styled.p`
  font-size: 20px;
  line-height: 24px;
  color: #585d61;
  margin-bottom: 16px;

  span {
    font-weight: bold;
  }

  @media ${({ theme }) => theme.devices.tablet} {
    font-size: 30px;
    line-height: 35px;
    margin-bottom: 24px;
  }

  @media ${({ theme }) => theme.devices.desktop} {
    font-size: 41px;
    line-height: 48px;
    margin-bottom: 31px;
  }
`;

export const CouponCode = styled.p`
  font-size: 22px;
  line-height: 99.6%;
  color: #63696e;
  display: flex;
  flex-direction: column;
  text-align: center;

  span {
    font-size: 33px;
    font-weight: bold;
    margin-top: 15px;
  }

  @media ${({ theme }) => theme.devices.tablet} {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 28px;

    span {
      font-size: 28px;
      margin-left: 8px;
      margin-top: 5px;
    }
  }

  @media ${({ theme }) => theme.devices.desktop} {
    font-size: 38px;

    span {
      font-size: 38px;
      margin-top: 4px;
      margin-left: 10px;
    }
  }
`;

export default HeaderContainer;
