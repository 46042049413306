import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import {
  IEventMessage,
  SubscriptionHandler,
  Unsubscribe,
  IEventBus,
} from 'domain/eventBus';

// Definitions

type EventEmitted = {
  topic: string;
  event: IEventMessage;
};

function createEventBus(): IEventBus {
  const mainSubject = new Subject<EventEmitted>();

  const publish = <T extends IEventMessage>(topic: string, event: T) => {
    mainSubject.next({ topic, event });
  };

  const subscribe = <T extends IEventMessage>(
    topic: string,
    handler: SubscriptionHandler<T>,
  ): Unsubscribe => {
    const subscription = mainSubject
      .pipe(filter(f => f.topic === topic))
      .subscribe(({ event }) => handler(event as T));

    return () => subscription.unsubscribe();
  };

  return {
    publish,
    subscribe,
  };
}

const EventBus = createEventBus();

export default EventBus;
