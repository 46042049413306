import { AxiosResponse, CancelTokenSource } from 'axios';
import Random from 'infrastructure/utils/random';
import { IMovement } from 'domain/model/movement';
import { MovementsResponse } from './movements.defs';

// Client
import { httpClient, token } from '../../client';

// Definitions
import { ExtendConfig } from '../../definitions';

// Dynamic token source
let source: CancelTokenSource;

const Movements = {
  getTransactions: (
    authToken: string | undefined,
    date: Date,
    nextPage: string,
    nextPageRef?: string,
    previousPageRef?: string,
  ): Promise<AxiosResponse<MovementsResponse>> => {
    source = token.source();

    const url = `v2/points/transactions/${date.getFullYear()}/${
      date.getMonth() + 1
    }`;

    const params = {
      nextPage: nextPage === '0' ? undefined : nextPage,
      ...(nextPageRef && {
        nextPageRef,
      }),
      ...(previousPageRef && {
        previousPageRef,
      }),
    };

    return httpClient.get<MovementsResponse>(url, {
      cancelToken: source.token,
      authToken,
      params,
    } as ExtendConfig);
  },
  exportToExcel: (
    authToken: string | undefined,
    transactions: IMovement[],
  ): Promise<AxiosResponse> => {
    source = token.source();

    const data = {
      transactions,
    };

    const url = 'v2/points/transactions/document?type=xlsx';

    // userData

    const encrypt = {
      secret: Random.string(32),
      iv: Random.string(16),
      encode: true,
    };

    return httpClient.post(url, data, {
      headers: {
        'Content-Type': 'application/json',
      },
      responseType: 'blob',
      cancelToken: source.token,
      encrypt,
      authToken,
    } as ExtendConfig);
  },
  exportToPdf: (
    authToken: string | undefined,
    transactions: IMovement[],
  ): Promise<AxiosResponse<string>> => {
    source = token.source();

    const url = 'v2/points/transactions/document?type=pdf';

    const encrypt = {
      secret: Random.string(32),
      iv: Random.string(16),
      encode: true,
    };
    const data = {
      transactions,
    };
    return httpClient.post(url, data, {
      headers: {
        'Content-Type': 'application/json',
      },
      cancelToken: source.token,
      encrypt,
      authToken,
    } as ExtendConfig);
  },
};

export default Movements;
