import { useHistory } from 'react-router-dom';
import useIsMobile from 'infrastructure/hooks/IsMobile';

import Modal from 'presentation/components/molecules/Modal';
import { Title } from 'presentation/components/atoms';
import HtmlParser from 'presentation/components/molecules/HtmlParser/HtmlParser';
import Button from 'presentation/components/atoms/Button/Button';
import Icon, { IconName } from 'presentation/assets/icons/Icon';

import Styled from './ModalUpgradeCategory.style';

export type ModalUpgradeCategoryData = {
  id: string;
  icon: string;
  title: string;
  titleMobile: string;
  description: string;
  cta: string;
  url: string;
};

export type ModalUpgradeCategoryProps = {
  isOpenModal: boolean;
  data: ModalUpgradeCategoryData;
  onClose: () => void;
};

export default function ModalUpgradeCategory({
  isOpenModal,
  onClose,
  data,
}: ModalUpgradeCategoryProps) {
  const history = useHistory();
  const { icon, title, titleMobile, description, cta, url } = data;
  const handleOnclick = () => {
    history.push(url);
  };
  const isMobile = useIsMobile();
  return (
    <Modal
      id="upgrade-category"
      open={isOpenModal}
      width="575px"
      rightIcon="close"
      contentStyle={{ padding: '0px' }}
      onClose={onClose}
      requireCloseAction={['rightIcon']}
      title={isMobile ? '¡Lo lograste!' : ''}>
      <Styled.Header>
        <Styled.HeaderImage />
        <Styled.WrapperIcon>
          <Icon size="large" name={icon as IconName} />
        </Styled.WrapperIcon>
      </Styled.Header>

      <Styled.WrapperContentModal isMobile={isMobile}>
        <Title level="1" strong>
          {isMobile ? titleMobile : title}
        </Title>
        <Styled.WrapperText className={icon}>
          <HtmlParser text={description} />
        </Styled.WrapperText>

        <Button uppercase={false} isFullWidth onClick={handleOnclick}>
          {cta}
        </Button>
      </Styled.WrapperContentModal>
    </Modal>
  );
}
