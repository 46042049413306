import { v4 as uuid } from 'uuid';

// Base random UUID function
const base = () => uuid().toString();

const Random = {
  uuid: (hyphen = true) => {
    const current = base();
    return hyphen ? current : current.replace(/-/g, '');
  },
  string: (length: number) => {
    if (length <= 0) throw new Error('Required a number greater than zero');
    return Random.uuid(false).substring(0, length);
  },
};

export default Random;
