const commerces = [
  {
    name: 'banco',
    url: 'https://www.bancofalabella.cl/',
    displayText: 'Banco',
  },
  {
    name: 'falabella',
    url: 'https://tienda.falabella.com/falabella-cl',
    displayText: 'Falabella',
  },
  {
    name: 'sodimac',
    url: 'https://sodimac.falabella.com/sodimac-cl ',
    displayText: 'Sodimac',
  },
  {
    name: 'tottus',
    url: 'https://tottus.falabella.com/tottus-cl',
    displayText: 'Tottus',
  },
  {
    name: 'linio',
    url: 'https://linio.falabella.com/linio-cl ',
    displayText: 'Linio',
  },
  {
    name: 'tottus-app',
    url: 'https://play.google.com/store/apps/details?id=com.tottus&hl=en',
    displayText: 'Tottus App',
  },
  {
    name: 'seguros',
    url: 'https://web.segurosfalabella.com/cl',
    displayText: 'Seguros',
  },
  {
    name: 'viajes',
    url: 'https://www.viajesfalabella.cl/',
    displayText: 'Viajes',
  },
  {
    name: 'mallplaza',
    url: 'https://www.mallplaza.cl/',
    displayText: 'Mallplaza',
  },
];

export default commerces;
