import React from 'react';

// Defs
import { HeadlongProps } from './Headlong.def';

// Icons
import { Confetti as IconConfetti, LogoCMR } from '../icons/index';

// Styled Components
import HeaderContainer, {
  ContentConfetti,
  ContentLogo,
  CouponCode,
  CuponsAvailable,
  WrapperContent,
  WrapperTextos,
} from './Headlong.styled';

const Headlong = ({ code, countCoupons, isCountry }: HeadlongProps) => {
  return (
    <HeaderContainer>
      <WrapperContent>
        <ContentConfetti>
          <IconConfetti />
        </ContentConfetti>

        <ContentLogo>
          <LogoCMR />
        </ContentLogo>
      </WrapperContent>

      <WrapperTextos>
        <CuponsAvailable>
          Tienes{' '}
          <span>
            {countCoupons} {countCoupons === 1 ? 'cupón' : 'cupones'}
          </span>{' '}
          {countCoupons === 1 ? 'disponible' : 'disponibles'}
        </CuponsAvailable>

        {!isCountry && countCoupons > 0 && (
          <CouponCode>
            Úsalo con el código <span> {code}</span>
          </CouponCode>
        )}
      </WrapperTextos>
    </HeaderContainer>
  );
};

export default React.memo(Headlong);
