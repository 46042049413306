import { AccessToken } from 'infrastructure/services/loyalty/resources/auth';

/* Create Subscription Definitions */
export type Subscription = {
  documentType: string;
  documentNumber: string;
  phone: string;
  email: string;
  terms: boolean;
};

export enum SubscriptionErrors {
  IsRegistered = 200,
  IsBankUserRegistered = 221,
  IsPhoneExisting = 201,
  IsEmailExisting = 202,
  IsDocumentNumberInvalid = 204,
  IsPhoneInvalid = 212,
  IsEmailInvalid = 213,
  ServiceError,
}

export type SubscriptionState = {
  success: boolean;
  error?: SubscriptionErrors;
  evaluation?: {
    approvedCreditCard: Boolean;
    approvedCurrentAccount: Boolean;
  };
};

export type CompleteProfileProps = {
  firstName: string;
  lastName: string;
  birthDate: Date | string | null;
  gender?: string;
  token: string;
  userToken: string;
};

/* Login User Definitions */
export type LoginAttr = {
  dni: string;
  password: string;
  type?: string;
  isSso?: boolean;
};

export type LoggedDef = {
  in?: boolean;
  ssoSuccess?: boolean;
  data?: AccessToken;
  error?: any;
};

/* Provider Definitions */
export type AuthProviderValue = {
  data: {
    subscription: SubscriptionState;
    logged: LoggedDef;
  };
  methods: {
    Create: (subscription: Subscription, tokenCaptcha: string) => Promise<void>;
    Login: (attr: LoginAttr, tokenCaptcha: string) => Promise<void>;
    OauthLogin: (authId: string) => Promise<void>;
    Logout: () => Promise<void>;
    Refresh: () => Promise<void>;
    CompleteProfile: (dataForm: CompleteProfileProps) => Promise<void>;
  };
  reset: {
    subscription: () => void;
    resetUpdateProfile: () => void;
  };
  profileUpdate?: {
    success: boolean;
    error: undefined | any;
  };
};
