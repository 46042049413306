import { ICouponsGroup, NoCouponsGroup } from 'domain/model/content/Coupon';

const CouponMapper = (collection: any) => {
  try {
    const newCollection = collection.map(({ fields }: ICouponsGroup) => fields);
    return newCollection;
  } catch {
    console.warn(
      '[CouponMapper] The content json structure received differed from expected',
    );

    return NoCouponsGroup;
  }
};

export default CouponMapper;
