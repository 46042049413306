import { IImageResponsive, ILink, NoImageResponsive } from './Common';

export interface ISpecialContent {
  name: string;
  image: IImageResponsive;
  text?: string;
  link?: ILink;
}

export const NoSpecialContent: ISpecialContent = {
  name: '',
  image: NoImageResponsive,
};
