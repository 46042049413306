// Analytics utils
import Analytics from 'infrastructure/utils/analytics';

import { AnalyticsMarkCall, AnalyticsMarks } from '../Analytics.def';

const { setEventOP, setError } = Analytics;

// Definitions
type LoginOPMarks = {
  submitted: AnalyticsMarkCall;
  success: AnalyticsMarkCall;
  update: AnalyticsMarkCall;
};

type LoginErrorMarks = {
  invalid: AnalyticsMarkCall;
  blocked: AnalyticsMarkCall;
  unexpected: AnalyticsMarkCall;
};

export type LoginAnalyticsMarks = AnalyticsMarks<
  {},
  LoginOPMarks,
  LoginErrorMarks
>;

// Constants
const LOGIN_ANALYTICS_CATEGORY = 'Login';

const marks: LoginAnalyticsMarks = {
  ui: {},
  op: {
    submitted: () => {
      setEventOP(LOGIN_ANALYTICS_CATEGORY, 'Petición de login ha sido enviada');
    },
    success: ({ to } = {}) => {
      setEventOP(LOGIN_ANALYTICS_CATEGORY, 'Petición de login ha sido exitosa');
    },
    update: () => {
      setEventOP(
        LOGIN_ANALYTICS_CATEGORY,
        'Petición de crear/recuperar contraseña enviada',
      );
    },
  },
  error: {
    invalid: () => {
      setError('Petición de login fallada por datos de usuario no válidos');
    },
    blocked: () => {
      setError('Petición de login fallada por cuenta bloqueada');
    },
    unexpected: () => {
      setError('Petición de login fallada por error inesperado');
    },
  },
};

export default marks;
