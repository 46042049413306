import * as React from 'react';
import { useState, useMemo, useEffect, lazy, Suspense } from 'react';

// Hooks
import { useConfiguration } from 'infrastructure/providers/Configuration';
import { useContentful } from 'infrastructure/providers/Contentful';

// Def

// Components
import { useAuth } from 'infrastructure/providers/Auth';
import CookiesPolicy from 'presentation/components/molecules/CookiesPolicy';
import getTemplate from './template';

// Lazy components
const ImageCampaignModal = lazy(
  () => import('presentation/components/organisms/ImageCampaignModal'),
);

// Definitions
export type Props = {
  children: React.ReactNode;
};

const SKIP_CAMPAIGN_ROUTES = new Set(['#ingresar']);

const Layout = (props: Props) => {
  // Props
  const { children } = props;

  // State
  const [showCampaign, setShowCampaign] = useState(false);

  // Hooks
  const {
    configuration: {
      layout,
      client,
      features: {
        ui: { common: commonUI, home: homeUI },
      },
    },
  } = useConfiguration();
  const {
    data: { common },
  } = useContentful();
  const {
    data: { logged },
  } = useAuth();

  const TemplateComponent = useMemo(() => getTemplate(layout), [layout]);

  useEffect(() => {
    if (!common.campaignProgram.visible) return;

    const locationHash = window.location.hash;
    const show = !SKIP_CAMPAIGN_ROUTES.has(locationHash);

    setShowCampaign(show);
  }, [common.campaignProgram.visible]);

  return TemplateComponent ? (
    <>
      <Suspense fallback={<></>}>
        <TemplateComponent
          header={commonUI.header}
          points={homeUI.points}
          notification={common.notificationMessageContent}>
          {children}
        </TemplateComponent>
      </Suspense>

      {commonUI.cookies && common.cookies?.content?.length > 0 && (
        <CookiesPolicy data={common.cookies} client={client} />
      )}

      <Suspense fallback={<></>}>
        {showCampaign && (
          <ImageCampaignModal
            campaign={common.campaignProgram}
            logged={logged}
          />
        )}
      </Suspense>
    </>
  ) : (
    <> {children} </>
  );
};

export default Layout;
