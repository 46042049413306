const costoDeAdministracion = {
  cl: {
    costoDeAdministracion: 'el costo de administración',
    cardUrl: 'https://solicitudes.bancofalabella.cl/',
  },
  co: {
    costoDeAdministracion: 'la cuota de manejo',
    cardUrl: 'https://solicitudes.bancofalabella.com.co/',
  },
  pe: {
    costoDeAdministracion: 'el costo de membresia',
    cardUrl: 'https://solicitudes.bancofalabella.pe/',
  },
};

const COUNTRY = process.env
  .REACT_APP_COUNTRY as keyof typeof costoDeAdministracion;

export const costoAdministracionString =
  costoDeAdministracion[COUNTRY].costoDeAdministracion;

export default costoDeAdministracion;
