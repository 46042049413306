import React, { CSSProperties } from 'react';

// Components
import Separator from 'presentation/components/atoms/Separator';

// Assets
import Icon, { IconName } from 'presentation/assets/icons';

type Props = {
  iconName?: IconName;
  variant?: 'dark' | 'light' | 'none';
  style?: CSSProperties;
};

const SeparatorLogos = (props: Props) => {
  const { iconName, variant, style } = props;

  return (
    <Separator
      variant={variant}
      alignElements="flex-start"
      spacing="none"
      style={style}>
      {iconName && <Icon name={iconName} />}
    </Separator>
  );
};

export default SeparatorLogos;
