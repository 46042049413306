import { IEnableDataDog, NoEnableDataDog } from 'domain/model/content';

const EnableDataDogMapper = (json: any): IEnableDataDog => {
  try {
    const { fields } = json;
    const { config, enable } = fields;

    return {
      config,
      enable,
    };
  } catch {
    console.warn(
      '[EnableDataDogMapper] The content json structure received differed from expected',
    );

    return NoEnableDataDog;
  }
};

export default EnableDataDogMapper;
