export interface IQuestion {
  question: string;
  answer: string;
}

export interface IFaqQuestion {
  title: string;
  subtitle: string;
  image: string;
  questions: IQuestion[];
}

export const NoFaqGroup = [
  {
    title: '',
    subtitle: '',
    image: '',
    questions: [],
  },
];

export const NoQuestion = {
  question: '',
  answer: '',
};
