import { css } from 'styled-components';

export default css`
  :root {
    --select-arrow: url("data:image/svg+xml,%3Csvg viewBox='0 0 14 7' xmlns='http://www.w3.org/2000/svg' width='14' height='7'%3E%3Cpath d='M1.343 0L7-5.657 12.657 0 7 5.657z' stroke='%238A9299' stroke-width='2' fill='none' fill-rule='evenodd'/%3E%3C/svg%3E%0A");
    --indicator: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.7071 5.79289C12.3166 5.40237 11.6834 5.40237 11.2929 5.79289L8 9.08579L4.70711 5.79289C4.31658 5.40237 3.68342 5.40237 3.29289 5.79289C2.90237 6.18342 2.90237 6.81658 3.29289 7.20711L7.29289 11.2071C7.68342 11.5976 8.31658 11.5976 8.70711 11.2071L12.7071 7.20711C13.0976 6.81658 13.0976 6.18342 12.7071 5.79289Z' fill='%238A9199'/%3E%3C/svg%3E%0A");
    --tooltips-green-icon: url("data:image/svg+xml,%3Csvg width='19' height='18' viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.8501 17C14.2684 17 17.8501 13.4183 17.8501 9C17.8501 4.58172 14.2684 1 9.8501 1C5.43182 1 1.8501 4.58172 1.8501 9C1.8501 13.4183 5.43182 17 9.8501 17Z' stroke='%2343B02A' stroke-width='2'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.87725 4.66199C9.06125 4.66199 7.96925 4.92599 7.11725 5.56199C6.97325 5.65799 6.98525 5.74199 7.04525 5.84999L7.57325 6.77399C7.66925 6.91799 7.75325 6.89399 7.88525 6.80999C8.53325 6.41399 9.12125 6.23399 9.72125 6.23399C10.3933 6.23399 10.7053 6.56999 10.7053 6.92999C10.7053 7.43399 10.3333 7.81799 9.92525 8.20199C9.36125 8.74199 8.70125 9.29399 8.70125 10.206V10.59C8.70125 10.722 8.71325 10.794 8.88125 10.794H10.1893C10.3453 10.794 10.3813 10.746 10.3813 10.614V10.338C10.3813 9.77399 10.9693 9.29399 11.5453 8.72999C12.1213 8.17799 12.6973 7.56599 12.6973 6.74999C12.6973 5.23799 11.3533 4.66199 9.87725 4.66199ZM10.5853 12.306C10.5853 11.61 10.0213 11.25 9.50525 11.25C8.89325 11.25 8.38925 11.718 8.38925 12.306C8.38925 12.906 8.88125 13.338 9.49325 13.338C10.1053 13.338 10.5853 12.894 10.5853 12.306Z' fill='%2343B02A'/%3E%3C/svg%3E");
    --tooltips-dark-icon: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 6.5C12 9.81371 9.31371 12.5 6 12.5C2.68629 12.5 0 9.81371 0 6.5C0 3.18629 2.68629 0.5 6 0.5C9.31371 0.5 12 3.18629 12 6.5ZM6.01893 3.24609C5.40693 3.24609 4.58793 3.44409 3.94893 3.92109C3.84093 3.99309 3.84993 4.05609 3.89493 4.13709L4.29093 4.83009C4.36293 4.93809 4.42593 4.92009 4.52493 4.85709C5.01093 4.56009 5.45193 4.42509 5.90193 4.42509C6.40593 4.42509 6.63993 4.67709 6.63993 4.94709C6.63993 5.32509 6.36093 5.61309 6.05493 5.90109C6.02356 5.93113 5.99179 5.96122 5.95985 5.99147C5.56122 6.36899 5.13693 6.77083 5.13693 7.40409V7.69209C5.13693 7.79109 5.14593 7.84509 5.27193 7.84509H6.25293C6.36993 7.84509 6.39693 7.80909 6.39693 7.71009V7.50309C6.39693 7.13448 6.73182 6.81371 7.10381 6.4574L7.10385 6.45736C7.15872 6.4048 7.2144 6.35147 7.26993 6.29709C7.70193 5.88309 8.13393 5.42409 8.13393 4.81209C8.13393 3.67809 7.12593 3.24609 6.01893 3.24609ZM6.54993 8.97909C6.54993 8.45709 6.12693 8.18709 5.73993 8.18709C5.28093 8.18709 4.90293 8.53809 4.90293 8.97909C4.90293 9.42909 5.27193 9.75309 5.73093 9.75309C6.18993 9.75309 6.54993 9.42009 6.54993 8.97909Z' fill='%23474C4E'/%3E%3C/svg%3E%0A");
    --checkbox-mark: url("data:image/svg+xml,%3Csvg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.5 6.5L5.5 10.5L14.5 1.5' stroke='%2343B02A' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E ");
  }
`;
