import { AxiosRequestConfig } from 'axios';

// Configuration
import Configuration from 'infrastructure/stores/Configuration';

// Definitions
import { ExtendConfig, LoyaltyOptionalHeader } from '../definitions';

//  Environments
const country = process.env.REACT_APP_COUNTRY as string;

const COMMON_HEADERS: { [key: string]: string } = {
  'x-country': country.toUpperCase(),
  'x-forwarded-for': '190.153.235.116, 10.78.255.9, 190.21.76.117', // Es sobrescrito por KONG
};

const COMMERCES: { [key: string]: string } = {
  cmrpuntos: 'Loyalty',
  banco: 'Banco',
  falabella: 'Falabella',
  tottus: 'Tottus',
  linio: 'Linio',
  sodimac: 'Sodimac',
  fpay: 'Fpay',
  mallplaza: 'Mallplaza',
  openplaza: 'OpenPlaza',
  circuloEspecialistasSodimac: 'CirculoEspecialistasSodimac',
};

const CHANNEL: { [key: string]: string } = {
  web: 'Web',
  app: 'App',
};

const DEVICE: { [key: string]: string } = {
  android: 'Android',
  ios: 'IOS',
  desktop: 'WEB',
};

const getLoyaltyHeaders = (optionalHeaders: LoyaltyOptionalHeader[] = []) => {
  const {
    client = '',
    platform = '',
    device = '',
    referrer = '',
  } = Configuration.get() || {};

  const headers: { [key: string]: string } = {
    ...COMMON_HEADERS,
    'x-commerce': COMMERCES[client],
    'x-channel': CHANNEL[platform],
  };

  if (referrer && optionalHeaders.includes('x-referer')) {
    headers['x-referer'] = referrer;
  }

  if (optionalHeaders.includes('x-sso')) {
    headers['x-sso'] = 'true';
  }

  if (optionalHeaders.includes('x-paytrue-channel')) {
    const channelDevice = DEVICE[device] ?? 'Web';
    headers['x-paytrue-channel'] = `Loyalty-${channelDevice}`;
  }

  if (optionalHeaders.includes('x-paytrue-commerce')) {
    headers['x-paytrue-commerce'] = COMMERCES[client];
  }

  return headers;
};

const AddLoyaltyHeadersInterceptor = (config: AxiosRequestConfig) => {
  const extend: ExtendConfig = { ...config };

  extend.headers = {
    ...getLoyaltyHeaders(extend?.optionalHeaders),
    ...extend.headers,
  };

  return extend;
};

export default AddLoyaltyHeadersInterceptor;
