import { Carousel } from 'presentation/components/molecules';
import CategoryAndBenefitsCard from 'presentation/components/molecules/CategoryAndBenefitsCard/CategoryAndBenefitsCard';
import categoryAndBenefits, {
  categoryAndBenefitsProps,
} from 'domain/configuration/categoryAndBenefits';

import Button from 'presentation/components/atoms/Button';
import Styled from './CategoryAndBenefitsSection.style';
import SmartLink from '../SmartLink';

const categoryAndBenefitsHard = categoryAndBenefits;

type Props = {
  title: string;
  description: string;
  categoryAndBenefits: categoryAndBenefitsProps[];
  cta: categoryAndBenefitsProps['cta'];
};

const breakpoints = {
  320: { slidesPerView: 1.1, spaceBetween: 16 },
  375: { slidesPerView: 1.2, spaceBetween: 16 },
  425: { slidesPerView: 1.2, spaceBetween: 16 },
  520: { slidesPerView: 1.4, spaceBetween: 16 },
  768: { slidesPerView: 1.7, spaceBetween: 32 },
  1024: { slidesPerView: 2.6, spaceBetween: 32 },
  1280: { slidesPerView: 3, spaceBetween: 32 },
  1440: { slidesPerView: 3, spaceBetween: 32 },
};
const CategoryAndBenefitsSection = (props: Props) => {
  const { title, description, cta } = props;
  let { categoryAndBenefits } = props;
  process.env.REACT_APP_COUNTRY === 'pe' &&
    (categoryAndBenefits = categoryAndBenefitsHard.categoryList);
  return (
    <>
      <Styled.Section>
        <Styled.Header>
          <Styled.Title>{title}</Styled.Title>
          <Styled.Subtitle>{description}</Styled.Subtitle>
        </Styled.Header>

        <Styled.Content>
          <Carousel
            id="CategoryAndBenefitsCardsCarousel"
            swiperOptions={{ breakpoints, navigation: true }}>
            {categoryAndBenefits &&
              categoryAndBenefits.map(card => (
                <CategoryAndBenefitsCard
                  key={card.type}
                  type={card.type}
                  name={card.name}
                  icon={card.icon}
                  points={card.points}
                  glosa={card.glosa}
                  benefitsList={card.benefitsList}
                  cta={card.cta}
                />
              ))}
          </Carousel>
        </Styled.Content>
        <Styled.Footer>
          <SmartLink
            url={cta.url || 'https://www.cmrpuntos.pe/categorias'}
            target="blank">
            <Button size="L">{cta.text || 'Comparar beneficios'}</Button>
          </SmartLink>
        </Styled.Footer>
      </Styled.Section>
    </>
  );
};

export default CategoryAndBenefitsSection;
