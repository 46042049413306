import styled from 'styled-components';

interface CouponListContainerProps {
  couponCounter: number;
}

const CouponListContainer = styled.div<CouponListContainerProps>`
  width: 100%;

  margin-bottom: 40px;
  padding-left: 20px;

  -webkit-overflow-scrolling: touch;
  scroll-snap-points-x: repeat(300px);
  scroll-snap-type: mandatory;
  overflow-x: auto;

  @media ${({ theme }) => theme.devices.tablet} {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: ${({ couponCounter }) =>
      couponCounter === 1 ? 'auto' : 'repeat(auto-fill, minmax(310px, auto))'};
    gap: ${({ couponCounter }) => (couponCounter === 1 ? 0 : '24px')};
    grid-auto-rows: auto;
    justify-content: center;
    margin-bottom: 64px;
    padding: 0 55px;
  }

  @media ${({ theme }) => theme.devices.desktop} {
    grid-template-columns: ${({ couponCounter }) =>
      couponCounter === 1
        ? 'auto'
        : 'repeat(auto-fit, minmax(340px, max-content))'};
  }

  @media ${({ theme }) => theme.devices.wideDesktop} {
    grid-template-columns: repeat(3, auto);
    /* grid-template-columns: repeat(auto-fit, minmax(380px, max-content)); */
    gap: ${({ couponCounter }) => (couponCounter === 1 ? 0 : '24px 32px')};
    margin-bottom: 66px;
    padding: 0 80px;
  }
`;

export default CouponListContainer;
