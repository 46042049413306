import { parse } from 'query-string';

const SSO_DOMAINS_ALLOWED = process.env.REACT_APP_SSO_DOMAINS_ALLOWED;

const processUrlCallback = (url: string): string => {
  const urlCallback = decodeURI(url);
  const isHttps = urlCallback.match('^https');

  if (isHttps) return urlCallback;

  const protocol = urlCallback.split(':')[0];
  const isHttp = protocol === 'http';

  if (isHttp) {
    return urlCallback.replace('http', 'https');
  }

  return `https://${urlCallback}`;
};

const getUrlCallback = (queryParams: string): string | undefined => {
  if (!SSO_DOMAINS_ALLOWED) {
    throw new Error(
      "SSO page couldn't be loaded because SSO_DOMAINS_ALLOWED environment var is undefined",
    );
  }

  const queryObject = parse(queryParams) as Record<string, string>;
  const urlCallback = processUrlCallback(queryObject.url_callback);
  const allowedDomains = new Set(SSO_DOMAINS_ALLOWED.split(','));
  const { hostname } = new URL(urlCallback);

  if (!allowedDomains.has(hostname)) return;

  return urlCallback;
};

export default getUrlCallback;
