import React, { CSSProperties, ReactNode } from 'react';
import styled from 'styled-components';

import {
  TextDefaultFontMixin,
  TextWeightMixin,
} from 'presentation/utils/styles/Mixins';
import { StyledExtendable } from 'presentation/utils/styles';

// Definitions
type TitleSize = 'small' | 'medium' | 'large';
type TitleLevel = '1' | '2' | '3' | '4' | '5' | '6';

export type Props = {
  level: TitleLevel;
  style?: CSSProperties;
  children: ReactNode;
  onClick?: () => void;
};

type RenderProps = {
  size?: TitleSize;
  strong?: boolean;
  style?: CSSProperties;
};

export type TitleProps = Props & Omit<RenderProps, 'style'> & StyledExtendable;

/**
 *
 * @param level '1' | '2' | '3' | '4' | '5' | '6'
 * @param size 'small' | 'medium' | 'large'
 * @param strong boolean
 * @param style CSSProperties
 * @param children
 * @returns
 */
const Title = (props: TitleProps) => {
  const { level, style, children, className, onClick, ...renderProps } = props;
  const headingElement = `h${level}` as any;

  return (
    <Render
      onClick={() => (onClick ? onClick() : undefined)}
      as={headingElement}
      style={style}
      className={className}
      {...renderProps}>
      {children}
    </Render>
  );
};

export const Render = styled.h1<RenderProps>`
  ${TextDefaultFontMixin};
  ${TextWeightMixin('light')};

  line-height: 140%;
  letter-spacing: -0.01em;
  color: var(--color-default);

  ${({ strong }) => strong && TextWeightMixin('regular')};

  ${({ size }) => size === 'small' && 'font-size: 24px;'};
  ${({ size }) => size === 'medium' && 'font-size: 28px;'};
  ${({ size }) => size === 'large' && 'font-size: 32px;'};
`;

export default Title;
