import { AxiosResponse, CancelTokenSource } from 'axios';

// Utils
import Random from 'infrastructure/utils/random';

// Client
import { httpClient, token } from '../../client';

// Definitions
import { ExtendConfig } from '../../definitions';
import {
  PasswordResource,
  CreatePasswordRequest,
  CreatePasswordResponse,
  ValidatePasswordRequest,
  ValidatePasswordResponse,
} from './password.defs';

// Dynamic token source
let source: CancelTokenSource;

const Password: PasswordResource = {
  cancel: () => source?.cancel('Password service cancelled'),
  validate: (
    request: ValidatePasswordRequest,
  ): Promise<AxiosResponse<ValidatePasswordResponse>> => {
    source = token.source();
    const encrypt = {
      secret: Random.string(32),
      iv: Random.string(16),
      encode: true,
    };

    return httpClient.post<ValidatePasswordResponse>(
      'v1/password/validation',
      request,
      {
        cancelToken: source.token,
        encrypt,
      } as ExtendConfig,
    );
  },
  create: (
    request: CreatePasswordRequest,
  ): Promise<AxiosResponse<CreatePasswordResponse>> => {
    source = token.source();
    const encrypt = {
      secret: Random.string(32),
      iv: Random.string(16),
      encode: true,
    };

    return httpClient.post<CreatePasswordResponse>('v1/password', request, {
      cancelToken: source.token,
      encrypt,
    } as ExtendConfig);
  },
};

export default Password;
