const variants = {
  orange: {
    background: '#F99300',
  },
  red: {
    background: '#E40046',
  },
  green: {
    background: '#007A33',
  },
  blue: {
    background: '#0072CE',
  },
  white: {
    background: 'white',
  },
  gray: {
    background: '#BAC4CF',
  },
};

export default variants;
