import * as React from 'react';
import qs from 'query-string';

import Anchor, { AnchorProps } from 'presentation/components/atoms/Anchor';
import Configuration from 'infrastructure/stores/Configuration';

import { useConfiguration } from 'infrastructure/providers/Configuration';
import { useAuth } from 'infrastructure/providers/Auth';
import getLinkRedirectSSO from './NewSSOLink.utils';

// Providers contexts

/**
 * source
 * medium
 * campaing
 * term
 * content
 */
export type UTM = {
  source?: string;
  medium?: string;
  campaign?: string;
  term?: string;
  content?: string;
};

export type AnchorTarget = AnchorProps['target'];

export type Props = {
  target: AnchorTarget | 'adaptable';
  utm?: UTM;
  dyDecisionId?: string;
  dySlotId?: string;
  /**
   * back: back arrow in app
   * giftcard: giftcard modules
   * redirect: redirect URL
   */
  deepLink?: 'back' | 'giftcard' | 'redirect';
} & Omit<AnchorProps, 'target'>;

const addsUrlUtm = (url: string, utm: UTM): string => {
  const { url: parseUrl, query: parseQuery } = qs.parseUrl(url);

  if (!parseUrl) return url;

  return `${parseUrl}?${qs.stringify({
    ...(parseQuery as any),
    ...Configuration.parseUtm(utm),
  })}`;
};

const SmartLink = (props: Props) => {
  const {
    data: { logged },
  } = useAuth();

  // Props
  const {
    children,
    height,
    id,
    mode = 'default',
    onClick,
    referrer,
    rel,
    style,
    target,
    url,
    width,
    utm,
    deepLink,
    dyDecisionId,
    dySlotId,
  } = props;

  // Contexts
  const { configuration } = useConfiguration();
  const { platform, deepLink: deepLinkConfiguration } = configuration;

  // Vars
  let endTarget = target;
  let endUrl = url;
  const deepLinkValue = deepLink && deepLinkConfiguration[deepLink];

  if (
    utm &&
    endTarget !== 'route' &&
    endUrl &&
    !endUrl.toLowerCase().includes('soap')
  ) {
    endUrl = addsUrlUtm(endUrl, utm);
  }

  if (endTarget === 'adaptable') {
    endTarget = platform === 'app' ? 'self' : 'blank';
  }

  if (deepLinkValue) {
    endUrl = deepLinkValue.replace('URL', endUrl);
    endTarget = 'self';
  }

  endUrl = logged.in ? getLinkRedirectSSO(endUrl) : endUrl;

  return (
    <Anchor
      height={height}
      id={id}
      mode={mode}
      onClick={onClick}
      referrer={referrer}
      rel={rel}
      style={style}
      target={endTarget}
      url={endUrl}
      dyDecisionId={dyDecisionId}
      dySlotId={dySlotId}
      width={width}>
      {children}
    </Anchor>
  );
};

export default SmartLink;
