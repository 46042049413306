import { AxiosResponse, CancelTokenSource } from 'axios';

import { Aes, Rsa } from 'infrastructure/utils/crypto';

// Utils
import { insertIf } from 'infrastructure/utils/array';
import Random from 'infrastructure/utils/random';

// Client
import { httpClient, token } from '../../client';

// Definitions
import { ExtendConfig, LoyaltyOptionalHeader } from '../../definitions';
import {
  CompleteProfileRequest,
  CreateRegistrationResponse,
  CreateSubscriptionRequest,
  CreateSubscriptionResponse,
  LoginOptions,
  LoginRequest,
  LoginResponse,
  ValidateUserRequest,
  createNewUserRequest,
  updatePersonalDataRequest,
} from './auth.defs';

// Dynamic token source
let source: CancelTokenSource;

// Http status codes
export enum LOGIN_STATUS_CODES {
  ERROR = 500,
  LOCKED = 423,
  INVALID = 409,
}

const Auth = {
  cancel: () => source?.cancel('Authentication service cancelled'),
  validateUser: (request: ValidateUserRequest): Promise<AxiosResponse> => {
    source = token.source();
    const encrypt = {
      secret: Random.string(32),
      iv: Random.string(16),
      encode: true,
    };

    const { secret, iv, encode } = encrypt;
    const options = iv || encode ? { encode, iv } : undefined;
    const encryptData = Aes.encrypt(request, secret, options);

    const headers = {
      'x-data': encryptData,
      'x-session-key': Rsa.encrypt({ AESKey: secret, iv }),
      'x-signature': Aes.signature(request, secret, options),
    };

    return httpClient.get('v2/subscriber', {
      withCredentials: true,
      cancelToken: source.token,
      headers,
    } as ExtendConfig);
  },
  createNewUser: (
    request: createNewUserRequest,
  ): Promise<AxiosResponse<CreateRegistrationResponse>> => {
    source = token.source();
    const encrypt = {
      secret: Random.string(32),
      iv: Random.string(16),
      encode: true,
    };
    const registryHeader = { 'x-commerce-exp': 'registry' };
    const loyaltyHeaders: LoyaltyOptionalHeader[] = ['x-referer'];

    return httpClient.post('v2/subscriber', request, {
      withCredentials: true,
      cancelToken: source.token,
      headers: registryHeader,
      optionalHeaders: loyaltyHeaders,
      encrypt,
    } as ExtendConfig);
  },
  create: (
    request: CreateSubscriptionRequest,
  ): Promise<AxiosResponse<CreateSubscriptionResponse>> => {
    source = token.source();
    const encrypt = {
      secret: Random.string(32),
      iv: Random.string(16),
      encode: true,
    };
    const registryHeader = { 'x-commerce-exp': 'registry' };
    const loyaltyHeaders: LoyaltyOptionalHeader[] = ['x-referer'];

    return httpClient.post('v1/subscriber', request, {
      withCredentials: true,
      cancelToken: source.token,
      headers: registryHeader,
      optionalHeaders: loyaltyHeaders,
      encrypt,
    } as ExtendConfig);
  },
  login: async (
    request: LoginRequest,
    options: LoginOptions,
  ): Promise<AxiosResponse<LoginResponse>> => {
    source = token.source();
    const encrypt = {
      secret: Random.string(32),
      iv: Random.string(16),
      encode: true,
    };
    const { isSso = false } = options;

    const loyaltyHeaders: LoyaltyOptionalHeader[] = [
      'x-paytrue-commerce',
      'x-paytrue-channel',
      ...insertIf<LoyaltyOptionalHeader>(isSso, 'x-sso'),
    ];

    return httpClient.post('v1/authenticate', request, {
      withCredentials: true,
      cancelToken: source.token,
      optionalHeaders: loyaltyHeaders,
      encrypt,
    } as ExtendConfig);
  },
  refresh: (): Promise<AxiosResponse<LoginResponse>> => {
    source = token.source();
    return httpClient.post('v1/refresh_token', undefined, {
      withCredentials: true,
      cancelToken: source.token,
    } as ExtendConfig);
  },
  oauth: (token: string, isSso?: boolean) => {
    const decrypt = decodeURIComponent(token);
    const headers = isSso
      ? { 'x-auth-id': decrypt, 'x-sso': isSso }
      : { 'x-auth-id': decrypt };
    return httpClient.post('v1/front/validate/authid', undefined, {
      headers,
    });
  },
  logout: () => {
    source = token.source();
    return httpClient.post('v1/logout', undefined, {
      withCredentials: true,
      cancelToken: source.token,
    } as ExtendConfig);
  },
  completeProfile: (
    request: CompleteProfileRequest,
  ): Promise<AxiosResponse<CreateSubscriptionResponse>> => {
    source = token.source();
    const encrypt = {
      secret: Random.string(32),
      iv: Random.string(16),
      encode: true,
    };
    const registryHeader = { 'x-commerce-exp': 'registry' };
    const loyaltyHeaders: LoyaltyOptionalHeader[] = ['x-referer'];

    return httpClient.patch('v2/customer', request, {
      withCredentials: true,
      cancelToken: source.token,
      headers: registryHeader,
      optionalHeaders: loyaltyHeaders,
      encrypt,
    } as ExtendConfig);
  },
  updatePersonalData: (
    request: updatePersonalDataRequest,
    authToken: string | undefined,
  ): Promise<AxiosResponse<CreateRegistrationResponse>> => {
    source = token.source();
    const encrypt = {
      secret: Random.string(32),
      iv: Random.string(16),
      encode: true,
    };
    const registryHeader = {
      'x-commerce-exp': 'update-personal-data',
      'x-expressions': 'Document',
    };
    const loyaltyHeaders: LoyaltyOptionalHeader[] = ['x-referer'];

    return httpClient.patch('v1/customer-data/update', request, {
      cancelToken: source.token,
      headers: registryHeader,
      optionalHeaders: loyaltyHeaders,
      encrypt,
      authToken,
    } as ExtendConfig);
  },
};

export default Auth;
