import { AxiosRequestConfig } from 'axios';

// Definitions
import { ExtendConfig } from '../definitions';

const AuthInterceptor = (config: AxiosRequestConfig) => {
  const extend: ExtendConfig = { ...config };
  if (extend.authToken) {
    extend.headers.Authorization = `Bearer ${extend.authToken}`;
  }
  return extend;
};

export default AuthInterceptor;
