import { IResource } from './Resource';

export interface IExchangeCategoriesCardsGroup {
  enable: boolean;
  name: string;
  cards: IResource[];
}

export const NoExchangeCategoriesCardsGroup = {
  enable: false,
  name: '',
  cards: [],
};
