import contentful from 'infrastructure/services/contentFul';

// Mappers
import {
  NotificationMessageMapper,
  RelatedCommercesImagesMapper,
  ImageResponsiveMapper,
  FaqGroupMapper,
  CampaingCardMapper,
  InterestLinksMapper,
  RichTextMapper,
  SpecialContentMapper,
} from '../mappers';

// Service
const {
  entriesResource: { getEntry },
} = contentful;

// Vars
const CommonEntryId = process.env.REACT_APP_CONTENTFUL_DATA_COMMON;

const LoadsCommon = async () => {
  try {
    const data = await getEntry<any>(CommonEntryId, {
      'sys.id': CommonEntryId,
      include: 10,
    });

    const { fields } = data;
    const {
      campaignProgram,
      companyData,
      derechos,
      faq,
      interestLinks,
      notificationMessage,
      pageAppRegistry,
      relatedCommerces,
      categoriesProgram,
      subscriptionTerms,
      cookies,
      loginIncentive,
      siteDescription,
    } = fields;

    return {
      interestLinks: InterestLinksMapper(interestLinks),
      relatedCommerces: RelatedCommercesImagesMapper(relatedCommerces),
      rights: derechos,
      notificationMessage: NotificationMessageMapper(notificationMessage),
      appRegistryBanner: ImageResponsiveMapper(pageAppRegistry),
      faq: FaqGroupMapper(faq),
      campaignProgram: CampaingCardMapper(campaignProgram),
      companyData,
      categoriesProgram: ImageResponsiveMapper(categoriesProgram),
      subscriptionTerms: RichTextMapper(subscriptionTerms),
      cookies: RichTextMapper(cookies),
      loginIncentive: SpecialContentMapper(loginIncentive),
      siteDescription,
    };
  } catch (error) {
    console.error(error);
  }
};

export default LoadsCommon;
