import styled from 'styled-components';

interface HeadContentProps {
  colorBox: string;
}

const CouponContainer = styled.div`
  width: 100%;
  height: 100%;
  /* max-width: 247px; */
  max-width: 100%;
  max-height: 100%;
  border: 2px solid #c9d4df;
  border-radius: 6px;

  @media ${({ theme }) => theme.devices.tablet} {
    max-width: 320px;
    max-height: 211px;
  }

  @media ${({ theme }) => theme.devices.desktop} {
    max-width: 340px;
    max-height: 269px;
  }

  @media ${({ theme }) => theme.devices.wideDesktop} {
    max-width: 405px;
    max-height: 274px;
  }
`;

export const HeadContent = styled.div<HeadContentProps>`
  width: 100%;
  display: grid;
  grid-template-columns: auto 66px;
  grid-template-rows: 78px;
  max-height: 78px;
  background: ${({ colorBox }) => colorBox};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  @media ${({ theme }) => theme.devices.tablet} {
    grid-template-columns: 252px 60px;
    grid-template-rows: 60px;
  }

  @media ${({ theme }) => theme.devices.desktop} {
    grid-template-columns: auto 60px;
  }

  @media ${({ theme }) => theme.devices.wideDesktop} {
    grid-template-columns: auto 79px;
    grid-template-rows: 78px;
  }
`;

export const ContentLogo = styled.div`
  padding-left: 11px;
  display: flex;
  align-items: center;

  img {
    width: 44px;
  }

  @media ${({ theme }) => theme.devices.tablet} {
    img {
      width: 33px;
    }
  }

  @media ${({ theme }) => theme.devices.wideDesktop} {
    img {
      width: 44px;
    }
  }
`;

export const WrapperCode = styled.div`
  width: 100%;
  height: 100%;
  padding: 17px 7px 19px 7px;

  color: white;

  h4 {
    font-weight: 400;
    font-size: 14px;
    /* line-height: 28px; */
    line-height: 24px;
  }

  p {
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
  }

  @media ${({ theme }) => theme.devices.tablet} {
    padding: 7px 7px 7px 7px;
    h4 {
      line-height: 24px;
    }

    p {
      font-size: 18px;
    }
  }

  @media ${({ theme }) => theme.devices.wideDesktop} {
    padding: 15px 10px 10px 10px;
    h4 {
      font-size: 21px;
      line-height: 28px;
    }
    p {
      font-size: 21px;
      line-height: 28px;
    }
  }
`;

export const ContentIcon = styled.div`
  border-left: 2px dashed #bac4cf;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 48px;
    max-width: 48px;
    max-height: 48px;
  }
`;

export const BodyContent = styled.div`
  display: grid;
  grid-template-columns: auto 66px;
  grid-template-rows: 191px;
  background: #fff;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  @media ${({ theme }) => theme.devices.tablet} {
    grid-template-columns: 252px 60px;
    grid-template-rows: 146px;
  }

  @media ${({ theme }) => theme.devices.desktop} {
    grid-template-columns: auto 60px;
  }

  @media ${({ theme }) => theme.devices.wideDesktop} {
    grid-template-columns: auto 79px;
    grid-template-rows: 191px;
  }
`;

export const ColumnLeft = styled.div`
  padding: 20px 10px 15px 10px;

  display: flex;
  flex-direction: column;

  div {
    margin-top: auto;
  }

  p {
    margin-bottom: 16px;
    font-size: 17px;
    line-height: 22px;
    font-weight: 300;
    color: #303335;
    padding-right: 15px;
  }

  @media ${({ theme }) => theme.devices.tablet} {
    padding: 15px 10px 13px 10px;
    p {
      margin-bottom: 12px;
      font-size: 17px;
      line-height: 22px;
    }
  }

  @media ${({ theme }) => theme.devices.wideDesktop} {
    padding: 25px 13px 14px 13px;
    p {
      margin-bottom: 16px;
      font-size: 21px;
      line-height: 28px;
    }
  }
`;

export const BadgeDate = styled.span`
  display: block;
  width: fit-content;
  position: relative;
  left: -12px;
  background: #44474b;
  border-radius: 0px 4px 4px 0px;
  font-size: 15px;
  line-height: 19px;
  font-weight: 600;
  color: #ffffff;
  padding: 5px 8px 3px 12px;
  margin-bottom: 16px;

  @media ${({ theme }) => theme.devices.tablet} {
    font-size: 12px;
    line-height: 14px;
    padding: 4px 13px 3px 13px;
    margin-bottom: 14px;
  }

  @media ${({ theme }) => theme.devices.wideDesktop} {
    left: -14px;
    font-size: 16px;
    line-height: 19px;
    padding: 6px 16px 3px 16px;
    margin-bottom: 16px;
  }
`;

export const LogoWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;

  img {
    width: 100%;
    max-width: 175px;
    height: 30px;
    display: block;
  }

  @media (max-width: 320px) {
    img {
      max-width: 148px;
    }
  }

  @media ${({ theme }) => theme.devices.tablet} {
    img {
      width: max-content;
      height: 26px;
    }
  }

  @media ${({ theme }) => theme.devices.wideDesktop} {
    img {
      height: 30px;
    }
  }
`;

export const ColumnRight = styled.div`
  position: relative;
  border-left: 2px dashed #bac4cf;
  padding: 8px;

  div {
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 5px;

    span {
      display: flex;
      align-items: center;
      position: absolute;
      bottom: 12px;
      margin: 10px auto;

      height: 50px;
      max-height: 50px;

      width: 100%;
      max-width: 66px;

      transform: rotate(-90deg);
      -webkit-transform: rotate(-90deg);
      -moz-transform: rotate(-90deg);
      -o-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      /* transform-origin: 0 0; */

      font-size: 19px;
      line-height: 28px;
      font-weight: 300;
      color: #303335;
    }
  }

  @media ${({ theme }) => theme.devices.tablet} {
    div {
      span {
        margin: 5px auto;
        font-size: 17px;
        line-height: 21px;
      }
    }
  }

  @media ${({ theme }) => theme.devices.wideDesktop} {
    div {
      span {
        margin: 12px auto;
        font-size: 23px;
        line-height: 28px;
      }
    }
  }
`;

export default CouponContainer;
