import { IImageResponsive, ILink, NoImageResponsive } from './Common';

export interface ICampaignCard {
  name: string;
  image: IImageResponsive;
  link?: ILink;
  visible: boolean;
  visibility?: 'publico' | 'privado' | 'ambos';
}

export const NoCampaignCard: ICampaignCard = {
  name: '',
  image: NoImageResponsive,
  visible: false,
};
