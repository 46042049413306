import styled from 'styled-components';

// Components
import Icon from 'presentation/assets/icons';
import { Paragraph } from 'presentation/components/atoms/Text';
import Button from 'presentation/components/atoms/Button';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  flex-direction: column;
  gap: 0.5rem;
`;

export const ContainerContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
`;

export const ContainerActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between !important;
  width: 100%;
`;

export const ButtonNext = styled(Button)`
  text-transform: initial;
  letter-spacing: normal;
  min-width: auto;
`;

export const Text = styled(Paragraph)`
  font-size: 14px;

  @media ${({ theme }) => theme.devices.desktop} {
    font-size: 16px;
  }
`;

export const WrapperNumberSteps = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: 'wrap';
`;

export const WrapperIcon = styled.div`
  display: flex;
  cursor: pointer;
`;

export const IconButon = styled(Icon)`
  path {
    fill: #5c6166;
  }
`;

export const dot = (current: boolean) => ({
  counterIncrement: 'dot',
  width: 8,
  height: 8,
  border: current ? '0' : '1px solid #caccce',
  borderRadius: '100%',
  padding: 0,
  display: 'block',
  transition: 'opacity 0.3s, transform 0.3s',
  transform: `scale(${current ? 1.25 : 1})`,
  color: current ? 'var(--reactour-accent, #007aff)' : '#caccce',
  background: current ? 'var(--reactour-accent, #007aff)' : 'none',
  '&:before': {
    content: 'counter(dot)',
    position: 'absolute',
    bottom: 'calc(100% + 0.25em)',
    left: '50%',
    opacity: 0,
    transform: 'translate(-50%, 1em)',
    transition: '0.3s',
  },
  '&:hover': {
    backgroundColor: 'currentColor',
    '&:before': {
      opacity: 0.5,
      transform: 'translate(-50%, -2px)',
    },
  },
});
