import AccountMenu, { AccountMenuAnalyticsMarks } from './AccountMenu';
import Login, { LoginAnalyticsMarks } from './Login';
import Password, { PasswordAnalyticsMarks } from './Password';
import Subscribe, { SubscribeAnalyticsMarks } from './Subscribe';

const Flow = {
  login: Login,
  subscribe: Subscribe,
  accountMenu: AccountMenu,
  password: Password,
};

export type {
  AccountMenuAnalyticsMarks,
  LoginAnalyticsMarks,
  PasswordAnalyticsMarks,
  SubscribeAnalyticsMarks,
};
export default Flow;
