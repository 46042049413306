import { useState, useMemo, useRef } from 'react';
import SwiperCore, { SwiperOptions } from 'swiper';

// Types
import { BreakpointsOptions } from './CarouselCoupons.def';

const FIX_MARGIN_ELEMENT = 8;
const CLASS_NAME_SLIDE = '.swiper-slide';

/* TODO: when breakpoints is null, should it recalculate in base slide width  */
const DEFAULT_BREAKPOINTS = {
  0: {
    slidesPerView: 1.4,
  },
  392: {
    slidesPerView: 1.4,
  },
  440: {
    slidesPerView: 1.4,
  },
  768: {
    slidesPerView: 2,
  },
  1024: {
    slidesPerView: 2,
  },
  1280: {
    slidesPerView: 3,
  },
  1440: {
    slidesPerView: 3,
  },
};

const useUtils = (options: {
  show: number;
  totalItems: number;
  spaceBetween: number;
  breakpoints?: { [width: string]: BreakpointsOptions };
}) => {
  const {
    show,
    totalItems,
    spaceBetween,
    breakpoints = DEFAULT_BREAKPOINTS,
  } = options;
  const [innerWidth, setInnerWidth] = useState(1);
  const innerWrapperRef = useRef<HTMLDivElement>(null);

  const newBreakpoints = useMemo(
    () =>
      createsBreakpoints({
        breakpoints,
        show,
      }),
    [show, breakpoints],
  );

  const updateInnerWrapper = (swiper: SwiperCore) => {
    const size = swiper.getBreakpoint(breakpoints);
    const breakpoint = newBreakpoints[size] || DEFAULT_BREAKPOINTS[0];
    const realItemToShow = breakpoint?.slidesPerView ?? show;

    const newInnerWidth = calculateInnerWidth({
      breakpoint,
      itemsToShow: show,
      innerElement: innerWrapperRef.current,
      spaceBetween,
    });

    setInnerWidth(newInnerWidth);

    if (totalItems === realItemToShow) {
      swiper.disable();
    }
  };

  return {
    breakpoints: newBreakpoints,
    innerWidth,
    innerWrapperRef,
    init: (swiper: SwiperCore) => {
      setTimeout(() => {
        updateInnerWrapper(swiper);
      }, 500);
    },
    updateInnerWrapper,
    updateInnerWrapperAfter: (swiper: SwiperCore) => {
      // force update width wrapper after changes swiper dom
      setTimeout(() => {
        updateInnerWrapper(swiper);
      }, 0);
    },
  };
};

// Utility internal functions
const calculateMinSlide = (
  totalSlide: number | string | undefined,
  value: number,
) => {
  if (!Number(totalSlide)) return totalSlide;

  return Math.min(Number(totalSlide), value);
};

// Carousel breakpoints options map
const createsBreakpoints = (options: {
  breakpoints: { [width: string]: BreakpointsOptions };
  show: number;
}): { [width: string]: SwiperOptions } => {
  const { show, breakpoints } = options;

  const arrayBreakpoints = Object.entries(breakpoints);
  const newBreakpoints = arrayBreakpoints.map(([key, settings]) => {
    return [
      key,
      {
        ...settings,
        slidesPerView: calculateMinSlide(settings.slidesPerView, show),
      },
    ];
  });

  return Object.fromEntries(newBreakpoints);
};

const calculateInnerWidth = (options: {
  breakpoint: SwiperOptions;
  innerElement: HTMLDivElement | null;
  itemsToShow: number;
  spaceBetween: number;
}): number => {
  const { breakpoint, itemsToShow, innerElement, spaceBetween } = options;

  if (!innerElement) return 0;

  const slide = innerElement.querySelector(CLASS_NAME_SLIDE);
  const slideWidth = slide?.firstElementChild?.clientWidth || 0;
  const itemsPerView = Number(breakpoint.slidesPerView) ?? itemsToShow;
  const totalWidthVisibleItems = slideWidth * itemsPerView + FIX_MARGIN_ELEMENT;
  const spaceBetweenItems = spaceBetween * (itemsPerView - 1);
  const innerWidth = totalWidthVisibleItems + spaceBetweenItems;

  return innerWidth;
};

export default useUtils;
