/**
 * when condition is true return array elements.
 * It's useful for insert optional elements an array.
 *
 * @param condition
 * @param elements
 * @returns array elements
 */
const insertIf = <T>(condition: boolean, ...elements: Array<T>): Array<T> =>
  condition ? elements : [];

export default insertIf;
