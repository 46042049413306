import React, { CSSProperties } from 'react';
import styled from 'styled-components';

// Definitions
type Props = {
  align?: 'left' | 'center' | 'right';
  verticalSpacing?: 'none' | 'S' | 'M' | 'L';
  children?: any;
  style?: CSSProperties;
  marginBottom?: string;
};

type StyledProps = Omit<Props, 'children'>;

const VerticalSpacingValues = {
  none: 0,
  S: 0.5,
  M: 1,
  L: 1.5,
};

/**
 * @param align 'left' | 'center' | 'right'
 * @param children any
 * @param margin 'low' | 'large'
 */
const Group = (props: Props) => {
  const { children, align, verticalSpacing, style, marginBottom } = props;
  return (
    <Render
      align={align}
      verticalSpacing={verticalSpacing}
      marginBottom={marginBottom}
      style={style}>
      {children}
    </Render>
  );
};

const Render = styled.div<StyledProps>`
  transition: all 0.3s ease-out;
  padding: ${({ verticalSpacing }) =>
    verticalSpacing
      ? `${VerticalSpacingValues[verticalSpacing]}rem 0`
      : '10px 0'};
  margin-bottom: ${({ marginBottom }) => marginBottom ?? '0px'};
  ${props => {
    const { align } = props;
    if (align === 'left') return 'text-align: left';
    if (align === 'right') return 'text-align: right';
    return 'text-align: center';
  }}
`;

export default Group;
