import { ICardImageLink, NoCardImageLink } from 'domain/model/content';
import ImageMapper from './ImageMapper';

const CardImageLinkMapper = (json: any): ICardImageLink => {
  try {
    const { fields } = json;
    const { name, mobile, desktop, link } = fields;

    return {
      name,
      mobile: ImageMapper(mobile, false),
      desktop: ImageMapper(desktop, false),
      link,
    };
  } catch {
    console.warn(
      '[CardImageLinkMapper] The content json structure received differed from expected',
    );

    return NoCardImageLink;
  }
};

export default CardImageLinkMapper;
