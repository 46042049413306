import styled from 'styled-components';

// Style utils
import {
  PointerMixin,
  TextSizeMixin,
  TextWeightMixin,
} from 'presentation/utils/styles/Mixins';

export type VariantTokens = {
  colors: {
    text: string;
    heading: string;
    border: string;
  };
  spacing?: {
    heading: string;
    link: string;
  };
};

const TOKENS: { [key: string]: VariantTokens } = {
  light: {
    colors: {
      text: '#8b929b',
      heading: '#8b929b',
      border: '#cfdae5',
    },
  },
  dark: {
    colors: {
      text: 'var(--color-white)',
      heading: 'var(--color-text-placeholder)',
      border: '#475059',
    },
  },
};

export const getVariantTokens = (variant: 'light' | 'dark'): VariantTokens =>
  TOKENS[variant];

export const Wrapper = styled.div``;

export const Heading = styled.div<{ expanded: boolean; tokens: VariantTokens }>`
  ${TextWeightMixin('semi-bold')};
  ${PointerMixin}

  font-size: 12px;
  line-height: 14.4px;
  letter-spacing: 2px;
  color: ${({ tokens }) => tokens.colors.heading};
  text-transform: uppercase;
  padding: 17.5px 48px 17.5px 24px;
  border-bottom: ${({ tokens }) => `${tokens.colors.border} 0.5px solid`};
  position: relative;

  &:after {
    content: '';
    width: 16px;
    height: 16px;
    background-image: var(--indicator);
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-block;
    position: absolute;
    right: 24px;
    top: 16px;
    transition: transform 0.2s ease-in-out;

    ${({ expanded }) =>
      expanded &&
      `
      transform: rotate(180deg);
    `}
  }
`;

export const Panel = styled.div<{ tokens: VariantTokens }>`
  ${TextWeightMixin('regular')};
  ${TextSizeMixin('regular')};

  max-height: 0;
  padding: 0 24px;
  color: ${({ tokens }) => tokens.colors.text};
  overflow: hidden;
  transition: max-height, padding 0.4s ease-out;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);

  &[aria-hidden='false'] {
    max-height: 800px;
    padding: 20px 24px;
    border-bottom: ${({ tokens }) => `${tokens.colors.border} 0.5px solid`};
  }
`;
