import { IRecommendedItems, NoRecommendedItems } from 'domain/model/content';
import ContentSectionMapper from './ContentSectionMapper';
import ReedemCardsGroupMapper from './ReedemCardsGroupMapper';
import TabMapper from './TabMapper';

const RecomendedItemsMapper = (
  ItemRecommendations: any,
  enable: boolean,
): IRecommendedItems => {
  try {
    return {
      enable,
      tabContent: TabMapper(ItemRecommendations, (json: any) =>
        ContentSectionMapper(json, ReedemCardsGroupMapper),
      ),
    };
  } catch {
    console.warn(
      '[RecomendedItemsMapper] The content json structure received differed from expected',
    );

    return NoRecommendedItems;
  }
};

export default RecomendedItemsMapper;
