import axios, { AxiosRequestConfig } from 'axios';

// Interceptors
import {
  EncryptInterceptor,
  AuthInterceptor,
  AddLoyaltyHeadersInterceptor,
} from './interceptors';

//  Environments
const url = process.env.REACT_APP_BASE_URL_LOYALTY as string;

// Configuration
const configuration = {
  baseURL: `${url}`,
} as AxiosRequestConfig;

// Http client instance
const httpClient = axios.create(configuration);

// Token instance
const token = axios.CancelToken;

// Request Interceptors
httpClient.interceptors.request.use(AddLoyaltyHeadersInterceptor, error =>
  Promise.reject(error),
);
httpClient.interceptors.request.use(EncryptInterceptor, error =>
  Promise.reject(error),
);
httpClient.interceptors.request.use(AuthInterceptor, error =>
  Promise.reject(error),
);

export { httpClient, token };
