import React from 'react';
import { ThemeProvider } from 'styled-components';

// Definitions
type Props = {
  children: any;
};

export const Breakpoints = {
  xs: 0,
  sm: 480,
  md: 768,
  lg: 1024,
  xl: 1280,
  xxl: 1920,
};

const Theme = (props: Props) => {
  const { children } = props;
  const theme = {
    colors: {
      active: { main: '#00943e', contrast: '#ffffff' },
      disabled: { main: '#cfdae6', contrast: '#ffffff' },
      inherit: { main: 'transparent', contrast: 'initial' },
      background: { main: '#f2f5f7', contrast: '#5c6166' },
    },
    font: {
      family: 'pfbeausans, Arial, sans-serif',
    },
    devices: {
      mobile: `(min-width: ${Breakpoints.xs}px)`,
      tablet: `(min-width: ${Breakpoints.md}px)`,
      desktop: `(min-width: ${Breakpoints.lg}px)`,
      wideDesktop: `(min-width: ${Breakpoints.xl}px)`,
    },
  };

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default Theme;
