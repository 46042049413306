import { createClient } from 'contentful';

const client = createClient({
  host: process.env.REACT_APP_CONTENTFUL_HOST,
  space: process.env.REACT_APP_CONTENTFUL_SPACE ?? '',
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN ?? '',
  environment: process.env.REACT_APP_CONTENTFUL_ENVIRONMENT ?? 'master',
});

export default client;
