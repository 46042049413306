import * as React from 'react';
import { ReactNode, ReactElement } from 'react';
import { createPortal } from 'react-dom';

// Utils
import { ariaAttr } from 'presentation/utils/aria';

import HtmlParser from 'presentation/components/molecules/HtmlParser';
import StyledTooltip from './Tooltip.styles';
import useTooltip, { Placement } from './Tooltip.utils';

// Definitions
const Portal = (props: { children: ReactNode }) => {
  return createPortal(props.children, document.body);
};

export type Props = {
  id?: string;
  text: string;
  placement?: Placement;
  showArrow?: boolean;
  space?: number;
  delay?: number;
  disabled?: boolean;
  children: ReactElement;
};

const Tooltip = (props: Props) => {
  const {
    id,
    text,
    placement = 'bottom',
    showArrow = true,
    space = 10,
    delay,
    disabled = false,
    children,
  } = props;

  const { open, position, tooltipRef, show, hide, updatePosition } = useTooltip(
    {
      placement,
      space,
    },
  );

  const handleMouseOver = (e: React.MouseEvent<HTMLElement>) => {
    updatePosition(e.currentTarget);
    show();
  };
  const handleMouseOut = () => hide();

  const testId = id && `${id}-tooltip`;
  const ariaAttrs = {
    ...ariaAttr('hidden', !open),
  };

  return (
    <>
      {disabled
        ? children
        : React.cloneElement(children, {
            ...(open && ariaAttr('describedby', id)),
            key: +open,
            onMouseOver: handleMouseOver,
            onMouseOut: handleMouseOut,
          })}
      {disabled || (
        <Portal>
          <StyledTooltip
            role="tooltip"
            id={id}
            data-testid={testId}
            open={open}
            placement={placement}
            positionX={position.x}
            positionY={position.y}
            showArrow={showArrow}
            delay={delay}
            ref={tooltipRef}
            {...ariaAttrs}>
            {HtmlParser({ text })}
          </StyledTooltip>
        </Portal>
      )}
    </>
  );
};

export default Tooltip;
