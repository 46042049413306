import shared from './Shared';

// Validations for specific countries
import peru from './country/pe';
import chile from './country/cl';
import colombia from './country/co';

const Validations = {
  ...shared,
  dni: {
    cl: chile.dni,
    co: colombia.dni,
    pe: peru.dni,
  },
  password: {
    cl: chile.password,
    pe: peru.password,
    co: colombia.password,
  },
};

export default Validations;
