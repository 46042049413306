import { IImage, NoImage } from './Common';

export interface ICardImageLink {
  name: string;
  mobile: IImage;
  desktop: IImage;
  link: string;
}

export const NoCardImageLink = {
  name: '',
  mobile: NoImage,
  desktop: NoImage,
  link: '',
};
