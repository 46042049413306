import { categoryAndBenefitsProps } from 'domain/configuration';
import { CustomerCategoryName } from 'domain/model';
import { IconName, IconSize } from 'presentation/assets/icons';

const CategoryAndBenefitsMapper = (json: any): categoryAndBenefitsProps[] => {
  const NoCategoryAndBenefitsProps: categoryAndBenefitsProps = {
    type: CustomerCategoryName.Normal,
    name: '',
    icon: 'search' as IconName,
    points: '',
    glosa: '',
    benefitsList: [],
    cta: {
      url: '',
      target: '',
      text: '',
    },
  };

  try {
    return json.map((item: any) => {
      const { type, name, icon, points, gloss, benefitsList, cta } =
        item.fields;

      return {
        type: type as CustomerCategoryName,
        name,
        icon: icon as IconName,
        points: points.toString(),
        glosa: gloss,
        benefitsList: benefitsList.map((benefit: any) => ({
          id: benefit.fields.id,
          icon: benefit.fields.icon.fields.file.url as IconName,
          sizeIcon: benefit.fields.sizeIcon as IconSize,
          text: benefit.fields.text,
          secondText: benefit.fields.secondText,
          description: benefit.fields.description,
          hasIconFcom: benefit.fields.hasIconFcom,
          link: benefit.fields.link
            ? {
                url: benefit.fields.link.url,
                target: benefit.fields.link.target,
                text: benefit.fields.link.text,
              }
            : undefined,
        })),
        cta: cta
          ? {
              url: cta.fields.url,
              target: cta.fields.target,
              text: cta.fields.text,
            }
          : undefined,
      };
    });
  } catch (error) {
    console.warn(
      '[CategoryAndBenefitsMapper] The content json structure received differed from expected',
      error,
    );

    return [NoCategoryAndBenefitsProps];
  }
};

export default CategoryAndBenefitsMapper;
