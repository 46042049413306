import React from 'react';

// Styles
import './Loader.styles.scss';

const Loader = () => {
  return (
    <div className="center-element">
      <div className="d-flex align-items-center">
        <div className="text-center m-auto">
          <div className="bg-loader">
            <div className="loader" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
