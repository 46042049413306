import {
  IExchangeByTranches,
  NoExchangeByTranches,
} from 'domain/model/content';

const ExchangeByTranchesMapper = (json: any): IExchangeByTranches => {
  try {
    const { fields } = json;
    const { title, initialText, fromPoints, upToPoints, link } = fields;

    return {
      title,
      initialText,
      fromPoints,
      upToPoints,
      link,
      isBetween: !initialText, // si no viene definida la variable 'initialText' se asumo tramo 'entre'
    };
  } catch {
    console.warn(
      '[ExchangeByTranchesMapper] The content json structure received differed from expected',
    );

    return NoExchangeByTranches;
  }
};

export default ExchangeByTranchesMapper;
