import { Dispatch, SetStateAction, useCallback } from 'react';
import { useTour } from '@reactour/tour';
import { useContexWalkthrough } from 'infrastructure/providers/Walkthrough';
import useIsMobile from 'infrastructure/hooks/IsMobile';

type useWalkthroughReturnType = {
  openWalkthrough: (currentStep?: number) => void;
  closeWalkthrough: () => void;
  finishWalkthrough: () => void;
  nextStep: (step: number) => void;
  setCurrentStepAuth: () => void;
  openMobileWalkthrough: (currentStep?: number) => void;
  setCurrentStep: Dispatch<SetStateAction<number>>;
};

const useWalkthrough = (): useWalkthroughReturnType => {
  const {
    triggerWalkthrough: { type, show },
    setTriggerWalkthrough,
  } = useContexWalkthrough();

  const { isOpen, setIsOpen, setCurrentStep } = useTour();

  const isMobile = useIsMobile();

  const openWalkthrough = useCallback(
    (currentStep?: number) => {
      if (type && !show) {
        setCurrentStep(currentStep || 0);
        setIsOpen(true);
        setTriggerWalkthrough(prev => ({ ...prev, show: true }));
      }
    },
    [show, type, setCurrentStep, setIsOpen, setTriggerWalkthrough],
  );

  const openMobileWalkthrough = useCallback(
    (currentStep?: number) => {
      setCurrentStep(currentStep || 0);
      setIsOpen(true);
    },
    [setCurrentStep, setIsOpen],
  );

  const closeWalkthrough = () => {
    isOpen && setIsOpen(false);
  };

  const finishWalkthrough = () => {
    if (!isMobile) {
      isOpen && setIsOpen(false);
      return;
    }

    if (isMobile) {
      isOpen && setIsOpen(false);
      setTriggerWalkthrough({ type: null, show: false });
    }
  };

  const nextStep = (step: number) => {
    if (!isMobile) {
      isOpen && setCurrentStep((prevState: number) => prevState + step);
      return;
    }

    if (isMobile) {
      setCurrentStep((prevState: number) => prevState + step);
      setIsOpen(true);
    }
  };

  const setCurrentStepAuth = () => {
    if (!isMobile) {
      isOpen && setCurrentStep(1);
      return;
    }

    if (isMobile && show) {
      setCurrentStep(1);
      setIsOpen(true);
    }
  };

  return {
    openWalkthrough,
    closeWalkthrough,
    finishWalkthrough,
    nextStep,
    setCurrentStepAuth,
    openMobileWalkthrough,
    setCurrentStep,
  };
};

export default useWalkthrough;
