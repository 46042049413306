import { UiEventMessage } from 'infrastructure/eventBus';

/**
 * Redeem cards
 */

const RedeemBaseEvent: UiEventMessage = {
  subject: '',
  module: 'Seccion_canjea',
  action: 'canjea',
  target: '',
};

export const RedeemEvents = {
  selectCard: {
    ...RedeemBaseEvent,
    subject: 'Redeem card selected',
    target: 'canjes destacados',
  },
  open: (target: string) => ({
    ...RedeemBaseEvent,
    subject: 'Exchange experience opened',
    target,
  }),
  openAll: (target: string) => ({
    ...RedeemBaseEvent,
    subject: 'All exchange experiences opened',
    target,
  }),
};

const MODULE_NAME_LANDING_EXCHANGE = 'Landing_canje';

export const RedeemEventsLandingExchangePage = {
  open: (target: string, titleTab: string, exchangeActive: string) =>
    ({
      action: `seleccionar caluga ${titleTab}`,
      module: `${MODULE_NAME_LANDING_EXCHANGE}_${exchangeActive}`,
      subject: 'Exchange experience opened',
      target,
    } as UiEventMessage),
  openAll: (target: string, titleTab: string, exchangeActive: string) =>
    ({
      action: `seleccionar caluga ${titleTab}`,
      module: `${MODULE_NAME_LANDING_EXCHANGE}_${exchangeActive}`,
      subject: 'All exchange experiences opened',
      target,
    } as UiEventMessage),
};

/**
 * Obtiene el evento para una card con respecto al cardId
 *
 * @param {string} cardId - Card id
 * @returns {UiEventMessage} Event object definition for the selected tab
 */
export function getSelectTabEvent(cardId: string): UiEventMessage | undefined {
  if (cardId === 'novedades en canje') return RedeemEvents.selectCard;
}
