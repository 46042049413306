import contentful from 'infrastructure/services/contentFul';

// Mappers
import {
  TabMapper,
  ContentSectionMapper,
  ExchangeCardsGroupMapper,
  CardsGroupMapper,
} from '../mappers';

// Service
const {
  entriesResource: { getEntry },
} = contentful;

// Vars
const ExchangesEntryId = process.env.REACT_APP_CONTENTFUL_DATA_EXCHANGES;

const LoadsExchanges = async () => {
  try {
    const data = await getEntry<any>(ExchangesEntryId, {
      'sys.id': ExchangesEntryId,
      include: 10,
    });

    return {
      productsExchangeTab: TabMapper(
        data.fields?.productsExchangeTab,
        (json: any) => ContentSectionMapper(json, ExchangeCardsGroupMapper),
      ),
      couponsExchangeTab: TabMapper(
        data.fields?.couponsExchangeTab,
        (json: any) => ContentSectionMapper(json, ExchangeCardsGroupMapper),
      ),
      giftcardsExchangeTab: TabMapper(
        data.fields?.giftcardsExchangeTab,
        (json: any) => ContentSectionMapper(json, ExchangeCardsGroupMapper),
      ),
      storesCatalogsSection: ContentSectionMapper(
        data.fields?.storesCatalogsSection,
        CardsGroupMapper,
      ),
      moreCouponsExchangesSection: ContentSectionMapper(
        data.fields?.moreCouponsExchangesSection,
        CardsGroupMapper,
      ),
    };
  } catch (error) {
    console.error(error);
  }
};

export default LoadsExchanges;
