import { useState, useEffect } from 'react';

// Event bus domain
import {
  IEventMessage,
  SubscriptionHandler,
  Unsubscribe,
} from 'domain/eventBus';

import EventBus from './EventBus';
import { EventTopicEnum } from './Events.def';

const useEventBus = <T extends IEventMessage>(topic: EventTopicEnum) => {
  const [unsubscribe, setUnsubscribe] = useState<Unsubscribe>(() => {});

  const publish = (event: T) => {
    EventBus.publish(topic, event);
  };

  const subscribe = (handler: SubscriptionHandler<T>) => {
    const unsub = EventBus.subscribe(topic, handler);
    setUnsubscribe(() => unsub);
  };

  // Set unsubscribe behavior when unmount
  useEffect(() => {
    return unsubscribe;
  }, [unsubscribe]);

  return {
    publish,
    subscribe,
  };
};

export default useEventBus;
