import { IImageResponsive, ILink, NoImageResponsive } from './Common';

export interface ILandingImage {
  title?: string;
  image?: IImageResponsive;
  link?: ILink;
}

export const NoLandigImage: ILandingImage = {
  title: '',
  image: NoImageResponsive,
};
