import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { TourProvider } from '@reactour/tour';

import { useConfiguration } from 'infrastructure/providers/Configuration';

// Components
import PopoverContent from './components/PopoverContent';

// Defs
import { TourGuidesProps, Walkthrough } from './Walkthrough.defs';
import WalkthroughConfig from './WalkthroughConfig';

// Styles
import { doArrow, POPOVER_STYLES } from './Walkthrough.styles';

const WalkthroughContext =
  createContext<
    | {
        triggerWalkthrough: Walkthrough;
        setTriggerWalkthrough: Dispatch<SetStateAction<Walkthrough>>;
      }
    | undefined
  >(undefined);

const WalkthroughProvider = ({ children }: TourGuidesProps) => {
  const {
    configuration: {
      features: {
        ui: {
          walkthrough: { showFab: showFabConfig },
        },
      },
    },
  } = useConfiguration();
  const [showFab, setShowFab] = useState<boolean>(false);
  const [triggerWalkthrough, setTriggerWalkthrough] = useState<Walkthrough>({
    show: false,
    type: null,
  });

  const lockScroll = useCallback(() => {
    setTimeout(() => {
      document.body.style.overflow = 'hidden';
    }, 600);
  }, []);

  const unlockScroll = useCallback(() => {
    document.body.style.overflow = '';
  }, []);

  const beforeCloseTour = () => {
    setShowFab(true);
    unlockScroll();
  };

  const afterOpenTour = () => {
    lockScroll();
  };

  const value = useMemo(
    () => ({
      triggerWalkthrough,
      setTriggerWalkthrough,
    }),
    [triggerWalkthrough],
  );

  return (
    <WalkthroughContext.Provider value={value}>
      <TourProvider
        steps={[]}
        disableFocusLock
        disableInteraction
        disableDotsNavigation
        showBadge={false}
        showCloseButton={false}
        showPrevNextButtons={false}
        showNavigation={false}
        afterOpen={afterOpenTour}
        beforeClose={beforeCloseTour}
        ContentComponent={PopoverContent}
        padding={{ popover: 13 }}
        styles={{
          popover: (base, state) => ({
            ...base,
            ...POPOVER_STYLES,
            ...doArrow(
              state?.position,
              state?.verticalAlign,
              state?.horizontalAlign,
            ),
          }),
          maskArea: base => ({ ...base, rx: 20 }),
        }}>
        {value.triggerWalkthrough.show && value.triggerWalkthrough.type && (
          <WalkthroughConfig
            type={value.triggerWalkthrough.type}
            showFab={showFab && showFabConfig}
            setShowFab={setShowFab}
            setShowWalkthrough={setTriggerWalkthrough}
          />
        )}
        {children}
      </TourProvider>
    </WalkthroughContext.Provider>
  );
};

export const useContexWalkthrough = () => {
  const context = useContext(WalkthroughContext);

  if (context === undefined)
    throw new Error(
      'useContexWalkthrough must be used within a WalkthroughProvider',
    );

  return context;
};

export default WalkthroughProvider;
