import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { ILegalText, NoLegalText } from 'domain/model/content/Coupon';

const CouponLegalMapper = (content: any): ILegalText => {
  try {
    return documentToHtmlString(content);
  } catch {
    console.warn(
      '[CouponLegalMapper] The content json structure received differed from expected',
    );

    return NoLegalText;
  }
};

export default CouponLegalMapper;
