import * as React from 'react';
import { useRef, useEffect, CSSProperties, ReactNode } from 'react';
import ReactDOM from 'react-dom';

// Hook
import useIsMobile from 'infrastructure/hooks/IsMobile';
import useWindowSize from 'infrastructure/hooks/WindowResize';

// Components
import { withDelayUnmounting } from 'presentation/hoc';
import AppBar, { IconConfig } from '../AppBar';

// Utils
import useModalContext from './ModalContext';
import useModalManager from './ModalManager';

// Styles
import { Background, Container, Content } from './Modal.styles';

// Definitions
type ChildrenFunc = (props: { onClose: () => void }) => ReactNode;

export type Props = {
  id?: string;
  open: boolean;
  title?: string;
  height?: string;
  width?: string;
  leftIcon?: IconConfig['icon'];
  rightIcon?: IconConfig['icon'];
  requireCloseAction?: ('leftIcon' | 'rightIcon')[];
  closeOnBackgroundClick?: boolean;
  appBarConfig?: { padding?: string };
  style?: CSSProperties;
  contentStyle?: CSSProperties;
  children: ChildrenFunc | ReactNode;
  testId?: string;
  onClose: (event?: 'shadow' | 'button') => void;
  onClickLeftIcon?: () => void;
  onClickRightIcon?: () => void;
  appPosition?: 'absolute' | 'normal';
  offsetTop?: number;
};

const Modal = (props: Props) => {
  // Props
  const {
    id,
    open = false,
    title = '',
    width = '600px',
    height,
    leftIcon = undefined,
    rightIcon = undefined,
    requireCloseAction = [],
    closeOnBackgroundClick = false,
    style,
    contentStyle,
    appBarConfig = { padding: '0 1rem 0' },
    onClickRightIcon = () => {},
    onClickLeftIcon = () => {},
    onClose = (event?: 'shadow' | 'button') => {},
    children,
    appPosition = 'normal',
    offsetTop = 0,
  } = props;

  // Hooks
  const isMobile = useIsMobile();
  const { height: heightScreen } = useWindowSize();
  const { modalContext } = useModalContext();
  const { index } = useModalManager();

  const domBody = useRef<HTMLElement>();

  // Vars
  const variant = 'dark';
  const type = 'button';
  const testId = id && `${id}-modal`;
  const withBar = !!(title || leftIcon || rightIcon);
  const isInIframe = window.self !== window.top;

  // Methods
  const methods = {
    on: {
      clickLeftIcon: () => {
        if (leftIcon) {
          if (requireCloseAction.includes('leftIcon')) {
            methods.on.handleClose(onClickLeftIcon);
          } else onClickLeftIcon();
        }
      },
      clickRightIcon: () => {
        if (rightIcon) {
          if (requireCloseAction.includes('rightIcon')) {
            methods.on.handleClose(onClickRightIcon);
          } else onClickRightIcon();
        }
      },
      handleBackgroundClick: () => {
        if (closeOnBackgroundClick) {
          onClose('shadow');
        }
      },
      handleClose: (callback?: () => void) => {
        if (callback) callback();

        onClose();
      },
    },
  };

  // On load
  useEffect(() => {
    domBody.current = document.body;

    return () => {
      domBody.current?.classList.remove('no-scroll');
    };
  }, []);

  // On open change
  useEffect(() => {
    if (open) {
      domBody.current?.classList.add('no-scroll');
      if (isInIframe) {
        domBody.current?.classList.add('in-iframe');
      }
    } else {
      domBody.current?.classList.remove('no-scroll');
    }
  }, [open, isInIframe]);

  return ReactDOM.createPortal(
    <>
      <Background index={index} onClick={methods.on.handleBackgroundClick} />
      <Container
        id="login-content"
        data-testid={testId}
        role="dialog"
        index={index}
        open={open}
        width={width}
        height={heightScreen && heightScreen > 812 ? 'auto' : height}
        style={style}
        offsetTop={Math.max(offsetTop, modalContext.offsetTop)}
        fullscreen={isMobile}>
        {withBar && (
          <AppBar
            id="modal-appbar"
            height="64px"
            titleConfig={{ text: title, variant }}
            leftIconConfig={leftIcon && { icon: leftIcon, variant, type }}
            rightIconConfig={rightIcon && { icon: rightIcon, variant, type }}
            onClickLeftIcon={methods.on.clickLeftIcon}
            onClickRightIcon={methods.on.clickRightIcon}
            padding={appBarConfig.padding}
            appPosition={appPosition}
          />
        )}
        <Content withBar={withBar} style={contentStyle}>
          {/* Allows share modal behavior to children if it needed */}
          {typeof children === 'function'
            ? children({ onClose: methods.on.handleClose })
            : children}
        </Content>
      </Container>
    </>,
    document.body,
  );
};

export default withDelayUnmounting<Props>(Modal);
