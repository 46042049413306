import { IImageResponsive, NoImageResponsive } from 'domain/model/content';
import ImageMapper from './ImageMapper';

const ImageResponsiveMapper = (json: any): IImageResponsive => {
  try {
    const { fields } = json;
    const { name, mobile, desktop, notOptimize } = fields;

    return {
      name,
      mobile: ImageMapper(mobile, !notOptimize),
      desktop: ImageMapper(desktop, !notOptimize),
    };
  } catch {
    console.warn(
      '[ImageResponsiveMapper] The content json structure received differed from expected',
    );

    return NoImageResponsive;
  }
};

export default ImageResponsiveMapper;
