import { SHA256 } from 'crypto-js';

// Definitions
type Element = string | object;

type DocumentTypes = {
  CL: {
    [id: number]: string;
  };
  PE: {
    [id: number]: string;
  };
  CO: {
    [id: number]: string;
  };
};

const DOCUMENT_TYPES: DocumentTypes = {
  CL: {
    1: 'RUT',
  },
  PE: {
    1: 'DNI',
    4: 'CE',
  },
  CO: {
    1: 'CC',
    3: 'CE',
  },
};

const COUNTRY =
  process.env.REACT_APP_COUNTRY?.toUpperCase() as keyof typeof DOCUMENT_TYPES;

const Hash = {
  sha256: (element: Element) => {
    if (typeof element === 'string') return SHA256(element).toString();
    if (typeof element === 'object')
      return SHA256(JSON.stringify(element)).toString();
    throw new Error('Element to hash is not object or string');
  },
  getSha256UserId(documentType: string, documentNumber: string) {
    const DEFAULT_VALUE = 'DEFAULT_VALUE';
    const documentTypeToNumber = Number(documentType);

    let codeDocumentType =
      DOCUMENT_TYPES[COUNTRY][documentTypeToNumber] || null;

    if (!codeDocumentType) {
      codeDocumentType = DEFAULT_VALUE;
    }

    const documentEncrypt = Hash.sha256(documentNumber);
    const valueFormat = `${COUNTRY}_${codeDocumentType}_${documentEncrypt}`;
    const encryptValue = Hash.sha256(valueFormat);

    return encryptValue;
  },
};

export default Hash;
