import { ICard, NoCard } from 'domain/model/content';
import ImageResponsiveMapper from './ImageResponsiveMapper';
import LinkMapper from './LinkMapper';
import TextResponsiveMapper from './TextResponsiveMapper';

const CardMapper = (json: any): ICard => {
  try {
    const { fields } = json;
    const { image, description, name, title, link, id, color } = fields;

    return {
      image: ImageResponsiveMapper(image),
      title: title && TextResponsiveMapper(title),
      description,
      name,
      link: link && LinkMapper(link),
      id,
      color,
    };
  } catch {
    console.warn(
      '[CardMapper] The content json structure received differed from expected',
    );

    return NoCard;
  }
};

export default CardMapper;
