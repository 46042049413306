// Components
import Spinner from 'presentation/components/atoms/Spinner';
import Styled from './LoadingPdf.style';

const Loading = () => {
  return (
    <Styled.LoadingWrapper data-testid="loading-pdf">
      <Spinner variant="normal" />
    </Styled.LoadingWrapper>
  );
};

export default Loading;
