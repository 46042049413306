import styled from 'styled-components';

const Styled = {
  LoadingWrapper: styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 82vh;
    margin: 24px auto;
    text-align: center;
    background-color: white;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);

    @media ${({ theme }) => theme.devices.desktop} {
      width: 67%;
      height: 86vh;
    }
  `,
};

export default Styled;
