import * as React from 'react';
import { Route } from 'react-router-dom';

type Props = {
  hash: string;
  component: React.FC<{ open: boolean; onClose: () => void }>;
};

const HashRoute = (props: Props) => {
  const { hash, component: Component } = props;

  return (
    <Route
      render={({ location, history }) => {
        return (
          <Component
            open={location.hash === `#${hash}`}
            onClose={() => {
              history.replace(location.pathname);
            }}
          />
        );
      }}
    />
  );
};

export default HashRoute;
