import { memo } from 'react';

import useIsMobile from 'infrastructure/hooks/IsMobile';

// Styles
import * as Styled from './PopoverContent.styles';

type RenderDotsProps = {
  currentStep: number;
  steps: any[];
};

type RightButtonProps = {
  isLastStep: boolean;
  setCurrentStep: any;
  setIsOpen: any;
};

const NumberSteps = ({ currentStep, steps }: RenderDotsProps) => {
  return (
    <Styled.WrapperNumberSteps>
      {steps.length > 0 ? <>{`${currentStep} de ${steps.length - 1}`}</> : null}
    </Styled.WrapperNumberSteps>
  );
};

const RightButton = ({
  isLastStep,
  setCurrentStep,
  setIsOpen,
}: RightButtonProps) => {
  const isMobile = useIsMobile();

  const handleClickButton = () => {
    isLastStep && setIsOpen(false);
    !isLastStep && setCurrentStep((prevState: number) => prevState + 1);
  };
  return (
    <Styled.ButtonNext
      onClick={handleClickButton}
      size={isMobile ? 'S' : 'M'}
      rounded
      variant={isLastStep ? 'primary' : 'tertiary'}>
      {isLastStep ? 'Entendido' : 'Siguiente'}
    </Styled.ButtonNext>
  );
};

const PopoverContent = ({
  currentStep,
  steps,
  setIsOpen,
  setCurrentStep,
}: any) => {
  const { content, stepInteraction, navDotAriaLabel } = steps[currentStep];
  const showActions = currentStep > 0 && !stepInteraction && !navDotAriaLabel;
  const isLastStep = currentStep === steps.length - 1;

  return (
    <Styled.Container>
      <Styled.ContainerContent>
        <Styled.Text>{content}</Styled.Text>
        {showActions && (
          <Styled.ContainerActions>
            <NumberSteps steps={steps} currentStep={currentStep} />
            <RightButton
              isLastStep={isLastStep}
              setCurrentStep={setCurrentStep}
              setIsOpen={setIsOpen}
            />
          </Styled.ContainerActions>
        )}
      </Styled.ContainerContent>
    </Styled.Container>
  );
};

export default memo(PopoverContent);
