import { createState, useState } from '@hookstate/core';

// Domain model
import { IMovement } from 'domain/model/movement';

// Services
import movements from 'infrastructure/services/loyalty/resources/movements';

import retry from 'infrastructure/utils/retry';

type StoreState = {
  transactions: { [isoDate: string]: IMovement[][] };
  nextPageDate: { [isoDate: string]: string };
  endPageDate: { [isoDate: string]: number };
  nexPageRefBack?: string;
  previousPageRefBack?: string;
};

const currentDateKey = new Date().toISOString().substring(0, 7);

const defaultState = {
  transactions: {
    [currentDateKey]: [],
  },
  nextPageDate: {
    [currentDateKey]: '0',
  },
  nexPageRefBack: '',
  previousPageRefBack: '',
  endPageDate: {
    [currentDateKey]: 0,
  },
};

// State
const state = createState<StoreState>(defaultState);

/**
 * Store constructor
 * @constructor
 */
export default function UseMovementsStore() {
  // Service
  const { getTransactions, exportToExcel, exportToPdf } = movements;

  // Current state
  const current = useState(state);

  const initNextPageDate = (date: Date) => {
    current.nextPageDate.merge({
      [date.toISOString().substring(0, 7)]: '0',
    });
  };

  const initTransactionDate = (date: Date) => {
    current.transactions[date.toISOString().substring(0, 7)].merge([]);
  };

  const initEndPageDate = (date: Date) => {
    current.endPageDate.merge({
      [date.toISOString().substring(0, 7)]: 0,
    });
  };

  return {
    loadTransactions: async (
      authToken: string | undefined,
      date: Date,
      page: number,
      nextPageDate: string,
      nextPageRef?: string,
      previousPageRef?: string,
    ) => {
      const key = date.toISOString().substring(0, 7);

      if (!current.transactions[key].get()) {
        initNextPageDate(date);
        initTransactionDate(date);
        initEndPageDate(date);
      }

      // const existPage = current.transactions[key][page - 1].get();

      // if (existPage) return;

      const options = {
        args: [authToken, date, nextPageDate, nextPageRef, previousPageRef],
        attemps: 3,
      };

      const {
        data: {
          data: {
            transactions,
            nextPage,
            nextPageRef: nexPageRefBack,
            previousPageRef: previousPageRefBack,
          },
        },
      } = await retry(getTransactions, options);

      current.nexPageRefBack.set(nexPageRefBack);
      current.previousPageRefBack.set(previousPageRefBack);

      if (nextPage === '') {
        current.endPageDate.merge({
          [date.toISOString().substring(0, 7)]: page,
        });
      }

      current.nextPageDate.merge({
        [date.toISOString().substring(0, 7)]: nextPage,
      });

      current.transactions[date.toISOString().substring(0, 7)].set([
        transactions,
      ]);
    },
    exportToExcel: async (
      authToken: string | undefined,
      transactions: IMovement[],
    ) => {
      const options = {
        args: [authToken, transactions],
        attemps: 3,
      };

      const { data } = await retry(exportToExcel, options);

      return data as Blob;
    },
    exportToPdf: async (
      authToken: string | undefined,
      transactions: IMovement[],
    ) => {
      const options = {
        args: [authToken, transactions],
        attemps: 3,
      };

      const { data } = await retry(exportToPdf, options);

      return data;
    },
    reset: () => {
      current.set({
        transactions: {
          [currentDateKey]: [],
        },
        nextPageDate: {
          [currentDateKey]: '0',
        },
        nexPageRefBack: '',
        previousPageRefBack: '',
        endPageDate: {
          [currentDateKey]: 0,
        },
      });
    },
    get transactions() {
      return current.transactions.get();
    },
    get nextPageDate() {
      return current.nextPageDate.get();
    },
    get endPageDate() {
      return current.endPageDate.get();
    },
    get nexPageRefBack() {
      return current.nexPageRefBack.get();
    },
    get previousPageRefBack() {
      return current.previousPageRefBack.get();
    },
  };
}
