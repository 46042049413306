import React from 'react';

// defs
import { CouponListProps } from './CouponList.def';

// Components
import CarouselCoupons from '../CarouselCoupons';
import Coupon from '../Coupon';

// Styled
import CouponListContainer from './CouponList.styled';

const CouponList = (props: CouponListProps) => {
  const {
    id,
    isCountry,
    coupons,
    itemsToShow,
    showCarousel,
    onClick = () => {},
  } = props;

  return (
    <>
      {showCarousel ? (
        <CouponListContainer couponCounter={coupons.length}>
          {coupons.map((item: any, index: number) => {
            if (Object.keys(item).length === 0) {
              return null;
            }

            return (
              <Coupon
                // eslint-disable-next-line react/no-array-index-key
                key={`coupon-${index * 2.3}`}
                isCountry={isCountry}
                code={item.code ?? ''}
                title={item.title}
                icon={item.icon}
                color={item.color}
                detail={item.detail}
                expiration={item.expiration}
                logo={item.logo}
                logoCommerce={item.logoCommerce}
                link={item.link?.fields}
                onClick={onClick}
              />
            );
          })}
        </CouponListContainer>
      ) : (
        <CarouselCoupons
          id={id}
          itemsToShow={itemsToShow}
          spaceBetween={16}
          speed={300}>
          {coupons.map((item: any, index: number) => {
            if (Object.keys(item).length === 0) {
              return null;
            }

            return (
              <Coupon
                // eslint-disable-next-line react/no-array-index-key
                key={`coupon-${index}`}
                isCountry={isCountry}
                code={item.code ?? ''}
                title={item.title}
                icon={item.icon}
                color={item.color}
                detail={item.detail}
                expiration={item.expiration}
                logo={item.logo}
                logoCommerce={item.logoCommerce}
                link={item.link?.fields}
                onClick={onClick}
              />
            );
          })}
        </CarouselCoupons>
      )}
    </>
  );
};

export default React.memo(CouponList);
