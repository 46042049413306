import { ILink, NoLink } from './Common';

export interface ILandingVideo {
  title: string;
  link: ILink;
  width?: Number;
  height?: Number;
}

export const NoLandigVideo: ILandingVideo = {
  title: '',
  link: NoLink,
  width: 640,
  height: 480,
};
