import {
  IImageResponsive,
  ILink,
  ITextResponsive,
  NoImageResponsive,
  NoTextResponsive,
} from './Common';

export interface ICard {
  name: string;
  title?: ITextResponsive;
  description: string;
  image: IImageResponsive;
  link?: ILink;
  id?: string;
  color?: string;
}

export const NoCard: ICard = {
  name: '',
  title: NoTextResponsive,
  description: '',
  image: NoImageResponsive,
};

export interface ICardsGroup {
  name: string;
  cards: ICard[];
  description?: string;
  visible?: boolean;
}

export const NoCardsGroup = {
  name: '',
  cards: [],
};
