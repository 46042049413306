import React, { useEffect, createContext, useContext } from 'react';

// Providers
import { useConfiguration } from 'infrastructure/providers/Configuration';

// Utils
import Analytics from 'infrastructure/utils/analytics';

// Event bus
import {
  useEventBus,
  EventTopicEnum,
  UiEventMessage,
} from 'infrastructure/eventBus';

// Flows
import Flow, {
  AccountMenuAnalyticsMarks,
  LoginAnalyticsMarks,
  PasswordAnalyticsMarks,
  SubscribeAnalyticsMarks,
} from './Flow';

// Definitions
export type AnalyticsProviderValue = {
  common: {
    setPageLoad: (pathname: string) => void;
  };
  accountMenu: AccountMenuAnalyticsMarks;
  login: LoginAnalyticsMarks;
  password: PasswordAnalyticsMarks;
  subscribe: SubscribeAnalyticsMarks;
};

type ProviderProp = {
  children: any;
  mock?: AnalyticsProviderValue;
};

// Default value
export const Defaults: AnalyticsProviderValue = {
  common: {
    setPageLoad: (pathname = '') => {},
  },
  accountMenu: Flow.accountMenu,
  subscribe: Flow.subscribe,
  login: Flow.login,
  password: Flow.password,
};

const Provider = (): AnalyticsProviderValue => {
  const { configuration } = useConfiguration();

  // Utils
  const { Load, setPage, setEventUI } = Analytics;

  // Event bus
  const { subscribe } = useEventBus<UiEventMessage>(EventTopicEnum.UI);

  // Methods
  const getCategory = (module?: string) => {
    const { client, platform } = configuration;
    let category = '';

    if (client.toLowerCase() === 'linio') {
      category = `SSO-WL-${platform}`;
    } else {
      category = `${client}-${platform}`;
    }

    if (module) category = `${category} / ${module}`;
    return category;
  };

  const methods = {
    ...Defaults,
    common: {
      setPageLoad: (pathname = '') => setPage(pathname),
    },
  };

  // On load
  useEffect(() => {
    Load();

    // Subscribe UI events channel
    subscribe(({ module, action, target }) => {
      const category = getCategory(module);
      setEventUI(category, action, target);
    });
  }, []);

  return methods;
};

const Context = createContext(Defaults);

/**
 * Component provider
 * @param children
 * @param mock
 * @return {*}
 * @constructor
 */
const AnalyticsProvider = ({ children, mock }: ProviderProp) => {
  const value = mock ?? Provider();

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

/**
 * Component hook
 */
const useAnalytics = () => useContext(Context);

export { AnalyticsProvider, useAnalytics };
