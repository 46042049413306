// Domain configuration
import Configuration from 'domain/configuration/form/login';

// Components
import Input from 'presentation/components/atoms/Input';
import Select from 'presentation/components/atoms/Select';
import ActionText from 'presentation/components/atoms/ActionText';
import SmartLink from 'presentation/components/organisms/SmartLink';
import { Strong, Paragraph } from 'presentation/components/atoms/Text';
import SeparatorLogos from 'presentation/components/organisms/Form/SeparatorLogos';

// Definitions
import { ValidatorFunc } from 'domain/validations';
import {
  FormSeparator,
  FormSelect,
  FormInput,
  FormText,
} from 'domain/configuration/form/Form.defs';
import { InputProps, SelectProps } from 'domain/configuration/loginForm';

// Form configuration
const { getComponent, getText } = Configuration('unified').methods;

const Utils = {
  hasDocumentType: (id: string) => {
    return !!getComponent(id);
  },
  render: {
    separator: (id: string) => {
      const separator = getComponent(id) as FormSeparator;
      if (!separator) return;
      const { icon } = separator;

      return <SeparatorLogos variant="none" iconName={icon} />;
    },
    label: (id: string) => {
      const input = getComponent(id) as FormInput;
      if (!input) return null;
      const { label } = input;
      return label;
    },
    input: (props: InputProps) => {
      const {
        id,
        visible = true,
        disabled,
        onChange,
        autoComplete,
        autoFocus,
      } = props;
      const input = getComponent(id) as FormInput;

      if (!(input && visible)) return null;

      const {
        placeholder,
        type,
        mode,
        disabled: disabledByConfig,
        maxLength,
        minLength,
        validator,
        formatter,
      } = input;

      return (
        <Input
          key={id}
          name={id}
          testId={id}
          placeholder={placeholder}
          type={type}
          mode={mode}
          disabled={disabled || disabledByConfig}
          validator={validator as ValidatorFunc}
          formatter={formatter}
          maxLength={maxLength}
          minLength={minLength}
          onChange={value => onChange(id, value)}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
        />
      );
    },
    select: (props: SelectProps) => {
      const { id, onChange, disabled: isDisabled } = props;
      const select = getComponent(id) as FormSelect;

      if (!select) return null;

      const { options, disabled } = select;
      const defaultValue = options.find(option => option.selected);

      return (
        <Select
          id={id}
          value={defaultValue?.value}
          options={options}
          disabled={disabled || isDisabled}
          onChange={value => onChange(id, value)}
        />
      );
    },
    specificText: (name: 'title' | 'subtitle') => {
      return (
        (getText(name) as FormText[])?.map((line, key: number) => {
          const { text, strong } = line as { text: string; strong: boolean };
          const updated = key ? ` ${text}` : text;

          if (strong) return <Strong key={text}>{updated}</Strong>;

          return updated;
        }) ?? null
      );
    },
    text: (
      id: string,
      callback?: () => void,
      recoveryOnNewPage: boolean = false,
    ) => {
      const component = getComponent(id) as FormText;

      if (!component) return null;

      const texts = component.text as FormText[];

      return texts.map((item, key) => {
        const { text, url, strong, target, testId } = item as {
          text: string;
          url?: string;
          strong?: boolean;
          target?: FormText['target'];
          testId?: string;
        };
        const print = key ? ` ${text}` : text;

        if (url) {
          return (
            <SmartLink
              key={text as string}
              target={recoveryOnNewPage ? 'blank' : target}
              id={testId}
              url={url}
              mode="stock"
              onClick={callback}>
              <ActionText size="large">{print}</ActionText>
            </SmartLink>
          );
        }

        if (strong) return <Strong key={text}>{print}</Strong>;

        return <Paragraph key={text}>{print}</Paragraph>;
      });
    },
  },
};

export default Utils;
