import { UiEventMessage } from 'infrastructure/eventBus';

const MODULE_NAME = 'Footer';

const SOCIAL_EVENT: UiEventMessage = {
  action: 'outbound link',
  module: MODULE_NAME,
  subject: 'Open social network',
  target: '',
};

const LEGAL_EVENT: UiEventMessage = {
  action: 'descarga pdf',
  module: MODULE_NAME,
  subject: 'Open legal information link',
  target: '',
};

const social = (target: string): UiEventMessage => {
  return {
    ...SOCIAL_EVENT,
    target: `social :: ${target}`,
  };
};

const legal = (target = 'link legal por defecto'): UiEventMessage => {
  return {
    ...LEGAL_EVENT,
    target,
  };
};

const events = {
  legal,
  social,
};

export default events;
