import styled from 'styled-components';

const Styled = {
  ListBadges: styled.div`
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    right: 16px;
    top: 16px;
    margin-bottom: 16px;
    gap: 8px;
  `,
};

export default Styled;
