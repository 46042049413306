import { IRichtext, NoRichText } from './RichText';

export interface ITextBox {
  title?: string;
  text?: IRichtext;
}

export const NoTextBox: ITextBox = {
  title: '',
  text: NoRichText,
};
