import styled from 'styled-components';
import { ITextImage } from 'domain/model/content';
import { InfoProps } from '../TextImageBanner/TextImageBanner.def';

// Styles
export const Container = styled.div<ITextImage>`
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  background-color: #3b9326;
  overflow: hidden;
  max-height: 448px;
  > * {
    width: 100%;
  }
  @media ${({ theme }) => theme.devices.desktop} {
    flex-direction: ${props =>
      props.itemsAlign === 'right' ? 'row-reverse' : 'row'};
    > * {
      width: ${props => (props.haveImage ? '50%' : '100%')};
    }
  }
  @media (min-width: 500px) and (max-width: 800px) {
    max-height: 23em;
    flex-direction: row-reverse;
  }
  @media (max-width: 460px) {
    max-height: 550px;
  }
`;

export const InfoWrapper = styled.div<InfoProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #ffffff;
  padding: 7em 2em 7em 9.5em;
  button {
    font-weight: 500;
  }
  @media (max-width: 460px) {
    padding: 1em;
  }
  @media (max-width: 800px) {
    padding: 1.5em;
  }
  @media (max-width: 1024px) {
    padding: 1.5em;
  }
  @media (min-width: 1700px) {
    padding: 0 1em 0 18em;
  }
  @media (min-width: 1800px) {
    padding: 1.5em 2em 1em 24em;
  }
  text-align: ${({ alignText }) => alignText || 'center'};
  align-items: ${({ alignText }) => {
    switch (alignText) {
      case 'left':
        return 'start';
      case 'right':
        return 'end';
      default:
        return 'center';
    }
  }};
  h2 {
    color: #ffffff;
    margin-bottom: 20px;
    font-size: 40px;
    font-weight: 400;
    @media ${({ theme }) => theme.devices.desktop} {
      font-size: 40px;
    }
    @media (max-width: 800px) {
      font-size: 32px;
    }
    @media (max-width: 360px) {
      font-size: 27px;
    }
  }
`;

export const TextWrapper = styled.div`
  margin-bottom: 30px;
  font-size: 22px;
  line-height: 21px;
  font-weight: 500;
  @media ${({ theme }) => theme.devices.desktop} {
    font-size: 22px;
    line-height: 32px;
    font-weight: 500;
  }
  p {
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 10px;
    font-weight: 500;
    @media (max-width: 800px) {
      font-size: 18px;
      font-weight: 400;
    }
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 50%;
  img {
    max-width: 100%;
  }
  @media (max-width: 499px) {
    max-height: 250px;
    overflow: hidden;
  }
  @media (min-width: 500px) and (max-width: 800px) {
    overflow: hidden;
    img {
      max-width: 120%;
    }
  }
`;
