import styled from 'styled-components';

const Styled = {
  WrapperSlider: styled.div`
    width: 100%;
  `,
  WrapperExpirationPoints: styled.div`
    padding: 16px;

    @media ${({ theme }) => theme.devices.desktop} {
      padding: 32px 5% 32px 5%;
    }
  `,
  WrapperChallengeCards: styled.section`
    width: 100%;
    padding: 48px 0px 48px 24px;
    background-color: #f4f7f9;
    height: fit-content;

    @media ${({ theme }) => theme.devices.desktop} {
      padding: 88px 20px 93px;
    }

    @media ${({ theme }) => theme.devices.wideDesktop} {
      padding: 88px 81px 93px;
    }
  `,
  WraperSection: styled.div`
    @media (min-width: 370px) {
      margin: 0 0.5em;
    }
    @media (min-width: 1024px) {
      padding: 0px 11.5%;
    }
    @media (min-width: 1440px) {
      padding: 0px 9.5%;
    }
    @media (min-width: 1700px) {
      padding: 0px 15.5%;
    }
    @media (min-width: 1900px) {
      padding: 0px 19.5%;
    }
  `,
  SectionTitle: styled.h2`
    text-align: center;
    font-weight: 400;
    font-size: 40px;
    color: #44474b;
    margin: 1.5em auto 0.5em;
    @media (max-width: 460px) {
      margin: 1.5em 0.5em 0.5em;
      font-size: 32px;
    }
  `,
};

export default Styled;
