import { opositeSideProps } from './Walkthrough.defs';

const opositeSide: opositeSideProps = {
  top: 'bottom',
  bottom: 'top',
  right: 'left',
  left: 'right',
};

export const POPOVER_STYLES = {
  display: 'flex',
  borderRadius: 10,
  padding: '1rem',
};

export const doArrow = (
  position: any,
  verticalAlign: any,
  horizontalAlign: any,
) => {
  if (!position) {
    return {};
  }

  if (position === 'custom') {
    return {
      '&::after': {
        content: "''",
        width: 10,
        height: 0,
        position: 'absolute',
        borderLeft: '8px solid transparent',
        borderRight: '8px solid transparent',
        borderTop: '12px solid white',
        bottom: -10,
        left: 22,
      },
    };
  }

  const width = 16;
  const height = 12;
  const color = 'white';
  const isVertical = position === 'top' || position === 'bottom';
  // const spaceFromSide = 60;
  const obj = {
    [isVertical ? 'borderLeft' : 'borderTop']: `${
      width / 2
    }px solid transparent`, // CSS Triangle width
    [isVertical ? 'borderRight' : 'borderBottom']: `${
      width / 2
    }px solid transparent`, // CSS Triangle width
    [`border${position[0].toUpperCase()}${position.substring(
      1,
    )}`]: `${height}px solid ${color}`, // CSS Triangle height
    [isVertical
      ? opositeSide[horizontalAlign as keyof opositeSideProps]
      : verticalAlign]: 'calc(50% - 1rem)', // space from side
    [opositeSide[position as keyof opositeSideProps]]: -height + 2,
  };

  return {
    '&::after': {
      content: "''",
      width: 10,
      height: 0,
      position: 'absolute',
      ...obj,
    },
  };
};
