const getfindurl: string = process.env.REACT_APP_FIND_URL_SSO || 'tienda.';
const getHostUrl: string = process.env.REACT_APP_GET_HOST_URL || '';
const country = process.env.REACT_APP_COUNTRY || '';

function getUrlStoreIncludes() {
  const payload = document.cookie
    .match('(^|;)\\s*payload\\s*=\\s*([^;]+)')
    ?.pop();

  return payload;
}

const getLinkRedirectSSO = (endUrl: string): string => {
  const urlStore = decodeURI(endUrl);
  const hostSSO =
    getHostUrl ||
    `${window.location.href}sso?integratorID=kK2dmLJQ5sJ9Q5kO2fhf0fkva1nWH4xR`;

  if (urlStore.includes(getfindurl)) {
    const payload = getUrlStoreIncludes();
    const pathBase = urlStore.split('?')[0];
    const urlParams = urlStore.split('?')[1];

    let urlCallbackSSO;
    const redirectPath = pathBase.split(`.${country.toLowerCase()}/`)[1];
    if (urlParams) {
      urlCallbackSSO = redirectPath
        ? `${hostSSO}payload=${payload}&url_callback=https%3A%2F%2F${getfindurl}%2Faccount%2Flogin_check&${
            urlStore.split('?')[1]
          }&redirectPath=/${redirectPath}`
        : `${hostSSO}payload=${payload}&url_callback=https%3A%2F%2F${getfindurl}%2Faccount%2Flogin_check&${
            urlStore.split('?')[1]
          }`;
    } else {
      urlCallbackSSO = redirectPath
        ? `${hostSSO}payload=${payload}&url_callback=https%3A%2F%2F${getfindurl}%2Faccount%2Flogin_check&redirectPath=/${redirectPath}`
        : `${hostSSO}payload=${payload}&url_callback=https%3A%2F%2F${getfindurl}%2Faccount%2Flogin_check`;
    }

    return urlCallbackSSO;
  }

  return endUrl;
};

export default getLinkRedirectSSO;
