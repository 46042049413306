import { ILink, NoLink } from 'domain/model/content';

const LinkMapper = (json: any): ILink => {
  try {
    const { fields } = json;
    const { url, text, external } = fields;

    return { url, text, external };
  } catch {
    console.warn(
      '[LinkMapper] The content json structure received differed from expected',
    );

    return NoLink;
  }
};

export default LinkMapper;
