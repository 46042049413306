import { useState } from 'react';
import { IRichtext } from 'domain/model/content';
import Button from 'presentation/components/atoms/Button';
import useIsMobile from 'infrastructure/hooks/IsMobile';
import methods from 'infrastructure/utils/analytics';

import RichText from '../RichText';

// Styles
import {
  Wrapper,
  Gradient,
  Container,
  WrapperButton,
} from './CookiesPolicy.style';

export type Props = {
  data: IRichtext;
  client: string;
};

export default function CookiesPolicy(props: Props) {
  const { data, client } = props;
  const [show, setShow] = useState(true);

  const isMobile = useIsMobile();
  const { userInteraction } = methods;

  const handleClick = () => {
    userInteraction('entendido', client, 'cookiespolicy');
    setShow(false);
  };
  return show ? (
    <Wrapper>
      <Gradient />
      <Container>
        <RichText content={data} />
        <WrapperButton>
          <Button
            variant="tertiary"
            onClick={handleClick}
            isFullWidth
            uppercase={false}
            style={{ marginTop: isMobile ? '20px' : '0px' }}>
            Entendido
          </Button>
        </WrapperButton>
      </Container>
    </Wrapper>
  ) : (
    <></>
  );
}
