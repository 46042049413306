import { AxiosResponse, CancelTokenSource } from 'axios';

// Client
import { httpClient, token } from '../../client';
import { ExtendConfig } from '../../definitions';
import { MyExchangesResponseData } from './myExchanges.defs';

let source: CancelTokenSource;

const Content = {
  getExchanges: (
    fromPeriod: string,
    periods: number,
    authToken: string | undefined,
    isBank: boolean,
  ): Promise<AxiosResponse<MyExchangesResponseData>> => {
    source = token.source();

    const headers = {
      'x-isBank': isBank,
    };

    return httpClient.get<MyExchangesResponseData>('/v1/exchanges', {
      params: {
        fromPeriod,
        periods,
      },
      cancelToken: source.token,
      authToken,
      headers,
    } as ExtendConfig);
  },
};

export default Content;
