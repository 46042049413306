import * as React from 'react';
import { ChangeEvent, useEffect, useState } from 'react';
import styled from 'styled-components';

// Definitions
import { InputType, InputMode } from './Input.defs';

// Definitions
type Validator = (
  value: string,
  options?: any,
) => { valid: boolean; message: string };

type Length = {
  value: number;
  message: string;
};

export type Props = {
  type: InputType;
  mode?: InputMode;
  name?: string;
  placeholder?: string;
  onChange?: (value?: string) => void;
  onError?: (value: string) => void;
  validator?: Validator;
  formatter?: (value: string | number) => typeof value;
  pattern?: string;
  maxLength?: Length;
  minLength?: Length;
  disabled?: boolean;
  value?: string | number;
  errorMessage?: string;
  testId?: string;
  autoComplete?: string;
  autoFocus?: boolean;
};

type RenderProps = Omit<
  Props,
  'onChange' | 'inputMode' | 'minLength' | 'maxLength'
> & {
  inputmode?: InputMode;
  error?: boolean;
  minLength?: number;
  maxLength?: number;
};

const Input = (props: Props) => {
  const {
    type,
    mode,
    name,
    disabled,
    maxLength,
    minLength,
    placeholder,
    pattern,
    testId,
    value: defaultValue = '',
    errorMessage: defaultErrorMessage,
    onChange = (value: string) => {},
    onError = (value: string) => {},
    formatter = (value: string | number) => value,
    validator: externalValidator = (value: string) => ({
      valid: true,
      message: '',
    }),
    autoComplete,
    autoFocus,
  } = props;

  // State
  const [errorMessage, setErrorMessage] = useState('');
  const [currentValue, setCurrentValue] =
    useState<string | number | undefined>(defaultValue);

  // Validators
  const validator = {
    length: (value: string) => {
      const response = { valid: false, message: '' };
      if (minLength && value.length < minLength.value) {
        response.message = minLength.message;
        return response;
      }
      if (maxLength && value.length > maxLength.value) {
        response.message = maxLength.message;
        return response;
      }
      return externalValidator(value);
    },
  };

  // Methods
  const methods = {
    on: {
      change: (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target?.value?.toString().trim();
        const formatted = formatter(value);
        const { valid, message } = validator.length(formatted.toString());
        setCurrentValue(formatted);
        setErrorMessage(message);
        if (valid) {
          onChange(formatted.toString());
        } else {
          onChange('');
          onError(value);
        }
      },
    },
  };

  // If input receive external error message
  useEffect(() => {
    if (defaultErrorMessage) setErrorMessage(defaultErrorMessage);
  }, [defaultErrorMessage]);

  return (
    <>
      <Render
        data-testid={testId}
        type={type}
        error={!!errorMessage}
        name={name}
        inputmode={mode}
        placeholder={placeholder}
        onChange={methods.on.change}
        maxLength={maxLength?.value}
        minLength={minLength?.value}
        disabled={disabled}
        value={currentValue}
        pattern={pattern}
        formNoValidate
        autoComplete={autoComplete}
        autoFocus={autoFocus}
      />

      {errorMessage && (
        <Error data-testid={`${testId}-error`}>{errorMessage}</Error>
      )}
    </>
  );
};

const Error = styled.span`
  display: block;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 1;
  letter-spacing: -0.175px;
  color: var(--color-error);
  width: 100%;
  margin-top: 0.5rem;
  text-align: left;
`;

const Render = styled.input<RenderProps>`
  font-family: 'pfbeausans', arial, serif;
  width: 100%;
  height: 48px;
  border-radius: 6px;
  font-size: 16px;
  text-indent: 16px;
  line-height: 19px;
  letter-spacing: -0.2px;
  color: var(--color-text-primary);
  transition: all 0.3s ease-out;
  ${props => {
    const { error } = props;
    return error
      ? 'border: 1px solid var(--color-error); background-color:var(--color-bg-error); '
      : 'border: 1px solid #CFDAE6;';
  }}

  &:focus {
    background-color: ${props => {
      const { error } = props;
      if (error) return 'var(--color-bg-error);';
      return '#fff;';
    }};

    border-color: ${props => {
      const { error } = props;
      if (error) return 'var(--color-error);';
      return 'var(--color-border-focus);';
    }};
    outline: 0;
    box-shadow: none;
  }

  &::placeholder {
    color: var(--color-text-placeholder);
    letter-spacing: -0.2px;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &[type='password'] {
    letter-spacing: 5px;
  }

  &[type='text'] {
    padding: 0;
  }

  &[type='number'] {
    padding: 0;
  }

  &[type='email'] {
    padding: 0;
  }
`;

export default Input;
