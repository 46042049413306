import { IImage, IImageResponsive, NoImage } from './Common';

export interface AccumulateImages {
  nombre?: string;
  name?: string;
  desktop: IImage;
  mobile: IImage;
}

export const NoAccumulateImages: AccumulateImages = {
  name: '',
  nombre: '',
  desktop: NoImage,
  mobile: NoImage,
};

export type AccumulateImagesGroup = IImageResponsive[];
export const NoAccumulateImagesGroup = [];
