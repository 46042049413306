import WalkthroughSteps from '../walkthroughConfig.defs';

const STEPS: WalkthroughSteps = {
  mainPath: '/movements',
  isPublic: false,
  desktop: [
    {
      selector: '#login-content',
      content: 'Inicia tu sesión de CMR Puntos.',
      highlightedSelectors: ['#login-content'],
      mutationObservables: ['#login-content'],
      resizeObservables: ['#login-content'],
      stepInteraction: true,
    },
    {
      selector: '#user-menu',
      content: 'Revisa que tu saldo sea suficiente para realizar tu redención.',
      resizeObservables: ['#user-menu'],
    },
  ],
  mobile: [
    {
      selector: '#login-content',
      content: 'Inicia tu sesión de CMR Puntos.',
      highlightedSelectors: ['#content-login-steps'],
      mutationObservables: ['#content-login-steps'],
      resizeObservables: ['#content-login-steps'],
    },
    {
      selector: '#user-menu',
      resizeObservables: ['#user-menu', '#user-menu-action-id'],
      content: 'Revisa que tu saldo sea suficiente para realizar tu redención.',
    },
  ],
};

export default STEPS;
