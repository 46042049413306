import LocaleConfig from 'domain/configuration/locale';

const { locale } = LocaleConfig;
const formatter = new Intl.NumberFormat(locale);

const numberFormat = (value: number): string => {
  return formatter.format(value);
};

export default numberFormat;
