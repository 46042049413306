import { useState, useEffect } from 'react';

// Hook
import useIsMobile from 'infrastructure/hooks/IsMobile';

// Definitions
export type ModalContext = {
  parentHeight: number;
  offsetTop: number;
};

const ModalOffsetTop = {
  normal: 50,
  embedded: 25,
};

const DefaultContext: ModalContext = {
  parentHeight: window.innerHeight,
  offsetTop: ModalOffsetTop.normal,
};

/**
 * Recalculate vh unit to handle mobile browsers viewport height issue
 *
 * @param {number} height - Height base for calculating the vh unit
 */
const setCustomVHUnit = (height?: number) => {
  if (height) {
    const vh = height * 0.01;
    document.body.style.setProperty('--vh', `${vh}px`);
  } else {
    document.body.style.removeProperty('--vh');
  }
};

/**
 * Custom hook for handling the context settings where the modal is open. Ej: Main site, Iframe embedded
 */
const useModalContext = () => {
  const [context, setContext] = useState<ModalContext>(DefaultContext);

  // Hooks
  const isMobile = useIsMobile();

  const setModalContext = () => {
    setContext(current => ({ ...current, parentHeight: window.innerHeight }));

    return () => {};
  };

  // On load
  useEffect(() => {
    const clearContext = setModalContext();
    const handleResize = () => setModalContext();

    window.addEventListener('resize', handleResize, false);

    return () => {
      clearContext();
      window.removeEventListener('resize', handleResize, false);
    };
  }, []);

  // On modal context gets updated on mobile
  useEffect(() => {
    if (isMobile) setCustomVHUnit(context.parentHeight);
  }, [isMobile, context]);

  return {
    modalContext: context,
    setModalContext,
    setCustomVHUnit,
  };
};

export default useModalContext;
