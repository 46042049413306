import * as React from 'react';
import { ReactNode } from 'react';
import styled, { css } from 'styled-components';

// Components
import { Paragraph } from 'presentation/components/atoms/Text';
import Title from 'presentation/components/atoms/Title';
import Images from 'presentation/assets/images';
import Separator from 'presentation/components/atoms/Separator';

// Definitions
type FormHeaderOptions = {
  showLogo?: boolean;
  showSeparator?: boolean;
  logoPosition?: 'left' | 'middle';
  alignText?: 'left' | 'center';
};

export type Props = {
  title: string | ReactNode;
  subtitle: string | ReactNode;
  options?: FormHeaderOptions;
};

const Header = (props: Props) => {
  // Props
  const { title, subtitle, options = {} } = props;
  const {
    showLogo = true,
    showSeparator = true,
    logoPosition = 'left',
    alignText = 'left',
  } = options;

  return (
    <>
      <Container options={{ showLogo, logoPosition }}>
        {showLogo && <Img src={Images.logo} alt="cmr-puntos-logo" />}

        <TextWrapper align={alignText}>
          <Title
            level="1"
            size="medium"
            style={{ margin: 0, color: '#323537' }}>
            {title}
          </Title>
          <Paragraph size="large" style={{ color: '#323537' }}>
            {subtitle}
          </Paragraph>
        </TextWrapper>
      </Container>

      {showSeparator && (
        <Separator
          spacing="none"
          style={{ margin: '0 -1.5rem', marginBottom: '32px', height: '1px' }}
        />
      )}
    </>
  );
};

const withMiddleLogoStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;

  img {
    align-self: center;
    margin-bottom: 12px;
  }
`;

const withLeftLogoStyle = css`
  display: flex;
  flex-direction: row;
  justify-content: center;

  img {
    margin-right: 16px;
  }
`;

const Container = styled.div<{ options: FormHeaderOptions }>`
  margin-bottom: 18px;

  ${({ options }) =>
    options.showLogo &&
    options.logoPosition === 'middle' &&
    withMiddleLogoStyle}

  ${({ options }) =>
    options.showLogo && options.logoPosition === 'left' && withLeftLogoStyle}
`;

const TextWrapper = styled.div<{ align: 'left' | 'center' }>`
  text-align: ${({ align }) => align};

  @media (max-width: 320px) {
    text-align: left;

    p {
      font-size: 14px;
    }
  }
`;

const Img = styled.img`
  width: 48px;
  height: 48px;
`;

export default Header;
